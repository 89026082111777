.grid-avances_container{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 80%;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(204, 204, 204, 0.267), 
        -2px 2px 15px rgba(204, 204, 204, 0.267), 2px 2px 15px rgba(204, 204, 204, 0.267);
        margin-top: 30px;
}

.grid-avances{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr 1fr;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    gap: 1rem;
    padding:1rem;
}
.columna-uno span{
    margin: 10px 0px;
    font-size:12px;
    display: block;
}
.button-entregas{
    background: #212156 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 6px #00000029;
    border-radius: 8px;
    padding: 5px;
    font-size:12px;
    color: #fff;
    text-transform: uppercase;
}

.button-entregas:hover{
    color: rgb(255, 178, 35);
    background-color: transparent;
    border-style: solid;
    border-color: rgb(255, 178, 35);
    border-width: 2px;
    font-weight: bold;
}

.columna-uno{
    flex-direction: column;
    display: flex;
    justify-content: center;
    width: 20%;
    margin-top: 40px;
    margin-left: 40px;
    justify-content: flex-start;
}

.entrega-item2:hover{
    background-color: #28157e2d;
}

.columna-dos{
    margin-top: 40px;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    width: 70%;
}

.container_dos_columnas{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.btn_avances_filter{
    background-color: #f5f5dc00;
    margin: 1rem;
    font-weight: bold;
}
.rating_avance{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    
}

.text_entrega_avance_col_uno{
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
}

.entregas-container{
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding:.7rem;
    max-height: 500px;
    overflow: scroll;
}

.entregas-container::-webkit-scrollbar {
    display: none;
}
.avances_clasificacion{
    margin: 0;
    display: flex;
    justify-content: space-around;
    width: 80%;
}
.fondo_avance_popup{
    width: 100%;
    height: 100%;    
}
.perfil__score-form-label2:hover,
.perfil__score-form-label2:hover ~ label {
  color: orange;
}
.perfil__score-form-label2 {
    font-size: 35px;
  }
  input[type="radio"]:checked ~ .perfil__score-form-label2 {
    color: orange;
  }
.entrega-item{
    padding: .5rem 0rem 0.5rem 0rem;
    /* font-size: 14px; */
    border-bottom: 1px solid;
    position: relative;
    border-color: lightgrey;
    display: flex;    
    border: 0.188rem solid #28157e00;
}
.entrega-item2{
    padding: .5rem 0rem 0.5rem 0rem;
    /* font-size: 14px; */
    border-bottom: 1px solid;
    border-color: lightgrey;
    filter: grayscale(1);
    display: flex;
}

.entregas-comentarios{
    max-height: 400px;
    overflow:scroll;
    text-align: start;
    text-transform:capitalize ;
    width: 35%;
    padding-bottom: 20px;
}

.comentarios-item{
    padding: .5rem;
    font-size: 13px;
}

.entregas-container::-webkit-scrollbar {
    -webkit-appearance: none;
}

.entregas-container::-webkit-scrollbar:vertical {
    width:10px;
}

.entregas-container::-webkit-scrollbar-button:increment,.entregas-container::-webkit-scrollbar-button {
    display: none;
} 

.entregas-container::-webkit-scrollbar:horizontal {
    height: 10px;
}

.entregas-container::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.entregas-container::-webkit-scrollbar-track {
    border-radius: 10px;  
}


.entregas-comentarios::-webkit-scrollbar {
    -webkit-appearance: none;
}

.entregas-comentarios::-webkit-scrollbar:vertical {
    width:10px;
}

.entregas-comentarios::-webkit-scrollbar-button:increment,.entregas-comentarios::-webkit-scrollbar-button {
    display: none;
} 

.entregas-comentarios::-webkit-scrollbar:horizontal {
    height: 10px;
}

.entregas-comentarios::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.entregas-comentarios::-webkit-scrollbar-track {
    border-radius: 10px;  
}



@media screen and (max-width:630px){
    .grid-avances{
        width: 100%;
        grid-template-columns: 1fr;
    }

  }

  .comentarios_avance{
      display: flex;
      flex-direction: column;
      justify-content: center;
     
  }

  .comentarios_avance button {
    justify-content: center;
    margin-left: 48%;
    margin-top: 15px;
}

.columna-tres{
    text-align: -webkit-left;
    display: flex;
    flex-direction: column;
}

.columna-tres h3 {
    margin-top: 10px;
    left:0;
    font-weight: 600;
}
/* ////////////////////////////////////////////////////////////////////////////////////////// */
.descarga_avances{
    width: 100%;
    top: 0;
    height: 99%;
    z-index: 1;
    background-color: white;
    position: absolute;
}
.descarga_avances:hover{
    box-shadow: 0px 0px 12px 1px rgb(0 0 0 / 20%);
}
.container_avances{
    width: 100%;
}
.card_avances{
    min-width: 65%;
    display: flex;
    max-width: 80%;
    text-align: start;
    margin: 0;
    font-weight: bold;
    padding: 1rem;
    align-items: center;
}
.btn_aceptar{
    border: none;
    background: white;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    color: #ff5925;
    padding: 0px 10px 0px 10px;
    margin-right: 5px;
}
.btn_aceptar:hover{
    border: 2px solid #ff5925;
}
.btn_rechazar{
    border: none;
    color: #212156;
    background: white;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
}
.btn_rechazar:hover{
    border: 2px solid #212156;
}

.imagen_avance{
    background-image: url("https://coally.com/wp-content/uploads/2021/10/entregaicon-1.png");
    width: 60px;
    height: 100%;
    background-size: cover;
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
}
.imagen_avance_logo{
    width: 65%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 4rem;
}
.imagen_avance_logo2{
    background-image: url("https://coally-images.s3.amazonaws.com/logo+blanco+png+1+(3).png");
    width: 60%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 11rem;
}
.container_img_avance{    
    min-width: 10%;    
    max-width: 10%;   
    padding: 1rem;
    height: 6rem;
}
.botones_avance{
    min-width: 40%;
    max-width: 40%;  
    display: flex  ;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.MuiOutlinedInput-notchedOutline{
    border-color: rgba(252, 252, 252, 0) !important;
}
.metho-details{
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.metho-details-entregas{
    background-color: #f1f4f6;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.metho-details-container-anchor:hover{
    color: blue;
    outline:1px  blue;
}
.metho-details-container-anchor{
    width: fit-content;
}
.metho-details-container-cmy{
    text-align: -webkit-center;
    padding-bottom: 40px;
}
.metho-details-container-cmy h3{
    font-size: 1rem;
    padding: 1rem 0px;
}
.metho-details-btn{
    margin-top: 3rem;
}

.answer-comment-task-company{
    background-color: #707070;
    border-radius: 8px !important;
    border: none;
    font-size: 13px;
    padding: .5rem;
    cursor: pointer;
    box-sizing: border-box;
    color: rgb(226, 225, 225);
    text-decoration: none;
    text-transform: uppercase;
}
.entrega_tittle{
    font-weight: bold;
    text-transform: uppercase;
}

.metho-details-company-answer{
    display: flex;
    background-color: #ffffff;
    width: 100%;
    gap: 1rem;
    margin: 0 auto;
    box-shadow: 0px 2px 10px #00000029;
    padding: 1rem 1rem 1rem 0rem;
    box-sizing: content-box;
    margin-bottom: 1rem;
    height: 20vh;
}

.metho-details-tarea{
    display: flex;
    background-color: #ffffff;
    width: 79%;
    gap: 1rem;
    margin: 0 auto;
    box-shadow: 0px 2px 10px #00000029;
    padding: 1rem 1rem 1rem 0rem;
    box-sizing: content-box;
    margin-bottom: 1rem;
    height: 20vh;
}

.metho-details-coment-span{
    text-align: left;
}

.comment-quotes-coallier{
    text-transform: capitalize;
    color: #5c5c5c;
    text-align: left !important;
    font-size: 14.5px;
    font-weight: 600;
}

.input-text-answer{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 6px;
    opacity: 1;
    text-align: center;
    color: #a7a3a3;
    width: 80%;
    outline-style: none;
    box-sizing: border-box;
    font-size: 13px;
}

.project-name-task-module{
    margin-top: 1em;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 43%;
    font-size: 15px;
}

.etiqueta{
    text-transform: capitalize;
    background-color: #21204B !important;
    color: #ffffff !important;
    padding: 0.5em 1.5em 0.5em 0.5em !important;
    font-size: 12px !important;
    font-weight: 500;
    padding-left: 100px !important;
    padding-right: 100px !important;
    justify-content: left !important;
}

@media screen and (max-width:800px){
    .metho-details-tarea{
        overflow: hidden;
    }
    .perfil_body-content{
        width: 96%;
        text-align: center;
    }
}
.barra_mensaje_dislike{
    background-image: linear-gradient(to right, #f7d1fc, #ff5281, #fe0007, #fe0007, #fe0007);
    
    height: 1rem;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
}
.barra_mensaje_like{
    background-image: linear-gradient(to right, #ffffff, #00ff88, #07fe00, #07fe00, #07fe00);
    height: 1rem;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
}
.mensaje_popup{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #00000073;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 10000;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
}
.container_popup_mensaje{
    transform: scale(1);
    animation: popupmensaje 0.25s alternate  ease-in;
}
.container_popup_mensaje4{
    transform: scale(1);
    animation: popupmensaje 0.25s alternate  ease-in;
}
.animacion_popup{
    transform: scale(1);
    animation: popupmensaje 0.25s alternate  ease-in;
}
.container_popup_mensaje2{
    transform: scale(1);
    /* animation: popupmensaje 0.25s alternate  ease-in; */
}
@keyframes popupmensaje {
    0%   {transform: scale(0);}
    100% {transform: scale(1);}
  }
@keyframes popupmensajeend {
    0%   {transform: scale(1);}
    100% {transform: scale(0);}
  }
.derecha_mensaje_popup{
    width: 100%;
    padding: 5%;
    display: flex;
    background-color: #212156;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.container_popup_mensaje{
    width: 40%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    position: absolute;
    flex-direction: column;
    border-radius: 15px;
}
.container_popup_mensaje4{
    width: 70%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    position: absolute;
    flex-direction: column;
    border-radius: 15px;
}
.container_popup_mensaje3{
    width: 50%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    position: absolute;
    flex-direction: column;
    border-radius: 15px;
    background: #212155;
}
.container_popup_mensaje2{
    width: 70%;
    display: flex;
    align-items: center;
    padding: 1rem;
    position: absolute;
    flex-direction: column;
    border-radius: 15px;
    background: #212155;
}
.container_input_p_mensaje{
    flex-direction: column;
    display: flex;
    margin-top: 5%;
    width: 60%;
}
.cancel_mensaje{   
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    padding: 1.5rem 2rem 0rem 0rem;
    cursor: pointer;
    font-weight: bold;
    color: black;
}
.cancel_mensaje2{
    position: absolute;
    padding: 0.5rem 1rem 0rem 0rem;
    cursor: pointer;
    bottom: 0;
    font-weight: bold;
}
.input_mensaje_popup:focus{
    outline: none;
}
.MuiSvgIcon-root_likes{
    fill: currentColor !important;
    width: 1em !important;
    height: 1em !important;
    display: inline-block !important;
    border: solid !important;
    padding: 4%;
    transform: rotateY( 
180deg
 );
    font-size: 4rem !important;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    flex-shrink: 0 !important;
    -webkit-user-select: none !important;
    user-select: none !important;
    border-radius: 100% !important;
}
.MuiSvgIcon-root_likes2{
    fill: currentColor !important;
    width: 1em !important;
    height: 1em !important;
    display: inline-block !important;
    border: solid !important;
    padding: 4%;
    font-size: 4rem !important;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    flex-shrink: 0 !important;
    user-select: none !important;
    border-radius: 100% !important;
}
.barra_check_popup{
    height: 3px;
    background: green;
    width: 100%;
    margin-right: 5px;
    border-radius: 100%;
}
.barra_descheck_popup{
    height: 3px;
    background: red;
    width: 100%;
    margin-right: 5px;
    border-radius: 100%;
}
.btn_mensaje_dislike{
    position: absolute;
    bottom: -7%;
    background-image: linear-gradient(to bottom, #707070, #3b3b3b, #000000, #000000, #000000);
    color: red;
    border-radius: 5px;
    padding: 1% 10% 1% 10%;
}
.btn_mensaje_like{
    position: absolute;
    bottom: -7%;
    background-image: linear-gradient(to bottom, #707070, #3b3b3b, #000000, #000000, #000000);
    color: green;
    border-radius: 5px;
    padding: 1% 10% 1% 10%;
}
.btn_mensaje{
    color: white;
    background: #F07130;
    margin-top: 10px;    
    padding: 0.3rem 1rem;
    position: absolute;
    bottom: 5%;
    border-radius: 5px;
}
.btn_mensaje3{
    color: white;
    width: 80%;
    background: #212156;
    margin-top: 10px;    
    padding: 4px;
    border-radius: 5px;
}
.btn_mensaje4{
    color: white;
    width: 100%;
    background: #10123c;
    padding: 0.5rem 0.5rem 0.5rem 1rem !important;
    margin-top: 10px;   
    padding: 4px;
    border-radius: 5px;
}
.btn_mensaje4:hover{
    color:#FF925A ;
}
.btn_mensaje5{
    color: white;
    width: 100%;
    background: #212156;
    margin-top: 10px; 
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0.5% 2%;
    border: solid 1px white;   
    padding: 4px;
    border-radius: 5px;
}
.btn_mensaje5:hover{
    color:#212156 ;
    background-color: white;
    border: solid 1px #212156  ;
}
.btn_mensaje2{
    color: #3c4472;
    width: 25%;
    background: white;
    margin-top: 10px;    
    padding: 4px;
    border-radius: 5px;
}
.btn_mensaje_cargando{
    color: #000000;
    width: 80%;
    background: #a5a2a2;
    margin-top: 10px;
    padding: 4px;
    cursor: default !important;
    position: absolute;
    bottom: 5%;
    border-radius: 5px;
}
.btn_mensaje_cargando2{
    color: #000000;
    width: 25%;
    background: #a5a2a2;
    margin-top: 10px;
    padding: 4px;
    cursor: default !important;
    border-radius: 5px;
}
.container_input_p_mensaje2{
    display: flex;
    margin: 0.25rem;
    width: 80%;
}