.header{
    width: 100%;
    padding-right: 10%;
}
/* .idioma22{
        
    text-align: center;
    z-index: 10;
    position: absolute;
    top: 0;
    color: #696a7c;
    z-index: 160;
    cursor: default;
    min-width: 40px;
    border-radius: 10px 10px 10px 10px;
    border-left: solid 2px #fff;
    border-right: solid 2px #fff;
    border-top: solid 2px #fff;
    background-color: #fff;
    margin-top: -0.2rem;

    } */
li, ul, ol {
    list-style: none;
}
.header__icons__group-ul{
    display: flex;
}

.header__icons__group-li{
    float: left;
}

.header__icons__group-li:hover > ul{
    display: inherit;
    margin-right: 80px;
}

.header__icons__group-icon{
    margin-left: 5px;
    height: 28px;
}

.header__display-menu{
    display: none;
    border-radius: 7px;
    border: 1px solid;
    padding: 5px;
    background-color: white;
    position: absolute;
    width: 260px;
    z-index: 100;
    min-width: 170px;
    font-size: 13px;
    margin-left: -220px;
}

.header__display-menu li{
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button-menu-side button{
    font-weight: 500;
    font-size: 15.5px;
    line-height: 0.6em;
    color: #fe7a00;
    background-color: white;
    border-radius: 10px;
    border: solid  #fe7a00;
    padding: 12px 30px 12px 30px;
}

.header__display__menu-item{
    font-size: 13px;
    display:block;
    padding: 5px 20px 5px 40px;
}

.header__display__menu-perfil{
    margin:0  auto;
    display: grid;
    padding: 5px;
    font-size: 13px;
    padding: 5px 20px 5px 40px;
    margin-bottom:10px ;
    grid-template-columns: repeat(2 1fr);
}

.header__display__menu__perfil-img{
    margin: 0 auto;
    border-radius: 40px;
    grid-column: 1;
}

.header__display__menu__perfil-data{
    text-align: center;
    grid-column: 2;
}

.header__display__menu__item-pagos{
    font-size: 13px;
    display:block;
    padding: 5px 20px 5px 40px;
    text-align: left;
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 5px;
}


@media screen and (max-width:500px){
    .header__display-menu{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: auto;
        background: white;
    }
 
    .header__icons__group-li a p{
        display: none;
        
    }
    .header__icons__group-li p {
        display: none;
        
    }
    .header-logo{
        margin-left: -1px !important;
    }
    
    .home__recomended{
    margin-left: 20px !important;
    font-size: 18px !important;
    }
      
      }

      .header__display__menu-item_company{
          margin-left: 22.5px;
      }
      /* .idioma-empresa{
        text-align: center;
        position: absolute;
        z-index: 160;
        display: flex;
        justify-content: center;
        top:0%;
        border-left: solid 2px #212156;
        border-right: solid 2px #212156;
        min-width: 90px;
        background-color: #FFFFFF;
        border-radius: 10px 10px 10px 10px; 
        transition: all 0.5s ease-out;
    }
    .idioma1-empresa{
        text-align: center;
        position: absolute;
        top: 8%;
        z-index: 160;
        min-width: 90px;
        border-radius: 10px 10px 10px 10px;
        background-color: #FFFFFF;
        border-left: solid 2px #212156;
        border-right: solid 2px #212156;
        border-bottom: solid 2px #212156; 
        transition: all 0.5s ease-out;
    }
    .idioma2-empresa{
        text-align: center;
        z-index: 10 ;
        position: absolute;
        top: 0;
        z-index: 160;
        cursor:default;
        min-width: 90px;
        border-radius: 10px 10px 10px 10px;
        border-left: solid 2px #212156;
        border-right: solid 2px #212156;
        border-top: solid 2px #212156;
        background-color: #FFFFFF; 
    }
    .idioma0-empresa:hover .idioma-empresa{
          top: 100%;
          display: flex;
          border-radius: 0px 0px 0px 0px;    
          transition: all 0.5s ease-out;
    }
    .idioma0-empresa:hover .idioma1-empresa{
          top: 200%;
          border-radius: 0px 0px 10px 10px;
          transition: all 0.5s ease-out;
    }
    .idioma0-empresa:hover .idioma2-empresa{      
      border-radius: 10px 10px 0px 0px;      
      z-index: 140;
        border-bottom: solid 2px rgba(0, 0, 0, 0);
        transition-delay:0.5s;
    }
    .idioma1-empresa:hover{
      background-color: #64acff;
      color: white;
    }
    .idioma-empresa:hover{
      background-color: #64acff;
      color: white;
    } */