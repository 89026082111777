.buttoooon{
  padding-left: .5em;
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 14px;
  text-decoration: underline;

}
.input-register-new input{

  width: 348px;
  height: 41px;

  font-style: normal;
  font-weight: 400;

  padding-left: 22px;
  font-size: 16px;
  border: 1px solid #C5C5C5;
  margin-left: 3em;
  margin-right: 3em;
}
.input-register-new input::placeholder{
  color: #C5C5C5;

}
.input-register-new input:focus{
  color: #636161;
  padding-left: 22px;
  outline: 0.5px solid #E7BF00;
}

.register-forgot-pass{
  color: #E7BF00;
  font-size: 14px;
  font-weight: 400;
  margin-top: 2%;
  cursor: pointer;
}
.register-forgot-pass:hover{
  color: #D4B000;
}
.register-button-new{
  background: #202256;
  border-radius: 5px;
  width: 100%;
  height: 33px;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: none;
}
.register-register-new{
  color: #202256;
  font-size: 14px;
  font-weight: bold;
  margin-top: 1%;
  cursor: pointer;
 margin-left: .5em;
  width: 6.5em;
  justify-content: flex-start;
}
.register-register-new:hover{
  color: #0B27E6;
}

.b-contain *,
.b-contain *::before,
.b-contain *::after {
  box-sizing: content-box !important;
}

.b-contain input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.b-contain span {
  line-height: 1.5;
  font-size: 1rem;
  font-family: inherit;
}

.b-contain {
  display: table;
  position: relative;
  padding-left: 1rem;
  cursor: pointer;
  margin-bottom: 0.4rem;


}

.b-contain input[type="checkbox"] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background: #f1f5f9;
  transition: background 250ms;
  border: 1px solid #94a3b8;
  border-radius: 3.21rem;
}

.b-contain input[type="radio"] ~ .b-input {
  position: absolute;
  top: 5px;
  left: 0;
  height: 0.80rem;
  width: 0.80rem;
  background: #f1f5f9;
  transition: background 250ms;
  border: 1px solid #94a3b8;
  border-radius: 4.15rem;
}

.b-contain input[type="checkbox"] ~ .b-input::after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 0.3rem;
  height: 0.6rem;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transition: background 250ms;
  transform: rotate(45deg);
}

.b-contain input[type="radio"] ~ .b-input::after {
  content: "";
  position: absolute;
  display: none;
  top: 3px;
  left: 3px;
  width: 0.40rem;
  height: 0.40rem;
  border-radius: 3rem;
  background: rgb(32, 34, 86);
  transition: background 250ms;
}

.b-contain input[type="checkbox"]:disabled ~ .b-input::after {
  border-color: #ffffff;
}

.b-contain input:checked ~ .b-input::after {
  display: block;
}

.b-contain:hover input[type="checkbox"]:not([disabled]) ~ .b-input,
.b-contain input[type="checkbox"]:focus ~ .b-input {
  background: #e2e8f0;
  border-color: #64748b;
}

.b-contain:hover input[type="radio"]:not([disabled]) ~ .b-input,
.b-contain input[type="radio"]:focus ~ .b-input {
  background: #e2e8f0;
  border-color: #64748b;
}

.b-contain input:focus ~ .b-input {
  box-shadow: 0 0 0 2px rgb(32, 34, 86);
}
.cropContainer{
  position: relative;
  width: 100%;
    height: 200px;
  background: #333;
}

@media(max-width: 1368px){
  .container-register{
    height: auto !important;
  }
  .container-register p{
margin-bottom: 0.5em !important;
  }
}

@media(max-width: 725px) {
  .terms-condition {
    width: auto !important;
    margin-left: 0 !important;
  }
}
