.Perfil-container-cards{
border-radius: 20px;
width: 324px;
min-height: 430px;
background-color: #ffffff;
font-size: .9em;
display:flex;
align-items: center;
justify-content: flex-start;
flex-direction: column;
margin-bottom:1em;

}


.editar-foto-perfil{
    border:1px solid white;
    width:30px;
    height: 30px;
    background-color: #ec7332;
    position:absolute;
    margin-top:-70px;
    margin-left:60px;
    border-radius: 50%;
    display:flex;
    align-items: center;
    justify-content: center;
}


.editar-foto-perfil button{
    background-color: transparent;
}

.avatar-element-perfil{
    position:absolute;
}

.Avatar-camara-perfil{

}

.nidiea button{
    background-color:#ec7332 ;
}


.Perfil-container-cards span{
width: 8.7em !important;
}

.porcentaje-complete{
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top:4em;

}

.porcentaje-editar-container{
    font-size: .9em;

    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.porcentaje-text{
    color:#21224e;
}
.porcentaje-p{
    margin-right:.5em;
    color:#898989;
}



.link-edit-image img{
    width:20px;
    height:20px;
    margin-top: -.2em;
    margin-left: 30px;

}

.link-edit-image :hover{
    cursor:pointer;
    color:#898989;
}
.link-edit-image{
    color:#898989;
    height: 33px;
    padding: 13px 5px;
    display:flex;
    align-items:center;
    justify-content: space-between;

}

.herre{
    color: #fbfbfb;
    width:80%;

}

.icon-cards{
    width:20px;
    height:20px;
    margin-top: -.2em;
    margin-right: 1em;
}

.contact-data{

    color:#898989;
    font-size: .9em;
}



/* Card Oportunidad e idiomas */

.innerCards{
    width:75%;
}
.oportunidad-cantidad{
  margin-top: 3em;
  /* border:1px solid black; */
  width: 80%;
}

.title-op{
    font-weight: bolder;

}

.text-contador{
    font-size:2em;
    color:#ec7332;
    font-weight: bolder;
}

.redes-cont{
    background-color: #fbfbfb;
    width: 85%;
    height:3em;
    display:flex;
    align-items:center;
    justify-content: flex-start;
    margin-bottom: 1em;
    border-radius: 10em;

}

.redes-cont div{

    width:46px;
    height: 49px;
    border-radius: 18px;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-right: .5em;
}

.redes-cont p{
    margin-top: 1.5em;
}

.TitleOp{

    width:94%;
    margin-top:-30px;
    margin-bottom: 10px;
    margin-left: 28.41px;
    padding:1em;
}


#face{
    background-color:#4166d8 ;
}

#drib{
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

#ln{
    background-color: #0073af;
}

#ytb{
    background-color: #ef0806;
}

/* responsive */


@media screen and (max-width: 500px){

    .porcentaje-editar-container{

        width: 80%;
    }

}
