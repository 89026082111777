.video {
    max-width: 300px;
    text-align: center;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 3px 6px #00000029;
}

.education-iframe-box{
    width: 260px;
    height: 170px;
    border-style: none;
}

.video__media-vd {
    width: 100%;
}

.video-data {
    padding: .5rem 1rem;
    text-align: center;
}

.video__data-head {
    display: flex;
    justify-content: space-around;
}

.video-data-p {
    font-weight: 500;
    font-size: 16px;
    text-align: left;
}

.video-data-desc {
    text-align: left;
    color: rgb(138, 138, 138);
}

.video-data-leng {
    text-align: left;
    color: rgb(138, 138, 138);
}

.video-data-prof {
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    color: rgb(138, 138, 138);
}

.videolist {
    box-sizing: border-box;
}

.videoslist__container {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(3, 1fr);
    margin-right: 50%;
}

@media (min-width: 1920px) and (orientation: landscape) {
    .videoslist__container {
        margin-left: -20%;
    }
 }

.videoslist__container-cpn {
    text-align: center;
    margin: 0 auto;
    width: 100%;
}

@media screen and (max-width:1080px) {
    .videoslist__container {
        display: block;
    }

    .videoslist__container-cpn {
        padding: 10px;
    }
}

 .education__filter-select .label{
    display: inline-block;
    cursor: pointer;
    color: var(--color-negro);
    position: relative;
    padding: 5px 15px 5px 51px;
    font-size: 1em;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.education__filter-select .education__filter-select label:hover
 {
    background: rgba(255, 65, 54, 0.1);
}

.education__filter-select label:before {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    position: absolute;
    left: 15px;
    border-radius: 50%;
    background: none;
    border: 3px solid var(--color-negro);;
}

input[type="radio"] {
    display: none;
}

input[name="continuarias"]:checked+label:before {
    display: none;
}

input[name="continuarias"]:checked+label {
    padding: 5px;
    background: var(--color-amarillo);
    border-radius: 2px;
    color: #fff;
}

input[name="categoriasFilter"]:checked+label:before {
    display: none;
}

input[name="categoriasFilter"]:checked+label {
    padding: 10px;
    background: var(--color-amarillo);
    border-radius: 2px;
    color: #fff;
}

