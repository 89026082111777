.form label{
  color: #b6b6b6;
  font-size: 14px;
 display: inline-block;
 margin-left: 1%;
 width: 100%;
/* margin-right: 5%; */
text-align: start;
}
.inputs{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.desc{
  display: flex;
  align-items: center;
  flex-direction: column;
}
/* fade in key frames */

.fade_in {
  animation-name: fadeIn;
  animation-duration: 1s;
  transition-timing-function: linear;
}

.form input , select, textarea{
  border: 1px solid #C5C5C5;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width:725px) {
  .formCv {    
    width: 100% !important;
     min-width: 400px !important;
  }
  .container{
    width: 90%    
  }
  .aptitudes{
    flex-direction: column !important;
  }
  .certificaciones{
    flex-direction: row !important;
    justify-content: space-between !important;    
  }
  .certificaciones button{
    margin-top: 20px !important;
    margin-bottom: 0 !important;
  }
}