.home-mentor-unilever-container-global {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.home-mentor-unilever-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 5%;
    width: 100%;
}

.home-mentor-unilever-sub-container {
    width: 74%;
}

/*filtro*/

.home-mentor-unilever-filtro-check-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.home-mentor-unilever-filtro-check-title{
    font-size: 14px;
    font-weight: 400;
    color: #5D5D5D;
}
.home-mentor-unilever-filtro {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 25px;
    width: 24%;
}

.home-mentor-unilever-filtro-titulo {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
}

.home-mentor-unilever-filtro-subtitulo {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.home-mentor-unilever-container-searchbar {
    background: #FFFFFF;
    border-radius: 8px;
    width: 85%;
    padding: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.home-mentor-unilever-button-searchbar {
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 35px;
    width: 105px;
}

.home-mentor-unilever-input-searchbar {
    background: #FFFFFF;
    border: none;
    height: 35px;
    width: 100%;
    text-indent: 40px;
}

.home-mentor-unilever-container-topbar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 30px;
}

.home-mentor-unilever-button-view {
    border: 1px solid #C5C5C5;
    border-radius: 8px;
    background-color: transparent;
    height: 35px;
    width: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-mentor-unilever-button-view-active {
    border: 1px solid #202256;
    border-radius: 8px;
    background-color: #202256;
    height: 35px;
    width: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-mentor-unilever-view-container {
    width: 110px;
}

.home-mentor-unilever-view-subcontainer {
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}