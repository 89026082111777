
.container-global-bootcamp{
     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.container-div-bootcamp{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 50%;
    min-width: 500px;   
    background-color: white;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 10%) 0px 7px 32px;
}

.container-form-bootcamp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.button-close-form-bootcamp{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #202256;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    margin-top: 9px;
    margin-left: 11px;
    background-color: transparent;
    border: 2px solid #202256;
}

.div-title-form-bootcamp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    width: 60%;
    minWidth: 500px;
}

.button-close-form-bootcamp:hover{
    background-color: #202256;
    color: white;
}

.checkbox-bootcamp{
    display: flex;
    justify-content: space-between;
    width: 25%;
    margin-bottom:1em;
}

.checkbox-bootcamp label{
    margin:0px;
    display: flex;
    align-items: center;
}

.button-submit-bootcamp{
    width: 25%;
    height: 30px;
    border-radius: 5px;
    background-color: #202256;
    color: white;
    border: none;
    margin-bottom: 1em;
}

@media screen and (max-width: 768px) {
    .container-div-bootcamp{
        width: 95%;
        min-width: auto;
    }
    .div-title-form-bootcamp{
        min-width:auto;
        width:100%;
    }
}