.loginadmin {
  color: #697a8d;
  height: 100vh;
  padding-top: 10vh;
  margin: auto;
  width: 90%;
}

.loginadmin__form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 50px 0px;
}

.loginadmin__input::placeholder {
  color: #697a8d;
}

.loginadmin__label {
  display: flex;
  flex-direction: column;
}

.loginadmin__logo {
  display: block;
  margin: auto;
  width: 120px;
}

.loginadmin__button {
  align-self: center;
  background-color: #fe7c7c;
  height: 33px;
  width: 118px;
  border-radius: 5px;
  color: #FFFFFF;
  text-align: center;
}

@media (min-width: 576px) {
  .loginadmin {
    width: 60%;
  }
}

@media (min-width: 992px) {
  .loginadmin {
    width: 30%;
  }
}

