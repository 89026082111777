.header{
    display:flex;
    justify-content: space-between;
    height: max-content; 
    width: 100%;
    z-index: 100;
    background-color: #ffffff;
    padding: 10px;
}

.header-display-name-coallier{
    padding-top: 5px;
    color: black;
    font-size: 15px;
    font-weight: 700;
}

.header-display-see-profile{
    color: #212156;
    font-size: 13px;
    text-decoration: underline;
    margin-top: -15px;
    font-weight: 400;
}

.header-logo{
    align-self: center;
    margin-left:10px;
}

.header__icons-group{
    align-self: center;
    z-index: 600;
}

li, ul, ol {
    list-style: none;
}
.header__icons__group-ul{
    display: flex;
    flex-direction: row;
    height: 20px;
}

.header__icons__group-li{
    float: left;
    text-align: center;
    margin-right: 40px;
    font-size: var(--font-size-regular);
}

.header__icons__group-icon{
    margin-left: 5px;
}

.header__display-menu{
    display: none;
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: 0px 2px 10px #00000029;
    padding: 5px;
    background-color: white;
    position: absolute;
    width: 300px;
    min-width: 170px;
    font-size: 13px;
}

.menu-text{
    display: inline-block;
    font-size: 14px;
    margin-left: 0.2em;
}

.header__display_copy{
    font-size: 13px;
    color: #7B7777;
    margin-top: 5px;
}

.header__display__menu-item_company{
    font-size: 13px;
    display:block;
    padding: 5px 5px 5px 5px !important;
    text-align: left;
    padding-bottom: 2px !important;
    margin-bottom: 3px;
    border-bottom: 1px solid black;
    text-align:start;
    width: 80%;
    margin-left: 27px;
}

.header__display__menu-item_coallier{
    font-size: 13px;
    display:block;
    padding: 5px 5px 5px 5px !important;
    text-align: left;
    margin-top: 10px !important;
    padding-top: 5px !important;
    text-align: left;
    width: 80%;
    margin-left: 9.2%;

}

.header__display__menu-item{
    font-size: 13px;
    display:block;
    padding: 5px 5px 5px 5px !important;
    text-align: left;
    padding-bottom: 0px !important;
    margin-bottom: -5px;
    margin-left: 9.2%;
    width: 80%;
}

.header__display__menu li{
    font-size: 13px;
    display:block;
    padding: 5px 5px 5px 5px !important;
    text-align: center;
    padding-bottom: 0px !important;
    margin-bottom: 0px;
}

.header__display__menu-perfil{
    margin:0  auto;
    display: grid;
    padding: 5px;
    font-size: 13px;
    padding: 5px 20px 5px 40px;
    margin-bottom:10px ;
    grid-template-columns: repeat(2 1fr);
}

.header__display__menu-perfil-ver-perfil{
    margin: 0 auto;
    display: grid;
    padding: 7px;
    border-bottom: 0.5px solid #D6D6D6;
    font-size: 25px;
    padding: 5px 20px 5px 20px;
    margin-bottom: 10px;
    grid-template-columns: repeat(2 1fr);
    width:70%
}

.header__display__menu__perfil-img{
    margin: 0 auto;
    grid-column: 1;
}

.header__display__menu__perfil-data{
    text-align: center;
    color: #8C00DF;
    font-weight: 500;
    font-size: 15px;
    grid-column: 2;
}

.header__display__menu__item-pagos{
    font-size: 13px;
    display:block;
    padding: 5px 5px 5px 5px !important;
    text-align: left;
    border-top: 0 !important;
    border-style: none;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
    text-align: left;
    width: 80%;
    margin-left: 9.2%;
    
}



.custom-select{
    background-color: #8C00DF !important;
    color: white !important;
}

.select-user-coally{
    background-color: #8C00DF;
    color: white;
}

@media screen and (max-width:1080px){
.header__display-menu{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    background: white;
}


.header-logo{
    margin-left: -1px !important;
}


  
  }

  .search_consultor{
      width: 550px;
      height: 40px;
      border-radius: 8px;
      color: #707070;
      border-style: solid;
      border-width: 1.5px;
      border-color: #c0c0c0;
      position: absolute;
      left: 18%;
  }

  .lapizIcon:hover{
      margin-left: 10px;
  }

  .search_consultor::placeholder{
      padding-left: 30px;
  }
  .idioma{
      text-align: center;
      position: absolute;
      z-index: 160;
      display: flex;
      justify-content: center;
      top:0%;
      color: #696a7c;
      border-left: solid 2px #fff;
      border-right: solid 2px #fff;
      min-width: 40px;  
      background-color: #fff;
      border-radius: 10px 10px 10px 10px; 
      transition: all 0.5s ease-out;
  }
  .idioma1{
      text-align: center;
      position: absolute;
      top: 8%;
      z-index: 160;
      min-width: 40px;  
      color: #696a7c;
      border-radius: 10px 10px 10px 10px;
      background-color: #fff;
      border-left: solid 2px #fff;
      border-right: solid 2px #fff;
      border-bottom: solid 2px #fff; 
      transition: all 0.5s ease-out;
  }
  .idioma2{
      text-align: center;
      z-index: 10 ;
      position: absolute;
      top: 0;
      color: #696a7c;
      z-index: 160;
      cursor:default;
      min-width: 40px;  
      border-radius: 10px 10px 10px 10px;
      border-left: solid 2px #fff;
      border-right: solid 2px #fff;
      border-top: solid 2px #fff;
      background-color: #fff; 
  }
  .idioma0:hover .idioma{
        top: 100%;
        display: flex;
        border-radius: 0px 0px 0px 0px;    
        transition: all 0.5s ease-out;
  }
  .idioma0:hover .idioma1{
        top: 200%;
        border-radius: 0px 0px 10px 10px;
        transition: all 0.5s ease-out;
  }
  .idioma0:hover .idioma2{     
    z-index: 140;
    border-radius: 10px 10px 0px 0px;
      border-bottom: solid 2px rgba(0, 0, 0, 0);
      transition-delay:0.5s;
  }
  .idioma1:hover{
    background-color: white;
    color: #f07130;
  }
  .idioma:hover{
    background-color: white;
    color: #f07130;
  }

.idioma-{
    text-align: center;
    position: absolute;
    z-index: 160;
    display: flex;
    justify-content: center;
    top:0%;
    color: white;
    border-left: solid 2px #151650;
    border-right: solid 2px #151650;
    min-width: 90px; 
    background-image: linear-gradient(175deg, #3b3e94 -32%, #101247 118%);
    border-radius: 10px 10px 10px 10px; 
    transition: all 0.5s ease-out;
}
.idioma1-{
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 160;
    color: white;
    min-width: 90px;
    border-radius: 10px 10px 10px 10px; 
    background-image: linear-gradient(175deg, #3b3e94 -32%, #101247 118%);
    border-left: solid 2px #151650;
    border-right: solid 2px #151650;
    border-bottom: solid 2px #151650; 
    transition: all 0.5s ease-out;
}
.idioma2-{
    text-align: center;
    z-index: 10 ;
    position: absolute;
    top: 0;
    z-index: 160;
    cursor:default;
    color: white;
    min-width: 90px;
    max-height: max-content;
    border-radius: 10px 10px 10px 10px;
    border-left: solid 2px #151650;
    border-right: solid 2px #151650;
    border-top: solid 2px #151650; background-image: linear-gradient(175deg, #3b3e94 -32%, #101247 118%);
}
.idioma0-:hover .idioma-{
      top: 100%;
      display: flex;
      color: white;
      border-radius: 0px 0px 0px 0px;   
}
.idioma0-:hover .idioma1-{
      top: 200%;
      border-radius: 0px 0px 10px 10px;
      color: white;
}
.idioma0-:hover .idioma2-{     
    z-index: 150;
  border-radius: 10px 10px 0px 0px;
  color: white;
  transition-delay:0.5s;
}
.idioma1-:hover{
  background-color: #64acff;
  color: white;
}
.idioma-:hover{
  background-color: #64acff;
  color: white;
}
.educacion-header{
    font-size: 1.25rem;
    color: #696a7c;    
}
.educacion-header-selecionado{
    font-size: 1.25rem;
    color: #F07130;
    border-bottom: 0.188rem solid #F07130;
}
.educacion-header:hover{
    font-size: 1.25rem;
    color: #F07130;
    border-bottom: 0.188rem solid #F07130;
}