.datapolicy_form {
      width: 1000px;

}  

.datapolicy{
       padding-top: 50px;
    display: flex;
    margin-bottom: 5em;
}

.section {
    padding: 3%;
    text-align: justify;
}

.title {
    padding: 1%;
}

.content, .sub-content {
    padding: 1%;
}