.foot-but-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.foot-but-select {
    width: 100%;
    height: 33px;
    background-color: #F07130;
    border-radius: 5px;
    border: none;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
}

.aplic-card-glob-sub-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 23px;
}

.aplic-card-info-subcont {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.aplic-card-info-subcont-2 {
    flex-direction: column;
    justify-content: center;
}

.aplic-blob-num {
    font-weight: 700;
    font-size: 12px;
    color: #FFFFFF;
    margin-top: 9px;
}

.blob-cont-1 {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F07130;
    width: 33px;
    height: 33px;    
    position: relative;
    top: 20px;
}

.blob-cont-2 {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFBD6E;
    width: 33px;
    height: 33px;    
    position: relative;
    top: 20px;
}

.blob-cont-3 {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFDB99;
    width: 33px;
    height: 33px;
    position: relative;
    top: 20px;
}

.aplic-card-img {
    width: 81px;
    height: 81px;
    object-fit: cover;
    border-radius: 50%;
}

.aplic-card-img-filtro-2 {
    filter: blur(3px);
    user-select: none;
    pointer-events: none;
}

.aplic-card-img-filtro {
    /*filter: blur(3px);*/
    user-select: none;
    pointer-events: none;
}


.aplic-card-compat {
    /*margin-left: 5px;*/
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #F07130;
}

.aplic-card-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.aplic-card-name {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.aplic-card-name-ent {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
    display: none;
}

.aplic-card-job {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
}

.aplic-card-job-ent {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
    display: none;
}

.aplicantes-card-glo-cont {
    background: #FFFFFF;
    border-radius: 8px;
    margin: 12px;
    min-width: 297px;
    height: fit-content;
    padding-right: 17px;
    padding-left: 20px;
}

.aplic-card-ver-perfil-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
; 
}
.aplic-card-ver-perfil:hover {
    filter: grayscale(0%); 
    color: #202256; 
    font-weight: 500;
    stroke-width: 1.5px; 
}

.aplic-card-ver-perfil svg{
    stroke: #202256;
}

.aplic-card-ver-perfil {
    font-weight: 400;
    font-size: 14px;
    color: #202256;
    margin-right: 10px;
    filter: grayscale(100%);
}

.aplic-card-button {
    background: transparent;
    border: 1px solid #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #202256;
    padding-right: 20px;
    padding-left: 20px;

}

.aplic-buttons-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .aplic-card-info-subcont {
        text-align: center;
    }

    .aplic-card-ver-perfil-cont {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 50%;
    }

    .aplic-card-name {
        font-weight: 700;
        font-size: 16px;
        color: #393939;
        display: none;
    }

    .aplic-card-name-ent {
        font-weight: 700;
        font-size: 16px;
        color: #393939;
        display: block;
    }

    .aplic-card-job {
        font-weight: 400;
        font-size: 12px;
        color: #5D5D5D;
        display: none;
    }

    .aplic-card-job-ent {
        font-weight: 400;
        font-size: 12px;
        color: #5D5D5D;
        display: block;
    }

    .aplic-card-glob-sub-cont {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: 23px;
    }
}

@media (max-width: 425px) {

    .aplic-card-ver-perfil-cont {
        border: 1px solid #202256;
        border-radius: 5px;
        width: 100%;
        margin-bottom: 15px;
    }

    .aplic-card-name {
        font-weight: 700;
        font-size: 16px;
        color: #393939;
        display: block;
    }

    .aplic-card-name-ent {
        font-weight: 700;
        font-size: 16px;
        color: #393939;
        display: none;
    }

    .aplic-card-job {
        font-weight: 400;
        font-size: 12px;
        color: #5D5D5D;
        display: block;
    }

    .aplic-card-job-ent {
        font-weight: 400;
        font-size: 12px;
        color: #5D5D5D;
        display: none;
    }

    .aplic-card-glob-sub-cont {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 23px;
    }

    .aplic-buttons-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .aplicantes-card-glo-cont {
        background: #FFFFFF;
        border-radius: 8px;
        border: 2px solid #F07130;
        min-width: 100%;
        margin: 15px 0 0 0;
        height: fit-content;
        padding-right: 17px;
        padding-left: 20px;
    }
}

@media (max-width: 1024px) {
    .aplicantes-card-glo-cont {
        background: #FFFFFF;
        border-radius: 8px;
        margin: 15px 0 0 0;
        height: fit-content;
        padding-right: 17px;
        padding-left: 20px;
    }
}