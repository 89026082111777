.CompanyForm{
    background-size: cover;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.CompanyForm-button-submit{
    margin-top: 40px !important;
    border-radius: 5px !important;
}

.MuiPaper-root makeStyles-paper-4 MuiPaper-elevation1 MuiPaper-rounded{
    width: 1000px !important;
}

.makeStyles-layout-3{
    width: 1000px !important;
    margin-left: 0; 
    margin-right: 0;
}

.CompanyForm-container {
    min-width: 500px;
    max-width: 1000px;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    margin: 0 auto;
    text-align: center;
    background-color: white;
    min-height: 400px;
    box-sizing: border-box;
    overflow: auto;
}

.MuiTypography-alignCenter{
    color: #540e87 !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
}

.makeStyles-layout-13{
    width: 1000px !important;
}

/* titulo */
.CompanyForm-tittle{
    font-size: 25px;
    font-weight: 700;
    margin-top: 2rem;
    text-align: center;
    text-transform: uppercase;
}

.CompanyForm_container-form{
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 5rem;
    padding: 40px 35px;
    background-color: #f6f6f6;
}

.CompanyForm-input{
    width: 100%;
    border: none;
    border-bottom: 1px solid;
    outline-style: none;
    font-size: 15px;
    margin-top: 4%;
    padding: 12px;
    box-sizing: border-box;
    background-color: transparent;
}
.CompanyForm-label{
    padding-top: 2rem;
    text-align: left;
    font-size: 15px;
    color: #757575; 
}


.CompanyForm-input:focus {
    border-bottom: 2px solid var(--main-yellow-findally);
    outline: none;
}

.select__value-container{
    font-size: 15px;
    padding: 8px;
}

.button_form_company {
    padding: 8px;
    color: white;
    background-color: #f46036;
    border-style: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 600;
    text-transform: uppercase;
}
