.DashboardCardContainer {
    background: #FFFFFF;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.DashboardCardContainerChildren{
    width: 100%;
}

.DashboardCardContainerTitle {
    background-color: #dbdbdb;
    width: 100%;
    border-radius: 8px 8px 0 0;
    padding: 5px;
}

.DashboardCardTitle {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
}