.container-cuenta button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:transparent;   
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    width:100%;
    height: 56px;
}
.container-cuenta button span{
    margin-left: 30px
}
.container-cuenta button:hover{
    background-color: #202256;
    color:white;
}
/* .container-cuenta button:focus{
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
} */
.container-dropdown{
    margin-left: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}
.box-item li{
    margin-left: 60px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
}
.box-item button{
    color: #000000;
}

.box-item hr{
    border: 1px solid #D6D6D6 ;
    margin-left: 3% ;  
    margin-top: 0px;  
    
}

 .division-hr{
    border: 1px solid #393939;
    margin: 0px;
    
}


@media (max-width:725px){
    .config-headertop{
        padding: 0rem 2rem !important;
        width: 100% !important;
        position:revert !important;
    }
    .container-configuracion{
        margin-top: 10% !important;
        max-width: 100% !important;
        margin:auto ;
        position: relative !important;
        padding: 0px !important;
        width: 90% !important;

    }
  }