.container-tests{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.container-back-tests{
    width: 100%;
    color: #202256;
    display: flex;
    margin-bottom: 5%;
    align-items: center;
    justify-content: space-between;
}

.Bootcamp-Subcontainer-Tests{
    width:100%;
    height: 500px;
    overflow-y: scroll;
}