.Container-Global-Pruebas{
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
}

.title-pruebas-tec{
    color: #202256;
    font-size: 40px;
    font-weight: 700;
}
.subtitle-pruebas-tec{
    color:  #393939;
    font-size: 24px;
    font-weight: 700;
}
.textarea-pruebas-tec{
    width: 100%;
    height: 100px;
    border: 1px solid #202256;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    color: #202256;
    resize: vertical;
}

.container-add-test{
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    
}

.text-add-test{    
    text-align: end;
    font-size: 20px;
    color: #F07130;
}

.button-cerrar-popup{    
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 184px;
    height: 28px;
    font-size: 16px; 
    background: #202256;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;     
}

.button-Abrir-popup{    
    display: flex;
    justify-content: center;
    align-items: center;
    width: 184px;
    height: 40px;
    font-size: 16px; 
    background: #F07130;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;     
}

.upload-test-input-contenedor{
    border: 0.5px solid #202256;
    border-radius: 5px;   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 160px;
}

.text-popup-upload{
    font-size: 14px;
    color: #202256;
    margin: 0;
}

.container-popup-prueba{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    flex-direction: column;
}



@media (max-width: 768px) {
    .Container-Global-Pruebas{
        padding-left: 0px;
        padding-right: 0px;
    }
    .container-popup-prueba{
        padding:20px;
    }
    .css-7wek2j{
        width:380px !important;
    }
}