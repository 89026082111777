.new-empresa-home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
}

.new-empresa-home-banner {
    width: 100%;
    height: 158px;
    /* background-image: url(https://coally.com/wp-content/uploads/2022/03/banners-landing-1-2.png); */
    background-image: url(../../../assets/imagenes/BannerHome.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-empresa-home-banner2 {
    width: 100%;
    height: 158px;
    /* background-image: url(https://coally.com/wp-content/uploads/2022/03/banners-landing-1-2.png); */
    background-image: url(../../../assets/imagenes/openai.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-empresa-home-banner-text {
    margin-left: 41px;
}

.new-empresa-home-banner-button {
    margin-right: 6rem;
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 135px;
    height: 28px;
    background: transparent;
    border: 1px solid #FFF;
    border-radius: 5px;
}

.new-empresa-home-banner-button2 {
    margin-right: 6rem;
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 135px;
    height: 28px;
    background: #F07130;
    border-radius: 5px;
}

.new-empresa-home-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;

}

.new-empresa-home-recommendation {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 64%;
    height: 100%;
}

.new-empresa-home-test-title {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    width: 30%;
    height: 30px;
}

.new-empresa-home-body-tests {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 2fr));
    grid-gap: 10px;
    margin-top: 10px;
}

.paginateStyle {
    display: flex;
    font-size: 1em;
    font-family: 'Poppins';
    color: #B0B0B0
}

.nextB {
    color: #202256
}

.previB {
    color: #202256
}

.paginateStyle li {
    padding: 8px;
    margin: 1em;
}

.pageAct {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #202256 !important;
    width: 40px !important;
    border-radius: 100% !important;
    color: #FFF !important;
}

.pageB {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    width: 40px;
    border-radius: 100%;
    color: #202256;
}

.contenedor-formacion-oportunidades-new {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 2%;
    width: 100%;
}

.new-home-empresa-statistics-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 300px;
    height: 100%;
}

.new-home-company-save-op-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 40px;
    padding: 10px;
    margin-bottom: 50px;
}

.new-home-empresa-statistics-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 50%;
    margin-top: 5%;
    margin-right: 10px;
}

.new-home-empresa-statistics-body-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    height: 50%;
    margin-left: 10px;
    margin-bottom: 10px;
}

.new-home-empresa-statistics-arrows {
    max-width: 20px;
}

.new-home-published-opportunities {
    width: 60%;
    margin-right: 33px;
}

.new-home-empresa-statistics-arrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
}

.new-home-empresa-statistics-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.new-home-empresa-videos-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 3fr));
    grid-gap: 10px;
    width: 100%;
}

.navigate-arrow-statistics {
    color: #202256;
    cursor: pointer;
}

.navigate-arrow-statistics:hover {
    color: #F07130;
}

@media (max-width: 1100px) {
    .contenedor-formacion-oportunidades-new {
        flex-direction: column-reverse;
        align-items: center
    }

    .new-empresa-home-recommendation {
        width: 100%;
    }

    .new-home-published-opportunities {
        width: 100%;
        margin-right: 0px;
    }

    .new-home-empresa-statistics-container {
        width: 100%;
    }

    .new-empresa-home-banner {
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .new-empresa-home-banner2 {
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .new-empresa-home-banner-button {
        margin-right: 0px;
        margin-top: 0px;
    }
    .new-empresa-home-banner-button2 {
        margin-right: 0px;
        margin-top: 0px;
    }
}

@media(max-width: 1550px){
    .new-empresa-home-banner2{
        border-radius: 8px;
    }
}
@media (max-width: 950px) {
    .pageAct {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        background-color: #202256 !important;
        width: 40px !important;
        height: 40px !important;
        border-radius: 50% !important;
        color: #FFF !important;
    }

    .pageB {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFF;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #202256;
    }

    .nextB {
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .previB {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
