.Container-square-home-profesional-unilever{
  background: #FFFFFF;
  width: 324px;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column; 
  height: 250px;
  margin: 22px;
}

.image-profesional-home-unilever{
  object-fit: cover;
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
.card-home-unilever-profesional-mid{
  width: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  align-items: center;
  margin-bottom: 38px;
  padding-left: 25px;
}
.card-home-unilever-profesional-mid-section-3{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.card-home-unilever-profesional-mid-section-list{
  width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 28px;
}
.home-profesional-unilever-list-Container-rectangular{
  background: #FFFFFF;
  width: 100%;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 175px;
  margin-bottom: 22px;
  padding-bottom: 20px;
}
.unilever-home-profesional-mid-Container-inner-3-1{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.unilever-home-profesional-Top-Container-rectangle-inner-2-1{
  margin-top: 20px;
  width: 100%;
}
.unilever-home-profesional-Top-Container-rectangle-inner-2{
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}