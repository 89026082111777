@font-face {
  font-family: Unilever;
  src: url("../../assets/font/UnileverShilling.ttf");
}
.landing-unilever-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
}
.unilever-landing-footer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  background-color: #1F36C7;
  color: #fff;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 400;
  font-family: Unilever;
}
[data-aos="fade-out"] {
  opacity: 1;
  transition-property: opacity;
}
[data-aos="fade-out"].aos-animate {
  opacity: 0;
}
.landing-unilever-section-1{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat; 
  background-position:center;
  background-image:linear-gradient(90deg, #00076e 6%, rgba(255, 255, 255, 0) 48.83%), url(../../assets/imagenes/unileverBG.png);
  mix-blend-mode: multiply;
}
.landing-unilever-section-1-container{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.landing-unilever-section-1-container-body{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row-reverse;
  width: 80%;
  height: 70%;
/*   margin-top: 4%; */
}
.landing-unilever-section-1-logo{
  width: 100%; 
  max-height: 112px;
  display:flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 30px;
  padding-right: 30px;
}
.landing-unilever-section-1-logo-bottom{
  width: 100%; 
  max-height: 112px;
  display:flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}
  .landing-unilever-body-scroll-container{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 2%;
}
.landing-unilever-logo{
  height:82px;
  width: 82px;
}
.layer-pedal-landing{
  /* style={{width:'50%', marginLeft: '-3%', height: 'auto'}} */
  width: 50%;
  margin-left: -3%;
  height: auto;
}

.landing-unilever-section-1-title-container{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  width: 60%;
  height: 100%;
}
.landing-unilever-section1-paraph-container{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 2%;
}
.scroll-container1{
  display: flex;
  position: absolute;
  right: 0;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 80px;
  transform: translate(50%);
  height: 50%;
  z-index: 5;
}
.scroll-container-3{
  display: flex;
  position: absolute;
  right: 0;
  justify-content: flex-end;
  align-items: flex-start;
  margin-right: 80px;
  height: 30%;
  z-index: 5;
}
.scroll-container-3 svg{
color:#005EEF !important;
}

.scroll-container{
  display: flex;
  position: absolute;
  right: 0;
  justify-content: flex-end;
  align-items: flex-start;
  margin-right: 80px;
  height: 30%;
  z-index: 5;
}
.landing-unilever-section1-button-container{
  margin-top: 20px;
}
.landing-unilever-section1-button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 48px;
  border-radius: 24px;
  background-color: #1F36C7;
  text-align: center;
}

.landing-unilever-section1-arrow-indicator{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  width: 80%;
  height: auto;
  margin-top: 2%;
  margin-bottom: 20px;
  z-index: 2;
}
.landing-unilever-section2-arrow-indicator{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 3%;
  margin-bottom: 20px;
  z-index: 2;
}
.landing-unilever-section3-arrow-indicator{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  width: 80%;
  height: 100%;
  margin-top: 3%;
  margin-bottom: 20px;
  z-index: 2;
}

.title-marcas-unilever{
font-family: 'Unilever';
font-size:56px;
color: #005EEF;
}

.landing-unilever-section1-arrow-social-media{
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-self: flex-end;
  align-items: flex-end;
  width: 30%;
  height: 80%;
}
.social-media-unilever-container{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  width: 100%;
  height: auto;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) -1.97%, rgba(11, 25, 129, 0.35) 15.45%, #0B1981 49.02%, rgba(11, 25, 129, 0.35) 77.81%, rgba(255, 255, 255, 0) 100%);
background-blend-mode: multiply;
}
.social-media-unilever-container2{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  width: 100%;
  height: 10%;
  margin-top: 10%;
  z-index: 2;
}

.gg-arrow-long-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  color: #fff;
  transform: scale(var(--ggs,1));
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  box-shadow: inset 0 0 0 2px;
  height: 100px;
  width: 6px
}
.gg-arrow-long-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  color: #fff;
  width: 12px;
  height: 12px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  bottom: 0;
  left: -5px
}

.unilever-landing-firulete1{
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-10%, 165%);
  z-index: 1;
}
.unilever-landing-ring1{
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(0, 50%);
  z-index: 1;
  margin-left: 30px;
}
.unilever-landing-firulete2{
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(0%, 350%);
  z-index: 1;
}
.unilever-landing-ring2{
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-15%, 250%);
  z-index: 1;
  margin-right: 30px;
}
.unilever-landing-ring3{
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(0, 800%);
  z-index: 1;
}
.unilever-landing-firulete3{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.unilever-landing-firulete4{
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.landing-unilever-section-2{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  background-color: #1f36c7;
}
.landing-unilever-section-2-container-1{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 30%;
  margin-top: 5%;
}

.landing-unilever-section-2-container-1-title{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.landing-unilever-section-2-container-2{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 100%;
}
.landing-unilever-section-2-container-2-1{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
  place-content: flex-start;
  grid-gap: 30px;
  margin-top: 32px;
  width: 100%;
  justify-items: center;
  align-items: center;
  padding-bottom: 150px;
}
.landing-unilever-section-2-container-2-2{
  display: flex;
  justify-content: space-between;
  gap: 80px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}
.unilever-section-2-cards{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 346px;
  width: 286px;
  border-radius: 50px 0px;
  perspective: 1000px;
}
.unilever-section-2-card-inner{
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.unilever-section-2-cards:hover .unilever-section-2-card-inner{
  transform: rotateY(180deg);
}

.unilever-section-2-card-front, .unilever-section-2-card-back{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.unilever-section-2-card-front{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 50px 0px;
  background-color: #005EEF;
  color: #fff;
}

.section-2-card-front-unilever-text{
  font-family: Unilever;
  font-weight: 900;
  color: #fff;
  font-size: 24px;
}

.section-2-card-front-unilever-paraph{
  font-family: Unilever;
  font-weight: 400;
  color: #fff;
  font-size: 20px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.unilever-section-2-card-back{
  width: 100%;
  height: 100%;
  border-radius: 50px 0px;
  background-color: #00d7c4; /* 00d7c4 */
  color: #fff;
  transform: rotateY(180deg);
}


.unilever-title-1{
  font-family: Unilever;
  font-weight: 600;
  font-size: 56px;
  color: #FFFFFF;
}
.unilever-title-2{
  font-family: Unilever;
  font-weight: 600;
  font-size: 56px;
  color: #FFFFFF;
}
.unilever-title-3{
  font-family: Unilever;
  font-weight: 600;
  font-size: 56px;
  color: #005EEF;
}

.unilever-subtitle-1{
  font-family: Unilever;
  width: 70%;
  margin-top: 45px;
}

.landing-unilever-section-3{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  background-color: #1f36c7;
}
.landing-unilever-section-3-container{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 80%;
  height: 100%;
}

.landing-unilever-section-3-title-subcontainer{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 80%;
  height: 100%;
  margin-top: 5%;
}

.landing-unilever-section-3-container-1{
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;  
}
.landing-unilever-section-3-title-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: start;
}

.landing-unilever-section-3-container-2{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  margin-top: 5%;
  margin-left: 10%;
}

.landing-unilever-section-3-container-2-cards{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-content: flex-start;
  justify-items: center;
  align-items: flex-start;
  grid-gap: 70px;
  width: 50%;
  height: 100%;
  margin-top: 5%;
}
.unilever-section-3-container-2-cards-middle{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}
.landing-unilever-section-3-card{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 312px;
  height: fit-content;
  background-color: #005EEF;
  border-radius: 16px;
}
.landing-unilever-section-3-card-container-1{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 50%;
  margin-top: 20px;
  padding: 40px;
  text-align: left;
}
.landing-unilever-section-3-circle{
  display: flex;
  position: absolute;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #252525;
  color: #fff;
  font-size: 50px;
  text-align: center;
  font-weight: 700;
}

.landing-unilever-section-4{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  background-color: #F5F5F5;
}
.landing-unilever-section-4-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 100%;
  margin-top: 5%;
}
.landing-unilever-section-4-dropdowns-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 60%;
  height: 100%;
  margin-top: 5%;
  padding-bottom: 40px;
}
.landing-unilever-section-4-dropdowns-summary{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.empty-div-unilever:empty::before{
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\A0";
  height: 100%;
  width: 100%;
}
.landing-unilever-section-4-dropdowns-details-container{
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  background-color: #E0E0E0;
  width: 100%;
  height: 100%;
}
.landing-unilever-section-4-dropdowns{
  background-color: #1f36c7;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before{
  display: none;
}
.landing-unilever-section-5{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  background-color: #F5F5F5;
  padding-bottom: 2%;
}
.landing-unilever-section-5-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: fit-content;
  margin-top: 5%;
}
.landing-unilever-section-5-container-row{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
}
.landing-unilever-section-5-inner-1{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  width: 60%;
  height: 100%;
}
.landing-unilever-section-5-column-1-inner-1-title{
  font-family: Unilever;
  font-weight: 600;
  font-size: 25px;
  color: #FFFFFF;
}
.landing-unilever-section-5-column-1-inner-1-title1{
  font-family: Unilever;
  font-weight: 600;
  font-size: 25px;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
}
.landing-unilever-section-5-column-1{
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100vh;
  text-align: center;
  margin-right: 22px;
}
.landing-unilever-section-5-column-1-inner-1{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #1f36c7;
  margin-top: 5%;
  text-align: center;
  border-radius: 8px;
  height: 100%;
}
.landing-unilever-section-5-column-1-inner-2{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #00a889;
  margin-top: 5%;
  text-align: center;
  border-radius: 8px;
  height: 100%;
}
.landing-unilever-section-5-column-1-inner-3{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #1f36c7;
  margin-top: 5%;
  text-align: center;
  border-radius: 8px;
  height: 100%;
  padding: 50px;
}
.landing-unilever-section-5-column-2{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100vh;
  text-align: center;
  border-radius: 8px;
}
.landing-unilever-section-5-column-2-inner-1{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #ffe300;
  margin-top: 5%;
  text-align: center;
  border-radius: 8px;
  height: 100%;
  padding: 60px;
}
.landing-unilever-section-5-column-2-inner-2{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f752c7;
  margin-top: 5%;
  text-align: center;
  border-radius: 8px;
  height: 100%;
  padding: 60px;
}
.landing-unilever-section-5-column-2-inner-3{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #9c44c0;
  margin-top: 5%;
  text-align: center;
  border-radius: 8px;
  height: 100%;
}

.landing-unilever-section-5-inner-2{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 40%;
  height: 100%;
}
.landing-unilever-section-5-inner-2-inner-1{
  display: flex;
  justify-content: center;
  align-self: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1838px) {
  .unilever-landing-firulete3{
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 1732px) {
  .unilever-landing-ring3{
    transform: translate(00, 850%);
  }
.unilever-landing-ring2{
  transform: translate(-50%, 240%);
}
.unilever-landing-firulete2{
  transform: translate(0, 450%);
}
.scroll-container1{
  margin: 10px;
}
.scroll-container{
  margin: 10px;
  transform: translate(50%, 50%);
}
}

@media only screen and (max-width: 1740px) {
  .landing-unilever-section1-arrow-social-media{
    width: 30%;
  }
}
@media only screen and (max-width: 1600px) {
  .landing-unilever-section-2-container-2-1{
    padding-bottom: 50px;
  }
  .landing-unilever-section-1-container{
  height:100vh;
  }
  .landing-unilever-section-3-container{
    flex-direction: column;
  }
  .landing-unilever-section-3-container-2{
    margin-left: 0;
  }
  .landing-unilever-section-3-title-container{
    flex-direction: row;
    align-items: center;
  }
  .unilever-landing-ring3{
    transform: translate(0, 1000%);
  }
  .landing-unilever-section-1{
    height: fit-content;
  }
  .landing-unilever-section-5-container-row{
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 1740px) {
 .landing-unilever-section1-arrow-indicator{
  margin-top: 20px
 }
 .landing-unilever-section2-arrow-indicator{
  margin-top: 20px
 }
  .landing-unilever-section3-arrow-indicator{
    margin-top: 20px
  }
}
@media only screen and (max-width: 1500px) {
  .layer-pedal-landing{
    width: 70%;
  }
}
@media only screen and (max-width: 1490px) {
  .unilever-landing-firulete1{
    transform: translate(-50%, 165%);
  }
}
@media only screen and (max-width: 1400px) {
  .landing-unilever-section1-arrow-social-media{
    width: 30%;
  }
  .unilever-landing-ring1{
    display: none;
  }
  .unilever-landing-firulete1{
    display: none;
  }
  .unilever-landing-firulete2{
    display: none;
  }
  .unilever-landing-ring2{
    display: none;
  }
  .unilever-landing-ring3{
    display: none;
  }
  .unilever-landing-firulete3{
    display: none;
  }
  .unilever-landing-firulete4{
    display: none;
  }
  .landing-unilever-section-5-inner-1{
    width: 100%;
  }
}
@media only screen and (max-width: 1071px) {
  .landing-unilever-section1-arrow-social-media{
    width: 35%;
  }
  .layer-pedal-landing{
    width: 80%;
  }
}
@media only screen and (max-width: 1000px) {
  .unilever-subtitle-1{
    width: 90%;
  }
  .scroll-container{
    margin: 10px;
  }
}
@media only screen and (max-width: 980px){
  .landing-unilever-section-3-container-1{
    align-items: center;
    width: 100%;
  }
  .coffe-girl-unilever{
  display: none;
}
.landing-unilever-section-5-inner-2-inner-1{
  display: none;
}
.landing-unilever-section-4-dropdowns-container{
  width: 90%;
}
}

@media only screen and (max-width: 923px){
  .landing-unilever-section-1-container-body{
    width: 95%;
  }
  .landing-unilever-section-1-title-container{
    width: 70%;
  }
  .landing-unilever-section1-arrow-social-media{
    width: 30%;
  }
  .landing-unilever-section1-arrow-indicator{
    width: 90%;
  }
}

@media only screen and (max-width: 900px){
  .scroll-container{
    margin: 10px;
  }
  .landing-unilever-section1-arrow-social-media{
    display: none;
  }
  .landing-unilever-section-1-title-container{
    width: 100%;
  }
  .layer-pedal-landing{
    width: 60%;
  }
}
@media only screen and (max-width: 848px){
  .landing-unilever-section-2-container-2-2{
    flex-direction: column;
    gap: 20px;
  }
  .landing-unilever-section-3-container-2-cards{
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .landing-unilever-container{
    overflow-x: hidden;
  }
  .layer-pedal-landing{
    width: 75%;
  }
  .landing-unilever-layer-top{
    width: 100%;
    height: 100%;
    max-width: 478px;
    max-height: 92px;
  }
  .unilever-subtitle-1{
    width: 90%;
    margin-top: 15px;
  }
  
  .landing-unilever-section-1{
    height: 100%;
    padding-left: 30px; 
    padding-right: 30px;
    background-position: 70%;
    background-image:linear-gradient(180deg, #00076e 6%, rgba(51, 51, 51, 0) 100%), url(../../assets/imagenes/unileverBg-Mobile.png);
  }
  .landing-unilever-section-2{
    padding-left: 30px; 
    padding-right: 30px;
  }
  .landing-unilever-section-1-container-body{
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    flex-direction:column;
  }
  .landing-unilever-body-scroll-container{
    margin-top: 40px;
  }
  .landing-unilever-section-1-title-container{
    width: 100%;
  }
  .landing-unilever-section1-paraph-container{
    width: 100%;
    height: 100%;
    margin-top: 2%;
  }
  .scroll-container, .scroll-container1, .scroll-container-3{
    display: none;
    margin-right: 0;
  }
  .landing-unilever-section1-button-container{
    margin-top: 20px;
  }
  .landing-unilever-section1-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 48px;
    border-radius: 24px;
    background-color: #1F36C7;
    text-align: center;
  }
  .landing-unilever-section-1-logo{
    width:100%;
  }
  .landing-unilever-section1-arrow-social-media{
    display: none;
  }
  .social-media-unilever-container{
    display: none;
  }
  .social-media-unilever-container2{
    display: none;
  }
  .landing-unilever-section1-arrow-indicator{
    margin-top: 40px;
    padding-bottom: 5%;
    width: 100%;
  }
  .landing-unilever-section2-arrow-indicator{
    margin-top: 40px;
  }
  .landing-unilever-section3-arrow-indicator{
    margin-top: 40px;
  }
  .gg-arrow-long-down {
    box-sizing: border-box;
    position: relative;
    display: block;
    color: #fff;
    transform: scale(var(--ggs,1));
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
    box-shadow: inset 0 0 0 2px;
    height: 100px;
    width: 6px;
    z-index: 100;
  }
  .landing-unilever-section-2-container-2{
    width: 100%;
  }
  .landing-unilever-section-3-container-2{
    margin-top: 10%;
  }
  .landing-unilever-section-3-circle{
    transform: translate(-20%, -50%);
  }
}
@media only screen and (max-width: 768px) {
  .unilever-title-2{
    font-size: 7vw;
  }
  .unilever-title-3{
    font-size: 7vw;
  }
  .landing-unilever-section-1-container{
    height: fit-content;
    padding-bottom: 10%;
  }
  .title-marcas-unilever{
    font-size: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .unilever-title-1{
    font-size: 8vw;
  }
  .landing-unilever-section-5-inner-1{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15%;
  }
  .landing-unilever-section-5-column-1{
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
  }
  .landing-unilever-section-5-column-2{
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .layer-pedal-landing{
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
.landing-unilever-section-1-container{
  height: fit-content;
}
}
@media only screen and (max-height: 790px) {
  .landing-unilever-section-1-container{
    height: fit-content;
    padding-bottom: 2%;
  }
  }
  @media only screen and (max-height: 700px){
    .landing-unilever-section-5{
      padding-bottom: 40%;
    }
  }