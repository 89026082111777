
.Profesional-Home-Contenedor-Global {
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
}

.Profesional-Home-container-inner {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
}

.Profesional-Home-Contenedor-1-new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.Profesional-Home-Contenedor-2-new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.Profesional-Home-Contenedor-3-new {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 32px;
}

.Profesional-Home-Contenedor-3-inner-1 {
    width: 70%;
    display: flex;
    margin-left: 30px;
    background-color: white;
    padding: 15px;
}

.Profesional-Home-Contenedor-3-inner-2 {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 30px;
    border-bottom: 1px solid #D6D6D6;
    cursor: pointer;
}

.Profesional-Home-Contenedor-3-inner-2-1 {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #C5C5C5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}

.Profesional-Home-Contenedor-3-inner-2-1-active {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #202256;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}

.Profesional-Home-Contenedor-3-inner-2-2 {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #C5C5C5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 18px;
    margin-bottom: 15px;
    cursor: pointer;
}

.Profesional-Home-Contenedor-3-inner-2-2-active {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #202256;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 18px;
    margin-bottom: 15px;
    cursor: pointer;
}

.Profesional-Home-Contenedor-4-new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 32px;
    width: 100%;
}

.Profesional-Home-Contenedor-4-new-grid {
    /* auto grid columns */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
    place-content: center;
    grid-gap: 30px;
    margin-top: 32px;
    width: 100%;
}

.new-home-profesiona-bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    width: 100%;
    padding-bottom: 40px;
}

.new-home-profesional-bottom-container-carousel {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

}

.new-home-profesional-bottom-container-carousel-title {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.new-home-profesional-bottom-container-carousel-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 32px;
}

.new-home-profesional-bottom-container-tour {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 32px;

    background-color: #33307F;
    border-radius: 20px;
}

.tour-button-container {
    padding: 20px;
    width: 100%;
}

.tour-button-activate {
    border-radius: 5px;
    background-color: #FFFFFF;
    color: #202256;
    font-size: 14px;
    font-weight: 400;
    height: 32px;
    width: 175px;
}

.tour-circle-container {
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    bottom: 0;
}

/* .tour-circle{
    width: 100px;
     height: 10px;
     background: #202256;
     border-radius: 100px 0 20px 0;
     -moz-border-radius: 100px 0 20px 0;
     -webkit-border-radius: 100px 0 20px 0;
} */


.Profesional-Home-Contenedor-Videos {
    width: 100%;
    place-content: center;
    align-content: center;
    align-items: center;
    justify-content: start;
}


.Profesional-Home-Title {
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 15px;
    color: #202256;
}

.Profesional-Home-SubTitle {
    /*font-style: normal;*/
    font-weight: 700;
    font-size: 16px;
    color: #5D5D5D;
}

.Profesional-Home-Video {
    /*object-fit: contain;*/
    /*height: 213px;*/
    width: 70%;
    margin-right: 20px;
    cursor: pointer;
}

.Profesional-Home-Video-Secundario {
    display: flex;
    justify-content: center;
    align-items: center;
    /*height: 213px;*/
    width: 70%;
    border-radius: 10px;
}

.Home-Profesional-Resultados {
    font-weight: 300;
    font-size: 16px;
    color: #C5C5C5;
}

.Profesional-Home-Sub-Sub-Contenedor-Videos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-content: space-evenly;
    justify-content: space-evenly;

}

.play-button {
    background: #3b3b3b;
}

.button-Profesional-Home {
    background: #F9F9F9;
    border: 0.5px solid #202256;
    border-radius: 5px;
    width: 303px;
    height: 28px;
    margin-right: 1em;
    color: #202256;
    font-size: 14px;
}

.button-Profesional-Home:hover {
    background: #202256;
    color: #FFFFFF;
}

.Profesional-Home-SubTitle-2 {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.Profesional-Home-Search {
    background-color: transparent;
    height: 35px;
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: #C5C5C5;
    text-indent: 25px;
    margin-left: 13px;
}

.Profesional-Home-Search::placeholder {
    color: #202256;
    margin-left: 13px;
}

.Profesional-Home-Search:focus {
    border-color: #202256;
    outline: none;
}

/* .profesional-Home-Search::-ms-clear {
    color: #202256 !important;
    display: none;
} */


.Profesional-Home-Search-Container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.Profesional-Home-Search-Selector {
    background-color: transparent;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    height: 35px;
    border-color: #C5C5C5;
    width: 100%;
    margin-bottom: 10px;
    text-indent: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.lupa {
    font-size: 50px;
    color: #202256;
    position: absolute;
    margin-top: 5px;
    margin-left: 5px;
}

.Profesional-Home-Search-Selector:focus {
    border-color: #202256 !important;
    color: #202256 !important;
    outline: none;
}

.cant-filtro {
    position: absolute;
    margin-left: -30px;
    margin-top: 10px;
    background-color: rgb(32, 34, 86);
    width: 17px;
    height: 17px;
    color: white;
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    border-radius: 100%;
}


.Home-Profesional-Test-Container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
    place-content: center;
    grid-gap: 30px;
    margin-top: 32px;
    width: 100%;
}

/* @media (max-width: 1025px) {
    .Profesional-Home-Search-Container {
        flex-direction: column;
        align-items: center;
    }

    .Profesional-Home-Search {
        width: 100%;
        text-indent: 5px;
    }

    .Profesional-Home-Search-Selector {
        width: 100%;
        margin-top: 10px;
        text-indent: 5px;
    }

    .lupa {
        color: transparent;
    }
    .cant-filtro{
        display: none;
    }

    .Profesional-Home-Contenedor-Global {
        padding-left: 0;
        padding-right: 0;
    }
} */
.contenedor-fix-1 {
    margin-left: calc(237px + 98px);
    margin-right: 20px;
}

.home-profesional-ai-button {
    border: none;
    border-radius: 8px;
    background-color: #33307f;
    color: white;
    font-size: 16px;
    width: 200px;
    height: 40px;
    margin-left: 30px;
    transition: 0.5s;
}

.home-profesional-ai-button:hover {
    background-color: #ee7919;
    transition: 0.5s;
}

@media (max-width: 1420px) {
    .new-home-profesiona-bottom-container {
        flex-direction: column;
    }

    .new-home-profesional-bottom-container-carousel {
        width: 100%;
    }
}

@media (max-width: 1300px) {
    .contenedor-fix-1 {
        margin-left: 20px;
    }

    .new-home-profesiona-bottom-container {
        flex-direction: row;
    }
}

@media (max-width: 1200px) {
    .new-home-profesiona-bottom-container {
        flex-direction: column;
    }

    .new-home-profesional-bottom-container-carousel {
        width: 100%;
    }

    .new-home-profesional-bottom-container-tour {
        width: 80%;
        margin-left: 0;
    }
}

@media (max-width: 425px) {
    .Profesional-Home-Search-Container {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 10px;
    }

    .Profesional-Home-Search {
        background-color: transparent;
        height: 35px;
        width: 100%;
        border-radius: 5px;
        border-style: solid;
        border-width: 2px;
        border-color: #C5C5C5;
        text-indent: 25px;
        margin-bottom: 20px;
    }

    .Profesional-Home-Search-Selector {
        background-color: transparent;
        border-radius: 5px;
        border-style: solid;
        border-width: 2px;
        height: 35px;
        border-color: #C5C5C5;
        width: 100%;
        margin-bottom: 10px;
        text-indent: 25px;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .subcontenedor-filtros {
        width: 100% !important;
    }

}

@media (max-width: 740px) {
    .new-home-profesional-bottom-container-carousel-container {
        margin-bottom: 32px;
        margin-right: 0;
    }
}

@media (max-width: 930px) {
    .Profesional-Home-Contenedor-Global {
        padding-left: 0;
        padding-right: 0;
    }

    .Profesional-Home-Contenedor-Videos {
        display: revert !important;
    }

    .Profesional-Home-Contenedor-Videos img {
        width: 298px !important;
    }

    .Profesional-Home-Sub-Sub-Contenedor-Videos {
        flex-direction: column;
        align-items: center;
    }

    .Profesional-Home-Video-Secundario {
        justify-content: normal;
    }

    .Profesional-Home-Contenedor-3-new {
        flex-direction: column;
        justify-content: center;
    }

    .Profesional-Home-Contenedor-3-inner-1 {
        width: 100%;
        margin: auto;
    }

    .Profesional-Home-Contenedor-3-inner-2 {
        width: 100%;
        margin: auto;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .Profesional-Home-Video {
        margin-bottom: 18px;
        width: auto !important;
    }

    .css-0 iframe {
        width: 330px !important;
        height: 330px;
        margin-left: 2em;
    }

    .popupcalifacionsubpop {
        display: revert !important;
    }

    .progres-cv, .progres-cv button {
        display: revert !important;
        margin: 0 auto !important;
    }

    .button-Profesional-Home {
        margin-bottom: 1em !important;
    }

    .css-14jnub0 {
        width: 360px !important;
    }

    .cant-filtro {
        display: none;
    }

    .container-opp {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .new-home-empresa-videos-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

