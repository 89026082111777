@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.font-top {
    color: rgba(32, 34, 86, 1);
    font-weight: 700;
    margin: 0;
    font-size: 45px;
    line-height: 7vh;
    font-family: 'Poppins';
    margin-bottom: 2rem;
    text-align: start !important;
}

.profilebutton {
    width: 59px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #F07130;
}

.profilebutton2 {
    margin-top: 40px;
    width: 59px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #F07130;
}

.profilebutton-active {
    background-color: #202256;
    width: 59px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}

@media (max-width: 725px) {
    .profilePopup{
        width: 90% !important;;  
    }
}

@media (max-height: 768px) {
    .profilePopup{
        height: auto !important;;  
    }
}