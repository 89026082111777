.container-infoCompany{   
    background: #FFFFFF;
    border-radius: 5px;      
    margin-top: 22px;
    margin-right: 3em;
    padding: 3em;
}

.projects-company h1{   
    font-weight: 700;
font-size: 40px;
line-height: 50px;
display: flex;
align-items: center;
color: #F07130;
}

.projects-company h3,
.info-company h3{   
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;   
    color: #202256;
}

.info-company{
width:50%;
}

.info-company p{   
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;   
    color: #5D5D5D;
    align-items: center;
}

.detalles-oportunidades p{
    color: #151515FC;
    font-size: 14px;
    font-weight: 700;
    margin: 1em 0 0 2em;
   

}

.detalles-oportunidades span{
    color: #5D5D5D;
    font-size: 14px;   
    margin-left: 2em;
}