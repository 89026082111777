.match-form {
    width: 85%;
    max-width: 1440px;
    margin: auto;
    display: flex;
    margin-bottom: 5rem;
    justify-content: center;
    background-color: #ffffff;
}

.match-projects__container{
display: flex;
gap: 5rem;
justify-content: center;
}
.text-center{
    text-align: center;
}
.match-btn {
    padding: 10px 10px 10px 10px;
    border-radius: 25px;
    background: var(--main-yellow-findally);
    font-size: 13px;
    font-family: var(--font-segundary);
}
.match-list{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.math-list:visited{
    background-color: var(--color-amarillo);
    color: white;
}
.math-list:focus{
    background-color: var(--color-amarillo);
    color: white;
}
.math-list:active{
    background-color: var(--color-amarillo);
    color: white;
}
.math-list:hover{
    background-color: var(--color-amarillo);
    color: white;
}
.match-users__list {
    background-color: #ffffff;
    height: 300px;
    overflow: scroll;
}
.match-proyect__list{
    
    background-color: #ffffff;
    height: 300px;
    overflow: scroll;
}