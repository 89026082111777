.login-emp {
  background-size: cover;
  box-sizing: content-box;
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.login__container {
  text-align: center;
  width: 450px;
  min-width: 400px;
  margin-left: 55%;
  background-color: white;
  margin-right: 2%;
  min-height: 400px;
  padding: 60px 40px 40px;
  border-radius: 5%;
  box-sizing: border-box;
  overflow: auto;
}
.unactive{
  display: none;
}
/* login form */
.login__container__form {
  display: flex;
  flex-direction: column;
}

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid;
  outline-style: none;
  margin-top: 4%;
  padding: 12px;
  box-sizing: border-box;
}

.input:focus {
  border-bottom: 2px solid var(--main-yellow-findally);
  outline: none;
}
.form__validate {
  margin-top: 5%;
  margin-bottom: 7%;
}

.login__form__button {
  margin-top: 5%;
  margin-bottom: 5%;
  background-color: var(--main-yellow-findally);
  width: 135px;
  height: 39px;
  border-radius: 1.25rem;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  color: black;
  font-size: large;
  font-weight: 700;
  text-decoration: none;
}

.login__form-social {
  margin-bottom: 6%;
}

.login__form-social-img {
  text-align: center;
  margin-left: 20px;
  align-self: center;
  border-radius: 3px;
}

.login__form-social-btn-fg {
  display: inline-flex;
  border-radius: 20px;
  width: 216px;
  height: 36px;
  color: white;
  text-decoration: none;
  border: 1px solid;
  background-color: #3b5998;
}

.login__form-social-btn-lin {
  display: inline-flex;
  border-radius: 20px;
  margin-top: 10px;
  width: 216px;
  height: 36px;
  text-decoration: none;
  color: white;
  border: 1px solid;
  background-color: #2867b2;
}

.login__form-social-btn-go {
  display: inline-flex;
  width: 210px;
  height: 36px;
  margin-top: 10px;
  border-radius: 20px;
  text-decoration: none;
  color: black;
  border: 1px solid;
}
.kep-login-facebook {
  border-radius: 20px;
    margin-top: 10px;
    width: 216px;
    height: 36px;
    text-decoration: none;
    color: white;
    border: 1px solid;
    padding: 10px !important;
    border-radius: 20px !important;
}
.login__form-social-txt {
  margin: auto;
}

.login__form__button:hover {
  border: 1px solid blueviolet;
}

.login__form__button:focus {
  border: 2px solid var(--main-purple-findally);
}
.form__noregister-text-link {
  font-size: large;
  font-weight: bold;
  text-decoration: none;
}

@media (max-width: 1050px) {
  .login__container {
    padding: 3%;
    box-sizing: border-box;
    width: 50%;
    margin: 0 auto;
    overflow: auto;
  }
}

@media (max-width: 700) {
  .login {
    display: block;
    margin: 0 auto;
  }

}
@media (max-width: 500px) {
  .login__container {
    width: 50%;
    margin: 0;
    margin-right: 0;
  }
  .login_form-social-txt {
    display: none;
  }
  .login__form-social-img {
    width: 20px;
    margin-left: 20px;
  }
  .login__form-social-btn-go {
    width: fit-content;
    margin-top: 10px;
    width: 80%;
  }
  .login__form-social-btn-lin {
    width: fit-content;
    width: 80%;
  }
  .login__form-social-btn-fg {
    width: fit-content;
    width: 80%;
  }
}
@media (max-width: 450px) {
  .login {
    display: inline;
  }
  .login__container {
    width: 100%;
    height: max-content;
    padding: 30px;
    min-width: 250px;
  }
}
