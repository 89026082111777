.SidebarCV-Button {
    background: #1f1561;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 35px;
    width: 170px;
    margin-bottom: 50px;
}

.SidebarCV-cont {
    width: 100%;
}

.SidebarCV-Err {
    margin-top: 15px;
    color: #5D5D5D;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}

.SidebarCV-LI {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-LI-2 {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E5DCF7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
}

.SidebarCV-LI-Err{
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E71C00;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV {
    display: flex;
    cursor: pointer;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV-disabled {
    display: flex;
    cursor: not-allowed !important;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #868686;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-Sub-Cont {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 45px;
}

.SidebarCV-Sub-Text {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #5D5D5D;
}

.SidebarCV-Sub-Cont-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 45px;
}