.perfil {
  width: 85%;
  margin: 0 auto;
  display: grid;
  padding: 2rem 0rem;
  grid-template-columns: 31.66% 1fr;
  grid-column-gap: 1.4em;
  grid-row-gap: 1em;
  background-color: white;
}
.projects-success-case {
  font-size: 15px !important;
  border-radius: 2px !important;
  padding: 0.5em 1.2em 0.5em 1.2em !important;
  border-left: 0.2em solid #afafaf;
  border-right: 0.2em solid #afafaf;
  margin: 0 !important;    
  width: 100%;
  height: 100%;
  text-align: center;
}
.perfil-header {
  grid-column-start: 1;
  grid-column-end: 3;
  height: 17.8em;
  background-color: white;
  -webkit-box-shadow: 0px 2px 10px 0px #7070701A;
  -moz-box-shadow: 0px 2px 10px 0px #7070701A;
  box-shadow: 0px 2px 10px 0px #7070701A;
}

.perfil__header-container {
  height: 9.4em;
  width: 100%;
  background-color: white;
}
.perfil__header__container-img {
  position: relative;
}

.perfil__header-profile {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  background-color: white;
}

.perfil__header__profile-txt {
  background-color: none;
}
.perfil__header-profile-img {
  border: 1px solid;
  border-radius: 50%;
  width: 112px;
  margin-bottom: 10px;
}

.perfil-info {
  grid-column-start: 1;
  grid-column-end: 3;
  height: 2.3em;
  padding: 1rem;
  text-align: center;
  background: transparent;
  background-color: transparent;
  -webkit-box-shadow: 0px 2px 10px 0px #7070701A;
  -moz-box-shadow: 0px 2px 10px 0px #7070701A;
  box-shadow: none;
}

.perfil__info-p {
  padding: 5px;
  background-color: white;
}

.perfil-data {
  grid-row-start: 3;
  padding: 1rem;
  grid-row-end: 5;
  background-color: white;
  -webkit-box-shadow: 0px 2px 10px 0px #7070701A;
  -moz-box-shadow: 0px 2px 10px 0px #7070701A;
  box-shadow: 0px 2px 10px 0px #7070701A;
}

.perfil__data-card {
  background-color: white;
  padding: 20px 0px;
  margin-bottom: 5px;
  -webkit-box-shadow: 0px 2px 10px 0px #7070701A;
  -moz-box-shadow: 0px 2px 10px 0px #7070701A;
  box-shadow: 0px 2px 10px 0px #7070701A;}

.perfil__data-tittle {
  background-color: white;
  margin-bottom: 10px;
  margin-left: 1.8em;
}

.perfil__data-text {
  background-color: white;
  margin-left: 1.8em;
}

.perfil-comments {
  background-color: white;
  grid-row-start: 5;
  grid-column: 2;
  padding: 1rem;
  padding-left: 29px;
  -webkit-box-shadow: 0px 2px 10px 0px #7070701A;
  -moz-box-shadow: 0px 2px 10px 0px #7070701A;
  box-shadow: 0px 2px 10px 0px #7070701A;
}

.perfil-comments-tittle {
  margin-bottom: 13px;
  margin-top: 25px;
}

.perfil-comments-text {
  margin-bottom: 23px;
}

.perfil__comments-list {
  display: block;
}

.perfil-score {
  grid-column: 1;
  height: 14.8em;
  background-color: white;
  margin: .5rem;
  text-align: center;
  padding: 1rem;
  -webkit-box-shadow: 0px 2px 10px 0px #7070701A;
  -moz-box-shadow: 0px 2px 10px 0px #7070701A;
  box-shadow: 0px 2px 10px 0px #7070701A;
}

.perfil__score-tittle {
  font-size: larger;
  text-align: center;
}

.perfil__score-form {
  width: 250px;
  margin: 0 auto;
  height: 50px;
}
.perfil__score-form p {
  text-align: center;
}
.perfil__score-form-label {
  font-size: 20px;
}
.perfil__score-form-input {
  display: none;
}
.perfil__score-form-label {
  color: grey;
}
.clasificacion {
  direction: rtl;
  unicode-bidi: bidi-override;
}
.perfil__score-form-label:hover,
.perfil__score-form-label:hover ~ label {
  color: orange;
}
input[type="radio"]:checked ~ .perfil__score-form-label {
  color: orange;
}

.perfil-skills {
  grid-column: 1;
  min-height: 4rem;
  height: auto;
  max-height: 6rem;
  background-color: white;
  grid-row-start: 4;
  padding: 2em;
  margin: .5rem;
  -webkit-box-shadow: 0px 2px 10px 0px #7070701A;
  -moz-box-shadow: 0px 2px 10px 0px #7070701A;
  box-shadow: 0px 2px 10px 0px #7070701A;
}
.perfil__skills-tittle {
  font-size: 16px;
  margin-bottom: 1em;
}
.perfil-update{
  grid-column: 1;
  text-align: center;
  background-color: white;
  grid-row-start: 5;
  height: 12.5em;
  padding: 2em;
  padding: 1rem;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 10px 0px #7070701A;
  -moz-box-shadow: 0px 2px 10px 0px #7070701A;
  box-shadow: 0px 2px 10px 0px #7070701A;
}
.perfil__update-title{
  margin-bottom: 22px;
}
.perfil__update-line{
  background-color: var(--main-yellow-findally);
  height: 14px;
  width: 227px;
}

.perfil__update-button{
  margin-top: 50px;
}

.container-casos-exito{
  background-size: cover;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.div-contenedor-botones{
display: flex;
  width: 100%;   
  height: 2.5em;
  justify-content: space-around;
}
.container-casos{
width: 80%;
min-width: 300px;
max-width: 700px;
margin-top: 50px !important;
margin-bottom: 50px !important;
margin: 0 auto;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
background-color: #EAEAEA;
min-height: 400px;
border-radius: 2%;
box-sizing: border-box;
overflow: auto;
}
.Dropzone {
background-color: #EAEAEA !important;
}
.Upload-caso{
background-color: #EAEAEA !important;
}
.tittle-casos-exito{
margin-top: 1em;
font-size: 22px;
font-weight: 800;
}

.subtittle-casos-exito{
color: #808080;
margin-top: 1em;
font-size: 15px;
font-weight: 400;
}


@media screen and (max-width:630px){
  .perfil{
      width: 100%;
      grid-template-columns: 1fr;
  }
  .perfil_body-content{
      width: 96%;
      text-align: center;
  }
}