.container-carrousel{
    width: 70%;
}

.Container-Logos-Aliados{
    width:100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;   
}

.Container-Logos-Aliados2{
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;   
}

.container-marcas-unilever{
    background-color:white ;
    width:100%;
    display: flex ;
    justify-content:flex-end;
    align-items:center; 
    height:160px;
}


@media(max-width:768px){
  
    .Container-Logos-Aliados{
        flex-direction: column;
       height:35vh;      
    }
    .logo-sena-responsive{
        width:450px
    }
    .Container-Logos-Aliados2{
        flex-direction: column;
        width:100%;
        height:35vh;
        margin-top: 20px;      
    }
    .container-marcas-unilever{
        flex-direction: column;       
        height:auto !important; 
        padding-bottom: 50px; 
        width:100vh;    
    }
}