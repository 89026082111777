.popup-date-unilever-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-date-unilever-inner-1-1{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.popup-date-unilever-inner-1{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-date-unilever-inner-2{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.date-popup-container-big{
  width: 500px;
  height: 800px;
}

.day-tile{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #fe7c7c;
  background-color: #fe7c7c;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
 background: #f8f8fa;
 border-radius: 6px;
}
.react-calendar__tile--now {
  background: transparent !important;
 border-radius: 6px;
 font-weight: normal;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
 border-radius: 6px;
 font-weight: bold;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
 background: #f8f8fa;
}
.react-calendar__tile--active {
 background: #f07130 !important;
 font-weight: bold;
 color: white;
}
.react-calendar__tile:disabled{
    background: #656565;
    font-weight: normal;
    color: #f8f8fa;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
 background: #f07130 !important;
 color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
 background-color: #f8f8fa;
}

.unilever-buttons-hours-grid{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  justify-content: center;
  place-items: center;
  align-items: center;
}
.unilever-select-button-hour{
  border: 1px solid #202256;
  border-radius: 8px;
  width: 100px;
  height: 30px;
  background-color: #E5DCF7;
  color: #202256;
}

.unilever-select-button-hour2{
  border: 1px solid #202256;
  border-radius: 8px;
  width: 100px;
  height: 30px;
  background-color: #202256;
  color: white;
}

.unilever-select-button-hour:hover{
  background-color: #f07130;
  color: white;
}

.unilever-select-button-hour2:hover{
  background-color: #f07130;
  color: white;
}

.unilever-select-button-hour-active{
  border: 1px solid #202256;
  width: 100px;
  height: 30px;
  background-color: #f07130;
  border-radius: 8px;
  color: white;
}