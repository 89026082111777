.contenedor-pricing-empresa{
  display: flex;
    align-items: center;   
}
.pricing-details-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 90%;
}
.pricing-details-body-card{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  height: 204px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 7px 32px 0px #0000001A;
  padding-bottom: 20px;
}
.pricing-details-card-inner-1{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}
.pricing-details-card-inner-1-1{
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
.pricing-details-card-inner-1-2{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
  margin: 20px;
}
.pricing-details-card-inner-2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  height: 100%;
}
.pricing-details-body-card-aditional{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  height: 100%;
  margin-top: 2%;
  padding-bottom: 5%;
}
.aditional-services-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  height: 90%;
  margin-top: 3%;
}
.aditional-services-dropdows-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.aditional-services-dropdows-container-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
}
.pricing-footer-body-total-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.container-popup-pago{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    flex-direction: column;
}
.container-buttons-popup{
  display: flex;
  width: 70%;
  justify-content: space-between;
}
.label-popup-pago{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 184px;
    font-size: 20px;    
    color: #fff; 
    background: #F07130;
    border-radius: 5px;
    cursor: pointer;  
}

.button-cerrar-popup{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 184px;
  height: 28px;
  font-size: 20px; 
  background: #202256;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}

@media (max-width: 1100px) {
  .pricing-details-body{
    width: 100%;
    height: 100%;
  }
  .aditional-services-container{
    margin-top: 5%;
  }
  .pricing-details-body-card{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .pricing-details-card-inner-1{
    flex-direction: column;
  }
  .pricing-details-card-inner-1-1{
    width: 100%;
    margin-top: 5%;
  }
  .pricing-details-card-inner-1-2{
    width: 100%;
  }
  .pricing-details-card-inner-2{
    width: 100%;
    margin-left: 10px;
  }
}