.CarruselCards-global-container {
    background: #FFFFFF;
    border-radius: 8px;
    min-width: 436px;
    height: 337px;
    margin-right: 40px;
    margin-bottom: 40px;
}

.CarruselCards-global-title {
    font-weight: 700;
    font-size: 20px;
    color: #393939;
    margin-bottom: 30px;
}

.CarruselCards-global-desc {
    font-weight: 400;
    font-size: 16px;
    color: #252525;
}

.CarruselCards-image-container {
    background-position: top;
    height: 150px;
    border-radius: 8px 8px 0 0;
    background-repeat: no-repeat;
    background-size: cover;
}
.CarruselCards-video-container{
    background-position: top;
    height: 100%;
    width: 100%;
    border-radius: 8px 8px 0 0;
    background-repeat: no-repeat;
    background-size: cover;
}

/*.CarruselCards-image {
    object-fit: cover;
    width: 100%;
    height: 150px;
    border-radius: 8px 8px 0 0;
}*/

.CarruselCards-sub-container {
    padding: 20px;
}