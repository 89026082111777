.Sidebar-Cont-admin {
  background: #fe7c7c;
  width: 237px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all 0.5s ease;
}

.Sidebar-Cont-admin-2{
  background: #FFFFFF;
  width: 237px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all 0.5s ease;
}

.Sidebar-Cont2-admin {
  background: #FFFFFF;
  width: 237px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.Sidebar-Logo-admin {
  width: 100px;
  margin-bottom: 60px;
}

.Sidebar-Sub-Cont-1-admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 72px;
}

.Sidebar-Sub-Cont-2-admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 35px;
}



.Sidebar-Sub-Cont-But-2-admin {
  padding-left: 28.15px;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #C5C5C5;
  width: 90%;
  height: 55px;
  background: transparent;
  margin-bottom: 1.5em;
  margin-left: 50px;
}
.Sidebar-Sub-Cont-But-2-admin:hover{
  text-decoration: none;
  color:#C5C5C5;
} 

.Sidebar-Sub-Cont-But-admin {
  padding-left: 28.15px;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #C5C5C5;
  width: 90%;
  height: 55px;
  background: transparent;  
  margin-left: 50px;
}

.Sidebar-Sub-Cont-But-active-admin {

  padding-left: 28.15px;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #202256;
  width: 90%;
  height: 55px;
  background: #F9F9F9;
  margin-left: 50px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
      
}
.Sidebar-Sub-Cont-But-admin:hover{
  text-decoration: none;
  color:#C5C5C5;
}

.Sidebar-Sub-Cont-But-active-admin:hover{
  text-decoration: none;
  color:#202256;
}

.Sidebar-Sub-Cont-Img-admin {
  margin-right: 15px;
}

.Sidebar-Sub-Cont-2-Special-But-admin {
  background: #F07130;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  height: 35px;
  width: 170px;
  margin-bottom: 50px;
}


@media (max-width: 1300px) {

      .Sidebar-Cont-admin-2{
        background: #fe7c7c;
        transition: 0.5s;
      }

     .Sidebar-Cont2-admin{
      /* display:none; */
      background-color: #202256;
     }

     .Sidebar-Sub-Cont-But-active-admin {

      padding-left: 28.15px;
      font-weight: 400;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      color: #202256;
      width: 80%;
      height: 55px;
      background: #F9F9F9;
      margin-left: 47px;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
          
  }
  .Sidebar-Cont-admin {
      display: none;
  }

}