.home-unilever-banner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
}

.container-buttons-uni {
    display: flex;
    margin-top: 3%;
    width: 90%;
}

.button-home-unilever {
    width: 324px;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #fff;
    border-radius: 8px;
    margin-right: 30px;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}

.button-home-unilever:hover {
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .container-buttons-uni {
        flex-direction: column;
        align-items: center;
        margin-top: 5%;
    }

    .button-home-unilever {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }
}

/*marcelo css*/

.home-unilever-subtitle {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
}

.home-unilever-carrusel-global-container {
    width: 90%;
}

.home-unilever-carrusel-top {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
    width: 100%;
}

.home-unilever-carrusel-sub-container {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
}

.home-unilever-arrow-button {
    background-color: transparent;
}

@media (max-width: 600px) {
    .home-unilever-carrusel-top {
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        width: 100%;
    }
}