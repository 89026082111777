
.contenedor {
    width: 15vw;
    width: 237px;
    float: left;
    height: 100vh;
    position: fixed;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    z-index: 100;
}

.h2-header-empresa {
    font-family: 'Poppins';
    color: #C5C5C5;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    transition: 400ms;
}

.h2-header-empresa:hover {
    color: #202256;
    font-weight: bold;
    transition: 400ms;
}

.h2-header-empresa-active {
    font-family: 'Poppins';
    color: #202256;
    /*font-style: normal;*/
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.circle {
    color: #C5C5C5;
}

.circle-active {
    color: #202256;
}

.link-header {
    text-decoration: none;
    display: flex;
    margin-bottom: 2rem;
    cursor: pointer;
    padding-bottom: 1vh;
    color: rgba(197, 197, 197, 1);
    padding-top: 0.5vh;
}

.link-header-color {
    text-decoration: none;
    display: flex;
    margin-bottom: 2rem;
    cursor: pointer;
    color: rgba(32, 34, 86, 1);
    padding-bottom: 1vh;
    padding-top: 1vh;
    border-right: rgba(32, 34, 86, 1) solid;
}

.popupproyecto {
    display: flex;
    position: absolute;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    z-index: 101;
    align-items: center;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
}

.popupproyectofondo {
    display: flex;
    position: absolute;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    z-index: 2;
    align-items: center;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba(125, 125, 125, 0.72);
    mix-blend-mode: normal;
    backdrop-filter: blur(31px);
}

.popupproyectocartel {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 98vw;
    max-width: 772px;
    border-radius: 10px;
    height: 90vh;
    max-height: 550px;
    z-index: 3;
    background-color: white;
}

.popupproyectocartel2 {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 772px;
    border-radius: 10px;
    height: 550px;
    z-index: 3;
    background-color: white;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 2em;
}

.css-qivjh0-MuiStepLabel-label {
    font-size: 0.9rem !important;
}

.imagenes-gris {
    filter: grayscale(100);
    opacity: 0.3;
}

.imagenes-color{
    filter: grayscale(0);
    opacity: 1;
}