*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 10px;
  border: 3px solid #ffffff;
  height: 10px;
}

.small-card-linked-container{
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  width: 100%;
  transition: all 0.3s ease-in-out;
    margin-top: 10px;
    border-radius: 10px;
    filter: drop-shadow(0px 7px 32px rgba(0, 0, 0, 0.1));
}

.small-card-linked-container-active{
    display: flex;
    flex-direction: column;
    background-color: #202256;
    width: 100%;
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
    margin-top: 10px;
    border-radius: 10px;
    filter: drop-shadow(0px 7px 32px rgba(0, 0, 0, 0.1));
}

.small-card-linked-image{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}

.big-card-linked-container{
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /*overflow: auto;*/
  width: 100%;
  border-radius: 8px;
}

.oportunidades-big-card-top-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 20px 0px 40px;
}

.oportunidades-big-card-top-image{
  display: flex;
  justify-content: center;
  align-self: flex-start;
}
.oportunidades-big-card-top-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 20px;
  width: calc(100% - 164px);
  padding-left: 20px;
}
.clipboard-opportunitie-card{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background-color: #f2f2f2;
    cursor: pointer;
    align-self: flex-start;
    transition: all 0.3s ease-in-out;
}
.oportunidades-big-card-body-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px 40px 20px 40px;
}
.oportunidades-big-card-body-inner{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 60%;
}
.oportunidades-big-card-body-inner-2{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 40%;
    padding: 0 30px 30px 30px;
}

@media (max-width: 1580px){
    .oportunidades-big-card-body-container{
        padding: 20px 0 20px 40px;
    }
}
