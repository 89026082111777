
.slider-home-container{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.slider-home{
    width: 90%;
    height: 100%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-home-row{
    width: 10%;
    margin-left: 26px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 26px;
}
.slider-home-arrow{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #C5C5C5;
}
.slider-home-arrow:hover{
    color: #202256;
}
.slide-home{
  opacity: 0;
  transition-duration: 1s ease;
}

.slide-home.active{
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

.slide-home-inner{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.slide-home-inner-title{
  color: white;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 70px;
  margin-right: 70px;
  font-size: 2vw;
  font-family: Roboto;
  font-weight: 700;
}
.slide-home-inner-1{
  width: 70%;
  height: 132px;
  margin: 26px 26px 26px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: left;
}
.slide-home-inner-2{
  width: 30%;
  height: 132px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 941px) {
  .slider-home-row{
    display: none;
  }
}

@media (max-width: 740px) {
  .slider-home{
    margin: 0;
  }
.slide-home-inner-title{
  font-size: 20px;
}
  .slide-home-inner{
    padding: 0 10px;
  }
  .slide-home-inner{
    flex-direction: column;
  }
  .slide-home-inner-2{
    width: 100%;
  }
  .slide-home-inner-1{
    width: 100%;
    margin: 26px 0px 26px 0px; 
  }
}