.navbar-sidebar-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    /*    este z index podría ser 1 pero es 4 por el calendario lo pisa*/

}

.navbar-userInfo-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 105px;
    right: 0;
    z-index: 10;
}

.navbar-userInfo-sub-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /*width: 100%;*/
    /*height: 100%;*/
    position: absolute;
    /*top: 105px;*/
    right: 150px;
    z-index: 1;


}

.navbar-close-button {
    background-color: transparent;
    margin-left: 180px;
    margin-top: 10px;
    position: relative;
    color: #f9f9f9;
    font-weight: 300;
    font-size: 40px;
    z-index: 2;
}

.navbar-close-button2 {
    display: flex;
    background-color: white;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: solid 1px black;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 15%;
}

.navbar-sidebar-container2 {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    background: #E6E6E6;
    mix-blend-mode: normal;
    opacity: 0.8;
    backdrop-filter: blur(15px);
}

.navbar-global-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: all 0.5s ease;
}

.navbar-container {
    height: 100px;
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.navbar-container2 {
    height: 100px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.navbar-linea {
    height: 1px;
    width: 95%;
    background-color: #D6D6D6;
}

.navbar-container-sub {
    width: 180px;
    margin-right: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /*    esto con la campanta comentada es space between*/
}

.navbar-orange-circle-universitie {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
}

.navbar-container-sub-sub {
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    justify-content: space-between;
    width: 80px;
    /*margin-right: 60px;*/
    /*    esto de arriba con la campana se tiene que descomentar*/
}

.navbar-container-sub-sub2 {
    cursor: pointer;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 40px;
    height: 20px;
    margin-left: 60px;
}

.lang-active {
    font-weight: 700;
    font-size: 18px;
    color: #202256;
    background-color: transparent;
}

.lang-unactive {
    font-weight: 300;
    font-size: 18px;
    color: #C5C5C5;
    background-color: transparent;
}




.navbar-linea-vert {
    background-color: #D6D6D6;
    width: 1px;
    height: 60px;
}

.burguer-tile {
    height: 2px;
    width: 100%;
    background-color: #202256;
}

@media (max-width: 1300px) {
    .navbar-container-sub-sub2 {
        display: flex;
    }

    .navbar-container {
        height: 100px;
        width: 100%;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

@media (max-width: 430px) {
    .navbar-container-sub-sub2 {
        cursor: pointer !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: space-between !important;
        width: 40px !important;
        height: 20px !important;
        margin-left: 0 !important;
    }
    .navbar-container-sub {
        width: 180px !important;
        margin-right: 0 !important;
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: space-evenly !important;
    }
    .navbar-userInfo-sub-container {
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        z-index: 1 !important;
        position: initial !important;
        right: 0 !important;
        top: 0 !important;
    }
}
@media (max-width: 425px) {
    .navbar-linea-vert {
        display: none;
    }

    .navbar-container-sub {
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 10%;

    }

    .navbar-container-sub-sub2 {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 40px;
        height: 20px;
        margin-left: 10%;
    }

    .navbar-container-sub-sub {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 80px;
        margin-right: 20%;
        /*    esto de arriba con la campana se tiene que descomentar*/
    }

    .navbar-userInfo-sub-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 90%;
        position: absolute;
        right: 0;
        z-index: 1;
    }
}

.contenedor-imagen-company-nav {
    width: 55px;
    height: 55px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contenedor-imagen-company-nav img {
    width: 100%;
}

