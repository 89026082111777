.welcome-courses-card-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 324px;
    height: 300px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 10%;
    transition: all 0.3s ease;
}

.welcome-courses-card-container:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
    transition: 0.5s;
}

.welcome-courses-card-container:hover .welcome-courses-card-title-container {
    background-color: #202256;
    transition: 0.5s;
}

.welcome-courses-card-container:hover .card-course-bootcamp-title {
    color: white;
    transition: 0.5s;
}

.card-course-bootcamp-descr {
    font-size: 12px;
    color: #393939;
    text-align: justify;
    margin: 10px 5px;
    align-self: center;
    transition: all 0.5s;
}

.welcome-courses-card-button{
    font-weight: 400;
    width: 125px;
    height: 30px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #202256;
    margin-bottom: 10px;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #202256;
}

.welcome-courses-card-container:hover .card-course-bootcamp-descr {
    color: white;
    transition: 0.5s;
}

.welcome-courses-card-container:hover .welcome-courses-card-button {
    color: white;
    transition: 0.5s;
    border: 1px solid white;
}

.welcome-courses-card-title-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    transition: all 0.3s ease;
    border-radius: 0 0 8px 8px;
}

.card-course-bootcamp-title {
    font-size: 18px;
    font-weight: bold;
    color: #393939;
    text-align: center;
    padding: 10px;
    align-self: center;
    transition: all 0.5s;
    margin-bottom: 5px;
}

.bootcamp-modal-precourses-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
