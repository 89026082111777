.card-info-dashboard{
    width: 320px;
    height: 170px;
    border-radius: 10px;
    display: flex;        
    padding: 20px;
    color: white;
}

.card-info-dashboard-Icon{
    display: flex;
    align-items: center;
}

.card-info-dashboard-container{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 4%;
}

.card-info-dashboard-title{
    font-size: 20px;
    font-weight: 500; 
    text-align: center;   
}

.card-info-dashboard-container-2{
    height: 100%;
    display: flex;
    align-items: flex-end;

}

.card-info-dashboard-value{
    font-size: 48px;
    font-weight: 600;    
}