.legal-tittle-ini{
    background-color: #ffffff;
    padding-top: 1em;
    padding-bottom: 1em;
}

.legal-tittle-content{
    color: #540e87;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
}
.img-feed-comp{
    max-width: 100%;
}
.img-feed-comp2{
    min-width: 20%;
    max-width: 20%;
    background-color: #ffffff;
    position:relative;
    height: 10em;
    border-radius: 10px;
    border: solid 1px rgba(80, 57, 57, 0.719);
position: relative;
display: flex;
margin-top: 50px;
flex-direction: column;
justify-content: flex-end;
align-items: center;
}
.img-feed-comp3{
    min-width: 20%;
    max-width: 20%;
    background-color: #ffffff;
    height: 10em;
    border-radius: 10px;
    border: solid 1px rgba(80, 57, 57, 0.719);
position: relative;
display: flex;
margin-top: 50px;
flex-direction: column;
justify-content: flex-end;
align-items: center;
}
.img-feed-comp3:hover{
    background-color: rgb(234, 236, 255);
}
.ch-card-txt-legal-docs2{
    font-size: 0.8em;
    max-width: 100%;
}
.contenedor-firmas{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #212156 !important;
}
.ch-card-txt-legal-docs{
    background-color: rgb(255, 255, 255);
    width: 100%;
    font-size: 15px !important;
    border-radius: 2px !important;
    padding: 0.5em 1.2em 0.5em 1.2em !important;
    border-left: 0.2em solid #afafaf;
    border-right: 0.2em solid #afafaf;
    border-top: 0.2em solid #afafaf;
    margin: 0 !important;
    text-align: center;
}
.botones_filter_legal{
    width: 100%;
    height: 3em;
    display: flex;
    justify-content: space-around;
}
.carpetas_filter_legal{
    width: 100%;
    padding: 0px 0px 50px 0px;
    display: flex;
    justify-content: space-around;
}
.userprojects__list{
    display: flex;
    flex-direction: column;
}
.ch-card-txt-legal-docs3{
    font-size: 2em;
}
.ch-card-txt-legal{
    background-color:#FAFAFA;
    color: black;
    text-transform: uppercase;
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
}