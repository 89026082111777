.contenedor-formacion {
    background-color: #F5F5F5;
    display: flex;
    z-index: 100;
}

.formacion-title{
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    color: #202256;
    margin-bottom: 10px;
}

.formacion-search-responsive {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.formacion-cards-videos{
    display: flex;
    align-items: flex-end;
}
.formacion-card-new-video{
    padding-right: 20px;
}

.formacion-cards{
    display: flex;
    justify-content: flex-start;
    margin-top: 2%;
    width: 100%;
    overflow-x: hidden;
}

@media (max-width: 768px) {
    .formacion-card-new-video{
        padding-right: 0px;
    }
    .formacion-cards-videos{
        flex-direction: column;
        align-items: center;
    }
    .formacion-search-responsive  {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .formacion-cards{
        overflow-x: scroll;
    }
}
