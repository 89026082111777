.education{
    width: 100%;
    background-color:#FAFAFA;
    margin: 0 auto;
}

.education__container{
    -webkit-box-shadow: 0px 2px 10px 0px #00000029;
    -moz-box-shadow: 0px 2px 10px 0px #00000029;
    box-shadow: 0px 2px 10px 0px #00000029;
    border-radius: 0px;
    text-align: center;
    padding: 36px 30px 35px 44px;
    background-image: url(https://coally.com/wp-content/uploads/2021/09/fondo-educacion-plataforma-coall.png);
}

.education__container-tittle{
    color: #ffffff;
    font-size: 51px;
    font-weight: 700;
}

.education__container-text{
    font-size: 20px;
    text-align: center;
    margin-bottom: 103px;
}

.education__container-text-subtittle{
    font-size: 20px;
    text-align: center;
    margin-bottom: 103px;
    width: 800px;
    color: white;
}

.subtitle_box{
    width: 100%;
    text-align: -webkit-center;
    margin-top: 10px;
}



.education-iframe{
    display: inline-block;
    width: 25%;
    min-width: 250px;
    max-width: 375px;
    padding-left:15px;
    padding-right: 15px;
    height: 220px;
    margin-bottom: -40px;
}

.education__container-description{
    width: 23%;
    display: inline-block;
    font-size: 20px;
    text-align: left;
    color: #ffffff;
    font-weight: 500;
}

.education__container-media{  
    display: flex;
    flex-direction: row; 
    margin-top: 30px;
    margin-bottom: 80px;
    width: 100%;
    justify-content: center;
    align-content: center;
}

.education__container__media-vid{
    border: 1px solid;
}

.education__text{
 text-align: center;
 font-size: 24px;
 font-weight: 800;
 margin: 69px;
 margin-left: 50px !important;
 margin-right: 50px !important;
 color: #393939;
}

.education__media-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 3rem;
    margin:0 auto;
    margin-left: 0px;
}

.education-filter{
    left: 0;
}

.education__filter-group{
    display: block;
}

.education__filter-tittle{
    margin-bottom: 1rem;
    font-weight: 800;
    background-color: #212156;
    left: 0;
    width: 300px;
    height: 38px;
    vertical-align: middle;
    padding-top: 6px;
    color: white;
}

.education__filter-tittle p {
    margin-left: 10px;
}

.education__filter-select{
    display: block;
}

.education__filter-input{
    margin-right: 5px;
}
.education__filter-label{
    font-size: 17px;
}
.education__filter{
    max-width: 30%;
}
.education__videos{
    grid-column-start:2;
    grid-column-end: 4;
}