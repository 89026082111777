


.feedback-unilever-banner{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    
}

.check{
    color: pink;
}
.uncheck{
    color: blue;
}


.font-bold-estudiante{
    font-weight: bold;
    /* position:absolute; */
    /* margin-left:97.5%;
    margin-top:-48px; */
}

.form-container-feedback{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 33px;
}

.container-feedback{
    display: flex;
    margin-top: 3%;
    width: 90%;
    flex-direction: column;
    border-radius: 8px;
    background-color: #ffffff;
    padding:33px 118px 76px;
}

/* form-puntuacion */

.form-puntuacion{
    border:1px solid white;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0px;  
}

.form-puntuacion .MuiSvgIcon-root{
    font-size: 50px;
    color: #202256;
     
}



.puntuacion-card{    
    display:flex;
    align-items: center;
   
}
.text-puntuacion{
    width:100%;
    
}

.form-puntuacion-title{
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    filter: grayscale(90%);

}

.form-puntuacion-selected{
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    filter: grayscale(0%);
}

.sub-title-feedback{
    font-weight: bold;
}

.feedback-descripcion{
    width: 100%;
    height: 188px;
    padding: 11px 34px 40.46px;
}

.button-container-feedback a{
    width: 216px;
    height: 33px;
    background-color: transparent;
    border:1px solid #202256;
    color:#202256;
    border-radius: 5px;
    padding:5.71px 30.38px 7.29px;
    margin-right:26px;
}

.button-container-feedback-send{
    width: 104.51px;
    height: 38px;
    color: white;
    background-color: #202256;
    border-radius: 5px;
    padding:4.71px 6.12px; 
}

.button-container-feedback{
    margin-top:51px;
    
}

.volver-mentoria{ 
    height: 38px;
    background: transparent;
    border: 0.5px solid #202256;
    border-radius: 5px;  
    margin-right: 26px; 
    margin-bottom: 20px;   
}



/* responsive */

@media (max-width: 768px) {
    .container-feedback{
        padding:33px 20px 76px;
    }

    .form-puntuacion{
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
}