.fc .fc-toolbar.fc-header-toolbar {
    display: flex;
    flex-direction: row;
    width: 65%;
    align-self: flex-start;
    margin-bottom: 5px;
}

th {
    background-color: #f9f9f9;
    border: #f9f9f9 !important;
    border-bottom: 1px solid #f9f9f9 !important;
    font-weight: normal;
}

.fc-scrollgrid-sync-inner {
    border-style: none !important;
}

.fc .fc-toolbar.fc-header-toolbar {
    border-style: none !important;
    width: 25%;
    margin-bottom: 20px;
}

.fc-toolbar-chunk {
    border: none !important;
}

.event-background {
    margin: 5px;
    width: 100%;
    background-color: #E5DCF7;
    border-left: 3px solid #202256;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    align-items: flex-start;
    padding: 5px;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
}

.event-mentor {
    font-weight: 300;
    font-size: 12px;
    color: #8875D1;
}

.event-background p {
    overflow: hidden;
}

.buttonUni-busqueda-mentor {
    background-color: #F07130;
    height: 37px;
    width: 200px;
    border-radius: 5px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-weight: normal;
    font-size: 12px;
}

.event-title {
    color: #202256;
    font-weight: 700;
    font-size: 12px;
}

.ContenedorUni-Search-Button-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.ContenedorUni-banner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
}

.img-bannerUni {
    width: 100%;
}

.ContenedorUni-Grande {
    width: 90%;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /*margin-left: 20%;*/
    margin: 5%;
    position: relative;
}

.buttonUni-acceso-meet {
    background-color: #f07130;
    height: 37px;
    width: 100%;
    border-radius: 5px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    margin-top: 20px;
}

.buttonUni-acceso-feedback {
    margin-top: 20px;
    background-color: #27165b;
    height: 37px;
    width: 100%;
    border-radius: 5px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    margin-top: 20px;
}

.titledetalleUni-acceso-meet {
    font-weight: 400;
    font-size: 16px;
    color: #393939;
}

.titlePrincipadetalleUni-acceso-meet {
    font-weight: 700;
    font-size: 20px;
    color: #393939;
}

.ContenedorUni-Search-Button-2 {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.ContenedorUni-busqueda {
    background-color: white;
    height: 35px;
    width: 330px;
    border-radius: 5px;
    border: none;
    font-size: 12px;

    text-indent: 25px;

}

.ContenedorUni-busqueda::placeholder {
    color: #202256;
    margin-left: 13px;
}

.ContenedorUni-busqueda:focus {
    border-color: #202256;
    outline: none;
}

.Search-icon-uni {
    font-size: 50px;
    color: #202256;
    position: absolute;
    margin-top: 5px;


}

.fc .fc-view-harness-active > .fc-view {
    background-color: white !important;
}

.event-hour {
    color: #202256;
    font-size: 12px;
    font-weight: 400;
}

.camera-event-background {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: -18px;
}

.fc-col-header {
    font-weight: lighter !important;
    background: transparent !important;
    color: black;

    border-style: none;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
}

:root {
    --fc-event-bg-color: #E5DCF7;
    --fc-event-text-color: #8875D1;
    --fc-event-border-color: #E5DCF7;


}

@media (max-width: 1510px) {

}

@media (max-width: 1430px) {

}

@media (max-width: 1335px) {

}

@media (max-width: 1230px) {

}



@media (max-width: 1058px) {


    .ContenedorUni-busqueda {
        width: 250px;
    }
}

@media (max-width: 980px) {


    .ContenedorUni-busqueda {
        width: 200px;
    }
}

@media (max-width: 795px) {


    .ContenedorUni-busqueda {
        width: 150px;
    }

    .event-img {
        display: none;
    }
}

@media (max-width: 700px) {


    .ContenedorUni-busqueda {
        width: 150px;
    }

    .buttonUni-busqueda-mentor {
        height: 28px;
        width: 130px;
    }

    .event-img {
        display: none;
    }

    .fc .fc-toolbar.fc-header-toolbar{
        width: 100%;
    }

    .event-background{
        margin:0px;
        width:auto;
        border-left: 0px;
        flex-direction: row;
    }
    .camera-event-background{
            width:auto;
    }
}

@media (max-width: 435px) {

}

@media (max-width: 395px) {

    .ContenedorUni-busqueda {
        width: 120px;

    }

    .buttonUni-busqueda-mentor {
        height: 24px;
        width: 90px;

    }
}

/* @media (max-width: 640px){
  .event-img{
      display: none;
  }
  .buttonUni-busqueda-mentor{
  margin-left: 5%;
  }
  .ContenedorUni-busqueda{
      width: 20px;
  }

}
@media (max-width: 530px){
  .ContenedorUni-Search-Button-1{
      width: 20%;
  }
}
@media (max-width: 730px){
.ContenedorUni-Search-Button-1{
  display: flex;
  width: 70%;
  flex-direction: column;
}
}
@media (max-width: 425px) {
  .ContenedorUni-Search-Button-1{
      display: flex;
      width: 80%;
      flex-direction: column;
  }
  .ContenedorUni-Search-Button-2 {
      display: flex;
      width: 100%;
      flex-direction: column;

      margin-top: 10px;
  }
  .event-img{
      display: none;
  }

  .ContenedorUni-busqueda {
      background-color: transparent;
      height: 35px;
      width: 20%;
      border-radius: 5px;
      border-style: solid;
      border-width: 2px;
      border-color: #C5C5C5;
      text-indent: 25px;
      margin-bottom: 20px;
  }
}
@media (max-width: 930px) {
  .ContenedorUni-Search-Button-1{
      flex-direction: column;

      width: 400px;
      margin-left: 10%;
  }
  .buttonUni-busqueda-mentor{
margin-left: 70%;
  }
  .event-img{
      display: none;
  }
  .ContenedorUni-busqueda{
      width: 25%;
      margin-right: 10%;
  }
  .ContenedorUni-Search-Button-2{
      width: 100%;
      margin: auto;
  }
.buttonUni-busqueda-mentor{
  width: 90px;
}
}
*/
