.sub-search-responsive {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sub-video-responsive {
    display: flex;
    flex-direction: row;
}

.video-container {
    position: relative;
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    margin-right: 47px;
}

.grid-view-responsive {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(5, 1fr);
}

.grid-view-responsive-3 {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
}
.tpaga-formacion-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-right: 10px;
    text-align: center;
}

.grid-view-responsive-2 {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-Items: center;
    justify-Content: center;
}

.input-responsive-box {
    color: black;
    padding-left: 10%;
    background-color: transparent;
    width: 350px;
    height: 33px;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: #C5C5C5;
}

.tab-title-responsive {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}

.tab-title-responsive-2 {
    font-size: 16px;
    color: #393939;
    margin-top: -20px;
    margin-right: 70px;
}

.iframe-responsive-education {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.iframe-video-container {
    position: relative;
    margin-bottom: 30px;
    border-radius: 8px;
    overflow: hidden;
    width: 50%;
    margin-right: 47px;
}

@media (max-width: 768px) {
    .tab-title-responsive-2 {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .tab-title-responsive {
        display: flex;
        flex-direction: column;
    }

    .sub-search-responsive {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .input-responsive-box {
        width: 100%;
    }

    .grid-view-responsive-2 {
        display: none;
    }

    .sub-video-responsive {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .iframe-video-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    /*.video-container {*/
    /*    display: flex;*/
    /*    width: 200px;*/
    /*    height: 150px;*/
    /*    flex-direction: row;*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*    margin-bottom: 50px;*/
    /*    margin-left: auto;*/
    /*}*/
    .grid-view-responsive {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }
}