.row {
  display: flex;
  flex-flow: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: relative;
  left: -1%;
  height: 100vh;
  width: 100%;
}

.row h1 {
  font-family: Poppins;
  text-align: center;
}

.card {
  border-radius: 5%;
  /*background-color: white;*/
  width: 35%;
  height: 45%;
}

.otp {
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
}

.otp input {
  margin: 5px;
  height: 35px;
  width: 40px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-family: Poppins;
  font-size: 1.2rem;
  background: #c8c8c8;
}

.body {
  border-radius: 5%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  color: white;
}

.body button {
  background-color: #202256;
  color: white;
  border-radius: 5%;
  margin-top: 2%;
  font-size: 16px;
  width: 25%;
  height: 50px;
}

.otp-button-new{
  background: #202256;
  border-radius: 5px;
  width: 300px;
  height: 33px;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: none;
}