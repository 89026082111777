.feedback_content{
    background-color: hsl(0, 0%, 98%);
    padding-top: 50px;
  }
  
  .feedback_form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #FFFFFF;
    text-align: center;
    width: 700px;
    margin-left: 28%;
    align-items: center;
    padding: 40px;
    box-sizing: border-box;
    overflow: auto;
    margin-bottom: 3em;
  }
  
  .textarea {
    width: 100%;
    outline-style: none;
    margin-top: 4%;
    padding: 2%;
    border: 2px solid #707070;
    border-radius: 10px;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 50px;
  }
  .textarea2 {
    width: 100%;
    height: 50%;
    outline-style: none;
    margin-top: 4%;
    padding: 7% !important;
    margin-left: 0!important;
    border-style: none !important ;
    background-color: #aaa9a981;
    border-radius: 10px !important;
    font-size: 14px;
    resize: none;
  }
  
  .title_form {
    color: #28157e;
    margin-bottom: 10%;
  }
  
  .button_feedback{
    background-color: #f46036;
    width: 17%;
    text-transform: uppercase;
    padding: 2%;
    font-weight: 500 !important;
    margin-top: 5%;
    border-radius: 10px;
    font: normal normal bold 14px/20px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
  }

  .section_feedback {
    margin: 0px 0px 20px 0px;
  }

  .label-radio{
    color: black;
    border: 1px solid rgba(0, 0, 0, 0);
    padding: 10px;
    cursor: pointer;
  }
  
  .label-radio:hover{
    color: black;
    border: 1px solid black;
    padding: 10px;
  }

  .label-radio:checked+label {
    padding: 10px;
    font-weight: 600;
    background: var(--color-amarillo);
    border-radius: 2px;
    color: rgb(255, 255, 255);
}

  /* .radio-button {
    margin-right: 15px;
  } */

.bar {
  background-color:#21204a;
  width: 100%;
  padding-left:1.2rem ;
  font: normal normal bold 16px/50px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  text-align: left;
}

.icon {
  padding-top: 8px;
  color: white;
}
.MuiSvgIcon-root{
  margin: 0;
}