.canva canvas{
    width: 400px;
    height: 100px;
 }

 .firma-botones{
     padding: 1em;
 }

 .boton-firma-co{
    background-color: #FFFFFF;
    color: #d0d0d0;
    padding: 1em;
    border-radius: 7px;
    border: 1px solid #d0d0d0;
    text-transform: capitalize;
 }