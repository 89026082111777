@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700italic,700,900italic,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
.form_form__gO21f label{
  color: #b6b6b6;
  font-size: 14px;
 display: inline-block;
 margin-left: 1%;
 width: 100%;
/* margin-right: 5%; */
text-align: start;
}
.form_inputs__klIWc{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.form_desc__3vFaG{
  display: flex;
  align-items: center;
  flex-direction: column;
}
/* fade in key frames */

.form_fade_in__3ygwD {
  -webkit-animation-name: form_fadeIn__2J1uL;
          animation-name: form_fadeIn__2J1uL;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  transition-timing-function: linear;
}

.form_form__gO21f input , select, textarea{
  border: 1px solid #C5C5C5;
}

@-webkit-keyframes form_FadeIn__3NtyB {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes form_FadeIn__3NtyB {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width:725px) {
  .form_formCv__3jBbv {    
    width: 100% !important;
     min-width: 400px !important;
  }
  .form_container__3pWbD{
    width: 90%    
  }
  .form_aptitudes__1nd3w{
    flex-direction: column !important;
  }
  .form_certificaciones__1YfcF{
    flex-direction: row !important;
    justify-content: space-between !important;    
  }
  .form_certificaciones__1YfcF button{
    margin-top: 20px !important;
    margin-bottom: 0 !important;
  }
}
.BodyWeb{
    width: calc(100vw - 237px);
}
.HeaderTotal-responsive{
    display: flex;
}
.Header-responsive{
    display: none;
}
@media (max-width:1300px) {
    .Header-responsive{
        display: inline;
        display: initial;
    }
    .HeaderTotal-responsive{
        display: none !important;
    }
    .BodyWeb{
        width: 100vw;
    }
   
}

@media (max-width: 725px) {
    .popUpProfile{
        width: 85% !important;
        padding: 1.5em !important;
    }
}
.contenedor{
    width: 15vw;
    height: 100vh;
    position: fixed;
    background-color: white;
    display: flex;
    justify-content: center;
    
}
.h2-header{
    font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 1.5vw;
line-height: 24px;
display: flex;
align-items: center;
margin-left: "2rem";
margin-bottom: 2rem;
color: #B3B6B6;
}

.h2-header-empresa:hover {
    color: #202256;
    font-weight: bold;
    transition: 400ms;
}

.popupcalifacion{    
    width: 100vw;
    height: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
}
.popupcalifacionsub{  
    max-width: 50vw;    
    z-index: 200;
}
.popupcalifacionsubpop{   
    padding: 2rem;
    display: flex;
    box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: white;
}
.buttonirahora{
    background: #202256;
    border-radius: 5px;
    color: white;
    padding: 0.3rem 1rem;
}
.fondopopupcalificacion{
    z-index: 200;  
    -webkit-backdrop-filter: blur(10px);  
            backdrop-filter: blur(10px);
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.705);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.imagenes-gris {
    -webkit-filter: grayscale(100);
            filter: grayscale(100);
    opacity: 0.3;
}

.imagenes-color{
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    opacity: 1;
}

.h2-header-empresa {
    font-family: 'Poppins';
    color: #C5C5C5;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    transition: 400ms;
}

.h2-header-empresa:hover {
    color: #202256;
    font-weight: bold;
    transition: 400ms;
}

.h2-header-empresa-active {
    font-family: 'Poppins';
    color: #202256;
    /*font-style: normal;*/
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}
.font-top {
    color: rgba(32, 34, 86, 1);
    font-weight: 700;
    margin: 0;
    font-size: 45px;
    line-height: 7vh;
    font-family: 'Poppins';
    margin-bottom: 2rem;
    text-align: start !important;
}

.profilebutton {
    width: 59px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #F07130;
}

.profilebutton2 {
    margin-top: 40px;
    width: 59px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #F07130;
}

.profilebutton-active {
    background-color: #202256;
    width: 59px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}

@media (max-width: 725px) {
    .profilePopup{
        width: 90% !important;;  
    }
}

@media (max-height: 768px) {
    .profilePopup{
        height: auto !important;;  
    }
}
.contact-us{
  background-color: hsl(0, 0%, 98%);
  padding-top: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 5em;
}

.info {
  background-color: #212156;
  width: 30%;
  height: -webkit-max-content;
  height: max-content;
  padding: 2%;
  margin-left: 20%;
  font-size: 16px;
  border-radius: 15px 15px 15px 15px;
}

.info p {
  margin-top: 1em;
  margin-bottom: 10%;
  margin-left: 1em;
  font-size: 16px;
  color: #ffffff;
}
.iconos-lista-contact{
  font-size:30px;
   color: #FF5925; 
   margin-Right: 20px

}
.lista-contact-coally-contenedor{
  background-color: rgba(253, 253, 253, 0.137);
  width: 100%;
  padding: 5%;
  margin-top: 5px;
  display: flex;
  border-radius: 10px;
}
.mensaje-directo-forma{
  display: flex;
  justify-content: space-between;
}

.highlight-word-contact{
  color: #FF5925;
  margin-left: 5px;
  font-weight: 600;
}

.list-contact-coally{
  font-size: 16px;
  color: #393939;
  text-align: center;
  margin-left: -20px;
}

.title_form_contact{
  color: #050931;
  font-size: 19px;
  font-weight: 700;
  text-align: start;
  margin-bottom: 1%;
}

.button_contact_us{
  background: #212156 0% 0% no-repeat padding-box;
  box-shadow: 4px 3px 6px #00000029;
  color: white;
  border-radius: 4px;
  padding: 0.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.button_div_contact{
  text-align: center;
  display: flex;
}

.input_contact_us{
  background-color: rgba(0, 0, 0, 0);
  font-size: 12.5px;    
  width: 100%;
  margin-top: 4%;
  padding: 3%;
  border: 0.1px solid #a5a2a26e;
}
.input_contact_us_2{
  background-color: rgba(0, 0, 0, 0);
  font-size: 12.5px;
  width: 45%;
  margin-top: 4%;
  padding: 3%;
  border: 0.1px solid #a5a2a26e;
}

.text_contact_us{
  background-color: rgba(0, 0, 0, 0);
  border: 0.1px solid #a5a2a26e;
  padding-bottom: 4em;
  font-size: 12.5px;
  margin-bottom: 2em;
  resize: none;
  margin-top: 2em;
}

.icon {
  color: #ffffff;
  margin-right: 2%;
  margin-top: 2%;
}

.form {
  width: 55%;
  padding-right: 12%;
  margin-right: 10%;
  padding-top: 2%;
}

.direct_message{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-left: 5%;
  padding: 10px;
  overflow: auto;
  width: 100%;
}

.input {
  width: 100%;
  margin-top: 4%;
  padding: 4%;
  border: none;
  border-bottom: 0.1px solid #707070;
  font-size: 14px;
}

.textarea {
  width: 100%;
  margin-top: 8%;
  border: 2px solid #707070;
  font-size: 14px;
}

.button {
  background-color: #540E87;
  width: 17%;
  padding: 2%;
  margin-left: 40%;
  margin-top: 5%;
  border-radius: 10px;
  font: normal normal bold 14px/20px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
}
.formulariocrear .MuiInputBase-root{
  border: none !important;
  width: 99%;
}
.MuiInput-underline:before{
  border: none !important;
}
.MuiSelect-select.MuiSelect-select{
  border-radius: 10px;
}
.MuiSelect-select.MuiSelect-select:active{
  border-radius: 10px;
  background-color: white;
}
.MuiSelect-select.MuiSelect-select:focus{
  border-radius: 10px;
  background-color: white;
}
.audioclase{
  position: absolute;
  display: flex;
  background-color: white;
}
.audioclase:hover{
opacity: 0;
}
.audioclasehover{
  display: flex;
}
.input_create_project_new_form{
  width: 99% !important;
}
audio {
    width: 99%;
    height: 45px;
    margin-left: 3%;
    margin-top: 0;
    margin-bottom: 5%;
  }
  audio::-webkit-media-controls-enclosure {
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    border: 5px solid #f4f5fb;
}
.textareaclass{
  line-height: normal !important;
  line-height: initial !important;
  height: 100px !important;
  resize: none !important;
  margin-top: 0 !important;
}

.MuiStepper-root{    
  padding: 2rem !important;
}
.css-10mg1vw-MuiStepper-root{    
  padding: 2rem !important;
}
.contenedorcompanynewcreate{
    width: 98vw;
    max-width: 772px; 
    position: relative; 
    z-index: 3;
}
.steppernewproject{
    display: inline;
    display: initial;
}

@media (max-width:767px){
    .steppernewproject{
        display: none;
    }
    .create-company-form{
            height: 90% !important;
    }
}

.contenedor {
    width: 15vw;
    width: 237px;
    float: left;
    height: 100vh;
    position: fixed;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    z-index: 100;
}

.h2-header-empresa {
    font-family: 'Poppins';
    color: #C5C5C5;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    transition: 400ms;
}

.h2-header-empresa:hover {
    color: #202256;
    font-weight: bold;
    transition: 400ms;
}

.h2-header-empresa-active {
    font-family: 'Poppins';
    color: #202256;
    /*font-style: normal;*/
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.circle {
    color: #C5C5C5;
}

.circle-active {
    color: #202256;
}

.link-header {
    text-decoration: none;
    display: flex;
    margin-bottom: 2rem;
    cursor: pointer;
    padding-bottom: 1vh;
    color: rgba(197, 197, 197, 1);
    padding-top: 0.5vh;
}

.link-header-color {
    text-decoration: none;
    display: flex;
    margin-bottom: 2rem;
    cursor: pointer;
    color: rgba(32, 34, 86, 1);
    padding-bottom: 1vh;
    padding-top: 1vh;
    border-right: rgba(32, 34, 86, 1) solid;
}

.popupproyecto {
    display: flex;
    position: absolute;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    z-index: 101;
    align-items: center;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
}

.popupproyectofondo {
    display: flex;
    position: absolute;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    z-index: 2;
    align-items: center;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba(125, 125, 125, 0.72);
    mix-blend-mode: normal;
    -webkit-backdrop-filter: blur(31px);
            backdrop-filter: blur(31px);
}

.popupproyectocartel {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 98vw;
    max-width: 772px;
    border-radius: 10px;
    height: 90vh;
    max-height: 550px;
    z-index: 3;
    background-color: white;
}

.popupproyectocartel2 {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 772px;
    border-radius: 10px;
    height: 550px;
    z-index: 3;
    background-color: white;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 2em;
}

.css-qivjh0-MuiStepLabel-label {
    font-size: 0.9rem !important;
}

.imagenes-gris {
    -webkit-filter: grayscale(100);
            filter: grayscale(100);
    opacity: 0.3;
}

.imagenes-color{
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    opacity: 1;
}
.contenedorcrearOportunidadNew {
 /*  max-width: 772px; */ 
  position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
  height: 419px;
 /*  background-color: #fff; */
  border-radius: 8px;
  overflow: auto;
  box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.1)

}

#eng{
  padding:2em;
}
.contenedorcrearOportunidadNew2 {
/* max-width: 772px;  */
position: absolute;
  top: 50%;
  left: 50%;
 /*  transform: translate(-50%, -50%); */
height: 100%;
/* background-color: #fff; */
border-radius: 8px;
overflow: auto;
box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.1)

}

.contenedorcrearOportunidadNew3 {
max-width: 772px; 
position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
height: 419px;
background-color: #fff;
border-radius: 8px;
overflow: auto;
box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.1)

}

/* ChooseType */

.contenedorChooseType {
display: flex; 
align-items: flex-start; 
flex-direction: column;

}

.contenedorChooseType .contenedorChooseType_title {
font-family: 'Poppins', sans-serif;
font-size: 14px;
font-weight: 700;
margin-bottom: 20px;
margin-left: -150px;

}
.contenedorChooseType
.chooseTypeRadio {
margin-left: -105px;
}

.chooseTypeButton {
display: flex;
width: 100%;
justify-content: space-between;
padding: 1rem 3rem 0rem 3rem;
align-items: center;
}

/* Modalidad   */

.contenedorModalidad {
display: flex; 
align-items: flex-start; 
flex-direction: column;

}
.modalidadRadio {
display: flex;
flex-direction: row;
}

.stepCounter {
font-family: 'Poppins', sans-serif;
font-size: 12px;
font-weight: 400;
}

/* setAnonimo */

.contenedorSetAnonimo {
display: flex; 
align-items: flex-start; 
flex-direction: column;
}

.contenedorSetAnonimo .contenedorSetAnonimo_title {
font-family: 'Poppins', sans-serif;
font-size: 14px;
font-weight: 700;
margin-bottom: 20px;
margin-left: -240px;

}
.setAnonimoRadio {
margin-left: -225px;
}

 /* Abilities  */

 .MuiInputBase-root{
  flex:auto !important;
} 

/* Duration */

.contenedorDuration {
display: flex; 
align-items: flex-start; 
flex-direction: column;
margin-left: 50px;
}
.contenedorDuration .contenedorDuration_title {
font-family: 'Poppins', sans-serif;
font-size: 14px;
font-weight: 700;
margin-bottom: 20px;
margin-left: -150px;

}
.contenedorDuration .durationRadio {
margin-left: -95px;
}

/* Presupuesto */

.contenedorPresupuesto {
display: flex;
align-items: flex-start;
flex-direction: column;
margin-left: -100px;
}
.contenedorPresupuesto .selectCurrency {
border: 0.5px solid rgb(197, 197, 197);
width: 80%;
height: 30px;
background: transparent;
margin-left: 10px;
margin-bottom: 10px;
}
.contenedorPresupuesto .contenedorPresupuesto_input {
border: 0.5px solid rgb(197, 197, 197);
width: 400px;
height: 41px;
margin-left: 10px;
}

.contenedorOportunityInfo {
display: flex;
align-items: flex-start; 
flex-direction: column; 
margin-bottom: 50px; 
}
.contenedorOportunityInfo .contenedorOportunityInfo_input {
border: 0.5px solid #C5C5C5;
width: 568px; 
height: 41px;
 margin-left: 10px;
}
.contenedorOportunityInfo .contenedorOportunityInfo_TextArea {
border: 0.5px solid #C5C5C5;
width: 568px;
height: 170px;
margin-left: 10px;
}
.contenedorOportunityInfo .contenedorOportunityInfo_title {
font-family: 'Poppins'; 
font-weight: 700; 
font-size: 14px; 
margin-bottom: 20px; 
margin-left: -5px;
}

/* external */
.contenedorExternal_input {
border: 0.5px solid #C5C5C5;
width: 568px;
height: 41px;
margin-left: 10px;
}

.contenedorHabilidad {
margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 80px;
}

.button_siguiente_create_B {
background: transparent 0% 0% no-repeat padding-box;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #202256;
  border: 1px solid #202256;
  width: 111px;
  height: 28px;
  border-radius: 5px;
  transition: all 0.25s ease-in-out 0s;
  margin-bottom: 28px;
}

.button_siguiente_create_C {
  background: transparent 0% 0% no-repeat padding-box;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
    border: 1px solid transparent;
    width: 111px;
    height: 28px;
    border-radius: 5px;
    transition: all 0.25s ease-in-out 0s;
    margin-bottom: 28px;
  }

.button_siguiente_create {
  background: #202256;;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #ffff;
    border: 1px solid #202256;
    width: 111px;
    height: 28px;
    border-radius: 5px;
    transition: all 0.25s ease-in-out 0s;
    margin-bottom: 28px;
  }

  .button_siguiente_create:disabled {
    background-color: '#C5C5C5';
    color: '#ffff';
    border: none;
    pointer-events: none;
  }

  .button_finish_project {
    background: #f37328 0% 0% no-repeat padding-box;
    box-shadow: 4px 3px 6px #00000029;
    color: white;
    border-radius: 4px;
    padding: 0.5em;
    margin-bottom: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
}
.contenedorCompanyInfo{
  display: flex; 
  flex-direction: column; 
  margin-top:1em; 
}

.contenedorCompanyInfo_title{
  font-family: Poppins;
   font-weight: 700;
    font-size: 14px;
     margin-bottom: 10px;
      margin-left: -5px;
}

.radio_unchecked_icon{
  /* width: '16px', height: '16px', color: '#C5C5C5' */
  width: 16px;
  height: 16px;
  color: #C5C5C5;
}

.radio_checked_icon{
  /* color: '#F07130', width: '16px', height: '16px' */
  color: #F07130;
  width: 16px;
  height: 16px;
}

.contenedorCompanyInfo_input{
width:300px;
border: 0.5px solid #C5C5C5
}


    @media (max-width: 768px) {
      .radio_unchecked_icon{
        width: 25px;
        height: 25px;
      }
      .radio_checked_icon{
        width: 25px;
        height: 25px;
      }
      .contenedorChooseType {
        align-items: center;
      }
      .contenedorChooseType .contenedorChooseType_title {
        margin-left: 0;
      }
      .contenedorChooseType
      .chooseTypeRadio {
        margin-left: 0;
      }
      .chooseTypeButton {
        padding: 1rem 1rem 0rem 1rem;
        flex-direction: column-reverse;
        margin-top: 20px;
      }

      .contenedorModalidad {
        align-items: center;
      }
      .modalidadRadio {
        flex-direction: column;
      }
      .contenedorSetAnonimo {
        align-items: center;
      }
      .contenedorSetAnonimo .contenedorSetAnonimo_title {
        margin-left: 0;
      }
      .setAnonimoRadio {
        margin-left: -100px;
      }
      .contenedorDuration {
        align-items: center;
      }
      .contenedorDuration .contenedorDuration_title {
        margin-left: 0;
      }
      .contenedorDuration .durationRadio {
        margin-left: -50px;
      }
      .contenedorPresupuesto {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 50px;
        margin-top: 10%;
        margin-left: 0px;
      }
      .contenedorPresupuesto .selectCurrency {
        border: 0.5px solid rgb(197, 197, 197);
        width: 150px;
        height: 30px;
        background: transparent;
        margin-bottom: 20px;
        margin-left: 0px;
      }
      .contenedorPresupuesto .contenedorPresupuesto_input {
        border: 0.5px solid rgb(197, 197, 197);
        width: 100%;
        height: 41px;
        margin-left: 0px;
      }
      .contenedorOportunityInfo {
        align-items: center;
        margin-left: 0;
      }
      .contenedorOportunityInfo .contenedorOportunityInfo_title {
        margin-left: 5px;
      }
      .contenedorOportunityInfo .contenedorOportunityInfo_input {
        border: 0.5px solid #C5C5C5;
        width: 95%;
        height: 41px;
        margin-left: 0px;
      }

      .contenedorOportunityInfo .contenedorOportunityInfo_TextArea {
        border: 0.5px solid #C5C5C5;
        width: 95%;
        height: 170px;
        margin-left: 10px;
      }

      .contenedorSetAnonimo .contenedorSetAnonimo_title {
        margin-left: 0;
        width: 100%;
      }

      .contenedorExternal_input {
        border: 0.5px solid #C5C5C5;
        width: 95%;
        height: 41px;
        margin-left: 0px;
      }

      .contenedorHabilidad {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 0px;
      }
      .css-185td0z ,.css-13arzbu, .css-1a94s3b{
        height:auto !important;
        padding:2em !important;
      }
      .stepCounter {
        margin-left: 10px;
        margin-right: 10px;
      }
      .button_siguiente_create {
        margin-bottom: 10px;
        width: 200px;
        height: 35px;
      }
      .button_siguiente_create_B {
        margin-bottom: 10px;
        width: 200px;
        height: 35px;
      }
      .button_siguiente_create_C {
        display: none;
      }
    }
.step-global-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.step-mini-sub-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    width: 150px;
}

.step-sub-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    margin-left: 10px;
    margin-right: 10px;
}

.title-active {
    font-weight: 400;
    font-size: 12px;
    color: #202256;
    margin-top: 13px;
}

.title-inactive {
    font-weight: 400;
    font-size: 12px;
    color: #C5C5C5;
    margin-top: 13px;
}

.step-circle-inactive {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #C5C5C5;
}

.step-circle-active {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #202256;
}

.step-big-circle-active {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #202256;
    display: flex;
    align-items: center;
    justify-content: center;
}

.step-big-circle-inactive {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #C5C5C5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.step-inner-circle-active {
    width: 20px;
    height: 20px;
    background: #202256;
    border-radius: 50%;
}

.step-inner-circle-inactive {
    width: 20px;
    height: 20px;
    background: #C5C5C5;
    border-radius: 50%;
}

@media  (min-height: 420px) {
    .step-global-cont {
        margin-top: 20px;
    }
}
.config-headertop{
    margin-left: calc(237px + 98px);
    margin-right: 20px;
}


.container-cuenta button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:transparent;   
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    width:100%;
    height: 56px;
}
.container-cuenta button span{
    margin-left: 30px
}
.container-cuenta button:hover{
    background-color: #202256;
    color:white;
}
/* .container-cuenta button:focus{
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
} */
.container-dropdown{
    margin-left: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}
.box-item li{
    margin-left: 60px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
}
.box-item button{
    color: #000000;
}

.box-item hr{
    border: 1px solid #D6D6D6 ;
    margin-left: 3% ;  
    margin-top: 0px;  
    
}

 .division-hr{
    border: 1px solid #393939;
    margin: 0px;
    
}

@media (max-width:1300px){
    .container-configuracion{
      position:revert !important;
      max-width: 100% !important;
    }
    .config-headertop{      
        padding: 0 1rem !important;
        margin: 0 !important;
    }
}


@media (max-width:725px){
    .config-headertop{
        padding: 0rem 2rem !important;
        width: 100% !important;
    }
    .container-configuracion{
        margin-top: 30% !important;
        max-width: 100% !important;
        margin:auto ;
        position: relative !important;
        padding: 0px !important;
        width: 90% !important;
    }
    .config-headertop{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
  }
.soporte{
  padding-top: 50px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 5em;
}

.soporte-contactUs {
    background-color: #FFFFFF;  
    padding: 2%;    
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width:449px;
    height: 338px;  
    border-radius: 8px;
  }
  
.soporte-contactUs p {
    margin-top: 2em;
    margin-bottom: 10%;
    text-align: center; 
    color: #5D5D5D;
  }
.soporte-contactUs li {
  padding:1em;
  }
.soporte-contactUs span{
    margin-left:10px;    
  }

.container-form{
    width:442px;
    height: 456px;
    background-color: #FFFFFF;
  }

.config-form{
    display: flex;
    flex-direction: column;
    justify-content: center;       
    padding: 1.5em;
    overflow: auto;
    width: 100%;
    border-radius: 8px;
  }

  .title-form-contact{
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #5D5D5D;
  }

  .select-contact-us{
    border: 0.5px solid #202256;
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    height: 33px;
    width: 184px;  
    color:#202256; 
  }

.text-contact_us{ 
    border: 0.1px solid #a5a2a26e;
    padding: 3%;
    font-size: 12.5px;
    margin-bottom: 2em;
    resize: none;
    margin-top: 2em;
    height:174px;
}

.button-contact_us{
    background-color: #202256;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;   
  }


@media (max-width:725px) {
    .soporte{
        flex-direction: column;
        align-items: center;
    }
    .soporte-contactUs{
        margin-bottom: 2em;
        width:auto !important;
    }
    .container-form{
        width: 100%;
    }
}
.Sidebar-Cont {
    background: #e0023c;
    width: 237px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.Sidebar-Cont22 {
    background: #FFFFFF;
    width: 237px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.Sidebar-Logo {
    width: 180px;
    margin-bottom: 60px;
}

.Sidebar-Sub-Cont-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 72px;
}

.Sidebar-Sub-Cont-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 35px;
}


.Sidebar-Sub-Cont-But-2 {
    padding-left: 28.15px;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    width: 90%;
    height: 55px;
    background: transparent;
    margin-bottom: 1.5em;
    margin-left: 50px;
}

.Sidebar-Sub-Cont-But-2:hover {
    text-decoration: none;
    color: white;
}

.Sidebar-Sub-Cont-But {
    padding-left: 28.15px;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    width: 90%;
    height: 55px;
    background: transparent;
    margin-left: 50px;
}

.Sidebar-Sub-Cont-But-active {

    padding-left: 28.15px;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #202256;
    width: 90%;
    height: 55px;
    background: #F9F9F9;
    margin-left: 50px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;

}

.Sidebar-Sub-Cont-But:hover {
    text-decoration: none;
    color: white;
}

.Sidebar-Sub-Cont-But-active:hover {
    text-decoration: none;
    color: #202256;
}

.Sidebar-Sub-Cont-Img {
    margin-right: 15px;
}

.Sidebar-Sub-Cont-2-Special-But {
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 35px;
    width: 170px;
    margin-bottom: 50px;
}


@media (max-width: 1300px) {

    .Sidebar-Cont22 {
        /* display:none; */
        background-color: #202256;
        width: 300px;
    }

    .Sidebar-Sub-Cont-But-active {

        padding-left: 28.15px;
        font-weight: 400;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: #202256;
        width: 80%;
        height: 55px;
        background: #F9F9F9;
        margin-left: 47px;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;

    }

    .Sidebar-Cont {
        display: none;
    }

}

.SidebarCV-Button {
    background: #1f1561;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 35px;
    width: 170px;
    margin-bottom: 50px;
}

.SidebarCV-cont {
    width: 100%;
}

.SidebarCV-Err {
    margin-top: 15px;
    color: #5D5D5D;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}

.SidebarCV-LI {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-LI-2 {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E5DCF7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
}

.SidebarCV-LI-Err{
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E71C00;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV {
    display: flex;
    cursor: pointer;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV-disabled {
    display: flex;
    cursor: not-allowed !important;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #868686;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-Sub-Cont {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 45px;
}

.SidebarCV-Sub-Text {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #5D5D5D;
}

.SidebarCV-Sub-Cont-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 45px;
}
.SidebarCV-Button {
    background: #1f1561;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 35px;
    width: 170px;
    margin-bottom: 50px;
}

.SidebarCV-Button-Dis {
    cursor: not-allowed !important;
    background: #D6D6D6;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    height: 35px;
    width: 170px;
    margin-bottom: 50px;
}

.SidebarCV-cont {
    width: 100%;
}

.SidebarCV-Err {
    margin-top: 15px;
    color: #5D5D5D;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}

.SidebarCV-LI {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-LI-2 {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E5DCF7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
}

.SidebarCV-LI-Err{
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E71C00;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV {
    display: flex;
    cursor: pointer;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV-disabled {
    display: flex;
    cursor: not-allowed !important;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #868686;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-Sub-Cont {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 45px;
}

.SidebarCV-Sub-Text {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #5D5D5D;
}

.SidebarCV-Sub-Cont-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 45px;
}
.navbar-sidebar-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    /*    este z index podría ser 1 pero es 4 por el calendario lo pisa*/

}

.navbar-userInfo-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 105px;
    right: 0;
    z-index: 10;
}

.navbar-userInfo-sub-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    /*top: 105px;*/
    right: 150px;
    z-index: 1;

}

.navbar-close-button3 {
    background-color: transparent;
    color: #f9f9f9;
    font-weight: 300;
    font-size: 40px;
    z-index: 2;
}

.navbar-close-button-container3 {
    display: flex;
    width: 280px;
    z-index: 2;
    justify-content: flex-end;
}

.navbar-close-button2 {
    display: flex;
    background-color: white;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: solid 1px black;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 15%;
}

.navbar-sidebar-container2 {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    background: #E6E6E6;
    mix-blend-mode: normal;
    opacity: 0.8;
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
}

.navbar-global-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: all 0.5s ease;
}

.navbar-container {
    height: 100px;
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.navbar-container2 {
    height: 100px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.navbar-linea {
    height: 1px;
    width: 95%;
    background-color: #D6D6D6;
}

.navbar-container-sub {
    width: 180px;
    margin-right: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /*    esto con la campanta comentada es space between*/
}

.navbar-orange-circle {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    background-color: #F07130;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
}

.navbar-container-sub-sub {
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    justify-content: space-between;
    width: 80px;
    /*margin-right: 60px;*/
    /*    esto de arriba con la campana se tiene que descomentar*/
}

.navbar-container-sub-sub2 {
    cursor: pointer;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 40px;
    height: 20px;
    margin-left: 60px;
}

.lang-active {
    font-weight: 700;
    font-size: 18px;
    color: #202256;
    background-color: transparent;
}

.lang-unactive {
    font-weight: 300;
    font-size: 18px;
    color: #C5C5C5;
    background-color: transparent;
}

.navbar-orange-circle:hover {
    background-color: #202256;
}

.navbar-orange-circle:focus {
    background-color: #202256;
}

.navbar-linea-vert {
    background-color: #D6D6D6;
    width: 1px;
    height: 60px;
}

.burguer-tile {
    height: 2px;
    width: 100%;
    background-color: #202256;
}

@media (max-width: 1300px) {
    .navbar-container-sub-sub2 {
        display: flex;
    }

    .navbar-container {
        height: 100px;
        width: 100%;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

@media (max-width: 425px) {
    .navbar-linea-vert {
        display: none;
    }

    .navbar-container-sub {
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 10%;

    }

    .navbar-container-sub-sub2 {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 40px;
        height: 20px;
        margin-left: 10%;
    }

    .navbar-container-sub-sub {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 80px;
        margin-right: 20%;
        /*    esto de arriba con la campana se tiene que descomentar*/
    }

    .navbar-userInfo-sub-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 90%;
        position: absolute;
        right: 0;
        z-index: 1;
    }
}

.contenedor-imagen-company-nav {
    width: 55px;
    height: 55px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contenedor-imagen-company-nav img {
    width: 100%;
}


.UserInfo-global-container {
    background: #FFFFFF;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 5px;
}

.UserInfo-name {
    margin-top: 15px;
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.UserInfo-separator {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 90%;
    height: 1px;
    background-color: #E5E5E5;
}

.UserInfo-button-pricing {
    color: #202256;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #202256;
    border-radius: 5px;
    width: 190px;
}
.UserInfo-button-pricing:hover{
    background: #202256;
    color: #FFFFFF;
}

.UserInfo-plan{
    font-weight: 700;
    font-size: 14px;
    color: #202256;
}

.UserInfo-image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 140px;

    border-radius: 50%;
    margin-bottom: 30px;
}
.UserInfo-image-container div div{

    margin-left:.1em;
}

.container-viewProfile{
    min-width: 105px;
    color: #202256;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}
.container-viewProfile:hover {
   -webkit-filter: grayscale(0%);
           filter: grayscale(0%);
   color: #202256;
   font-weight: 500;
   stroke-width: 1.5px;
}

.container-viewProfile svg{
    stroke: #202256;
}





a:hover{
    cursor:pointer;
}

@media (max-width: 425px) {
    .UserInfo-global-container {
        width: 100%;
    }
}




/* Card de consultor */

.container-card-data{

    padding: 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}


.Data_usuario{

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top:-1em;

    width:-webkit-fit-content;

    width:-moz-fit-content;

    width:fit-content;
    padding: 5px;
}

.contenedor-iner-data{

    width:100%;
    margin-bottom:30px;
    margin-left:20px;
}

.nombre{
    font-weight: bold;
    margin-bottom:2em;
}

.TituloDesc{
 color:#3d3c60;
 font-weight: bold;
}

.TextoInterno{
color:#737373;
margin-top: -1em;
}

.Idioma{

    display:flex;
    align-items:center;
    justify-content: flex-start;

}

.Idioma p{
    margin-right: .5em;

}
.herre{
    color: #ececec;
    width:100%;
    /* margin-left:5%; */
}

.img-company-perfil{
    border-radius: 50%;
    width:138.94px;
    height: 138.94px;
    background-color: #f07130;
}



a:hover{
    cursor:pointer;
}

@media (max-width: 425px) {
    .UserInfo-global-container {
        width: 100%;
    }
}




/* Card de consultor */

.container-card-data{

    padding: 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}


.Data_usuario{

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top:-1em;

    width:-webkit-fit-content;

    width:-moz-fit-content;

    width:fit-content;
    padding: 5px;
}

.contenedor-iner-data{

    width:100%;
    margin-bottom:30px;
    margin-left:20px;
}

.nombre{
    font-weight: bold;
    margin-bottom:2em;
}

.TituloDesc{
 color:#3d3c60;
 font-weight: bold;
}

.TextoInterno{
color:#737373;
margin-top: -1em;
}

.Idioma{

    display:flex;
    align-items:center;
    justify-content: flex-start;

}

.Idioma p{
    margin-right: .5em;

}
.herre{
    color: #ececec;
    width:100%;
    /* margin-left:5%; */
}

.img-company-perfil{
    border-radius: 50%;
    width:138.94px;
    height: 138.94px;
    background-color: #f07130;
}

.CircularProfile{
    
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content: center;
}

.non-circle{
    display:none;
}

.CircularProfile span{
    color:#f07130;
    width: 100% !important;
    margin-left: -6.1em;
    
}

#SecondCirclie{
    color:#dfdfdf;
    position:absolute;
    width: 8.7em !important;
}

.Avatar-container{
    width:6.5em;
    height: 6.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position:absolute;
   
    border-radius:50%;
    margin-left:-.5em;

}

.Avatar-container img{
    width:100%;
    position:absolute;
    background-color: #f07130;
    
}

/* Icon Nav Styles */



.CircularProfile-nab{

width:100%;
height: 100%;
display:flex;
align-items: center;
justify-content: center;
}

.CircularProfile-nab span{
    color:#f07130;
    width: 72px !important;
    margin-left: -2em;
    
    
}

#SecondCirclie-nab{
    color:#dfdfdf;
    position:absolute;
    
}

.Avatar-container-nav{
    width:56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position:absolute;
    /* border:1px solid black; */
    border-radius:50%;
    margin-left:-1px;
}

.Avatar-container-nav img{
    width:100%;
    position:absolute;

}
.userprojects{
    width: 85%;
    margin: 0 auto;
    padding-bottom: 35px;
}

.userprojects__buttons{
    display: flex;
    justify-content: center;
    padding: 5px;
    border: none;
    margin-bottom: 1rem;
}

.userprojects__buttons-btn{
    background-color: #212156 !important;
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    font-size: 16px;
    padding: 10px;
}
.userprojects__buttons-btn:hover{
    background-color: white !important;
    color: #212156 !important;
    cursor: pointer;
    border-bottom: 5px solid #212156;
    font-weight: 700;
}
.userprojects__buttons-btn:focus{
    background-color: white !important;
    color: #212156 !important;
    cursor: pointer;
    border-bottom: 5px solid #212156;
    font-weight: 700;
}
.userprojects__buttons-btn:active{
    background-color: white !important;
    color: #212156 !important;
    cursor: pointer;
    border-bottom: 5px solid #212156;
    font-weight: 700;
}
.userprojects__buttons-btn2{
    background-color: #f07130;
       color: white;
    border: 1px solid white;
    border-radius: 10px;
    font-size: 16px;
    padding: 10px;
}
.userprojects__buttons-btn2:hover{
    background-color: rgb(240, 240, 240) !important;
    color: #212156 !important;
    cursor: pointer;
    border-bottom: 5px solid #212156;
    font-weight: 700;
}
.userprojects__buttons-btn2:focus{
    background-color: rgb(240, 240, 240) !important;
    color: #212156 !important;
    cursor: pointer;
    border-bottom: 5px solid #212156;
    font-weight: 700;
}
.userprojects__buttons-btn2:active{
    background-color: rgb(240, 240, 240) !important;
    color: #212156 !important;
    cursor: pointer;
    border-bottom: 5px solid #212156;
    font-weight: 700;
}
.userprojects__list{
    
    margin-top: 15px;
}

.login_form_button{
    background-color: #F46036;
    color: white;
    padding: 5px 7px 5px 7px;
    border-radius: 5px;
    margin-top: 20px;
}

.n-projects{
    width: 100%;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.volver-legal{
    background-color: #212156 !important;
}

.hr_projects{
    height: 1px;
    background-color: rgb(185, 185, 185);
    width: 100%;
    position: relative;
    margin-top: -15px;
}


.header{
    display:flex;
    justify-content: space-between;
    height: -webkit-max-content;
    height: max-content; 
    width: 100%;
    z-index: 100;
    background-color: #ffffff;
    padding: 10px;
}

.header-display-name-coallier{
    padding-top: 5px;
    color: black;
    font-size: 15px;
    font-weight: 700;
}

.header-display-see-profile{
    color: #212156;
    font-size: 13px;
    text-decoration: underline;
    margin-top: -15px;
    font-weight: 400;
}

.header-logo{
    align-self: center;
    margin-left:10px;
}

.header__icons-group{
    align-self: center;
    z-index: 600;
}

li, ul, ol {
    list-style: none;
}
.header__icons__group-ul{
    display: flex;
    flex-direction: row;
    height: 20px;
}

.header__icons__group-li{
    float: left;
    text-align: center;
    margin-right: 40px;
    font-size: var(--font-size-regular);
}

.header__icons__group-icon{
    margin-left: 5px;
}

.header__display-menu{
    display: none;
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: 0px 2px 10px #00000029;
    padding: 5px;
    background-color: white;
    position: absolute;
    width: 300px;
    min-width: 170px;
    font-size: 13px;
}

.menu-text{
    display: inline-block;
    font-size: 14px;
    margin-left: 0.2em;
}

.header__display_copy{
    font-size: 13px;
    color: #7B7777;
    margin-top: 5px;
}

.header__display__menu-item_company{
    font-size: 13px;
    display:block;
    padding: 5px 5px 5px 5px !important;
    text-align: left;
    padding-bottom: 2px !important;
    margin-bottom: 3px;
    border-bottom: 1px solid black;
    text-align:start;
    width: 80%;
    margin-left: 27px;
}

.header__display__menu-item_coallier{
    font-size: 13px;
    display:block;
    padding: 5px 5px 5px 5px !important;
    text-align: left;
    margin-top: 10px !important;
    padding-top: 5px !important;
    text-align: left;
    width: 80%;
    margin-left: 9.2%;

}

.header__display__menu-item{
    font-size: 13px;
    display:block;
    padding: 5px 5px 5px 5px !important;
    text-align: left;
    padding-bottom: 0px !important;
    margin-bottom: -5px;
    margin-left: 9.2%;
    width: 80%;
}

.header__display__menu li{
    font-size: 13px;
    display:block;
    padding: 5px 5px 5px 5px !important;
    text-align: center;
    padding-bottom: 0px !important;
    margin-bottom: 0px;
}

.header__display__menu-perfil{
    margin:0  auto;
    display: grid;
    padding: 5px;
    font-size: 13px;
    padding: 5px 20px 5px 40px;
    margin-bottom:10px ;
    grid-template-columns: repeat(2 1fr);
}

.header__display__menu-perfil-ver-perfil{
    margin: 0 auto;
    display: grid;
    padding: 7px;
    border-bottom: 0.5px solid #D6D6D6;
    font-size: 25px;
    padding: 5px 20px 5px 20px;
    margin-bottom: 10px;
    grid-template-columns: repeat(2 1fr);
    width:70%
}

.header__display__menu__perfil-img{
    margin: 0 auto;
    grid-column: 1;
}

.header__display__menu__perfil-data{
    text-align: center;
    color: #8C00DF;
    font-weight: 500;
    font-size: 15px;
    grid-column: 2;
}

.header__display__menu__item-pagos{
    font-size: 13px;
    display:block;
    padding: 5px 5px 5px 5px !important;
    text-align: left;
    border-top: 0 !important;
    border-style: none;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
    text-align: left;
    width: 80%;
    margin-left: 9.2%;
    
}



.custom-select{
    background-color: #8C00DF !important;
    color: white !important;
}

.select-user-coally{
    background-color: #8C00DF;
    color: white;
}

@media screen and (max-width:1080px){
.header__display-menu{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    background: white;
}


.header-logo{
    margin-left: -1px !important;
}


  
  }

  .search_consultor{
      width: 550px;
      height: 40px;
      border-radius: 8px;
      color: #707070;
      border-style: solid;
      border-width: 1.5px;
      border-color: #c0c0c0;
      position: absolute;
      left: 18%;
  }

  .lapizIcon:hover{
      margin-left: 10px;
  }

  .search_consultor::-webkit-input-placeholder{
      padding-left: 30px;
  }

  .search_consultor:-ms-input-placeholder{
      padding-left: 30px;
  }

  .search_consultor::placeholder{
      padding-left: 30px;
  }
  .idioma{
      text-align: center;
      position: absolute;
      z-index: 160;
      display: flex;
      justify-content: center;
      top:0%;
      color: #696a7c;
      border-left: solid 2px #fff;
      border-right: solid 2px #fff;
      min-width: 40px;  
      background-color: #fff;
      border-radius: 10px 10px 10px 10px; 
      transition: all 0.5s ease-out;
  }
  .idioma1{
      text-align: center;
      position: absolute;
      top: 8%;
      z-index: 160;
      min-width: 40px;  
      color: #696a7c;
      border-radius: 10px 10px 10px 10px;
      background-color: #fff;
      border-left: solid 2px #fff;
      border-right: solid 2px #fff;
      border-bottom: solid 2px #fff; 
      transition: all 0.5s ease-out;
  }
  .idioma2{
      text-align: center;
      z-index: 10 ;
      position: absolute;
      top: 0;
      color: #696a7c;
      z-index: 160;
      cursor:default;
      min-width: 40px;  
      border-radius: 10px 10px 10px 10px;
      border-left: solid 2px #fff;
      border-right: solid 2px #fff;
      border-top: solid 2px #fff;
      background-color: #fff; 
  }
  .idioma0:hover .idioma{
        top: 100%;
        display: flex;
        border-radius: 0px 0px 0px 0px;    
        transition: all 0.5s ease-out;
  }
  .idioma0:hover .idioma1{
        top: 200%;
        border-radius: 0px 0px 10px 10px;
        transition: all 0.5s ease-out;
  }
  .idioma0:hover .idioma2{     
    z-index: 140;
    border-radius: 10px 10px 0px 0px;
      border-bottom: solid 2px rgba(0, 0, 0, 0);
      transition-delay:0.5s;
  }
  .idioma1:hover{
    background-color: white;
    color: #f07130;
  }
  .idioma:hover{
    background-color: white;
    color: #f07130;
  }

.idioma-{
    text-align: center;
    position: absolute;
    z-index: 160;
    display: flex;
    justify-content: center;
    top:0%;
    color: white;
    border-left: solid 2px #151650;
    border-right: solid 2px #151650;
    min-width: 90px; 
    background-image: linear-gradient(175deg, #3b3e94 -32%, #101247 118%);
    border-radius: 10px 10px 10px 10px; 
    transition: all 0.5s ease-out;
}
.idioma1-{
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 160;
    color: white;
    min-width: 90px;
    border-radius: 10px 10px 10px 10px; 
    background-image: linear-gradient(175deg, #3b3e94 -32%, #101247 118%);
    border-left: solid 2px #151650;
    border-right: solid 2px #151650;
    border-bottom: solid 2px #151650; 
    transition: all 0.5s ease-out;
}
.idioma2-{
    text-align: center;
    z-index: 10 ;
    position: absolute;
    top: 0;
    z-index: 160;
    cursor:default;
    color: white;
    min-width: 90px;
    max-height: -webkit-max-content;
    max-height: max-content;
    border-radius: 10px 10px 10px 10px;
    border-left: solid 2px #151650;
    border-right: solid 2px #151650;
    border-top: solid 2px #151650; background-image: linear-gradient(175deg, #3b3e94 -32%, #101247 118%);
}
.idioma0-:hover .idioma-{
      top: 100%;
      display: flex;
      color: white;
      border-radius: 0px 0px 0px 0px;   
}
.idioma0-:hover .idioma1-{
      top: 200%;
      border-radius: 0px 0px 10px 10px;
      color: white;
}
.idioma0-:hover .idioma2-{     
    z-index: 150;
  border-radius: 10px 10px 0px 0px;
  color: white;
  transition-delay:0.5s;
}
.idioma1-:hover{
  background-color: #64acff;
  color: white;
}
.idioma-:hover{
  background-color: #64acff;
  color: white;
}
.educacion-header{
    font-size: 1.25rem;
    color: #696a7c;    
}
.educacion-header-selecionado{
    font-size: 1.25rem;
    color: #F07130;
    border-bottom: 0.188rem solid #F07130;
}
.educacion-header:hover{
    font-size: 1.25rem;
    color: #F07130;
    border-bottom: 0.188rem solid #F07130;
}
.education{
    width: 100%;
    background-color:#FAFAFA;
    margin: 0 auto;
}

.education__container{
    box-shadow: 0px 2px 10px 0px #00000029;
    border-radius: 0px;
    text-align: center;
    padding: 36px 30px 35px 44px;
    background-image: url(https://coally.com/wp-content/uploads/2021/09/fondo-educacion-plataforma-coall.png);
}

.education__container-tittle{
    color: #ffffff;
    font-size: 51px;
    font-weight: 700;
}

.education__container-text{
    font-size: 20px;
    text-align: center;
    margin-bottom: 103px;
}

.education__container-text-subtittle{
    font-size: 20px;
    text-align: center;
    margin-bottom: 103px;
    width: 800px;
    color: white;
}

.subtitle_box{
    width: 100%;
    text-align: -webkit-center;
    margin-top: 10px;
}



.education-iframe{
    display: inline-block;
    width: 25%;
    min-width: 250px;
    max-width: 375px;
    padding-left:15px;
    padding-right: 15px;
    height: 220px;
    margin-bottom: -40px;
}

.education__container-description{
    width: 23%;
    display: inline-block;
    font-size: 20px;
    text-align: left;
    color: #ffffff;
    font-weight: 500;
}

.education__container-media{  
    display: flex;
    flex-direction: row; 
    margin-top: 30px;
    margin-bottom: 80px;
    width: 100%;
    justify-content: center;
    align-content: center;
}

.education__container__media-vid{
    border: 1px solid;
}

.education__text{
 text-align: center;
 font-size: 24px;
 font-weight: 800;
 margin: 69px;
 margin-left: 50px !important;
 margin-right: 50px !important;
 color: #393939;
}

.education__media-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    grid-gap: 3rem;
    gap: 3rem;
    margin:0 auto;
    margin-left: 0px;
}

.education-filter{
    left: 0;
}

.education__filter-group{
    display: block;
}

.education__filter-tittle{
    margin-bottom: 1rem;
    font-weight: 800;
    background-color: #212156;
    left: 0;
    width: 300px;
    height: 38px;
    vertical-align: middle;
    padding-top: 6px;
    color: white;
}

.education__filter-tittle p {
    margin-left: 10px;
}

.education__filter-select{
    display: block;
}

.education__filter-input{
    margin-right: 5px;
}
.education__filter-label{
    font-size: 17px;
}
.education__filter{
    max-width: 30%;
}
.education__videos{
    grid-column-start:2;
    grid-column-end: 4;
}
.video {
    max-width: 300px;
    text-align: center;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
}

.education-iframe-box{
    width: 260px;
    height: 170px;
    border-style: none;
}

.video__media-vd {
    width: 100%;
}

.video-data {
    padding: .5rem 1rem;
    text-align: center;
}

.video__data-head {
    display: flex;
    justify-content: space-around;
}

.video-data-p {
    font-weight: 500;
    font-size: 16px;
    text-align: left;
}

.video-data-desc {
    text-align: left;
    color: rgb(138, 138, 138);
}

.video-data-leng {
    text-align: left;
    color: rgb(138, 138, 138);
}

.video-data-prof {
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    color: rgb(138, 138, 138);
}

.videolist {
    box-sizing: border-box;
}

.videoslist__container {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(3, 1fr);
    margin-right: 50%;
}

@media (min-width: 1920px) and (orientation: landscape) {
    .videoslist__container {
        margin-left: -20%;
    }
 }

.videoslist__container-cpn {
    text-align: center;
    margin: 0 auto;
    width: 100%;
}

@media screen and (max-width:1080px) {
    .videoslist__container {
        display: block;
    }

    .videoslist__container-cpn {
        padding: 10px;
    }
}

 .education__filter-select .label{
    display: inline-block;
    cursor: pointer;
    color: var(--color-negro);
    position: relative;
    padding: 5px 15px 5px 51px;
    font-size: 1em;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.education__filter-select .education__filter-select label:hover
 {
    background: rgba(255, 65, 54, 0.1);
}

.education__filter-select label:before {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    position: absolute;
    left: 15px;
    border-radius: 50%;
    background: none;
    border: 3px solid var(--color-negro);;
}

input[type="radio"] {
    display: none;
}

input[name="continuarias"]:checked+label:before {
    display: none;
}

input[name="continuarias"]:checked+label {
    padding: 5px;
    background: var(--color-amarillo);
    border-radius: 2px;
    color: #fff;
}

input[name="categoriasFilter"]:checked+label:before {
    display: none;
}

input[name="categoriasFilter"]:checked+label {
    padding: 10px;
    background: var(--color-amarillo);
    border-radius: 2px;
    color: #fff;
}


.container_header__resume{
  height:400px;
  width: 100%;
  text-align: -webkit-center;
}

.header_resume{
 width: 100%;
 position: absolute;
 background-color: white;
}

.perfil-resume {
    width: 100%;
    background-color: transparent !important; 
  }
  .perfil-header-resume {
    background-color: transparent;
    justify-content: center;
    padding-bottom: 3em;
  }
  
  .perfil__header-container-resume {
    width: 100%;
  }
  .perfil__header__container-img-resume {
    position: relative;
  }
  
  .perfil__header-profile-resume {
    text-align: left;
    display: flex;
    margin-left: 3%;
    padding-top: 22vh;
  }
  
  .perfil__header__profile-txt-resume {
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    margin-left: 20%;
    margin-top: 20px;
    z-index: 3;
  }
  .logo_resume_derecha{    
    position: absolute;
    width: 28%;
    z-index: -1;
    right: 0;
    margin-top: 13vh;
  }
  .perfil__header__profile-txt-resume2 {
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
    margin-left: 20%;
    margin-top: 20px;
    z-index: 3;
  }

  .perfil__header__profile-profesion-txt-resume{
      color: white;
      font-weight: 200;
      font-size: 16px;
      text-transform: uppercase;
  }
  .perfil__header-profile-img-resume {
    border: 1px solid;
    padding: 10px;
    width: 125px;
    margin-bottom: 10px;
    margin-top: -3em;
    background-color: transparent;
  }
  
  .general-resume-tittle{    
    height: -webkit-max-content;    
    height: max-content;
    width: 28%;
    padding-left: 2em;
    position: relative;
    padding-right: 2em;
    padding-top: 2em;
    margin-top: 80px;
    color: white !important;
    right: 0;
    display: flex;
    justify-content: center;
  } 

    .id1{
      margin-left:-36px;
      font-size:15px
    }

  .general-resume-content{   
    min-height: 600px;
    width: 65%;
    padding-top: 2em;
    margin-top: 80px;
    margin-bottom: 40px;
  }

  .tittle-left-resume{
    font-size: 19px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: left;
  }
  .tittle-left-resume2{
    font-size: 19px;
    font-weight: 400;
    color: white;
    text-transform: uppercase;
    padding: 1rem 5rem 1rem 1rem;
    display: flex;
    justify-content: flex-start;
    background-color: #212156;
  }

  .tittle-rigth-one-resume{
    color: #ffffff;
    background-color: #212156;
    font-size: 19px;
    padding: 1rem 5rem 1rem 1rem;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 1.5em;
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }

  .titles_right_column{
    display:flex;
    flex-direction:row;
    border:solid 1px #f47227;
    margin-bottom: 2vh;
    justify-content: center;
    padding: 0.5rem 0rem 0.5rem 0rem;
  }

  .titles_right_column h4{
    margin: 0;
  }

  .content-one-experience{
    margin-top:17px
  }

  .description-left-resume{
      text-align: left;
      font-size:14px;
      width: 100%
  }

  .calificacion_resume{
    display:flex;
    flex-direction:row;
  }

  .list-left-resume{
      margin-bottom: 10px;
  }

  .content-resume-columns{
    display: flex;
    flex-direction: row-reverse;
    width: 97%;
    margin-left: 3%;
    justify-content: space-between;
  }

  .experience-content-resume{
    margin-bottom: 0.3em;
    font-size:15px;
    color: #344863;
    margin-left:5px;
    font-weight:500
    }
  
  .institute_form{
    font-size:15px;
    margin-left: 30px;
    color:#344863;
  }

  .experience_form{
    font-size:14px;
    margin-left: 30px;
    width:90%;
    color: #7f8083;
  }
  
  .education-content-resume{
    margin-bottom: 0.3em;
    margin-left:5px;
    font-size:15px;
    color: #7f8083;
    width:90%
    }

  @media screen and (max-width:630px){
    .perfil-resume{
        width: 100%;
        grid-template-columns: 1fr;
    }
  }
.lineamedioperfil{
  margin-top: 6vh;
}
  @media (min-width:1919px) {
    
.perfil__header-profile-resume{
  text-align: left;
  display: flex;
  margin-left: 3%;
  padding-top: 9vh;
}
.lineamedioperfil{
  margin-top: 3.5vh;
}
.logo_resume_derecha{
  position: absolute;
    width: 28%;
    z-index: -1;
    right: 0;
    margin-top: 3vh;
}
}
  @media (min-width:1819px) {
    
.perfil__header-profile-resume{
  text-align: left;
  display: flex;
  margin-left: 3%;
  padding-top: 16vh;
}
.lineamedioperfil{
  margin-top: 4.5vh;
}
.logo_resume_derecha{
  position: absolute;
    width: 28%;
    z-index: -1;
    right: 0;
    margin-top: 3vh;
}
}
    
  
.header{
    width: 100%;
    padding-right: 10%;
}
/* .idioma22{
        
    text-align: center;
    z-index: 10;
    position: absolute;
    top: 0;
    color: #696a7c;
    z-index: 160;
    cursor: default;
    min-width: 40px;
    border-radius: 10px 10px 10px 10px;
    border-left: solid 2px #fff;
    border-right: solid 2px #fff;
    border-top: solid 2px #fff;
    background-color: #fff;
    margin-top: -0.2rem;

    } */
li, ul, ol {
    list-style: none;
}
.header__icons__group-ul{
    display: flex;
}

.header__icons__group-li{
    float: left;
}

.header__icons__group-li:hover > ul{
    display: inherit;
    margin-right: 80px;
}

.header__icons__group-icon{
    margin-left: 5px;
    height: 28px;
}

.header__display-menu{
    display: none;
    border-radius: 7px;
    border: 1px solid;
    padding: 5px;
    background-color: white;
    position: absolute;
    width: 260px;
    z-index: 100;
    min-width: 170px;
    font-size: 13px;
    margin-left: -220px;
}

.header__display-menu li{
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button-menu-side button{
    font-weight: 500;
    font-size: 15.5px;
    line-height: 0.6em;
    color: #fe7a00;
    background-color: white;
    border-radius: 10px;
    border: solid  #fe7a00;
    padding: 12px 30px 12px 30px;
}

.header__display__menu-item{
    font-size: 13px;
    display:block;
    padding: 5px 20px 5px 40px;
}

.header__display__menu-perfil{
    margin:0  auto;
    display: grid;
    padding: 5px;
    font-size: 13px;
    padding: 5px 20px 5px 40px;
    margin-bottom:10px ;
    grid-template-columns: repeat(2 1fr);
}

.header__display__menu__perfil-img{
    margin: 0 auto;
    border-radius: 40px;
    grid-column: 1;
}

.header__display__menu__perfil-data{
    text-align: center;
    grid-column: 2;
}

.header__display__menu__item-pagos{
    font-size: 13px;
    display:block;
    padding: 5px 20px 5px 40px;
    text-align: left;
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 5px;
}


@media screen and (max-width:500px){
    .header__display-menu{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: auto;
        background: white;
    }
 
    .header__icons__group-li a p{
        display: none;
        
    }
    .header__icons__group-li p {
        display: none;
        
    }
    .header-logo{
        margin-left: -1px !important;
    }
    
    .home__recomended{
    margin-left: 20px !important;
    font-size: 18px !important;
    }
      
      }

      .header__display__menu-item_company{
          margin-left: 22.5px;
      }
      /* .idioma-empresa{
        text-align: center;
        position: absolute;
        z-index: 160;
        display: flex;
        justify-content: center;
        top:0%;
        border-left: solid 2px #212156;
        border-right: solid 2px #212156;
        min-width: 90px;
        background-color: #FFFFFF;
        border-radius: 10px 10px 10px 10px; 
        transition: all 0.5s ease-out;
    }
    .idioma1-empresa{
        text-align: center;
        position: absolute;
        top: 8%;
        z-index: 160;
        min-width: 90px;
        border-radius: 10px 10px 10px 10px;
        background-color: #FFFFFF;
        border-left: solid 2px #212156;
        border-right: solid 2px #212156;
        border-bottom: solid 2px #212156; 
        transition: all 0.5s ease-out;
    }
    .idioma2-empresa{
        text-align: center;
        z-index: 10 ;
        position: absolute;
        top: 0;
        z-index: 160;
        cursor:default;
        min-width: 90px;
        border-radius: 10px 10px 10px 10px;
        border-left: solid 2px #212156;
        border-right: solid 2px #212156;
        border-top: solid 2px #212156;
        background-color: #FFFFFF; 
    }
    .idioma0-empresa:hover .idioma-empresa{
          top: 100%;
          display: flex;
          border-radius: 0px 0px 0px 0px;    
          transition: all 0.5s ease-out;
    }
    .idioma0-empresa:hover .idioma1-empresa{
          top: 200%;
          border-radius: 0px 0px 10px 10px;
          transition: all 0.5s ease-out;
    }
    .idioma0-empresa:hover .idioma2-empresa{      
      border-radius: 10px 10px 0px 0px;      
      z-index: 140;
        border-bottom: solid 2px rgba(0, 0, 0, 0);
        transition-delay:0.5s;
    }
    .idioma1-empresa:hover{
      background-color: #64acff;
      color: white;
    }
    .idioma-empresa:hover{
      background-color: #64acff;
      color: white;
    } */
.footer_bg_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10em 0 10em;
}
.footer_bg_container2{
    background-image: url(https://coally.com/wp-content/uploads/2021/08/fondo-perfiles-consultor.png);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 10em 0 10em;
}

.footer-general{
    background-color: white;
}

.border_bottom__footer{
    display: flex;
    height: 6vh;
    justify-content: center;
}

.footer-copyright{
    color: white;
    font-size: 12px;
    margin-right: 1em;
    height: 70%;
    margin-top: 10px;
}

.footer_bg_container h3{
    font-size: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ff5925;
}

.coAlly_data__footer{
    padding-top: 10vh;
    padding-bottom: 8vh;
    display: flex;
    color: #696a7c;
    flex-direction: column;
}

.coAlly_data__footer__socials{
    padding-top: 10vh;
    padding-bottom: 8vh;
    display: flex;
    flex-direction: column;
}

.coAlly_data__footer__socials h3{
    justify-content: center;
    text-align: center;
}

.container__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.img-not-found{
    text-align: center;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 400;
  }
  
  .popup-inner {
    position: absolute;
    display: flex;   
    padding: 10px;
    top:auto !important;
    background: #1bb96d;
    width: 50%;
    align-items: center;
    color: white;
    height: auto;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
  }
  
  .popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  
  .close-btn-pop{
    /* padding: 7px; */
    background-color: white;
    color: #7dbe6a;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 12px;
    border-style: none;
    border-radius: 5px;
    text-transform: uppercase;
  }

  @media(max-width: 725px){
    .popup-inner {
      width: 100% !important;
    }
  }
.match-form {
    width: 85%;
    max-width: 1440px;
    margin: auto;
    display: flex;
    margin-bottom: 5rem;
    justify-content: center;
    background-color: #ffffff;
}

.match-projects__container{
display: flex;
grid-gap: 5rem;
gap: 5rem;
justify-content: center;
}
.text-center{
    text-align: center;
}
.match-btn {
    padding: 10px 10px 10px 10px;
    border-radius: 25px;
    background: var(--main-yellow-findally);
    font-size: 13px;
    font-family: var(--font-segundary);
}
.match-list{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.math-list:visited{
    background-color: var(--color-amarillo);
    color: white;
}
.math-list:focus{
    background-color: var(--color-amarillo);
    color: white;
}
.math-list:active{
    background-color: var(--color-amarillo);
    color: white;
}
.math-list:hover{
    background-color: var(--color-amarillo);
    color: white;
}
.match-users__list {
    background-color: #ffffff;
    height: 300px;
    overflow: scroll;
}
.match-proyect__list{
    
    background-color: #ffffff;
    height: 300px;
    overflow: scroll;
}
.container_body_metho{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10vh;
}

.sub_header{
    display: flex;
    flex-direction: column;
}

.metho-details-containercopy{
    display: flex;
    flex-direction:row-reverse;
    justify-content: space-between;
}

.formulariopopup_izquierda::-webkit-scrollbar {
    -webkit-appearance: none;
}

.formulariopopup_izquierda::-webkit-scrollbar:vertical {
    width:10px;
}

.formulariopopup_izquierda::-webkit-scrollbar-button:increment,.formulariopopup_izquierda::-webkit-scrollbar-button {
    display: none;
} 

.formulariopopup_izquierda::-webkit-scrollbar:horizontal {
    height: 10px;
}

.formulariopopup_izquierda::-webkit-scrollbar-thumb {
    background-color: #21204B;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.formulariopopup_izquierda::-webkit-scrollbar-track {
    border-radius: 10px;  
}
.btn_formulario_popup{    
    color: white;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    padding: 4px;
    border-radius: 5px;
}
.metho-details_editcopy{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    overflow: scroll;
    max-height: 635px;
    color: #212156;
    overflow-x: hidden; 
    border-radius: 0px;
}
.metho-details_editcopy2{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 26.5%; 
    margin-top: 2%;
}

  
.metho-details_editcopy::-webkit-scrollbar {
    -webkit-appearance: none;
}

.metho-details_editcopy::-webkit-scrollbar:vertical {
    width:11px;
}

.metho-details_editcopy::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
    display: none;
} 

.metho-details_editcopy::-webkit-scrollbar:horizontal {
    height: 10px;
}

.metho-details_editcopy::-webkit-scrollbar-thumb {
    background-color: #454545;
    border-radius: 20px;
    border: 2px solid #454545;
}

.metho-details_editcopy::-webkit-scrollbar-track {
    border-radius: 10px;  
    background-color: #c1c1c4;
}

  

  .sub_header{
    margin-left:10%
  }

  .tittle-project-methodology_edit{
      font-size:30px;
      font-weight:bold;
    margin-top:2%
  }

  .metho-details-tittle-p_subtittle_edit{
      font-size:15px;
      font-weight:600;
      margin-top:-10px;
      margin-bottom:20px
  }

  .metho-details-list-definition-project_edit{
    font-size:15px;
    font-weight:bold;
    margin-top:10%;
    margin-left:7%
  }

.video_archivos__containercopy{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 71%;
    margin-top: 2%;
    margin-left: 1px;
    margin-right: 1%;
    border-radius: 0px;
    padding-bottom: 30px;
}
.icon_list_methocopy{    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
/* .icon_list_methocopy path {
} */

.metho-details-list_edit{
    position: relative;
    justify-content: center;
    margin-left: 5%;
    margin-top:0px
}

.metho-label-new:hover{
    color: #474545;
    background-color: #c1c1c0;
}

.metho-label-new:active{
    color: #474545;
    background-color: #c1c1c0;
}

.subcontainer_btn_list_metho:hover{
    color: #474545;
    background-color: #c1c1c0;
}
.subcontainer_btn_list_metho:active{
    color: #474545;
    background-color: #c1c1c0;
}

.metho-details-list_edit button{
    background-color: transparent;
    margin-top: 10px;
}

.subcontainer_list_metho{
    height: 80px;
}

.metho-details-btn_edit{
    position: relative;
    text-align: left;
    width: 95%;
    justify-content: center;
}

.subcontainer_btn_list_metho{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.text_list_metho{
    margin-left: 10px;
    cursor: pointer;
    font-weight: 600;
    color: #44434f;
    text-transform: capitalize;
}
@-webkit-keyframes crescendo {
    0%   {-webkit-transform: scale(.8);transform: scale(.8);}
    100% {-webkit-transform: scale(1.5);transform: scale(1.5);}
  }
@keyframes crescendo {
    0%   {-webkit-transform: scale(.8);transform: scale(.8);}
    100% {-webkit-transform: scale(1.5);transform: scale(1.5);}
  }



  .checkboxestilo{
    background-color: #8e8f9f;
    border-radius: 50%;
    cursor: pointer;
    height: 6px;
    min-width: 6px;
    margin: 0;
    z-index: 2;
    top: 0;
    width: 6px;
    min-height: 6px;
  }
  .checkboxestilo:after{
/*     border: 2px solid #212156;
 */    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 5px;
    -webkit-transform: rotate(
-45deg);
            transform: rotate(
-45deg);
    width: 12px;
  }
  .checkboxestilo2{
    visibility: hidden;
  }
  .checkboxestilo3{
    position: absolute;
    left: -100vw;
  }
  .checkboxestilo4{
    position: absolute;
    left: -100vw;
  }
  .checkboxestilo2:checked + .checkboxestilo{
    background-color: #1bb96d;
    border-color: #ffffff;
  }
  .checkboxestilo2:checked + .checkboxestilo::after{
    opacity: 1;
  }
  .checkboxestilo3:checked + .deliverablesclasproject{
    display: none;
  }
  .checkboxestilo4:checked + .labelfinalproject{
    display: none;
  }







  .linea_ht{
    width: 0px !important;
    position: absolute !important;
    height: 200% !important;
    display: none;
    background-color: rgba(255, 255, 255, 0) !important;
    border: solid !important;
    z-index: 1;
    border-radius: 100%;
    color: #ffffff00;
  }
  .fondo_methodology{
      width: 100%;
      height: 100vh;
      position: fixed;
      z-index: -1;
  }

  /* //////////etapascopy.css */
  .metho-details-content-Done {
    padding: 10px 10px;
    margin: .4rem;
    width: 150;
    background: transparent;
    background-color: var(--color-amarillo);
    color: white;
    border-radius: 10px;
    outline: none;
    font-size: 14px;
    text-align: center;
}
.metho-details-content{    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.placeholder-task-metho{
    text-align: start;
    color: #a4a4a4;
    padding-top: 1em;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
}

.metho-details-content-video{
    width:98%;
    height:-webkit-max-content;
    height:max-content;
    margin-top:1%
}

.metho-details-content-description-title{
    font-size: 17px;
    text-align: left;
    width:90%;
    margin-left:5%;
    font-weight:600;
    color: #3A3A3A
}

.metho-details-content-description{
    font-size: 15px;
    text-align: left;
    width:98%;
    font-weight:500;
    color: #3A3A3A
}
.btn_mensaje5-tarea{
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: #f47227;
    margin-top: 10px; 
    margin: 0.5% 2%;
    border: solid 1px white;   
    padding: 4px;
    border-radius: 5px;
}


.comments-text {
    margin-bottom: 23px;
    min-height: 50px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
}

.comments-text-inputcopy{
    margin-bottom: 23px;
    background-color: #F8FAFB;
    min-height: 50px;
    height: -moz-fit-content;
    border: none !important;
    border-style: none !important;
    text-align: center;
    font-weight: 300;
    font-size: 15px;
    resize: none;
    text-transform: uppercase;
    height: -webkit-fit-content;
    height: fit-content;
    width: 100%;
}

.tarea-container-methocopy{
    background-color: #F8FAFB;
    margin-top: 1.5em;
    border-radius: 5px;
    width:90%;
    padding: 0.5% 2.5% 0.5% 0.5%;
    margin-left:5%;
    margin-bottom:40px
}

.upload_file_css{
    width:90%;
    margin-left:5%
}
.Dropzone {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none;
}

.Upload {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-color: white;
  padding: 1rem;
  align-items: flex-start;
  box-shadow: 0px 4px 17px -10px rgba(125,120,125,1);
  text-align: left;
  overflow: hidden;
}

.Upload-caso {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-color: white;
  padding: 1rem;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
}

.Content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
}

.Files {
  margin-left: 32px;
  align-items: flex-start;
  justify-items: flex-start;
  flex: 1 1;
  overflow-y: auto;
}

.Actions {
  display: flex;
  flex: 1 1;
  width: 100%;
  flex-direction: column;
}

.Title-upload-caso{
  color: #264AD9B3;
  font-size: 13px;
}

.description-upload{
  margin-bottom: 32px;
  color: #555;
  font-size: 13px;
}

.Title {
  margin-bottom: 32px;
  color: #555;
}

.Filename {
  margin-bottom: 8px;
  font-size: 16px;
  color: #555;
}

.Row {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  padding: 8px;
  overflow: hidden;
  box-sizing: border-box;
}

.CheckIcon {
  opacity: 0.5;
  margin-left: 32px;
}

.ProgressWrapper {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
}

.section-upload-botton{
  text-align: center !important;
}

.upload-btn {
  font-family: "Roboto medium", sans-serif;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  height: 36px;
  min-width: 88px;
  padding: 6px 16px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  border-radius: 2px;
  background:transparent;
  color: #212156;
  outline: 0;
}

button:disabled {
  background: rgb(189, 189, 189);
  cursor: default;
}

.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: rgb(183, 155, 229);
  border-radius: 5px;
}

.Progress {
  background-color: rgba(103, 58, 183, 1);
  height: 100%;
  margin: 0;
  border-radius: 5px;
}
.bar{
  height: auto !important;
}
.Upload {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-color: white;
  padding: 1rem;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
}

.Upload-caso {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-color: white;
  padding: 1rem;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
}

.Content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
}

.Files {
  margin-left: 32px;
  align-items: flex-start;
  justify-items: flex-start;
  flex: 1 1;
  overflow-y: auto;
}

.Actions {
  display: flex;
  flex: 1 1;
  width: 100%;
  flex-direction: column;
}

.Title-upload-caso{
  color: #264AD9B3;
  font-size: 13px;
}

.description-upload{
  margin-bottom: 32px;
  color: #555;
  font-size: 13px;
}

.Title {
  margin-bottom: 32px;
  color: #555;
}

.Filename {
  margin-bottom: 8px;
  font-size: 16px;
  color: #555;
}

.Row {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  padding: 8px;
  overflow: hidden;
  box-sizing: border-box;
}

.CheckIcon {
  opacity: 0.5;
  margin-left: 32px;
}

.ProgressWrapper {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
}

.section-upload-bottoncopy{
  margin-top: 4%;
  display: flex;
  text-align: center !important;
  justify-content: flex-end;
}

.upload-btncopy {
  font-family: "Roboto medium", sans-serif;
  font-size: 16px;
  display: inline-block;
  height: 36px;
  min-width: 88px;
  padding: 6px 16px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  border-radius: 5px;
  background: #21204a;
  color: #ffffff;
  outline: 0;
}

button:disabled {
  background: rgb(189, 189, 189);
  cursor: default;
}

.calendar-events-company{
width: 88%;
margin-left: 5%;
margin-right: 5%;
background-color: white;
height: 700px;
}

.fc{
    height: 500px;
}

.calendar-events-company-main{
    width: 60%;
    display: inline-block;
    position: relative;
    top: -17em;
    margin-left: 4%;
}

.calendar-events-company-side-form{
    width: 30%;
    position:relative;
    display: inline-block;
    margin-left: 1.5em;
    top: 5em;
    font-size: 13px;
    padding-left: 15px;
    box-shadow: 1px 2px 2px 1px #55555515;
    padding-bottom: 10em;
}

.input_calendar{
    border-style: none;
    max-width: 90%;
    background-color: transparent;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    padding-left: 10px;
    padding-right: 15em;
    border-bottom: solid 1px #000000;
}

.input_calendar-date{
    display: inline-block;
    border-style: none;
    background-color: transparent;
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: solid 1px #000000;
}

.buttons-calendar{
    padding-top: 2em;
    text-align: -webkit-center;
    margin-top: 10px;
}

.buttons-calendar-cerrar{
background-color: white;
color: black;
border-radius: 5px;
border: 1px solid black;
padding: 0.5em 1.5em 0.5em 1.5em;
justify-content: center;
}

.buttons-calendar-guardar{
    background-color: #212156;
    color: white;
    border-radius: 5px;
    border: 1px solid 540E87;
    padding: 0.5em 1.5em 0.5em 1.5em;
    margin-right: 1em;
}

.fc-scrollgrid-sync-inner{
    border-style: none;
}

.fc-event-main{
    background: rgb(32 32 73);
   
}

.fc-h-event {
    display: block;
    border: 1px solid rgb(32 32 73);
    border: 1px solid rgb(32 32 73);
    border: 1px solid var(--fc-event-border-color, rgb(32 32 73));
    background-color: rgb(32 32 73);
    background-color: rgb(32 32 73);
    background-color: var(--fc-event-bg-color, rgb(32 32 73));
}
.fc-col-header {
    background: rgb(32 32 73) !important;
    background: linear-gradient(156deg, rgb(32 32 73) 20%, rgb(0 0 177) 100%)!important;
    color: white;
    border-style: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.fc .fc-toolbar.fc-header-toolbar{
    display: flex;
    flex-direction: row;
    width: 65%;
    align-self: flex-end;
    margin-bottom: 5px;
}

.fc .fc-col-header-cell-cushion{
    font-size: 9px;
}

.fc-day-today{
    background-color: #8C00DF1A !important;
}


.fc-button{
    color: rgb(32 32 73) !important;
    background-color: white !important;
    font-size: 11px !important;
}

.fc-toolbar-title{
    font-size: 14px !important;
    font-weight: 500 !important;
}

@media screen and (max-width:960px){
        .calendar-events-company{
            margin: 0 auto;
            width: 90%;
            padding-bottom: 2rem;
            height: -webkit-max-content;
            height: max-content;
            display: flex;
            flex-direction: column;
        }
        .calendar-events-company-main{
            position: static;
            position: initial;
            top: 0;
            margin: 0 auto;
            padding: .4rem;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;

        }
        .calendar-events-company-side-form{
            width: 80%;
            margin: auto;
            top: 0;
            padding-bottom: 1rem;
            margin-top: 1rem;
        }
        .fc-header-toolbar{
            display: block;
        }
  }
.input-login-new input{
  
  background: #F5F5F5;
  font-style: normal;
  font-weight: 400;
  width: 348px;
  height: 41px;
  padding-left: 22px;
  font-size: 16px;
  border: 1px solid #C5C5C5;
  
}
.input-login-new input::-webkit-input-placeholder{
  color: #C5C5C5;
}
.input-login-new input:-ms-input-placeholder{
  color: #C5C5C5;
}
.input-login-new input::placeholder{
  color: #C5C5C5;
}
.input-login-new input:focus{
  color: #636161;
  padding-left: 22px;
  outline: 0.5px solid #E7BF00;
}

.login-forgot-pass{
  color: #E7BF00;
  font-size: 14px;
  font-weight: 400;
  margin-top: 2%;
  cursor: pointer;
}
.login-forgot-pass:hover{
  color: #D4B000;
}
.login-button-new{
  background: #202256;
  border-radius: 5px;
  width: 347px;
  height: 33px;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: none;
  margin-left: -1.2em;
}
.login-register-new{
  color: #202256;
  font-size: 14px;
  font-weight: bold;
  margin-top: 2%;
  margin-left: 0.2em;
  cursor: pointer;
  text-align: center;
}
.login-register-new:hover{
  color: #0B27E6;
}
 

.wave2{
  z-index: 1;

  width: 100%;
}


.wavess{
  position: absolute;
bottom: 0;
width: 100%;

 
}
.wave1{
margin-top: -12em;

  z-index: 2;
  width: 100%;
} 
.foto-creativity{
  position: absolute;
  width: 235px;
height: 235px;
left: 8em;
top: 8em;
background-color: #0B27E6;
}

.foto-imgcodigo{
  position: absolute;
  width: 235px;
height: 235px;
right: 8em;
bottom: 1em;
background-color: #D4B000;
}

.buttoooon{
  padding-left: .5em;
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 14px;
  text-decoration: underline;

}
.input-register-new input{

  width: 348px;
  height: 41px;

  font-style: normal;
  font-weight: 400;

  padding-left: 22px;
  font-size: 16px;
  border: 1px solid #C5C5C5;
  margin-left: 3em;
  margin-right: 3em;
}
.input-register-new input::-webkit-input-placeholder{
  color: #C5C5C5;

}
.input-register-new input:-ms-input-placeholder{
  color: #C5C5C5;

}
.input-register-new input::placeholder{
  color: #C5C5C5;

}
.input-register-new input:focus{
  color: #636161;
  padding-left: 22px;
  outline: 0.5px solid #E7BF00;
}

.register-forgot-pass{
  color: #E7BF00;
  font-size: 14px;
  font-weight: 400;
  margin-top: 2%;
  cursor: pointer;
}
.register-forgot-pass:hover{
  color: #D4B000;
}
.register-button-new{
  background: #202256;
  border-radius: 5px;
  width: 100%;
  height: 33px;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: none;
}
.register-register-new{
  color: #202256;
  font-size: 14px;
  font-weight: bold;
  margin-top: 1%;
  cursor: pointer;
 margin-left: .5em;
  width: 6.5em;
  justify-content: flex-start;
}
.register-register-new:hover{
  color: #0B27E6;
}

.b-contain *,
.b-contain *::before,
.b-contain *::after {
  box-sizing: content-box !important;
}

.b-contain input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.b-contain span {
  line-height: 1.5;
  font-size: 1rem;
  font-family: inherit;
}

.b-contain {
  display: table;
  position: relative;
  padding-left: 1rem;
  cursor: pointer;
  margin-bottom: 0.4rem;


}

.b-contain input[type="checkbox"] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background: #f1f5f9;
  transition: background 250ms;
  border: 1px solid #94a3b8;
  border-radius: 3.21rem;
}

.b-contain input[type="radio"] ~ .b-input {
  position: absolute;
  top: 5px;
  left: 0;
  height: 0.80rem;
  width: 0.80rem;
  background: #f1f5f9;
  transition: background 250ms;
  border: 1px solid #94a3b8;
  border-radius: 4.15rem;
}

.b-contain input[type="checkbox"] ~ .b-input::after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 0.3rem;
  height: 0.6rem;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transition: background 250ms;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.b-contain input[type="radio"] ~ .b-input::after {
  content: "";
  position: absolute;
  display: none;
  top: 3px;
  left: 3px;
  width: 0.40rem;
  height: 0.40rem;
  border-radius: 3rem;
  background: rgb(32, 34, 86);
  transition: background 250ms;
}

.b-contain input[type="checkbox"]:disabled ~ .b-input::after {
  border-color: #ffffff;
}

.b-contain input:checked ~ .b-input::after {
  display: block;
}

.b-contain:hover input[type="checkbox"]:not([disabled]) ~ .b-input,
.b-contain input[type="checkbox"]:focus ~ .b-input {
  background: #e2e8f0;
  border-color: #64748b;
}

.b-contain:hover input[type="radio"]:not([disabled]) ~ .b-input,
.b-contain input[type="radio"]:focus ~ .b-input {
  background: #e2e8f0;
  border-color: #64748b;
}

.b-contain input:focus ~ .b-input {
  box-shadow: 0 0 0 2px rgb(32, 34, 86);
}
.cropContainer{
  position: relative;
  width: 100%;
    height: 200px;
  background: #333;
}

@media(max-width: 1368px){
  .container-register{
    height: auto !important;
  }
  .container-register p{
margin-bottom: 0.5em !important;
  }
}

@media(max-width: 725px) {
  .terms-condition {
    width: auto !important;
    margin-left: 0 !important;
  }
}

.row {
  display: flex;
  flex-flow: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: relative;
  left: -1%;
  height: 100vh;
  width: 100%;
}

.row h1 {
  font-family: Poppins;
  text-align: center;
}

.card {
  border-radius: 5%;
  /*background-color: white;*/
  width: 35%;
  height: 45%;
}

.otp {
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
}

.otp input {
  margin: 5px;
  height: 35px;
  width: 40px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-family: Poppins;
  font-size: 1.2rem;
  background: #c8c8c8;
}

.body {
  border-radius: 5%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  color: white;
}

.body button {
  background-color: #202256;
  color: white;
  border-radius: 5%;
  margin-top: 2%;
  font-size: 16px;
  width: 25%;
  height: 50px;
}

.otp-button-new{
  background: #202256;
  border-radius: 5px;
  width: 300px;
  height: 33px;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: none;
}
.login-emp {
  background-size: cover;
  box-sizing: content-box;
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.login__container {
  text-align: center;
  width: 450px;
  min-width: 400px;
  margin-left: 55%;
  background-color: white;
  margin-right: 2%;
  min-height: 400px;
  padding: 60px 40px 40px;
  border-radius: 5%;
  box-sizing: border-box;
  overflow: auto;
}
.unactive{
  display: none;
}
/* login form */
.login__container__form {
  display: flex;
  flex-direction: column;
}

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid;
  outline-style: none;
  margin-top: 4%;
  padding: 12px;
  box-sizing: border-box;
}

.input:focus {
  border-bottom: 2px solid var(--main-yellow-findally);
  outline: none;
}
.form__validate {
  margin-top: 5%;
  margin-bottom: 7%;
}

.login__form__button {
  margin-top: 5%;
  margin-bottom: 5%;
  background-color: var(--main-yellow-findally);
  width: 135px;
  height: 39px;
  border-radius: 1.25rem;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  color: black;
  font-size: large;
  font-weight: 700;
  text-decoration: none;
}

.login__form-social {
  margin-bottom: 6%;
}

.login__form-social-img {
  text-align: center;
  margin-left: 20px;
  align-self: center;
  border-radius: 3px;
}

.login__form-social-btn-fg {
  display: inline-flex;
  border-radius: 20px;
  width: 216px;
  height: 36px;
  color: white;
  text-decoration: none;
  border: 1px solid;
  background-color: #3b5998;
}

.login__form-social-btn-lin {
  display: inline-flex;
  border-radius: 20px;
  margin-top: 10px;
  width: 216px;
  height: 36px;
  text-decoration: none;
  color: white;
  border: 1px solid;
  background-color: #2867b2;
}

.login__form-social-btn-go {
  display: inline-flex;
  width: 210px;
  height: 36px;
  margin-top: 10px;
  border-radius: 20px;
  text-decoration: none;
  color: black;
  border: 1px solid;
}
.kep-login-facebook {
  border-radius: 20px;
    margin-top: 10px;
    width: 216px;
    height: 36px;
    text-decoration: none;
    color: white;
    border: 1px solid;
    padding: 10px !important;
    border-radius: 20px !important;
}
.login__form-social-txt {
  margin: auto;
}

.login__form__button:hover {
  border: 1px solid blueviolet;
}

.login__form__button:focus {
  border: 2px solid var(--main-purple-findally);
}
.form__noregister-text-link {
  font-size: large;
  font-weight: bold;
  text-decoration: none;
}

@media (max-width: 1050px) {
  .login__container {
    padding: 3%;
    box-sizing: border-box;
    width: 50%;
    margin: 0 auto;
    overflow: auto;
  }
}

@media (max-width: 700) {
  .login {
    display: block;
    margin: 0 auto;
  }

}
@media (max-width: 500px) {
  .login__container {
    width: 50%;
    margin: 0;
    margin-right: 0;
  }
  .login_form-social-txt {
    display: none;
  }
  .login__form-social-img {
    width: 20px;
    margin-left: 20px;
  }
  .login__form-social-btn-go {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 10px;
    width: 80%;
  }
  .login__form-social-btn-lin {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 80%;
  }
  .login__form-social-btn-fg {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 80%;
  }
}
@media (max-width: 450px) {
  .login {
    display: inline;
  }
  .login__container {
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    padding: 30px;
    min-width: 250px;
  }
}

.register-empresa {
  background-size: cover;
  box-sizing: content-box;
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/* register form */
.register__container__form {
  display: flex;
  flex-direction: column;
}

.register__form-radio {
  display: flex;
  justify-content: space-evenly;
  margin-top: 9%;
  margin-bottom: 5%;
}

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid;
  outline-style: none;
  margin-top: 4%;
  padding: 12px;
  box-sizing: border-box;
}

.input:focus {
  border-bottom: 2px solid var(--main-yellow-findally);
  outline: none;
}

.register__form-conditions {
  font-size: smaller;
  width: 95%;
  margin: 0 auto;
  margin-top: 1%;
  margin-bottom: 4%;
}

.register__form-social {
  margin-bottom: 6%;
}

.register__form-social-img {
  text-align: center;
  margin-left: 20px;
  align-self: center;
  border-radius: 3px;
}

.register__form-social-btn-fg {
  display: inline-flex;
  border-radius: 20px;
  width: 120px;
  height: 36px;
  margin-right: 5px;
  color: white;
  text-decoration: none;
  border: 1px solid;
  background-color: #3b5998;
}

.register__form-social-btn-lin {
  display: inline-flex;
  margin-top: 1%;
  border-radius: 20px;
  width: 120px;
  margin-right: 5px;
  height: 36px;
  text-decoration: none;
  color: white;
  border: 1px solid;
  background-color: #2867b2;
}

.register__form-social-btn-go {
  display: inline-flex;
  width: 120px;
  height: 36px;
  margin-top: 10px;
  border-radius: 20px;
  text-decoration: none;
  color: black;
  border: 1px solid;
}

.register__form-social-txt {
  margin: auto;
}

.register__form__button {
  background-color: var(--main-yellow-findally);
  width: 135px;
  height: 39px;
  margin: 5px auto;
  margin-bottom: 3%;
  border-radius: 1.25rem;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  color: black;
  font-size: large;
  font-weight: 700;
  text-decoration: none;
}

.register__form__button:hover {
  border: 1px solid blueviolet;
}

.register__form__button:focus {
  border: 2px solid var(--main-purple-findally);
}

input[type="radio"].css-checkbox {
  display: none;
}
input[type="radio"].css-checkbox + label.css-label {
  padding-left: 1.6875rem;
  height: 1.12rem;
  display: inline-block;
  line-height: 1.12rem;
  background-repeat: no-repeat;
  background-position: 0 0;
  vertical-align: middle;
  cursor: pointer;
}
input[type="radio"].css-checkbox:checked + label.css-label {
  background-position: 0 -1.1rem;
}
label.css-label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*media querys*/
@media (max-width: 1050px) {
  .register__container {
    padding: 3%;
    box-sizing: border-box;
    width: 50%;
    margin: 0 auto;
    overflow: auto;
  }
}

@media (max-width: 700) {
  .register {
    display: block;
    margin: 0 auto;
  }
}
@media (max-width: 500px) {
  .register__container {
    width: 50%;
    margin: 0;
    margin-right: 0;
  }
  .register_form-social-txt {
    display: none;
  }
  .register__form-social-img {
    width: 20px;
    margin-left: 20px;
  }
  .register__form-social-btn-go {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 0px;
    width: 50%;
  }
  .register__form-social-btn-lin {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 50%;
  }
  .register__form-social-btn-fg {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 50%;
  }
}

@media (max-width: 450px) {
  .register {
    display: inline;
  }
  .register__container {
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    padding: 30px;
    min-width: 250px;
  }
}

.tyc_form {
  width: 1000px;
 
}

.section {
  padding: 3%;
  text-align: justify;
}

.title {
  padding: 1%;
  font-weight: 600;
}

.titulo-preguntas{
font-weight: 600;
text-decoration: underline;
text-transform: uppercase;
font-size: 17px;
}

.content, .sub-content {
  padding: 1%;
}
.titulo-legal{
background: white;
color: black;
text-align: center;
padding-top:1em;
font-size: 18px;
padding-bottom:1em;
}

.tyc{
background-color: hsl(0, 0%, 98%);
display: flex;
}
.box {
width: 100%;
background-color:  #00000000;

}
.box .box-item {
border-bottom: 1px solid #d8d8d8 !important;
color: #050931;
}
.box:hover .box-item:hover {
border-bottom: 1px solid #d8d8d8 !important;
color: #F46036A6;
content: 'hola';
}
.box .title {
margin: 0;
padding: 10px;
}
.box-item-content{
height: 0;
overflow: hidden;
color: #7E7E7E;
margin: 0;
padding-left: 20px;
transition: 0.5s;
}
ul{
list-style: none;
}
ul li{
padding-top: 5px;
}
.box:hover .box-item:hover .box-item-content {
height: 90px;
}
.upload-container{
    width: 50%;
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 0px 2px 10px #00000029;
}

.legal-tittle-ini{
    background-color: #ffffff;
    padding-top: 1em;
    padding-bottom: 1em;
}

.legal-tittle-content{
    color: #540e87;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
}
.img-feed-comp{
    max-width: 100%;
}
.img-feed-comp2{
    min-width: 20%;
    max-width: 20%;
    background-color: #ffffff;
    position:relative;
    height: 10em;
    border-radius: 10px;
    border: solid 1px rgba(80, 57, 57, 0.719);
position: relative;
display: flex;
margin-top: 50px;
flex-direction: column;
justify-content: flex-end;
align-items: center;
}
.img-feed-comp3{
    min-width: 20%;
    max-width: 20%;
    background-color: #ffffff;
    height: 10em;
    border-radius: 10px;
    border: solid 1px rgba(80, 57, 57, 0.719);
position: relative;
display: flex;
margin-top: 50px;
flex-direction: column;
justify-content: flex-end;
align-items: center;
}
.img-feed-comp3:hover{
    background-color: rgb(234, 236, 255);
}
.ch-card-txt-legal-docs2{
    font-size: 0.8em;
    max-width: 100%;
}
.contenedor-firmas{
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #212156 !important;
}
.ch-card-txt-legal-docs{
    background-color: rgb(255, 255, 255);
    width: 100%;
    font-size: 15px !important;
    border-radius: 2px !important;
    padding: 0.5em 1.2em 0.5em 1.2em !important;
    border-left: 0.2em solid #afafaf;
    border-right: 0.2em solid #afafaf;
    border-top: 0.2em solid #afafaf;
    margin: 0 !important;
    text-align: center;
}
.botones_filter_legal{
    width: 100%;
    height: 3em;
    display: flex;
    justify-content: space-around;
}
.carpetas_filter_legal{
    width: 100%;
    padding: 0px 0px 50px 0px;
    display: flex;
    justify-content: space-around;
}
.userprojects__list{
    display: flex;
    flex-direction: column;
}
.ch-card-txt-legal-docs3{
    font-size: 2em;
}
.ch-card-txt-legal{
    background-color:#FAFAFA;
    color: black;
    text-transform: uppercase;
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
}
.canva canvas{
    width: 400px;
    height: 100px;
 }

 .firma-botones{
     padding: 1em;
 }

 .boton-firma-co{
    background-color: #FFFFFF;
    color: #d0d0d0;
    padding: 1em;
    border-radius: 7px;
    border: 1px solid #d0d0d0;
    text-transform: capitalize;
 }
.certificate_form{
    margin-top: 2em;
    padding: 2% 15% 0% 15%;
    margin-bottom: 4em;
}

.certif-location-date{
    text-align: right;
    margin-bottom: 1em;
}

.tittle-certificado{
    margin-top: 3em;
    margin-bottom: 2em;
    text-align: center;
}

.cert-rep-legal{
    display: inline-block;
}

.cert-company-name{
    display: inline-block;
}

.cert-company{
    display: inline-block;
}

.cert-nit-company{
    display: inline-block;
}

.cert-firm{
    margin-top: 3em;
    margin-bottom: 2em;
}
.confidencialidad{
    margin-top: 2em;
    padding: 2% 15% 0% 15%;
    margin-bottom: 4em;
}

.confif-location-date{
    text-align: right;
    margin-bottom: 1em;
}

.tittle-confificado{
    margin-top: 3em;
    margin-bottom: 2em;
    text-align: center;
}

.conf-rep-legal{
    display: inline-block;
}

.conf-company-name{
    display: inline-block;
}

.conf-company{
    display: inline-block;
}

.conf-nit-company{
    display: inline-block;
}

.conf-firm{
    margin-top: 3em;
    margin-bottom: 2em;
}

.conf-desc-in{
    margin-top: 2em;
    margin-bottom: 2em;
}

.conf-personal-data{
    text-align: justify;
    margin-top: 1em;
    margin-bottom: 2em;
    font-size: 15px;
}

.conf-tittle-consid{
    margin-top: 1em;
    margin-bottom: 1em;
}

.conf-tittle-sec{
    text-align: justify;
    text-align: center;
    font-size: 19px;
}

.conf-description{
    text-align: justify;
    font-size: 15px;
}

.conf-content-contract{
    text-align: justify;
    font-size: 15px;
}

.doc-button-pdf{
    padding: 5px;
    color: #f46036;
    background-color: #ffffff;
    border: 1px solid #f46036;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 500;
    text-transform: uppercase;
}

.div-button-pdf-gen{
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    margin-bottom: 20px;
}
.kps_form{
    margin-top: 2em;
    padding: 2% 15% 0% 15%;
    margin-bottom: 4em;
}

.kps{
    font-size: 15px;
}

.kps-location-date{
    text-align: right;
    margin-bottom: 1em;
}

.tittle-kps{
    margin-top: 3em;
    margin-bottom: 2em;
    font-size: 19px !important;
    text-align: center;
}

.kps-tittle-sec{
    display: inline-block;
}

.kps-rep-legal{
    display: inline-block;
}

.kps-company-name{
    display: inline-block;
}

.kps-company{
    display: inline-block;
}

.kps-nit-company{
    display: inline-block;
}

.kps-firm{
    margin-top: 3em;
    margin-bottom: 2em;
}
.datapolicy_form {
      width: 1000px;

}  

.datapolicy{
       padding-top: 50px;
    display: flex;
    margin-bottom: 5em;
}

.section {
    padding: 3%;
    text-align: justify;
}

.title {
    padding: 1%;
}

.content, .sub-content {
    padding: 1%;
}
.support_ticket{
  background-color: hsla(0, 0%, 100%, 0);
  padding-top: 50px;
  display: flex;
  z-index: 1;
}
.bottom-blur-advance{
  position: absolute;
  background-size: cover;
  background: #212156;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.bottom-blur-advance2{
  position: absolute;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}


.volver-legal{
  background: #212156;
  color: white;
  text-align: left;
  font-size: 18px;
  padding-top: 13px;
  padding-bottom: 13px;
}
.ejemplo-imagen{
  position: relative;
  width: 100%;
  height:100%;
  background-color: rgb(0, 0, 0);
  margin-right: 20px;
  border-radius: 10px;
}
.volver-leg-icon{
margin-left: 15px;
}
.text-form-right{
  height: 100%;
  width:40%;
  margin-left: 9em;
  margin-top: 1em;
}
.support_form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fffffff6;
  width: 900px;
  height: 550px;
  margin: 17%;
  margin-top: 5%;
  border-radius: 10px;
  padding: 40px;
  box-sizing: border-box;
  position: absolute;
}

.content-contact-support-info{
  color: #212156;
}

.support_form2{
  display: flex;
  flex-direction: column;
  background-color: #fffffff6;
  width: 700px;
  height: -webkit-max-content;
  height: max-content;
  margin-top: 0;
  margin-left: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  position: absolute;
}

.input_support {
  width: 80%;
  margin-top: 4%;
  border-radius: 5px;
  outline: none;
  padding: 2%;
  border: 1px solid #d9d9d9;
  font-size: 16px;
}

.textarea-support{
  width: 80%;
  height: 50%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  resize: none;
  outline-style: none;
  margin-top: 2%;
  padding: 2em;
}

.textarea {
  width: 100%;
  height: 50%;
  outline-style: none;
  margin-top: 4%;
  padding: 7% !important;
  margin-left: 0!important;
  border-style: none !important ;
  background-color: #aaa9a981;
  border-radius: 50px !important;
  font-size: 14px;
  resize: none;
}

.category-label {
  padding: 3%;
  color: #393939;
}

.category-options{
  font-size: 13px;
}

.title_form_ticket {
  color: #ffffff !important;
  margin-bottom: 10%;
  text-transform: uppercase;
  font-weight: 300;
}

.button_support{
  background-color: #212156;
  width: 30%; 
  text-transform: uppercase;
  padding: 2%;  
  margin-top: 5%;
  border-radius: 10px;
  font: normal normal bold 14px/20px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
}
.contenedor-indo{
  display: flex;
  
}
.info- {
  border: 4px solid #212156;  
  width: 30%;
  height: 440px;
  padding: 2%;
  margin-top: 3.5%;
  margin-left: 15%;
  font-size: 16px;
  border-radius: 15px ; 
}

.feedback_content{
    background-color: hsl(0, 0%, 98%);
    padding-top: 50px;
  }
  
  .feedback_form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #FFFFFF;
    text-align: center;
    width: 700px;
    margin-left: 28%;
    align-items: center;
    padding: 40px;
    box-sizing: border-box;
    overflow: auto;
    margin-bottom: 3em;
  }
  
  .textarea {
    width: 100%;
    outline-style: none;
    margin-top: 4%;
    padding: 2%;
    border: 2px solid #707070;
    border-radius: 10px;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 50px;
  }
  .textarea2 {
    width: 100%;
    height: 50%;
    outline-style: none;
    margin-top: 4%;
    padding: 7% !important;
    margin-left: 0!important;
    border-style: none !important ;
    background-color: #aaa9a981;
    border-radius: 10px !important;
    font-size: 14px;
    resize: none;
  }
  
  .title_form {
    color: #28157e;
    margin-bottom: 10%;
  }
  
  .button_feedback{
    background-color: #f46036;
    width: 17%;
    text-transform: uppercase;
    padding: 2%;
    font-weight: 500 !important;
    margin-top: 5%;
    border-radius: 10px;
    font: normal normal bold 14px/20px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
  }

  .section_feedback {
    margin: 0px 0px 20px 0px;
  }

  .label-radio{
    color: black;
    border: 1px solid rgba(0, 0, 0, 0);
    padding: 10px;
    cursor: pointer;
  }
  
  .label-radio:hover{
    color: black;
    border: 1px solid black;
    padding: 10px;
  }

  .label-radio:checked+label {
    padding: 10px;
    font-weight: 600;
    background: var(--color-amarillo);
    border-radius: 2px;
    color: rgb(255, 255, 255);
}

  /* .radio-button {
    margin-right: 15px;
  } */

.bar {
  background-color:#21204a;
  width: 100%;
  padding-left:1.2rem ;
  font: normal normal bold 16px/50px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  text-align: left;
}

.icon {
  padding-top: 8px;
  color: white;
}
.MuiSvgIcon-root{
  margin: 0;
}
.MuiStepLabel-active {
    color: black;
    font-weight: 700 !important;
    font-size: 22px !important;
    text-transform: uppercase;
}

.MuiStepLabel-label {
    color: black;
    text-transform: uppercase;
    font-size: 18px !important;
}

.MuiButton-containedSecondary {
    color: #fff;
    background-color: #F46036 !important;
}

.MuiOutlinedInput-inputMarginDense {
    padding-top: 10.5px;
    padding-bottom: 5px !important;
}

.ConsultorForm-container{
    min-width: 300px;
    max-width: 700px;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    margin: 0 auto;
    text-align: center;
    background-color: white;
    min-height: 400px;
    border-radius: 2%;
    box-sizing: border-box;
    overflow: auto;
    
}

/* titulo */
.ConsultorForm-tittle{
    font-size: 25px;
    font-weight: 700;
    margin-top: 2rem;
    text-align: center;
    text-transform: uppercase;
}

.ConsultorForm_container-form{
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 5rem;
    padding: 40px 35px;
    background-color: #f6f6f6;
}
.consultor-cv-button-form{
    width: 200px; 
    height: 25px; 
    color: #FFFFFF; 
    background-color: #202256; 
    border-radius: 5px;
    transition: 400ms;
}
.consultor-cv-button-form:hover{
    background-color: #F07130;
    color: #FFFFFF;
}
.ConsultorForm-input{
    width: 100%;
    border: none;
    border-bottom: 1px solid;
    outline-style: none;
    font-size: 15px;
    margin-top: 4%;
    padding: 12px;
    box-sizing: border-box;
    background-color: transparent;
}
.ConsultorForm-label{
    padding-top: 2rem;
    text-align: left;
    font-size: 15px;
    color: #757575; 
}
.css-2b097c-container {
    position: relative;
    box-sizing: border-box;
    padding: 0px 10px 20px 0px;
    margin-top: 1em;
}

.ConsultorForm-input:focus {
    border-bottom: 2px solid var(--main-yellow-findally);
    outline: none;
} 

.ConsultorForm-button{
    padding: 8px;
    color: white;
    background-color: #f46036;
    border-style: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 600;
    text-transform: uppercase;
}

.ConsultorForm-label-activity{
    padding-top: 1rem;
    text-align: left;
    font-size: 15px;
    color: #757575;   
}

.ConsultorForm-select-activity{
    padding: 5px;
    margin-top: 10px;
    margin-left: 10px;
    border-color: #cccccc;
    border-radius: 2%;
    color: #757575;
}

.grapgrid{  
    grid-gap: 10px;
  }
  .buttondetalleoportunidad{
    padding: 0.2rem 0.7rem;
    height: 28px;
    left: 650px;
    top: 411px;
    color:white;
    background: #c5c5c5;
    border-radius: 5px;
    transition: 400ms;
  }
  .buttondetalleoportunidadchecked:hover{
    font-weight: bold;
    transition: 400ms;
    background: #F07130;
  }
  .buttondetalleoportunidadchecked{
    padding: 0.2rem 0.7rem;
    height: 28px;
    left: 650px;
    top: 411px;
    color:white;
    background: #202256;
    border-radius: 5px;
    transition: 400ms;
  }
  .textootroasoportunidades{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 36px;

display: flex;
align-items: center;


color: #393939;
  }
  .titulooportunidadesdetalle{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 40px;

display: flex;
align-items: center;

color: #202256;
  }
  .contedor-carta-recomendados-aplicar{    
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 300px;
    max-width: 297px;
    min-width: 70%;
    margin-bottom: 21px;
    margin-top: 10px;
    margin-left: 20px;
    border-radius: 8px;
  }
  .contenedor-primero-aplicar{    
    width: 100%;
    display: flex;
    padding-top: 20px;
    grid-gap: 0rem;
    gap: 0rem;
    align-items: flex-start;
    padding-bottom: 20px;
    margin: 4em;
  }

  .img-primero-aplicar{
    margin-bottom: 1rem;
    width: 20%;
    height: 10%;
      max-height: 10%;
        max-width: 20%;
    border-radius: 8px;
    object-fit: fill;
  }
  .ifo-primero-aplicar{
    width: 40%;
    margin-left: 10%;
  }

  .tittle-popup-applied{
    margin-bottom: 0px 
  }

  @media (max-width:620px){
  .BodyWeb{
    padding: 0 1rem !important;
    }

    .img-primero-aplicar{
      margin-bottom: 1rem;
      width: 80%;
      height: 40%;
      border-radius: 8px;
      object-fit: fill;
    }
    .ifo-primero-aplicar{
      width: 80%;
    }
    .contenedor-primero-aplicar{    
        flex-direction: column;
        margin: 0;
      }
      .contedor-carta-recomendados-aplicar{
        margin-left: 0;
        max-width: 270px;
      }
      .contedor-carta-recomendados-aplicar div img{
        width: 270px !important;
      }
      .contedor-carta-recomendados-aplicar div {
        width: 270px !important;
      }
      .contedor-carta-recomendados-aplicar div div{
        width: 270px !important;
      }
      .titulooportunidadesdetalle{
        font-size: 20px;
        margin:0px !important;
      }
  }

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    margin:0px !important;
  }

  .oportunity-scroll {
    height: 190px;
    overflow: auto;
    overflow-y: scroll;
  scrollbar-color: orange rgb(189, 189, 189);
  scrollbar-width: thin;
  }
  .oportunity-scroll::-webkit-scrollbar-track
  {
      background-color: rgb(189, 189, 189);
  }
  
  .oportunity-scroll::-webkit-scrollbar
  {
      width: 5px;
      background-color: #F5F5F5;
  }
  
  .oportunity-scroll::-webkit-scrollbar-thumb
  {
      background-color: orange;
      border-radius: 10px;
  }

  .infoOportunity{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-left: 4em;
  }

  .infoOportunity-left{
    width: 46%;
    overflow-wrap: break-word;
    margin-right: 3em;
    margin-left: -30px;
  }

  .infoOportunity-ritgh{
    width: 40%;
    margin-left: 0;
  }

  @media (max-width:520px){
    .infoOportunity{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-left: 0;
    }
  
    .infoOportunity-left{
      width: 100%;
      margin-bottom: 1em;
      margin-right: 0;
      margin-left: 0;
    }
  
    .infoOportunity-ritgh{
      width: 100%;
    }
    .container-other-opp{
      align-items: center;
    }
    .contenedor-primero-aplicar img{
      align-self: center;
    }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 400;
  }
  
  .popup-inner {
    position: absolute;
    display: flex;
    top: 7%;
    padding: 10px;
    background: #1bb96d;
    width: 40%;
    align-items: center;
    color: white;
    height: auto;
    border-radius: 8px;
    justify-content: space-evenly;
  }
  
  .popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  
  .close-btn-pop{
    /* padding: 7px; */
    background-color: white;
    color: #7dbe6a;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 12px;
    border-style: none;
    border-radius: 5px;
    text-transform: uppercase;
  }
.CardOportunidadProfesional-Container {
    background: #FFFFFF;
    width: 100%;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    height: 144px;
    margin-bottom: 22px;
}

.CardOportunidadProfesional-Image {
    object-fit: cover;
    height: 144px;
    width: 144px;
    border-radius: 8px 0 0 8px;
}

.CardOportunidadProfesional-Sub-Container {
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
}

.CardOportunidadProfesional-Button {
    height: 30px;
    width: 140px;
    background: transparent;
    border: 1px solid #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
    transition: all 0.3s ease;
}
.CardOportunidadProfesional-Button:hover {
    background: #202256;
    color: #FFFFFF;
    transition: all 0.3s ease;
}

.CardOportunidadProfesional-Button-applied{
    height: 30px;
    width: 140px;
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    transition: all 0.3s ease;
}

.CardOportunidadProfesional-Button-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    width: 140px;
    background: transparent;
    border: none;
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.CardOportunidadProfesional-Button-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}

.CardOportunidadProfesional-Salario {
    color: #0B27E6;
    font-weight: 700;
    font-size: 14px;
    margin-top: 10px;
}

.CardOportunidadProfesional-Titulo {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.CardOportunidadProfesional-Descripcion {
    font-weight: 300;
    font-size: 14px;
    color: #5D5D5D;
    margin: 0;
    padding: 0;
}

.CardOportunidadProfesional-Descripcion-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CardOportunidadProfesional-Data-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.CardOportunidadProfesional-Salario-Null {
    font-weight: 300;
    font-size: 14px;
    margin-top: 10px;
}

@media (max-width: 1000px) {
    .CardOportunidadProfesional-Descripcion {
        display: none;
    }
}

@media (max-width: 725px){
    .CardOportunidadProfesional-Container{
        height:auto;
    }
    .CardOportunidadProfesional-Sub-Container{
        display:revert;
    }
    .CardOportunidadProfesional-Button-Container{
       justify-content: normal;
        margin-bottom: 1em;
    }
    .CardOportunidadProfesional-Image{
        height: 100%;
    }
}
.img-demo-content{
    height: 544px;
    width: 1315px;
    margin-bottom: 2.5em;
}

.carrusel-demo{
    justify-content: center;
    text-align: center;
    text-align: center;
    background-color: white;
}
  
.swiper-pagination-bullet {
    width: 30px !important;
    height: 30px !important;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    color: white !important;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
}
  
  .swiper-pagination-bullet-active {
    color: #fff !important;
    background: #8C00DF !important;
  }
  

  @media (max-width: 700) {
    .img-demo-content{
        height: 100px;
        width: 248px;
        margin-bottom: 3em;
    }
  
  }

  @media (max-width: 500) {
    .img-demo-content{
        height: 100px;
        width: 248px;
        margin-bottom: 3em;
    }
  
  }

  @media (max-width: 450) {
    .img-demo-content{
        height: 100px;
        width: 248px;
        margin-bottom: 3em;
    }
  
  }
.CompanyForm-input-one {
    background-color: rgb(255, 255, 255);
    font-family: 'Roboto';
    width: 100%;
    font-size: 15px !important;
    margin-left: 8px !important;
    border-color: #cccccc !important;
    border-radius: 4px !important;
}

.MuiSelect-select[multiple] {
    height: auto;
    z-index: 1 !important;
    background: white !important;
    margin-bottom: 6em;
}

.MuiSelect-select.MuiSelect-select {
    border: solid 1px #202256;
    padding-left: 20px;
    border-radius: 5px;
}

.MuiStepIcon-completed {
    color: #202256 !important;
}

.MuiStepIcon-active {
    color: red !important;
}

.jss3 {
    width: 1000px !important;
}

.Companies-CP-container {
    max-width: 1080px;
    margin: 0 auto;
}

.CompanyForm-label-otra {
    background-color: rgb(255, 255, 255);
    font-family: 'Roboto';
    width: 100%;
    font-size: 15px !important;
    margin-left: 8px !important;
    border-color: #cccccc !important;
    border-radius: 4px !important;
}

.CompanyForm-label-comments {
    background-color: rgb(255, 255, 255);
    font-family: 'Roboto';
    width: 100%;
    font-size: 15px !important;
    margin-left: 8px !important;
    margin-bottom: 15px;
    border-color: #cccccc !important;
    border-radius: 4px !important;
    margin-top: 10px;
}

.CompanyForm-button-submit {
    padding: 8px;
    color: white;
    background-color: #f46036;
    border-style: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 600;
    text-transform: uppercase;
}

/*Unregister form styles*/

label + .MuiInput-formControl {
    margin-top: 4em !important;
}

/*end*/

/*Create new form*/

.MuiButton-containedPrimary {
    color: #fff;
    background-color: #ff5925;
}

.select-area-create-project {
    display: flex;
}

.col-one-create-project {
    width: 60%;
}

.col-two-create-project {
    width: 60%;
}

.col-two-create-project3 {
    width: 45%;
}

.col-two-create-project3 .MuiInputBase-root {
    margin-right: 20rem !important;
    margin-top: 9% !important;
}

.radio-button-form {
    display: inline-block !important;
}

.input_create_project_new_form_sec {
    width: 40%;
    height: 45px;
    background: #fff;
    color: #555;
    /*     line-height: 50px;
     */
    border: 2px solid #c8c8c8;
    font-size: 16px;
    margin-bottom: 25px;
    margin-left: 2%;
    margin-top: 9%;
    padding-right: 5px !important;
}

.p-question-create-project {
    text-align: left;
    font-size: 13.5px;
    color: #555;
}

.message-alert-create-project {
    font-size: 13px;
    color: rgb(255, 255, 255);
    text-align: center;
    padding-top: 1em;
    padding-left: 5em;
    padding-right: 5em;
}

.paymet-section-buttons {
    width: 90%;
}

.input_create_project_new_form {
    width: 100%;
    height: 41px;
    /*padding-left: 20px;*/
    background: transparent;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    color: #414153;
    font-size: 14px;
    margin-bottom: 25px;
    margin-left: 3%;
    margin-top: 4%;
    padding-right: 15px !important;
}

.input_create_project_new_form:focus {
    outline: 1px solid #0B27E6;
}

.tittle-question-create-project {
    text-align: left;
    margin-left: 0.3em;
    margin-top: 1.5em;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: rgba(21, 21, 21, 0.99);
}

.creeate-project-form-container {
    border-radius: 7px;
    min-width: 500px;
    overflow-x: hidden !important;
    max-width: 1150px;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    margin: 0 auto;
    text-align: center;
    background-color: white;
    min-height: 400px;
    box-sizing: border-box;
    overflow: auto;
}

.label-confindential-project {
    margin-right: 20px;
    margin-bottom: 15px;
    margin-top: 10px;
}

.title_form_create_project {
    font-size: 29px;
    color: #23224c;
    margin-bottom: 1em;
    margin-top: 2em;
    font-weight: 700;
    text-transform: uppercase !important;
    font-weight: 700;
}

.section-altert-content-payment {
    min-width: 500px;
    max-width: 1000px;
    margin-top: 10px;
    margin: 0 auto;
    text-align: center;
    background-color: #333333;
    box-sizing: border-box;
    overflow: auto;
}

.button_create_project {
    background: #f37328 0% 0% no-repeat padding-box;
    box-shadow: 4px 3px 6px #00000029;
    color: white;
    border-radius: 4px;
    padding: 0.5em;
    margin-bottom: 2em;
    margin-left: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.button-add {
    background: #23224c 0% 0% no-repeat padding-box;
    box-shadow: 4px 3px 6px #00000029;
    color: white;
    border-radius: 4px;
    padding: 0.5em;
    margin-bottom: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border: none;
}

.button-add {
    transition-duration: 0.4s;
}

.button-add:hover {
    background-color: #191838 !important;
    color: white;
}

.button-add:active {
    background-color: #191838 !important;
    color: white;
}

.button-remove {
    background: #f37328 0% 0% no-repeat padding-box;
    box-shadow: 4px 3px 6px #00000029;
    color: white;
    border-radius: 4px;
    padding: 0.5em;
    margin-bottom: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border: none;
}

.button-remove {
    transition-duration: 0.4s;
}

.button-remove:hover {
    background-color: #be571b !important;
    color: white;
}

.button-remove:active {
    background-color: #be571b !important;
    color: white;
}

.buttons-add-dynamic-input {
    margin-top: 1em;
    text-align: left;
    justify-content: left;
    display: flex;
}

.tittle-section-question {
    display: flex;
    font-size: 14px !important;
    margin-left: 1em;
}

.tittle-section-question2 {
    display: flex;
    font-size: 14px !important;
}

.tittle-section-question2 .MuiInputBase-root {
    margin-top: 1000% !important;
}

.number-question-form {
    width: 30px;
    height: 30px;
    background: transparent linear-gradient(180deg, #540E87 0%, #FF2300 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    color: white;
    border-radius: 100px;
    padding-top: 0.2em;
    margin-top: 1.1em;
    margin-left: 0.6em;
    font-size: 16.5px;
}

.section-tittle-create-project-form {
    background: white;
    padding: 18px 25px;
    text-align: left;
}

.tittle-create-project-form {
    color: black;
    font-size: 17px;
    font-weight: 900;
}

.section-tittle-out-form {
    background: white;
    padding: 22px 29px;
    text-align: left;
}

.tittle-out-form {
    color: black;
    font-size: 22px;
    font-weight: 900;
}

.select-create-project {
    background: transparent;
    width: 100%;
    height: 45px;
    border: 1px solid #202256;
    color: #202256 !important;
}

.MuiInputBase-root {
    background: #fff;
    color: #555;
    border: 2px solid #c8c8c8;
    height: 45px !important;
    margin: 4% 3% !important;
}

.create-company-form {
    /* background-image: url(/static/media/fondo-formularios.ee3709f4.png); */
    background-size: cover;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 88%;
}

.MuiStepper-root-offi {
    /*padding: 2rem !important;*/
    width: 100%;
    margin-top: 0;
    /*margin-bottom: -160px;*/
    height: 0;
}

.MuiStepConnector-horizontal {
}

.MuiStep-horizontal {
    background-color: white;
    /*margin-top: -40px;*/
    margin-bottom: 0;
}

.MuiStepLabel-root {
    background-color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #C5C5C5;
    text-align: center;
}

.Mui-active {
 color: red;
}

.Mui-completed {
    color: #202256;
}

.MuiStepLabel-label {
    font-family: Poppins !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #202256 !important;
    text-transform: capitalize;
}

.botoncerrarpopuppro {
    display: flex;
    color: white;
    align-content: center;
    justify-content: center;
    border: 2px solid white;
    width: 30px;
    height: 25px;
    padding-bottom: 25px;
    border-radius: 5px;
}

.contadordepreguntas {
    color: #202256;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}

.css-10mg1vw-MuiStepper-root {
}

.btn-box {
    text-align: center;
    margin-top: 1em;
}

.col-one-buttons-dynamic-input {
    width: 45%;
    text-align: center;
}

.col-two-buttons-dynamic-input {
    width: 45%;
    text-align: center;
}

.button_div_create_project {
    text-align: center;
}

/*end new form*/

.MuiStepLabel-label.MuiStepLabel-active {
    font-size: var(--font-size-item-grande) !important;
    font-weight: 700 !important;
}

.MuiStepLabel-label {
    font-size: var(--font-size-item-grande) !important;
}

.css-1t4i7pk-MuiButtonBase-root-MuiButton-root {
    background-color: #F46036 !important;
    box-shadow: 2px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
}

.MuiStepper-root {
    /*padding: 10vmin !important;*/
}

.MuiInput-root {
    width: 100%;
}


.option-btn {
    background-color: var(--color-gris-ligth);
    color: var(--color-gris-medio);
    width: -webkit-max-content;
    width: max-content;
    padding: 10px;
    margin: 5px;
}

.MuiFormControl-root {
    width: 90%;
    padding: 5px;
}

.step-tittle {
    font-size: var(--font-size-item);
    color: var(--color-text-gris);
    text-align: center;
    padding: 3vmin;
}

.MuiButton-root.Mui-disabled {
    background-color: var(--color-gris-ligth) !important;
    color: black !important;
}

.makeStyles-button-2 {
    background: #F46036 0% 0% no-repeat padding-box !important;
    box-shadow: 2px 3px 6px #00000029 !important;
    color: black;
    border-radius: 8px !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
    width: 1.8em;
    height: 1.8em;
    border-radius: 50px;
    color: transparent !important;
    background: transparent linear-gradient(180deg, #23224c 0%, #23224c 100%) 0% 0% no-repeat padding-box;
    /* background: transparent linear-gradient(180deg, #540E87 0%, #FF2300 100%) 0% 0% no-repeat padding-box; */
}

.css-1wm3cm5-MuiSvgIcon-root-MuiStepIcon-root.MuiStepIcon-completed {
    top: 129px;
    left: 180px;
    width: 35px;
    height: 33px;
    color: white;
    background: transparent linear-gradient(180deg, #540E87 0%, #FF2300 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    box-shadow: 2px 3px 6px #00000029;
    border-radius: 50%;
}

.css-d0ukip-MuiButtonBase-root-MuiButton-root {
    border-style: solid;
    border-width: 1px;
    border-color: black;
    color: black;
    box-shadow: 2px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    padding-left: 30px;
    padding-right: 30px;
}


.MuiStepper-vertical {
    flex-direction: column;
    margin-left: 50px;
}

.css-1yudz7t-MuiTypography-root-MuiStepLabel-label.Mui-active {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 900;
    font-size: 24px !important;
    opacity: 1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.css-gga68z-MuiInputBase-input {
    padding-right: 100px !important;
    min-width: 500px !important;

}


.css-1t4i7pk-MuiButtonBase-root-MuiButton-root {
    background-color: #F46036 !important;
    box-shadow: 2px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
}

.css-1wm3cm5-MuiSvgIcon-root-MuiStepIcon-root.MuiStepIcon-completed {
    top: 129px;
    left: 180px;
    width: 35px;
    height: 33px;
    color: white;
    background: transparent linear-gradient(180deg, #540E87 0%, #FF2300 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 50%;
}

.css-d0ukip-MuiButtonBase-root-MuiButton-root {
    border-style: solid;
    border-width: 1px;
    border-color: black;
    color: black;
    box-shadow: 2px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    padding-left: 30px;
    padding-right: 30px;
}

.makeStyles-root-21 {
    max-width: 1000px !important;
}

.css-1yudz7t-MuiTypography-root-MuiStepLabel-label.Mui-active {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 900;
    font-size: 30px;
    opacity: 1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.col-two-create-project2 {
    width: 100%;
    display: flex;
    /* position: relative; */
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.formulariocrear .MuiInputBase-root {
    background: transparent !important;
}

.MuiSelect-select.MuiSelect-select {
    background: white !important;
}

.button_create_projectform {
    width: 111px;
    height: 28px;
    font-style: normal;
    background: #202256 0% 0% no-repeat padding-box;
    color: white;
    font-weight: 400;
    font-size: 14px;
    border-radius: 5px;
    transition: all 0.25s ease-in-out 0s;
    margin-bottom: 28px;
}

.button_create_projectform:hover {
    font-weight: 700;
}

.button_create_projectform2 {
    background: transparent 0% 0% no-repeat padding-box;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
    border: 1px solid #202256;
    width: 111px;
    height: 28px;
    border-radius: 5px;
    transition: all 0.25s ease-in-out 0s;
    margin-bottom: 28px;
}

.button_create_projectform2:hover {
    background: #202256 0% 0% no-repeat padding-box;
    color: white;
}

.audioo {

    margin-left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.input_create_project_new_form23 {
    height: 100%;
    padding-left: 20px;
    background: transparent;
    color: #414153;
    line-height: 50px;
    border: none;
    font-size: 14px;
    margin-bottom: 25px;
    margin-top: 25px;
    border-radius: 6px;
    padding-right: 15px !important;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}

.input_create_project_new_form23:focus {
    outline: 1px solid #0B27E6;
}

.CompanyForm{
    background-size: cover;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.CompanyForm-button-submit{
    margin-top: 40px !important;
    border-radius: 5px !important;
}

.MuiPaper-root makeStyles-paper-4 MuiPaper-elevation1 MuiPaper-rounded{
    width: 1000px !important;
}

.makeStyles-layout-3{
    width: 1000px !important;
    margin-left: 0; 
    margin-right: 0;
}

.CompanyForm-container {
    min-width: 500px;
    max-width: 1000px;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    margin: 0 auto;
    text-align: center;
    background-color: white;
    min-height: 400px;
    box-sizing: border-box;
    overflow: auto;
}

.MuiTypography-alignCenter{
    color: #540e87 !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
}

.makeStyles-layout-13{
    width: 1000px !important;
}

/* titulo */
.CompanyForm-tittle{
    font-size: 25px;
    font-weight: 700;
    margin-top: 2rem;
    text-align: center;
    text-transform: uppercase;
}

.CompanyForm_container-form{
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 5rem;
    padding: 40px 35px;
    background-color: #f6f6f6;
}

.CompanyForm-input{
    width: 100%;
    border: none;
    border-bottom: 1px solid;
    outline-style: none;
    font-size: 15px;
    margin-top: 4%;
    padding: 12px;
    box-sizing: border-box;
    background-color: transparent;
}
.CompanyForm-label{
    padding-top: 2rem;
    text-align: left;
    font-size: 15px;
    color: #757575; 
}


.CompanyForm-input:focus {
    border-bottom: 2px solid var(--main-yellow-findally);
    outline: none;
}

.select__value-container{
    font-size: 15px;
    padding: 8px;
}

.button_form_company {
    padding: 8px;
    color: white;
    background-color: #f46036;
    border-style: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 600;
    text-transform: uppercase;
}

.generic_price_table{
  background-color: .0eded;
}

/*PRICE COLOR CODE START*/

.card_container_pricing{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  width: 300px;
  height: 580px;
  border-radius: 8px;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}
.card_container_pricing2{
  display: flex;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  background-color: #202256;
  width: 300px;
  height: 580px;
  border-radius: 8px;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}
.card_container_pricing3{
  display: flex;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  background-color: #F07130;
  width: 300px;
  height: 580px;
  border-radius: 8px;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}
.card_pricing_content{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}
.card_pricing_content_header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}
.card_title_tag{
  margin-top: 31px;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 700;
}
.card_pricing_tag_title{
  margin-bottom: 30px;
  text-align: center;
}
.card_pricing_tag_title2{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 30px;
  text-align: center;
}
.card_price_text{
  font-family: 'Roboto', sans-serif;

  font-size: 36px;
  font-weight: 700;
}
.card_price_text2{
  font-family: 'Roboto', sans-serif;

  font-size: 30px;
  font-weight: 700;
}
.card_price_text3{
  font-family: 'Roboto', sans-serif;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
}
.card_pricing_content_body{
  margin-left: 24px;
  margin-right: 24px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 60%;
  margin: 0;
  padding: 0;
}
.card_pricin_button_epayco{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100px;
}
.card_pricin_button_epayco1{
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 191px;
  height: 28px;
  background: none;
  border: 1px solid #D6D6D6;
  border-radius: 5px;
}
.card_pricin_button_epayco2{
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 191px;
  height: 28px;
  background: #F07130;
  border-radius: 5px;
}
.card_pricin_button_epayco3{
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 191px;
  height: 28px;
  background: #202256;
  border-radius: 5px;
}
.card_pricin_button_epayco1 button{
  background: none;
  border: none;
}
.card_pricing_body_inner{
  margin-left: 24px;
  margin-top: 20px;
}
.card_pricing_body_inner span{
  width: 100%;
}
.generic_price_table .generic_content{
  width: 297px;
  height: 600px;
  background-color: #fff;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}
.generic_price_table .generic_content .generic_head_price{
  background-color: #f6f6f6;
}

.generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg{
  border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
}

.generic_price_table .generic_content .generic_head_price .generic_head_content .head span{
  color: #525252;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign{
    color: #414141;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency{
    color: #393939;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent{
    color: #414141;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .month{
    color: #414141;
}

.generic_price_table .generic_content .generic_feature_list ul li{  
  color: #a7a7a7;
}

.generic_price_table .generic_content .generic_feature_list ul li span{
  color: #414141;
}


.generic_price_table .generic_content .generic_price_btn a{
  border: 1px solid #202256; 
    color: #202256;
} 

.generic_price_table .generic_content.active .generic_head_price .generic_head_content .head_bg,
.generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg{
  border-color: #202256 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #202256;
  color: #fff;
}

.generic_feature_container_list{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

}

.generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head span,
.generic_price_table .generic_content.active .generic_head_price .generic_head_content .head span{
  color: #fff;
}

.generic_price_table .generic_content:hover .generic_price_btn a,
.generic_price_table .generic_content.active .generic_price_btn a{
  background-color: #202256;
  color: #fff;
} 
.generic_price_table{
  margin: 50px 0 50px 0;
    font-family: 'Raleway', sans-serif;
}

.row-pricing{
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  flex-direction: row;
}

/*PRICE BODY CODE START*/

.generic_price_table .generic_content{
  
  text-align: center;
}

.generic_price_table .generic_content .generic_head_price {
  margin: 0 0 20px 0;
}

.generic_price_table .generic_content .generic_head_price .generic_head_content{
  margin: 0 0 50px 0;
}

.generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg{
    border-style: solid;
    border-width: 90px 1411px 23px 399px;
  position: absolute;
}

.generic_price_table .generic_content .generic_head_price .generic_head_content .head{
  padding-top: 40px;
  position: relative;
  z-index: 1;
}

.generic_price_table .generic_content .generic_head_price .generic_head_content .head span{
    font-family: "Raleway",sans-serif;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 2px;
    margin: 0;
    margin-top: 31px;
    padding: 0;
    text-transform: uppercase;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag{
  padding: 0 0 20px;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .price{
  display: block;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign{
    display: inline-block;
    font-family: "Lato",sans-serif;
    font-size: 24px;
    margin-top: 31px;
    font-weight: 400;
    vertical-align: middle;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency{
  
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    margin-top: 31px;
    font-weight: 700;
    letter-spacing: -2px;
    line-height: 36px;
    padding: 0;
    vertical-align: middle;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent{
    display: inline-block;
    font-family: "Lato",sans-serif;
    font-size: 24px;
    font-weight: 400;
    vertical-align: bottom;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .month{
    font-family: "Lato",sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 3px;
    vertical-align: bottom;
}

.generic_price_table .generic_content .generic_feature_list ul{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
}

.generic_price_table .generic_content .generic_feature_list ul li{
  font-family: "Lato",sans-serif;
  margin-left: 5%;
  margin-right: 5%;
  text-align: start;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 18px;
  padding: 15px 0;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table .generic_content .generic_feature_list ul li:hover{
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;

}
.generic_price_table .generic_content .generic_feature_list ul li .fa{
  padding: 0 10px;
}
.generic_price_table .generic_content .generic_price_btn{
  margin: 20px 0 32px;
}

.generic_price_table .generic_content .generic_price_btn a{
    border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-border-radius: 50px;
    display: inline-block;
    font-family: "Lato",sans-serif;
    font-size: 18px;
    outline: medium none;
    padding: 12px 30px;
    text-decoration: none;
    text-transform: uppercase;
}

.generic_price_table .generic_content,
.generic_price_table .generic_content:hover,
.generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg,
.generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg,
.generic_price_table .generic_content .generic_head_price .generic_head_content .head h2,
.generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head h2,
.generic_price_table .generic_content .price,
.generic_price_table .generic_content:hover .price,
.generic_price_table .generic_content .generic_price_btn a,
.generic_price_table .generic_content:hover .generic_price_btn a{
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
} 
@media (max-width: 320px) { 
}

@media (max-width: 767px) {
  .generic_price_table .generic_content{
    margin-bottom:75px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .generic_price_table .col-md-3{
    float:left;
    width:50%;
  }
  
  .generic_price_table .col-md-4{
    float:left;
    width:50%;
  }
  
  .generic_price_table .generic_content{
    margin-bottom:75px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
}
@media (min-width: 1200px) {
}
.generic_price_table_home{
   font-family: 'Raleway', sans-serif;
}

.text-center h1,
.text-center h1 a{
  color: .7885CB;
  font-size: 30px;
  font-weight: 300;
  text-decoration: none;
}
.demo-pic{
  margin: 0 auto;
}
.demo-pic:hover{
  opacity: 0.7;
}

.generic_price_table_home ul{
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: table;
}
.generic_price_table_home li{
  float: left;
}
.generic_price_table_home li + li{
  margin-left: 10px;
  padding-bottom: 10px;
}
.generic_price_table_home li a{
  display: block;
  width: 50px;
  height: 50px;
  font-size: 0px;
}
.generic_price_table_home .blue{
  background: #3498DB;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table_home .emerald{
  background: #202256;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table_home .grey{
  background: #7F8C8D;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table_home .midnight{
  background: #34495E;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table_home .orange{
  background: #E67E22;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table_home .purple{
  background: #9B59B6;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table_home .red{
  background: #E74C3C;
  transition:all 0.3s ease-in-out 0s;
}
.generic_price_table_home .turquoise{
  background: #1ABC9C;
  transition: all 0.3s ease-in-out 0s;
}

.generic_price_table_home .blue:hover,
.generic_price_table_home .emerald:hover,
.generic_price_table_home .grey:hover,
.generic_price_table_home .midnight:hover,
.generic_price_table_home .orange:hover,
.generic_price_table_home .purple:hover,
.generic_price_table_home .red:hover,
.generic_price_table_home .turquoise:hover{
  border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table_home .divider{
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
}
.generic_price_table_home .divider span{
  width: 100%;
  display: table;
  height: 2px;
  background: #ddd;
  margin: 50px auto;
  line-height: 2px;
}
.generic_price_table_home .itemname{
  text-align: center;
  font-size: 50px ;
  padding: 50px 0 20px ;
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
  text-decoration: none;
    font-weight: 300;
}
.generic_price_table_home .itemnametext{
    text-align: center;
    font-size: 20px;
    padding-top: 5px;
    text-transform: uppercase;
    display: inline-block;
}
.generic_price_table_home .footer{
  padding:40px 0;
}

.price-heading{
    text-align: center;
}
.price-heading h1{
  color: .666;
  margin: 0;
  padding: 0 0 50px 0;
}
.demo-button {
    background-color: #333333;
    color: #ffffff;
    display: table;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 50px;
    outline-color: -moz-use-text-color;
    outline-style: none;
    outline-width: medium ;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
}
.bottom_btn{
  background-color: #333333;
    color: #ffffff;
    display: table;
    font-size: 28px;
    margin: 60px auto 20px;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
}
.demo-button:hover{
  background-color: .666;
  color: #FFF;
  text-decoration:none;
  
}
.bottom_btn:hover{
  background-color: #666;
  color: #FFF;
  text-decoration:none;
}

@media (max-width: 375px) {
  .row-pricing{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -30px;
    flex-direction: row;
  }
  .card_container_pricing{
    margin-bottom: 25px;
  }
  .card_container_pricing2{
    margin-bottom: 25px;
  }
}
.contenedor-pricing-empresa{
  display: flex;
    align-items: center;   
}
.pricing-details-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 90%;
}
.pricing-details-body-card{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  height: 204px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 7px 32px 0px #0000001A;
  padding-bottom: 20px;
}
.pricing-details-card-inner-1{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}
.pricing-details-card-inner-1-1{
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
.pricing-details-card-inner-1-2{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
  margin: 20px;
}
.pricing-details-card-inner-2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  height: 100%;
}
.pricing-details-body-card-aditional{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  height: 100%;
  margin-top: 2%;
  padding-bottom: 5%;
}
.aditional-services-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  height: 90%;
  margin-top: 3%;
}
.aditional-services-dropdows-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.aditional-services-dropdows-container-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
}
.pricing-footer-body-total-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.container-popup-pago{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    flex-direction: column;
}
.container-buttons-popup{
  display: flex;
  width: 70%;
  justify-content: space-between;
}
.label-popup-pago{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 184px;
    font-size: 20px;    
    color: #fff; 
    background: #F07130;
    border-radius: 5px;
    cursor: pointer;  
}

.button-cerrar-popup{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 184px;
  height: 28px;
  font-size: 20px; 
  background: #202256;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}

@media (max-width: 1100px) {
  .pricing-details-body{
    width: 100%;
    height: 100%;
  }
  .aditional-services-container{
    margin-top: 5%;
  }
  .pricing-details-body-card{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .pricing-details-card-inner-1{
    flex-direction: column;
  }
  .pricing-details-card-inner-1-1{
    width: 100%;
    margin-top: 5%;
  }
  .pricing-details-card-inner-1-2{
    width: 100%;
  }
  .pricing-details-card-inner-2{
    width: 100%;
    margin-left: 10px;
  }
}
.top-part{
    width: calc(100vw - 237px);
}

.container-prof {
    width: 95%;
    margin: 4rem auto; 
}

.info-section {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.main-section-info {
    margin-left: 3%;
     width: 90%; 
     display: flex;
}
.first-container {
    width: 74vw;
}
.card-container-info {
    background-color: white;
    width: 40%;
    border-radius: 5px;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    padding: 44px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.about .extracto-info {
    font-family: "Poppins";
    color: #5D5D5D;
    font-weight: 300;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    word-wrap: break-word;
}

.container-test-result-profile{
    padding-inline: 100px;
    margin-top: 4%;
}

.subcontainer-test-result-profile{
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    flex-wrap: wrap;
}

@media(max-width: 1300px) {
 .container-prof {
    max-width: revert !important;   
 }
 .top-part{
    width:100% !important;
    margin-right: auto !important;
 }
}

@media (max-width:725px) {
    .container-prof {
     display: contents !important; ;
     justify-content: center !important;
    }
    .top-part {
        display: contents !important;
        justify-content: center !important;
    }
    /*.title-top {*/
    /*    margin-top: 3em !important;*/
    /*    margin-bottom: 2em !important;   */
    /*    margin-left: 1em !important;  */
    /*}*/
    .first-container{
       width: 100vw;
       display: contents !important;     
    }
    .info-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .info-section .info-section-avatar {
        margin-bottom: 1em !important;
    }
    .info-section .about {
        width: 360px;
       /*  margin-right: 60em; */
        margin-top: 3em;
        display: contents !important; ;
     justify-content: center !important;
    }
    .info-section .card-container-info {
        width: 90%;
        margin-top: 2em;
    }
    .info-section .stars-rating {
        margin-right: 5em !important;
    }
    .about .extracto-info {
        width: 320px !important;
    }
    .main-section-info {
        margin-left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }   
    .subcontainer-test-result-profile{    
        flex-direction: column;
    }

}
.Perfil-container-cards-coally{
    border-radius: 20px;
    width: 324px;
    min-height: 320px;
    background-color: #ffffff;
    font-size: .9em;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1em;
    justify-content: space-evenly;

}


.editar-foto-perfil{
    border:1px solid white;
    width:30px;
    height: 30px;
    background-color: #ec7332;
    position:absolute;
    margin-top:-70px;
    margin-left:60px;
    border-radius: 50%;
    display:flex;
    align-items: center;
    justify-content: center;
}


.editar-foto-perfil button{
    background-color: transparent;
}

.avatar-element-perfil{
    position:absolute;
}

.Avatar-camara-perfil{

}

.nidiea button{
    background-color:#ec7332 ;
}


.Perfil-container-cards-coally span{
width: 8.7em !important;
}

.porcentaje-complete{
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top:4em;

}

.porcentaje-editar-container{
    font-size: .9em;

    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.porcentaje-text-coally{
    color:#21224e;
    font-size: 14px;
}
.porcentaje-p{
    margin-right:.5em;
    color:#898989;
}



.link-edit-image img{
    width:20px;
    height:20px;
    margin-top: -.2em;
    margin-left: 30px;

}

.link-edit-image :hover{
    cursor:pointer;
    color:#898989;
}
.link-edit-image{
    color:#898989;
    height: 33px;
    padding: 13px 5px;
    display:flex;
    align-items:center;
    justify-content: space-between;

}

.herre{
    color: #fbfbfb;
    width:80%;

}

.icon-cards{
    width:20px;
    height:20px;
    margin-top: -.2em;
    margin-right: 1em;
}

.contact-data{

    color:#898989;
    font-size: .9em;
}



/* Card Oportunidad e idiomas */

.innerCards{
    width:75%;
}
.oportunidad-cantidad{
  margin-top: 3em;
  /* border:1px solid black; */
  width: 80%;
}

.title-op{
    font-weight: bolder;

}

.text-contador{
    font-size:2em;
    color:#ec7332;
    font-weight: bolder;
}

.redes-cont{
    background-color: #fbfbfb;
    width: 85%;
    height:3em;
    display:flex;
    align-items:center;
    justify-content: flex-start;
    margin-bottom: 1em;
    border-radius: 10em;

}

.redes-cont div{

    width:46px;
    height: 49px;
    border-radius: 18px;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-right: .5em;
}

.redes-cont p{
    margin-top: 1.5em;
}

.TitleOp{

    width:94%;
    margin-top:-30px;
    margin-bottom: 10px;
    margin-left: 28.41px;
    padding:1em;
}


#face{
    background-color:#4166d8 ;
}

#drib{
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

#ln{
    background-color: #0073af;
}

#ytb{
    background-color: #ef0806;
}

/* responsive */


@media screen and (max-width: 500px){

    .porcentaje-editar-container{

        width: 80%;
    }

}

.grid-avances_container{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 80%;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(204, 204, 204, 0.267), 
        -2px 2px 15px rgba(204, 204, 204, 0.267), 2px 2px 15px rgba(204, 204, 204, 0.267);
        margin-top: 30px;
}

.grid-avances{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr 1fr;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    grid-gap: 1rem;
    gap: 1rem;
    padding:1rem;
}
.columna-uno span{
    margin: 10px 0px;
    font-size:12px;
    display: block;
}
.button-entregas{
    background: #212156 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 6px #00000029;
    border-radius: 8px;
    padding: 5px;
    font-size:12px;
    color: #fff;
    text-transform: uppercase;
}

.button-entregas:hover{
    color: rgb(255, 178, 35);
    background-color: transparent;
    border-style: solid;
    border-color: rgb(255, 178, 35);
    border-width: 2px;
    font-weight: bold;
}

.columna-uno{
    flex-direction: column;
    display: flex;
    justify-content: center;
    width: 20%;
    margin-top: 40px;
    margin-left: 40px;
    justify-content: flex-start;
}

.entrega-item2:hover{
    background-color: #28157e2d;
}

.columna-dos{
    margin-top: 40px;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    width: 70%;
}

.container_dos_columnas{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.btn_avances_filter{
    background-color: #f5f5dc00;
    margin: 1rem;
    font-weight: bold;
}
.rating_avance{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    
}

.text_entrega_avance_col_uno{
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
}

.entregas-container{
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding:.7rem;
    max-height: 500px;
    overflow: scroll;
}

.entregas-container::-webkit-scrollbar {
    display: none;
}
.avances_clasificacion{
    margin: 0;
    display: flex;
    justify-content: space-around;
    width: 80%;
}
.fondo_avance_popup{
    width: 100%;
    height: 100%;    
}
.perfil__score-form-label2:hover,
.perfil__score-form-label2:hover ~ label {
  color: orange;
}
.perfil__score-form-label2 {
    font-size: 35px;
  }
  input[type="radio"]:checked ~ .perfil__score-form-label2 {
    color: orange;
  }
.entrega-item{
    padding: .5rem 0rem 0.5rem 0rem;
    /* font-size: 14px; */
    border-bottom: 1px solid;
    position: relative;
    border-color: lightgrey;
    display: flex;    
    border: 0.188rem solid #28157e00;
}
.entrega-item2{
    padding: .5rem 0rem 0.5rem 0rem;
    /* font-size: 14px; */
    border-bottom: 1px solid;
    border-color: lightgrey;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    display: flex;
}

.entregas-comentarios{
    max-height: 400px;
    overflow:scroll;
    text-align: start;
    text-transform:capitalize ;
    width: 35%;
    padding-bottom: 20px;
}

.comentarios-item{
    padding: .5rem;
    font-size: 13px;
}

.entregas-container::-webkit-scrollbar {
    -webkit-appearance: none;
}

.entregas-container::-webkit-scrollbar:vertical {
    width:10px;
}

.entregas-container::-webkit-scrollbar-button:increment,.entregas-container::-webkit-scrollbar-button {
    display: none;
} 

.entregas-container::-webkit-scrollbar:horizontal {
    height: 10px;
}

.entregas-container::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.entregas-container::-webkit-scrollbar-track {
    border-radius: 10px;  
}


.entregas-comentarios::-webkit-scrollbar {
    -webkit-appearance: none;
}

.entregas-comentarios::-webkit-scrollbar:vertical {
    width:10px;
}

.entregas-comentarios::-webkit-scrollbar-button:increment,.entregas-comentarios::-webkit-scrollbar-button {
    display: none;
} 

.entregas-comentarios::-webkit-scrollbar:horizontal {
    height: 10px;
}

.entregas-comentarios::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.entregas-comentarios::-webkit-scrollbar-track {
    border-radius: 10px;  
}



@media screen and (max-width:630px){
    .grid-avances{
        width: 100%;
        grid-template-columns: 1fr;
    }

  }

  .comentarios_avance{
      display: flex;
      flex-direction: column;
      justify-content: center;
     
  }

  .comentarios_avance button {
    justify-content: center;
    margin-left: 48%;
    margin-top: 15px;
}

.columna-tres{
    text-align: -webkit-left;
    display: flex;
    flex-direction: column;
}

.columna-tres h3 {
    margin-top: 10px;
    left:0;
    font-weight: 600;
}
/* ////////////////////////////////////////////////////////////////////////////////////////// */
.descarga_avances{
    width: 100%;
    top: 0;
    height: 99%;
    z-index: 1;
    background-color: white;
    position: absolute;
}
.descarga_avances:hover{
    box-shadow: 0px 0px 12px 1px rgb(0 0 0 / 20%);
}
.container_avances{
    width: 100%;
}
.card_avances{
    min-width: 65%;
    display: flex;
    max-width: 80%;
    text-align: start;
    margin: 0;
    font-weight: bold;
    padding: 1rem;
    align-items: center;
}
.btn_aceptar{
    border: none;
    background: white;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    color: #ff5925;
    padding: 0px 10px 0px 10px;
    margin-right: 5px;
}
.btn_aceptar:hover{
    border: 2px solid #ff5925;
}
.btn_rechazar{
    border: none;
    color: #212156;
    background: white;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
}
.btn_rechazar:hover{
    border: 2px solid #212156;
}

.imagen_avance{
    background-image: url("https://coally.com/wp-content/uploads/2021/10/entregaicon-1.png");
    width: 60px;
    height: 100%;
    background-size: cover;
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
}
.imagen_avance_logo{
    width: 65%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 4rem;
}
.imagen_avance_logo2{
    background-image: url("https://coally-images.s3.amazonaws.com/logo+blanco+png+1+(3).png");
    width: 60%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 11rem;
}
.container_img_avance{    
    min-width: 10%;    
    max-width: 10%;   
    padding: 1rem;
    height: 6rem;
}
.botones_avance{
    min-width: 40%;
    max-width: 40%;  
    display: flex  ;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.MuiOutlinedInput-notchedOutline{
    border-color: rgba(252, 252, 252, 0) !important;
}
.metho-details{
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.metho-details-entregas{
    background-color: #f1f4f6;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.metho-details-container-anchor:hover{
    color: blue;
    outline:1px  blue;
}
.metho-details-container-anchor{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.metho-details-container-cmy{
    text-align: -webkit-center;
    padding-bottom: 40px;
}
.metho-details-container-cmy h3{
    font-size: 1rem;
    padding: 1rem 0px;
}
.metho-details-btn{
    margin-top: 3rem;
}

.answer-comment-task-company{
    background-color: #707070;
    border-radius: 8px !important;
    border: none;
    font-size: 13px;
    padding: .5rem;
    cursor: pointer;
    box-sizing: border-box;
    color: rgb(226, 225, 225);
    text-decoration: none;
    text-transform: uppercase;
}
.entrega_tittle{
    font-weight: bold;
    text-transform: uppercase;
}

.metho-details-company-answer{
    display: flex;
    background-color: #ffffff;
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    margin: 0 auto;
    box-shadow: 0px 2px 10px #00000029;
    padding: 1rem 1rem 1rem 0rem;
    box-sizing: content-box;
    margin-bottom: 1rem;
    height: 20vh;
}

.metho-details-tarea{
    display: flex;
    background-color: #ffffff;
    width: 79%;
    grid-gap: 1rem;
    gap: 1rem;
    margin: 0 auto;
    box-shadow: 0px 2px 10px #00000029;
    padding: 1rem 1rem 1rem 0rem;
    box-sizing: content-box;
    margin-bottom: 1rem;
    height: 20vh;
}

.metho-details-coment-span{
    text-align: left;
}

.comment-quotes-coallier{
    text-transform: capitalize;
    color: #5c5c5c;
    text-align: left !important;
    font-size: 14.5px;
    font-weight: 600;
}

.input-text-answer{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 6px;
    opacity: 1;
    text-align: center;
    color: #a7a3a3;
    width: 80%;
    outline-style: none;
    box-sizing: border-box;
    font-size: 13px;
}

.project-name-task-module{
    margin-top: 1em;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 43%;
    font-size: 15px;
}

.etiqueta{
    text-transform: capitalize;
    background-color: #21204B !important;
    color: #ffffff !important;
    padding: 0.5em 1.5em 0.5em 0.5em !important;
    font-size: 12px !important;
    font-weight: 500;
    padding-left: 100px !important;
    padding-right: 100px !important;
    justify-content: left !important;
}

@media screen and (max-width:800px){
    .metho-details-tarea{
        overflow: hidden;
    }
    .perfil_body-content{
        width: 96%;
        text-align: center;
    }
}
.barra_mensaje_dislike{
    background-image: linear-gradient(to right, #f7d1fc, #ff5281, #fe0007, #fe0007, #fe0007);
    
    height: 1rem;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
}
.barra_mensaje_like{
    background-image: linear-gradient(to right, #ffffff, #00ff88, #07fe00, #07fe00, #07fe00);
    height: 1rem;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
}
.mensaje_popup{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #00000073;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 10000;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
}
.container_popup_mensaje{
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation: popupmensaje 0.25s alternate  ease-in;
            animation: popupmensaje 0.25s alternate  ease-in;
}
.container_popup_mensaje4{
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation: popupmensaje 0.25s alternate  ease-in;
            animation: popupmensaje 0.25s alternate  ease-in;
}
.animacion_popup{
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation: popupmensaje 0.25s alternate  ease-in;
            animation: popupmensaje 0.25s alternate  ease-in;
}
.container_popup_mensaje2{
    -webkit-transform: scale(1);
            transform: scale(1);
    /* animation: popupmensaje 0.25s alternate  ease-in; */
}
@-webkit-keyframes popupmensaje {
    0%   {-webkit-transform: scale(0);transform: scale(0);}
    100% {-webkit-transform: scale(1);transform: scale(1);}
  }
@keyframes popupmensaje {
    0%   {-webkit-transform: scale(0);transform: scale(0);}
    100% {-webkit-transform: scale(1);transform: scale(1);}
  }
@-webkit-keyframes popupmensajeend {
    0%   {-webkit-transform: scale(1);transform: scale(1);}
    100% {-webkit-transform: scale(0);transform: scale(0);}
  }
@keyframes popupmensajeend {
    0%   {-webkit-transform: scale(1);transform: scale(1);}
    100% {-webkit-transform: scale(0);transform: scale(0);}
  }
.derecha_mensaje_popup{
    width: 100%;
    padding: 5%;
    display: flex;
    background-color: #212156;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.container_popup_mensaje{
    width: 40%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    position: absolute;
    flex-direction: column;
    border-radius: 15px;
}
.container_popup_mensaje4{
    width: 70%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    position: absolute;
    flex-direction: column;
    border-radius: 15px;
}
.container_popup_mensaje3{
    width: 50%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    position: absolute;
    flex-direction: column;
    border-radius: 15px;
    background: #212155;
}
.container_popup_mensaje2{
    width: 70%;
    display: flex;
    align-items: center;
    padding: 1rem;
    position: absolute;
    flex-direction: column;
    border-radius: 15px;
    background: #212155;
}
.container_input_p_mensaje{
    flex-direction: column;
    display: flex;
    margin-top: 5%;
    width: 60%;
}
.cancel_mensaje{   
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    padding: 1.5rem 2rem 0rem 0rem;
    cursor: pointer;
    font-weight: bold;
    color: black;
}
.cancel_mensaje2{
    position: absolute;
    padding: 0.5rem 1rem 0rem 0rem;
    cursor: pointer;
    bottom: 0;
    font-weight: bold;
}
.input_mensaje_popup:focus{
    outline: none;
}
.MuiSvgIcon-root_likes{
    fill: currentColor !important;
    width: 1em !important;
    height: 1em !important;
    display: inline-block !important;
    border: solid !important;
    padding: 4%;
    -webkit-transform: rotateY( 
180deg
 );
            transform: rotateY( 
180deg
 );
    font-size: 4rem !important;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    flex-shrink: 0 !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
        user-select: none !important;
    border-radius: 100% !important;
}
.MuiSvgIcon-root_likes2{
    fill: currentColor !important;
    width: 1em !important;
    height: 1em !important;
    display: inline-block !important;
    border: solid !important;
    padding: 4%;
    font-size: 4rem !important;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    flex-shrink: 0 !important;
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
    border-radius: 100% !important;
}
.barra_check_popup{
    height: 3px;
    background: green;
    width: 100%;
    margin-right: 5px;
    border-radius: 100%;
}
.barra_descheck_popup{
    height: 3px;
    background: red;
    width: 100%;
    margin-right: 5px;
    border-radius: 100%;
}
.btn_mensaje_dislike{
    position: absolute;
    bottom: -7%;
    background-image: linear-gradient(to bottom, #707070, #3b3b3b, #000000, #000000, #000000);
    color: red;
    border-radius: 5px;
    padding: 1% 10% 1% 10%;
}
.btn_mensaje_like{
    position: absolute;
    bottom: -7%;
    background-image: linear-gradient(to bottom, #707070, #3b3b3b, #000000, #000000, #000000);
    color: green;
    border-radius: 5px;
    padding: 1% 10% 1% 10%;
}
.btn_mensaje{
    color: white;
    background: #F07130;
    margin-top: 10px;    
    padding: 0.3rem 1rem;
    position: absolute;
    bottom: 5%;
    border-radius: 5px;
}
.btn_mensaje3{
    color: white;
    width: 80%;
    background: #212156;
    margin-top: 10px;    
    padding: 4px;
    border-radius: 5px;
}
.btn_mensaje4{
    color: white;
    width: 100%;
    background: #10123c;
    padding: 0.5rem 0.5rem 0.5rem 1rem !important;
    margin-top: 10px;   
    padding: 4px;
    border-radius: 5px;
}
.btn_mensaje4:hover{
    color:#FF925A ;
}
.btn_mensaje5{
    color: white;
    width: 100%;
    background: #212156;
    margin-top: 10px; 
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0.5% 2%;
    border: solid 1px white;   
    padding: 4px;
    border-radius: 5px;
}
.btn_mensaje5:hover{
    color:#212156 ;
    background-color: white;
    border: solid 1px #212156  ;
}
.btn_mensaje2{
    color: #3c4472;
    width: 25%;
    background: white;
    margin-top: 10px;    
    padding: 4px;
    border-radius: 5px;
}
.btn_mensaje_cargando{
    color: #000000;
    width: 80%;
    background: #a5a2a2;
    margin-top: 10px;
    padding: 4px;
    cursor: default !important;
    position: absolute;
    bottom: 5%;
    border-radius: 5px;
}
.btn_mensaje_cargando2{
    color: #000000;
    width: 25%;
    background: #a5a2a2;
    margin-top: 10px;
    padding: 4px;
    cursor: default !important;
    border-radius: 5px;
}
.container_input_p_mensaje2{
    display: flex;
    margin: 0.25rem;
    width: 80%;
}
/* .pay-card-resume-cost{
    width: 55%;
    display: inline-block;
    position: relative;
    margin-left: 6%;
    top: -18em;
    padding: 2em;
    background-color: white;
    border-radius: 7px;
    padding-bottom: 27em;
    box-shadow: 0px 3px 6px #00000029;
}

.pay-card-comp-gen{
    width: 30%;
    position:relative;
    display: inline-block;
  align-items: center;
  justify-content: center;
    margin-left: 1.5em;
    top: 3.5em;
    font-size: 13px;
    padding-left: 15px;
    background-color: #707070;
    border-radius: 5px;
    padding: 4em 1em 0em 1em;
}

.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
  } 

  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

.pay-card-comp{
    top: 5em;
 border-radius: 10px;
  background: url(https://static.vecteezy.com/system/resources/previews/001/946/031/original/bank-card-prototype-with-abstract-wave-background-abstract-bank-abstract-payment-system-the-best-illustration-of-the-credit-cards-on-the-internet-vector.jpg) no-repeat center center; 
  background-size: cover;
  box-sizing: content-box;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 250px;
  width: 424px;
  text-align: center;
  margin-bottom: 3em;
}

.button-pay-comp-co{
    background: #7406B8;
box-shadow: 0px 3px 6px #F5CC001A;
border-radius: 6px;
color: white;
padding-top: 1em;
text-transform: uppercase;
text-align: center;
padding-top: 1.5em;
padding-bottom: 1.5em;
padding-left: 3em;
padding-right: 3em;
margin-top: 5em;
margin-bottom: 5em;
font-weight: 600;
font-size: 16px;

}

.tittle-pay-resume{
    text-align: center;
    font-size: 20px !important;
    margin-bottom: 4em;
    font-weight: 600;
}

.pay-content-resume{
    font-size: 17px;
    margin-left: 4em;
    margin-right: 4em;
    margin-bottom: 0.5em;
}

.pay-cond-par{
    margin-top: 2em;
    font-size: 13px;
    margin-left: 4em;
    margin-right: 4em;
    text-align: center;
    color: #7B7777;
}

.pay-card-comp-info{
  text-align: center;
}

.input-payment-frm::-webkit-input-placeholder { color: white; line-height: 1.3em; text-transform: uppercase; }

.input-payment-frm{
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  border-style: none;
  background-color: transparent;
  color: white;
  padding-top: 1.5em;
  width: 400px;
  padding-bottom: 1.5em;
  border-bottom: solid 1px white;
} */

/*NUEVO - VERIFICAR*/
/* 
.PaymentRoot{
  margin-top: 3em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.AppWrapper {
  background: #3a3b42;
  width: 600px;
  height: 400px;
  position: relative;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

.Form {
  animation: fade 200ms ease-out;
  margin-top: 3em;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #3a3b42;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #3a3b42;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid white;
}

.FormRow:first-child {
  border-top: none;
}

.FormRowLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}
.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #3a3b42;
  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
}

.FormRowInput {
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 0;
  border-left: 1px solid;
  padding-left: 5px;
  color: #fff;
  background-color: transparent;
  animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
  color: white;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  border: 1px solid white;
}

.SubmitButton {
  display: block;
  font-size: 16px;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: #121f53;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #121f53;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
}

.SubmitButton:active {
  background-color: #121f53;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #121f53;
  transform: scale(0.99);
}

.SubmitButton.SubmitButton--error {
  transform: translateY(15px);
}
.SubmitButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.SubmitButton:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: white;
  box-shadow: none;
}

.ErrorMessage {
  color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  transform: translateY(-15px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.ErrorMessage svg {
  margin-right: 10px;
}

.Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.ResultMessage {
  color: white;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}

.ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent;
}
 */
#paymentWidgetContainerCard {
    max-width: 820px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border-color: #23224c;
}

#paymentSelectForm {
    display: flex;
    flex-direction: column;
    text-align: center;
}

#paymentArea {
    padding-top:0px; 
    width:100%;
}

.paymennequi{
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}
.paymennequi2{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}
.caja2paymen{
    background: #fff;
    width: 50%;
    border: solid 1px #23224c;
    padding: 20px;
    position: relative;
    border-radius: .25rem;
}
.butonesnequi{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.caja2paymen2{
    background: #fff;
    width: 50%;
    border: solid 1px #23224c;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: .25rem;
}
.inputnequi{
    width: 100%;
    padding: .375rem .75rem;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    background-color: #fff;
}
.pnequi{
    margin: 1rem 0px 0px 0px;
}
.inputnequi:focus{
    outline:#23224c;
    border:solid 3px #5c5aeb80;
}
.btnnequi{
    background: #23224c;
    color: white;
    padding: 10px 20px 10px 20px;
    border-radius: .25rem;
    margin-top: 1rem;
}
/* /////////////////// */
/* /////////////////// */
/* /////////////////// */
@-webkit-keyframes ldio-ft58je84is8 {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
@keyframes ldio-ft58je84is8 {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  .ldio-ft58je84is8 div {
    left: 145.825px;
    top: 84.425px;
    position: absolute;
    -webkit-animation: ldio-ft58je84is8 linear 0.8928571428571428s infinite;
            animation: ldio-ft58je84is8 linear 0.8928571428571428s infinite;
    background: #f47128;
    width: 15.35px;
    height: 27.63px;
    border-radius: 7.675px / 9.3942px;
    -webkit-transform-origin: 7.675px 69.075px;
            transform-origin: 7.675px 69.075px;
  }.ldio-ft58je84is8 div:nth-child(1) {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-animation-delay: -0.8370535714285714s;
            animation-delay: -0.8370535714285714s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(2) {
    -webkit-transform: rotate(22.5deg);
            transform: rotate(22.5deg);
    -webkit-animation-delay: -0.7812499999999999s;
            animation-delay: -0.7812499999999999s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(3) {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-animation-delay: -0.7254464285714285s;
            animation-delay: -0.7254464285714285s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(4) {
    -webkit-transform: rotate(67.5deg);
            transform: rotate(67.5deg);
    -webkit-animation-delay: -0.6696428571428571s;
            animation-delay: -0.6696428571428571s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(5) {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-animation-delay: -0.6138392857142857s;
            animation-delay: -0.6138392857142857s;
    background: #21204b;
  }.ldio-ft58je84is8 div:nth-child(6) {
    -webkit-transform: rotate(112.5deg);
            transform: rotate(112.5deg);
    -webkit-animation-delay: -0.5580357142857142s;
            animation-delay: -0.5580357142857142s;
    background: #21204b;
  }.ldio-ft58je84is8 div:nth-child(7) {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    -webkit-animation-delay: -0.5022321428571428s;
            animation-delay: -0.5022321428571428s;
    background: #21204b;
  }.ldio-ft58je84is8 div:nth-child(8) {
    -webkit-transform: rotate(157.5deg);
            transform: rotate(157.5deg);
    -webkit-animation-delay: -0.4464285714285714s;
            animation-delay: -0.4464285714285714s;
    background: #21204b;
  }.ldio-ft58je84is8 div:nth-child(9) {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-animation-delay: -0.39062499999999994s;
            animation-delay: -0.39062499999999994s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(10) {
    -webkit-transform: rotate(202.5deg);
            transform: rotate(202.5deg);
    -webkit-animation-delay: -0.33482142857142855s;
            animation-delay: -0.33482142857142855s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(11) {
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
    -webkit-animation-delay: -0.2790178571428571s;
            animation-delay: -0.2790178571428571s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(12) {
    -webkit-transform: rotate(247.5deg);
            transform: rotate(247.5deg);
    -webkit-animation-delay: -0.2232142857142857s;
            animation-delay: -0.2232142857142857s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(13) {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-animation-delay: -0.16741071428571427s;
            animation-delay: -0.16741071428571427s;
    background: #21204b;
  }.ldio-ft58je84is8 div:nth-child(14) {
    -webkit-transform: rotate(292.5deg);
            transform: rotate(292.5deg);
    -webkit-animation-delay: -0.11160714285714285s;
            animation-delay: -0.11160714285714285s;
    background: #21204b;
  }.ldio-ft58je84is8 div:nth-child(15) {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
    -webkit-animation-delay: -0.055803571428571425s;
            animation-delay: -0.055803571428571425s;
    background: #21204b;
  }.ldio-ft58je84is8 div:nth-child(16) {
    -webkit-transform: rotate(337.5deg);
            transform: rotate(337.5deg);
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    background: #21204b;
  }
  .loadingio-spinner-spinner-9bz1nhdfjp {
    width: 307px;
    height: 307px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-ft58je84is8 {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
  .ldio-ft58je84is8 div { box-sizing: content-box; }
/* /////////////////// */
/* /////////////////// */
/* /////////////////// */
  .C,
  .a,
  .r,
  .g{color:#ef732d ;font-size: 2rem;}
  .a2,
  .n,
  .d,
  .o,
  .punto1,
  .punto2,
  .punto3{color:#222046;font-size: 2rem;}
  .C {-webkit-animation: C  1s infinite;animation: C  1s infinite;opacity: 0;}
  .a {-webkit-animation: a  1s infinite;animation: a  1s infinite;-webkit-animation-delay: 0.07s;animation-delay: 0.07s;opacity: 0;}
  .r {-webkit-animation: r  1s infinite;animation: r  1s infinite;-webkit-animation-delay: 0.14s;animation-delay: 0.14s;opacity: 0;}
  .g {-webkit-animation: g  1s infinite;animation: g  1s infinite;-webkit-animation-delay: 0.21s;animation-delay: 0.21s;opacity: 0;}
  .a2{-webkit-animation: a2 1s infinite;animation: a2 1s infinite;-webkit-animation-delay: 0.38s;animation-delay: 0.38s;opacity: 0;}
  .n {-webkit-animation: n  1s infinite;animation: n  1s infinite;-webkit-animation-delay: 0.55s;animation-delay: 0.55s;opacity: 0;}
  .d {-webkit-animation: d  1s infinite;animation: d  1s infinite;-webkit-animation-delay: 0.62s;animation-delay: 0.62s;opacity: 0;}
  .o {-webkit-animation: o  1s infinite;animation: o  1s infinite;-webkit-animation-delay: 0.69s;animation-delay: 0.69s;opacity: 0;}
  .punto1{-webkit-animation: punto1 1s infinite;animation: punto1 1s infinite;-webkit-animation-delay: 0.76s;animation-delay: 0.76s;opacity: 0;}
  .punto2{-webkit-animation: punto2 1s infinite;animation: punto2 1s infinite;-webkit-animation-delay: 0.82s;animation-delay: 0.82s;opacity: 0;}
  .punto3{-webkit-animation: punto3 1s infinite;animation: punto3 1s infinite;-webkit-animation-delay: 0.90s;animation-delay: 0.90s;opacity: 0;}
  @-webkit-keyframes C {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes C {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @-webkit-keyframes a {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes a {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @-webkit-keyframes r {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes r {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @-webkit-keyframes g {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes g {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @-webkit-keyframes a2{0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes a2{0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @-webkit-keyframes n {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes n {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @-webkit-keyframes d {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes d {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @-webkit-keyframes o {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes o {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @-webkit-keyframes punto1 {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes punto1 {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @-webkit-keyframes punto2 {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes punto2 {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @-webkit-keyframes punto3 {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes punto3 {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
/* These styles are used if a demo specific stylesheet is not present */
/* 
*,
*:before,
*:after {
  box-sizing: border-box;
}

form {
  margin:auto;
  font-size: 18px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

label {
  color: #444;
  font-weight: 300;
  letter-spacing: 0.025em;
  margin-top: 16px;
  display: block;
}

button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

button[disabled] {
  opacity: 0.6;
}

input {
  display: block;
  border: none;
  font-size: 18px;
  margin: 10px 0 20px 0;
  max-width: 100%;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
  color: #424770;
  letter-spacing: 0.025em;
  width: 100%;
}

input::placeholder {
  color: #cccccc;
}

.result,
.error {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.error {
  color: #e4584c;
}

.result {
  color: #666ee8;
}
 */
/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal,
.StripeElementFpx {
  display: block;
  margin: 10px 0 20px 0;
  /* max-width: 500px; */
  width: 100%;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementFpx--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  transition: all 150ms ease;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementFpx {
  padding: 0;
}

.gUpMat {
    height: 60% !important;
    background-color: #463371 !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    color: rgb(255 255 255 / 87%) !important;
}

.table-proyects{
    margin: 0% 5% 5% 5%;
}

.g-users-section{
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: #540E87A6;
}

.g-users-tittle{
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
    color: white;
}

.img-support-user{
    height: 50px;
    width: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.MuiAppBar-colorPrimary {
    color: #fff;
    background-color: #202256 !important;
}

.header__display-menu_admin{
    display: none;
    margin-left: -120px;
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: 0px 2px 10px #00000029;
    padding: 5px;
    background-color: white;
    position: absolute;
    width: 220px;
    z-index: 100;
    min-width: 170px;
    font-size: 13px;
}
.perfil {
  width: 85%;
  margin: 0 auto;
  display: grid;
  padding: 2rem 0rem;
  grid-template-columns: 31.66% 1fr;
  grid-column-gap: 1.4em;
  grid-row-gap: 1em;
  background-color: white;
}
.projects-success-case {
  font-size: 15px !important;
  border-radius: 2px !important;
  padding: 0.5em 1.2em 0.5em 1.2em !important;
  border-left: 0.2em solid #afafaf;
  border-right: 0.2em solid #afafaf;
  margin: 0 !important;    
  width: 100%;
  height: 100%;
  text-align: center;
}
.perfil-header {
  grid-column-start: 1;
  grid-column-end: 3;
  height: 17.8em;
  background-color: white;
  box-shadow: 0px 2px 10px 0px #7070701A;
}

.perfil__header-container {
  height: 9.4em;
  width: 100%;
  background-color: white;
}
.perfil__header__container-img {
  position: relative;
}

.perfil__header-profile {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  background-color: white;
}

.perfil__header__profile-txt {
  background-color: none;
}
.perfil__header-profile-img {
  border: 1px solid;
  border-radius: 50%;
  width: 112px;
  margin-bottom: 10px;
}

.perfil-info {
  grid-column-start: 1;
  grid-column-end: 3;
  height: 2.3em;
  padding: 1rem;
  text-align: center;
  background: transparent;
  background-color: transparent;
  box-shadow: none;
}

.perfil__info-p {
  padding: 5px;
  background-color: white;
}

.perfil-data {
  grid-row-start: 3;
  padding: 1rem;
  grid-row-end: 5;
  background-color: white;
  box-shadow: 0px 2px 10px 0px #7070701A;
}

.perfil__data-card {
  background-color: white;
  padding: 20px 0px;
  margin-bottom: 5px;
  box-shadow: 0px 2px 10px 0px #7070701A;}

.perfil__data-tittle {
  background-color: white;
  margin-bottom: 10px;
  margin-left: 1.8em;
}

.perfil__data-text {
  background-color: white;
  margin-left: 1.8em;
}

.perfil-comments {
  background-color: white;
  grid-row-start: 5;
  grid-column: 2;
  padding: 1rem;
  padding-left: 29px;
  box-shadow: 0px 2px 10px 0px #7070701A;
}

.perfil-comments-tittle {
  margin-bottom: 13px;
  margin-top: 25px;
}

.perfil-comments-text {
  margin-bottom: 23px;
}

.perfil__comments-list {
  display: block;
}

.perfil-score {
  grid-column: 1;
  height: 14.8em;
  background-color: white;
  margin: .5rem;
  text-align: center;
  padding: 1rem;
  box-shadow: 0px 2px 10px 0px #7070701A;
}

.perfil__score-tittle {
  font-size: larger;
  text-align: center;
}

.perfil__score-form {
  width: 250px;
  margin: 0 auto;
  height: 50px;
}
.perfil__score-form p {
  text-align: center;
}
.perfil__score-form-label {
  font-size: 20px;
}
.perfil__score-form-input {
  display: none;
}
.perfil__score-form-label {
  color: grey;
}
.clasificacion {
  direction: rtl;
  unicode-bidi: bidi-override;
}
.perfil__score-form-label:hover,
.perfil__score-form-label:hover ~ label {
  color: orange;
}
input[type="radio"]:checked ~ .perfil__score-form-label {
  color: orange;
}

.perfil-skills {
  grid-column: 1;
  min-height: 4rem;
  height: auto;
  max-height: 6rem;
  background-color: white;
  grid-row-start: 4;
  padding: 2em;
  margin: .5rem;
  box-shadow: 0px 2px 10px 0px #7070701A;
}
.perfil__skills-tittle {
  font-size: 16px;
  margin-bottom: 1em;
}
.perfil-update{
  grid-column: 1;
  text-align: center;
  background-color: white;
  grid-row-start: 5;
  height: 12.5em;
  padding: 2em;
  padding: 1rem;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px 0px #7070701A;
}
.perfil__update-title{
  margin-bottom: 22px;
}
.perfil__update-line{
  background-color: var(--main-yellow-findally);
  height: 14px;
  width: 227px;
}

.perfil__update-button{
  margin-top: 50px;
}

.container-casos-exito{
  background-size: cover;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.div-contenedor-botones{
display: flex;
  width: 100%;   
  height: 2.5em;
  justify-content: space-around;
}
.container-casos{
width: 80%;
min-width: 300px;
max-width: 700px;
margin-top: 50px !important;
margin-bottom: 50px !important;
margin: 0 auto;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
background-color: #EAEAEA;
min-height: 400px;
border-radius: 2%;
box-sizing: border-box;
overflow: auto;
}
.Dropzone {
background-color: #EAEAEA !important;
}
.Upload-caso{
background-color: #EAEAEA !important;
}
.tittle-casos-exito{
margin-top: 1em;
font-size: 22px;
font-weight: 800;
}

.subtittle-casos-exito{
color: #808080;
margin-top: 1em;
font-size: 15px;
font-weight: 400;
}


@media screen and (max-width:630px){
  .perfil{
      width: 100%;
      grid-template-columns: 1fr;
  }
  .perfil_body-content{
      width: 96%;
      text-align: center;
  }
}
*{
    margin: 0px;
    
}
.card-2contenedor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-radius: 10%;
    padding: 2rem;
    width: 90%;
}

.card-2cartas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sectioncards-card1-empresa {
    width: 100%;
    display: flex;
    grid-row-gap: 2rem;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    justify-content: space-evenly;
    display: flex;
}

.sectioncards-card2-empresa {
    display: grid;
    place-content: center;
    place-items: center;
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    width: 100%;
}

.sectioncards-card3-empresa {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    width: 100%;
    background-color: #e6e7e8;
}

.section-two-empresa {
    width: 100%;
    margin-top: 1rem;
    padding-right: 5rem;
    padding-bottom: 1rem;
}

.cards2 {
    width: 100%;
    height: 500px;
}

.section-two-empresa .card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-content: center;
    align-items: center;
    height: 95px;
    border-radius: 8px;
    transition: 400ms;
}

.card-body-2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 150%;
}

.card-body-content {
    margin-left: 2rem;
    width: 100%;
}

.card-body-content-title {
    font-size: 1.25rem;
    width: 100%;
    font-weight: 700;
    font-family: Poppins;
    color: #000;
    transition: 400ms;
}

.card-body-content-text {
    margin-bottom: 1rem;
    color: #5D5D5D;
    font-family: Poppins;
    font-weight: 300;
    transition: 400ms;
}

.card-body-content-text2 {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 1rem;
    color: #24D054;
    font-family: Poppins;
    font-weight: 300;
    transition: 400ms;
}

/*  hovers */
.section-two-empresa .card:hover {
    background-color: #202256;
    transition: 400ms;
}

.section-two-empresa .card:hover .card-body-content .card-body-content-title {
    color: white;
    transition: 400ms;
}

.section-two-empresa .card:hover .card-body-content .card-body-content-text {
    color: white;
    transition: 400ms;
}

.section-three-empresa {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

}

.section-three-empresa .cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.section-three-empresa .card {
    width: 20rem;
    padding: 1rem;
    border-radius: 10px;
    margin-right: 2rem;
    height: 100%;
}

.section-three-empresa .card .card-header {
    height: 5rem;
    margin-bottom: 1rem;
    background-color: #f3f3f3;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.section-three-empresa .card .card-body a {
    color: #c4c4c4;
}

.section-three-empresa .card .card-footer {
    margin-top: 1rem;
}

.section-three-empresa .card .card-footer button {
    font-size: 1rem;
    padding: 0.3rem 0.6rem;
    font-weight: 550;
    box-shadow: none;
    border: none;
}

.section-three-empresa .card2 {
    display: flex;
    background-color: #e6e7e8;
    border-radius: 10px;
    padding: 3rem 5rem;
}

.section-three-empresa .card2 .card-item-left {
    margin-right: 1rem;
}

.section-three-empresa .card2 .card-item-rigth {
    margin-left: 1rem;
}

.fuenteestadoproyecto {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 2.7vw;
    color: #393939;
}

.card-v2-op {
    display: flex;
    flex-direction: row;
    height: 89px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    margin-top: 31px;
    justify-content: space-between;
    align-items: center;
    padding-right: 31px;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}

.card-v2-title {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    font-family: 'Poppins'
}

.card-v2-subtitle {
    font-size: 14px;
    font-weight: 300;
    color: #5D5D5D;
    font-family: 'Poppins'
}

.card-v2-resposive-applicant{
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 70px;
}

.card-v2-resposive-image{
    width: 214px;
    height: 144px;
    border-radius: 8px 0 0 8px;
    object-fit: cover;
}

.card-v2-subtitle-green {
    margin-left: 10px;
    padding-top: 15px;
    font-size: 14px;
    font-weight: 300;
    color: #24D054;
    font-family: 'Poppins'
}
.card-v2-detalle {
    font-size: 14px;
    font-weight: 400;
    color: #C5C5C5;
    font-family: 'Poppins'
}

.card-v2-op:hover {
    background-color: #202256;
    transition: 400ms;
    margin-left: 25px;
    color: white;
}

.card-v2-op:hover .card-v2-title {
    color: white;
    transition: 400ms;
}

.card-v2-op:hover .card-v2-subtitle {
    color: white;
    transition: 400ms;
}

.card-v2-espera:hover .card-v2-detalle {
    color: white;
    transition: 400ms;
}
.card-v2-op-div{
    flex-direction: row;
    display: flex;
}
.card-v2-op-img{    
    width: 124px;
    height: 89px;
    object-fit: cover;
    border-radius: 8px 0px 0px 8px;
}
.input-card-v2-op{    
    color: black;
    padding-left: 2.5rem;
    background-color: transparent;
    width: 55vw;
    height: 35px;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(197, 197, 197);
    margin-bottom: 10px;
}
@media (max-width:625px){

    .card-v2-resposive-image{
        width: 114px;
        height: 144px;
        border-radius: 8px 0 0 8px;
        object-fit: cover;
    }

    .card-v2-resposive-applicant{
        display: none;
    }
    .card-v2-resposive-desc {
        display: none;
    }
    .card-v2-op{
        flex-direction: column;
        height: -webkit-max-content;
        height: max-content;
        padding-right:0
    }
    .card-v2-op-div{
        flex-direction: column !important;
        height: -webkit-max-content;
        height: max-content;
        width: 100%;
    }
    .card-v2-op-img{
        width: 100%;
    }
    .fuenteestadoproyecto {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 2.7vw;
        color: #393939;
    }
    .font-top{
        font-size: 20px;
    }
}
.line-spinner {
  width: 64px;
  height: 64px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-right: 4px solid #F07130;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.contenedor-notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}



  .Container-Card-square{
    background: #FFFFFF;
    width: 324px;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column; 
    height: 315px;
    margin: 22px;
  }
  
  .Top-Container-Card{
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 15px;
   margin-top: 25px;
  }

  .CardOportunidad-Image{
    object-fit: cover;
    height: 55px;
    width: 60px;
    border-radius: 8px;
  }

  .CardOportunidadCompany-Button-new {
    height: 30px;
    width:auto;   
    background: transparent;
    border: 0.5px solid #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
  }
  .CardOportunidadProfesional-Button-new:hover {
    background: #202256;
    color: #FFFFFF;
  }

  .CardOportunidad-mid{
    width: 100%;
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    align-items: center;
    margin-bottom: 38px;
    margin-top: 15px;
    margin-left: 25px;
  }
  .CardOportunidad-mid-inner-1{
    width: 100%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  .CardOportunidad-mid-inner-2{
    width: 100%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 15px;
    word-wrap: break-word;
    padding-right: 40px;
    font-size: 14px;
    color: #5D5D5D;
    text-align: left;
    font-weight: 400;
    height: 50px;
  }

  .CardOportunidad-mid-inner-3{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  .CardOportunidad-mid-inner-3-1{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .CardOportunidad-mid-inner-3-2{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
  } 

  .CardOportunidad-Container-rectangular{
    background: #FFFFFF;
    width: 100%;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 175px;
    margin-bottom: 22px;
    margin-top: 22px;
  }
  .CardOportunidad-Top-Container-rectangle{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .CardOportunidad-Top-Container-rectangle-inner-1{
    margin-left: 34px;
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .CardOportunidad-Top-Container-rectangle-inner-1-1{
    width: 100%;
    display: flex;
    height: 55px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  
  .CardOportunidad-Top-subContainer-1{
    width: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .CardOportunidad-Top-subContainer-2{
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .CardOportunidad-Top-Container-rectangle-inner-1-2{
    width: 100%;
    display: flex;
    height: calc(100% - 55px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .CardOportunidad-Top-subContainer-3{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .CardOportunidad-Top-subContainer-4{
    width: 100%;
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;
    margin-top: 8px;
    word-wrap: break-word;
    font-size: 14px;
    color: #5D5D5D;
    text-align: left;
    font-weight: 400;
    height: 50px;
  }
  
  .CardOportunidad-Top-Container-rectangle-inner-2{
    margin-right: 34px;
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
  
  .CardOportunidad-Top-Container-rectangle-inner-2-1{
    margin-top: 20px;
  }
  .CardOportunidad-Top-Container-rectangle-inner-2-2{
    margin-bottom: 25px;
  }
  

  

  
  .CardOportunidad-mid-Container-rectangle{
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 18px;
   margin-top: 15px;
  }
  

  
  @media (max-width: 725px) {
    .CardOportunidad-Container-square{
      margin: auto;
    }
  }
.new-empresa-home-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
  }
  
  .new-empresa-home-banner{
    width: 100%;
    height: 158px;
    /* background-image: url(https://coally.com/wp-content/uploads/2022/03/banners-landing-1-2.png); */
    background-image: url(/static/media/BannerHome.8f9af2e2.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    display: flex; 
    align-items: center;
    justify-content: space-between;
  }
  .new-empresa-home-banner-text{
    margin-left: 41px;
  }
  .new-empresa-home-banner-button{
    margin-right: 6rem;
    margin-top:4rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 180px;
    height: 30px;
    background: #202256;    
    border-radius: 5px;
  }
  .new-empresa-home-body{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
   
  }
  .new-empresa-home-recommendation{
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 64%;
    height: 100%;
  }
  .new-empresa-home-test-title{
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    width: 30%;
    height: 30px;
  }
  .new-empresa-home-body-tests{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(330px, 2fr));
      grid-gap: 10px;
      margin-top: 10px;
  }
  .paginateStyle {
    display: flex;
    font-size: 1em;
    font-family: 'Poppins';
    color: #B0B0B0
  }
  
  .nextB {
    color: #202256
  }
  
  .previB {
    color: #202256
  }
  
  .paginateStyle li {
    padding: 8px;
    margin: 1em;
  }
  
  .pageAct {
    border-bottom: 1px solid #202256;
    color: #202256
  }

  .container-grid-list{
    display:flex;
    justify-content: space-between;
  }
  .Company-Home-Contenedor-3-inner-2{
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 30px;
    border-bottom: 1px solid #D6D6D6;
    cursor: pointer;
}

.container-cards-grid{
  display:flex;
  flex-wrap: wrap;
  justify-content: center;

}

.Company-Home-Contenedor-3-inner-2-1{
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #C5C5C5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}
.Company-Home-Contenedor-3-inner-2-1-active{
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: #202256;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.Company-Home-Contenedor-3-inner-2-2{
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #C5C5C5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 18px;
  margin-bottom: 15px;
  cursor: pointer;
}

.Company-Home-Contenedor-3-inner-2-2-active{
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: #202256;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 18px;
  margin-bottom: 15px;
  cursor: pointer;
}

  .container-formacion-oportunidades-new {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 2%;
    width: 100%;
  }
  .new-home-empresa-statistics-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 300px;
    height: 100%;
  }
  .new-home-company-save-op-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 40px;
    padding: 10px;
    margin-bottom: 50px;
  }
  .new-home-empresa-statistics-title{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 50%;
    margin-top: 5%;
    margin-right: 10px;
  }
  .new-home-empresa-statistics-body-title{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    height: 50%;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .new-home-empresa-statistics-arrows{
    max-width: 20px;
  }
  .home-published-opportunities{
     width:70%;
  }
  .new-home-empresa-statistics-arrows{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
  }
  .new-home-empresa-statistics-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .new-home-empresa-videos-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 3fr));
    grid-gap: 10px;
    width: 100%;
  }
  .navigate-arrow-statistics{
    color: #202256;
    cursor: pointer;
  }
  .navigate-arrow-statistics:hover{
    color: #F07130;
  }

  .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar {
    color: #F07130 !important;   
    background: none !important;   
}

.new-home-company-container-tour{
  align-items: flex-start;
  background-color: #FFFFFF;
  border-radius: 20px;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  padding: 30px;
  position: relative;
  overflow: hidden;
  width: 360px;
}

  @media (max-width: 768px) {
    .container-formacion-oportunidades-new {
        flex-direction: column;
        align-items: center
    }
    .new-empresa-home-recommendation{
      width: 100%;
    }
    .new-home-published-opportunities{
      width: 100%;
      margin-right: 0px;
    }
    .new-home-empresa-statistics-container{
      width: 100%;
    }
    .new-empresa-home-banner{
      flex-direction:column;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .new-empresa-home-banner-button{
      margin-right: 0px;
      margin-top: 0px;
    }
    .new-home-company-container-tour{
      width: 100%;
      margin-bottom: 20px;
    }
  }
.container-cuenta button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:transparent;   
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    width:100%;
    height: 56px;
}
.container-cuenta button span{
    margin-left: 30px
}
.container-cuenta button:hover{
    background-color: #202256;
    color:white;
}
/* .container-cuenta button:focus{
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
} */
.container-dropdown{
    margin-left: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}
.box-item li{
    margin-left: 60px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
}
.box-item button{
    color: #000000;
}

.box-item hr{
    border: 1px solid #D6D6D6 ;
    margin-left: 3% ;  
    margin-top: 0px;  
    
}

 .division-hr{
    border: 1px solid #393939;
    margin: 0px;
    
}


@media (max-width:725px){
    .config-headertop{
        padding: 0rem 2rem !important;
        width: 100% !important;
        position:revert !important;
    }
    .container-configuracion{
        margin-top: 10% !important;
        max-width: 100% !important;
        margin:auto ;
        position: relative !important;
        padding: 0px !important;
        width: 90% !important;

    }
  }
.soporte{
  padding-top: 50px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 5em;
}

.soporte-contactUs {
    background-color: #FFFFFF;  
    padding: 2%;    
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width:449px;
    height: 338px;  
    border-radius: 8px;
  }
  
.soporte-contactUs p {
    margin-top: 2em;
    margin-bottom: 10%;
    text-align: center; 
    color: #5D5D5D;
  }
.soporte-contactUs li {
  padding:1em;
  }
.soporte-contactUs span{
    margin-left:10px;    
  }

.container-form{
    width:442px;
    height: 456px;
    background-color: #FFFFFF;
  }

.config-form{
    display: flex;
    flex-direction: column;
    justify-content: center;       
    padding: 1.5em;
    overflow: auto;
    width: 100%;
    border-radius: 8px;
  }

  .title-form-contact{
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #5D5D5D;
  }

  .select-contact-us{
    border: 0.5px solid #202256;
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    height: 33px;
    width: 184px;  
    color:#202256; 
  }

.text-contact_us{ 
    border: 0.1px solid #a5a2a26e;
    padding: 3%;
    font-size: 12.5px;
    margin-bottom: 2em;
    resize: none;
    margin-top: 2em;
    height:174px;
}

.button-contact_us{
    background-color: #202256;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;   
  }


@media (max-width:725px) {
    .soporte{
        flex-direction: column;
        align-items: center;
    }
    .soporte-contactUs{
        margin-bottom: 2em;
        width:auto !important;
    }
    .container-form{
        width: 100%;
    }
}
.body-card-oportunidad {
    background-color: white;
    width: 100%;
    height: 150px;
    border-radius: 8px;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    margin: 8px;
    display: flex;

}

.img-card-oportunidad {
    border-radius: 8px 0 0 8px;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.titulo-card-oportunidad {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}

.descripcion-card-oportunidad {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #5D5D5D;
}

.boton-vermas-card-oportunidad {
    background-color: transparent;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    color: #C5C5C5;
}

.boton-vermas-card-oportunidad:hover {
    color: #151515;
}

.boton-editar-card-oportunidad:hover {
    cursor: pointer;
}

@media (max-width:625px){
    .descripcion-card-oportunidad{
        display: none;
    }
    .applicant-company-card-home{
        display: none;
    }
}

.new-empresa-home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
}

.new-empresa-home-banner {
    width: 100%;
    height: 158px;
    /* background-image: url(https://coally.com/wp-content/uploads/2022/03/banners-landing-1-2.png); */
    background-image: url(/static/media/BannerHome.8f9af2e2.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-empresa-home-banner2 {
    width: 100%;
    height: 158px;
    background-image: url(/static/media/openai.6edf75ae.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-empresa-home-banner-text {
    margin-left: 41px;
}

.new-empresa-home-banner-button {
    margin-right: 6rem;
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 135px;
    height: 28px;
    background: transparent;
    border: 1px solid #FFF;
    border-radius: 5px;
}

.new-empresa-home-banner-button2 {
    margin-right: 6rem;
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 135px;
    height: 28px;
    background: #F07130;
    border-radius: 5px;
}

.new-empresa-home-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;

}

.new-empresa-home-recommendation {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 64%;
    height: 100%;
}

.new-empresa-home-test-title {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    width: 30%;
    height: 30px;
}

.new-empresa-home-body-tests {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 2fr));
    grid-gap: 10px;
    margin-top: 10px;
}

.paginateStyle {
    display: flex;
    font-size: 1em;
    font-family: 'Poppins';
    color: #B0B0B0
}

.nextB {
    color: #202256
}

.previB {
    color: #202256
}

.paginateStyle li {
    padding: 8px;
    margin: 1em;
}

.pageAct {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #202256 !important;
    width: 40px !important;
    border-radius: 100% !important;
    color: #FFF !important;
}

.pageB {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    width: 40px;
    border-radius: 100%;
    color: #202256;
}

.contenedor-formacion-oportunidades-new {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 2%;
    width: 100%;
}

.new-home-empresa-statistics-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 300px;
    height: 100%;
}

.new-home-company-save-op-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 40px;
    padding: 10px;
    margin-bottom: 50px;
}

.new-home-empresa-statistics-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 50%;
    margin-top: 5%;
    margin-right: 10px;
}

.new-home-empresa-statistics-body-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    height: 50%;
    margin-left: 10px;
    margin-bottom: 10px;
}

.new-home-empresa-statistics-arrows {
    max-width: 20px;
}

.new-home-published-opportunities {
    width: 60%;
    margin-right: 33px;
}

.new-home-empresa-statistics-arrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
}

.new-home-empresa-statistics-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.new-home-empresa-videos-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 3fr));
    grid-gap: 10px;
    width: 100%;
}

.navigate-arrow-statistics {
    color: #202256;
    cursor: pointer;
}

.navigate-arrow-statistics:hover {
    color: #F07130;
}

@media (max-width: 1100px) {
    .contenedor-formacion-oportunidades-new {
        flex-direction: column-reverse;
        align-items: center
    }

    .new-empresa-home-recommendation {
        width: 100%;
    }

    .new-home-published-opportunities {
        width: 100%;
        margin-right: 0px;
    }

    .new-home-empresa-statistics-container {
        width: 100%;
    }

    .new-empresa-home-banner {
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .new-empresa-home-banner2 {
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .new-empresa-home-banner-button {
        margin-right: 0px;
        margin-top: 0px;
    }
    .new-empresa-home-banner-button2 {
        margin-right: 0px;
        margin-top: 0px;
    }
}

@media(max-width: 1550px){
    .new-empresa-home-banner2{
        border-radius: 8px;
    }
}
@media (max-width: 950px) {
    .pageAct {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        background-color: #202256 !important;
        width: 40px !important;
        height: 40px !important;
        border-radius: 50% !important;
        color: #FFF !important;
    }

    .pageB {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFF;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #202256;
    }

    .nextB {
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .previB {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.Company-Test-Card-container {
    display: flex;
    background-color: #FFFFFF;
    border-radius: 16px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 330px;
    max-width: 460px;
    margin-top: 22px;
    margin-right: 18px;
    padding: 17px 20px 8px 16px;    
}

.Company-Test-Card-container:hover {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25);
    border: 1px solid #202256;
    cursor: pointer;
}

.Company-Test-Card-Title {
    font-weight: 700;
    font-size: 14px;
    color: #393939;
}

.Company-Test-Card-P {
    font-weight: 300;
    font-size: 14px;
    color: #5D5D5D;
    margin-top: 10px;
}

.Company-Test-Card-sub-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
}
.Company-Test-Card-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    width: 90px;
    /*border-radius: 10px;*/
    /*background-color: #F2F2F2;*/
}
.Company-Test-Popup-button{
    background-color: transparent;
    height: 28px;
    width: 141px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    font-weight: 400;
    color: #202256;
}
.Company-Test-Popup-button:hover{
    background-color: #202256;
    color: #FFFFFF;
}
.sub-search-responsive {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sub-video-responsive {
    display: flex;
    flex-direction: row;
}

.video-container {
    position: relative;
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    margin-right: 47px;
}

.grid-view-responsive {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(5, 1fr);
}

.grid-view-responsive-3 {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
}
.tpaga-formacion-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-right: 10px;
    text-align: center;
}

.grid-view-responsive-2 {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-Items: center;
    justify-Content: center;
}

.input-responsive-box {
    color: black;
    padding-left: 10%;
    background-color: transparent;
    width: 350px;
    height: 33px;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: #C5C5C5;
}

.tab-title-responsive {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}

.tab-title-responsive-2 {
    font-size: 16px;
    color: #393939;
    margin-top: -20px;
    margin-right: 70px;
}

.iframe-responsive-education {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.iframe-video-container {
    position: relative;
    margin-bottom: 30px;
    border-radius: 8px;
    overflow: hidden;
    width: 50%;
    margin-right: 47px;
}

@media (max-width: 768px) {
    .tab-title-responsive-2 {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .tab-title-responsive {
        display: flex;
        flex-direction: column;
    }

    .sub-search-responsive {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .input-responsive-box {
        width: 100%;
    }

    .grid-view-responsive-2 {
        display: none;
    }

    .sub-video-responsive {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .iframe-video-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    /*.video-container {*/
    /*    display: flex;*/
    /*    width: 200px;*/
    /*    height: 150px;*/
    /*    flex-direction: row;*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*    margin-bottom: 50px;*/
    /*    margin-left: auto;*/
    /*}*/
    .grid-view-responsive {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }
}
.home-card-body {
    box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.1);
}

/*botón primario*/
.primary-button-card {
    background-color: #202256;
    border-radius: 5px;
    width: 140px;
    height: 28px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins';
}


.primary-button-card:hover {
    font-weight: 700;
    color: white;
}

.primary-button-card:active {
    font-weight: 700;
    color: white;
    background-color: #0D0033;
}

/*botón terciario*/
.tertiary-button-card {
    background-color: transparent;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    color: #C5C5C5;
    width: 80px;
    height: 20px;
    margin-right: 170px;
}

.tertiary-button-card:hover {
    color: #151515;
}

.primary-button-card:active {
    color: #151515;
}

.box-tertiary-button-card {
    background-color: transparent;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    color: #C5C5C5;
    width: 80px;
    height: 20px;
}

.box-tertiary-button-card:hover {
    color: #151515;
}

.box-primary-button-card {
    background-color: #202256;
    border-radius: 5px;
    width: 87px;
    height: 28px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins';
}

.box-primary-button-card:hover {
    font-weight: 700;
    color: white;
}

.box-primary-button-card:active {
    font-weight: 700;
    color: white;
    background-color: #0D0033;
}
.line-spinner {
  width: 64px;
  height: 64px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-right: 4px solid #F07130;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.Profesional-Home-Contenedor-Global {
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
}

.Profesional-Home-container-inner{
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
}
.Profesional-Home-Contenedor-1-new{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}
.Profesional-Home-Contenedor-2-new{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}
.Profesional-Home-Contenedor-3-new{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 32px;
}

.Profesional-Home-Contenedor-3-inner-1{
  width: 70%;
  display: flex;
    flex-direction: row;
  margin-left: 30px;
}

.Profesional-Home-Contenedor-3-inner-2{
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 30px;
    border-bottom: 1px solid #D6D6D6;
    cursor: pointer;
}
.Profesional-Home-Contenedor-3-inner-2-1{
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #C5C5C5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}
.Profesional-Home-Contenedor-3-inner-2-1-active{
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #202256;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}
.Profesional-Home-Contenedor-3-inner-2-2{
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #C5C5C5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 18px;
    margin-bottom: 15px;
    cursor: pointer;
}
.Profesional-Home-Contenedor-3-inner-2-2-active{
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #202256;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 18px;
    margin-bottom: 15px;
    cursor: pointer;
}

.Profesional-Home-Contenedor-4-new{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 32px;
    width: 100%;
}

.Profesional-Home-Contenedor-4-new-grid{
    /* auto grid columns */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
    place-content: center;
    grid-gap: 30px;
    margin-top: 32px;
    width: 100%;
}

.new-home-profesiona-bottom-container{
    display: flex;
    flex-direction: row;
    grid-gap: 40px;
    gap: 40px;
    justify-content: center;
    align-items: center;
    margin: 32px 0;
    width: 100%;
}
.new-home-profesional-bottom-container-carousel{
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

}
.new-home-profesional-bottom-container-carousel-title{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.new-home-profesional-bottom-container-carousel-container{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 32px;
}
.new-home-profesional-container-tour{
    align-items: flex-start;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    padding: 30px;
    position: relative;
    overflow: hidden;
    width: 400px;
}

.tour-button{
    border: solid #FFFFFF 2px;
    border-radius: 5px;
    background-color: #202256;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    height: 32px;
    width: 175px;
    z-index: 2;
}
.tour-circle{
    background-color: #202256;
    border-radius: 50%;
    bottom: -60px;
    height: 130px;
    right: -10px;
    position: absolute;
    width: 130px;
}

.Profesional-Home-Contenedor-Videos {
    width: 100%;
    place-content: center;
    align-content: center;
    align-items: center;
    justify-content: start;
}


.Profesional-Home-Title {
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 15px;
    color: #202256;
}

.Profesional-Home-SubTitle {
    /*font-style: normal;*/
    font-weight: 700;
    font-size: 16px;
    color: #5D5D5D;
}

.Profesional-Home-Video {
    /*object-fit: contain;*/
    /*height: 213px;*/
    width: 70%;
    margin-right: 20px;
    cursor: pointer;
}

.Profesional-Home-Video-Secundario {
    display: flex;
    justify-content: center;
    align-items: center;
    /*height: 213px;*/
    width: 70%;
    border-radius: 10px;
}

.Home-Profesional-Resultados {
    font-weight: 300;
    font-size: 16px;
    color: #C5C5C5;
}

.Profesional-Home-Sub-Sub-Contenedor-Videos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-content: space-evenly;
    justify-content: space-evenly;

}

.play-button {
    background: #3b3b3b;
}

.button-Profesional-Home {
    background: #F9F9F9;
    border: 0.5px solid #202256;
    border-radius: 5px;
    width: 303px;
    height: 28px;
    margin-right: 1em;
    color: #202256;
    font-size: 14px;
}

.button-Profesional-Home:hover {
    background: #202256;
    color: #FFFFFF;
}

.Profesional-Home-SubTitle-2 {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.Profesional-Home-Search {
    background-color: transparent;
    height: 35px;
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: #C5C5C5;
    text-indent: 25px;
    margin-left: 13px;
}
.Profesional-Home-Search::-webkit-input-placeholder {
    color: #202256;
    margin-left: 13px;
}
.Profesional-Home-Search:-ms-input-placeholder {
    color: #202256;
    margin-left: 13px;
}
.Profesional-Home-Search::placeholder {
    color: #202256;
    margin-left: 13px;
}

.Profesional-Home-Search:focus {
    border-color: #202256;
    outline: none;
}

/* .profesional-Home-Search::-ms-clear {
    color: #202256 !important;
    display: none;
} */


.Profesional-Home-Search-Container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.Profesional-Home-Search-Selector {
    background-color: transparent;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    height: 35px;
    border-color: #C5C5C5;
    width: 100%;
    margin-bottom: 10px;
    text-indent: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.lupa {
    font-size: 50px;
    color: #202256;
    position: absolute;
    margin-top: 5px;
    margin-left: 5px;
}

.Profesional-Home-Search-Selector:focus {
    border-color: #202256 !important;
    color: #202256 !important;
    outline: none;
}

.cant-filtro {
    position: absolute;
    margin-left: -30px;
    margin-top: 10px;
    background-color: rgb(32, 34, 86);
    width: 17px;
    height: 17px;
    color: white;
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    border-radius: 100%;
}


.Home-Profesional-Test-Container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
    place-content: center;
    grid-gap: 30px;
    margin-top: 32px;
    width: 100%;
}

/* @media (max-width: 1025px) {
    .Profesional-Home-Search-Container {
        flex-direction: column;
        align-items: center;
    }

    .Profesional-Home-Search {
        width: 100%;
        text-indent: 5px;
    }

    .Profesional-Home-Search-Selector {
        width: 100%;
        margin-top: 10px;
        text-indent: 5px;
    }

    .lupa {
        color: transparent;
    }
    .cant-filtro{
        display: none;
    }

    .Profesional-Home-Contenedor-Global {
        padding-left: 0;
        padding-right: 0;
    }
} */
.contenedor-fix-1 {
    margin-left: calc(237px + 98px);
    margin-right: 20px;
}

.home-profesional-ai-button{
    border: none;
    border-radius: 8px;
    background-color: #33307f;
    color: white;
    font-size: 16px;
    width: 200px;
    height: 40px;
    margin-left: 30px;
    transition: 0.5s;
}

.home-profesional-ai-button:hover{
    background-color: #ee7919;
    transition: 0.5s;
}

@media (max-width: 1420px) {
    .new-home-profesiona-bottom-container{
        flex-direction: column;
    }
    .new-home-profesional-bottom-container-carousel{
        width: 100%;
    }
}
@media (max-width: 1300px) {
    .contenedor-fix-1 {
        margin-left: 20px;
    }
    .new-home-profesiona-bottom-container{
        flex-direction: row;
    }
}
@media (max-width: 1200px) {
    .new-home-profesiona-bottom-container{
        flex-direction: column;
    }
    .new-home-profesional-bottom-container-carousel{
        width: 100%;
    }
    .new-home-profesional-bottom-container-tour{
        width: 80%;
        margin-left: 0;
    }
}

@media (max-width: 425px) {
    .Profesional-Home-Contenedor-3-inner-1{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .Profesional-Home-Search-Container {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 10px;
    }

    .Profesional-Home-Search {
        background-color: transparent;
        height: 35px;
        width: 100%;
        border-radius: 5px;
        border-style: solid;
        border-width: 2px;
        border-color: #C5C5C5;
        text-indent: 25px;
        margin-bottom: 20px;
    }

    .Profesional-Home-Search-Selector {
        background-color: transparent;
        border-radius: 5px;
        border-style: solid;
        border-width: 2px;
        height: 35px;
        border-color: #C5C5C5;
        width: 100%;
        margin-bottom: 10px;
        text-indent: 25px;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .subcontenedor-filtros {
        width: 100% !important;
    }

    .new-home-profesional-container-tour{
        width: 90%;
    }

}
@media(max-width: 740px){
    .new-home-profesional-bottom-container-carousel-container{
        margin-bottom: 32px;
        margin-right: 0;
    }
}

@media (max-width: 930px) {
    .Profesional-Home-Contenedor-Global {
        padding-left: 0;
        padding-right: 0;
    }

    .Profesional-Home-Contenedor-Videos {
        display: revert !important;
    }

    .Profesional-Home-Contenedor-Videos img {
        width: 298px !important;
    }

    .Profesional-Home-Sub-Sub-Contenedor-Videos {
        flex-direction: column;
        align-items: center;
    }

    .Profesional-Home-Video-Secundario {
        justify-content: normal;
    }
    .Profesional-Home-Contenedor-3-new{
        flex-direction: column;
        justify-content: center;
    }
    .Profesional-Home-Contenedor-3-inner-1{
        width: 100%;
        margin: auto;
    }
    .Profesional-Home-Contenedor-3-inner-2{
        width: 100%;
        margin: auto;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .Profesional-Home-Video {
        margin-bottom: 18px;
        width: auto !important;
    }

    .css-0 iframe {
        width: 330px !important;
        height: 330px;
        margin-left: 2em;
    }

    .popupcalifacionsubpop {
        display: revert !important;
    }

    .progres-cv, .progres-cv button {
        display: revert !important;
        margin: 0 auto !important;
    }

    .button-Profesional-Home {
        margin-bottom: 1em !important;
    }

    .css-14jnub0 {
        width: 360px !important;
    }

    .cant-filtro {
        display: none;
    }

    .container-opp {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .new-home-empresa-videos-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


.NewCardOportunidad-Container-rectangular{
  background: #FFFFFF;
  width: 100%;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 175px;
  margin-bottom: 22px;
}
.NewCardOportunidad-Container-square1{
  background: #FFFFFF;
  width: 350px;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  /*height: 350px;*/
  margin: 22px;
}


.NewCardOportunidad-Top-Container{
 width: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 margin-bottom: 15px;
 margin-top: 25px;
}
.NewCardOportunidad-Top-Container-rectangle{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.NewCardOportunidad-Top-Container-rectangle-inner-1{
  margin-left: 34px;
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.NewCardOportunidad-Top-Container-rectangle-inner-1-1{
  width: 100%;
  display: flex;
  height: 55px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  margin-top: 20px;
}

.NewCardOportunidad-Top-subContainer-1{

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.NewCardOportunidad-Top-subContainer-2{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 60px;
}

.NewCardOportunidad-Top-Container-rectangle-inner-1-2{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.NewCardOportunidad-Top-subContainer-3{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.NewCardOportunidad-Top-subContainer-4{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  margin-top: 8px;
  word-wrap: break-word;
  font-size: 14px;
  color: #5D5D5D;
  text-align: left;
  font-weight: 400;
  height: 50px;
}

.NewCardOportunidad-Top-Container-rectangle-inner-2{
  margin-right: 34px;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.NewCardOportunidad-Top-Container-rectangle-inner-2-1{
  margin-top: 20px;
}
.NewCardOportunidad-Top-Container-rectangle-inner-2-2{
  margin-bottom: 25px;
}

.NewCardOportunidad-mid-Container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 38px;
  margin-top: 15px;
  margin-left: 25px;
}
.NewCardOportunidad-mid-Container-inner-1{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
}
.NewCardOportunidad-mid-Container-inner-2{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
  word-wrap: break-word;
  padding-right: 40px;
  font-size: 14px;
  color: #5D5D5D;
  text-align: left;
  font-weight: 400;
  height: 50px;
}

.NewCardOportunidad-mid-Container-inner-3{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
}
.NewCardOportunidad-mid-Container-inner-3-1{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.NewCardOportunidad-mid-Container-inner-3-2{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
}

.CardOportunidadProfesional-Button-new {
  height: 30px;
  width: 120px;
  background: transparent;
  border: 0.5px solid #202256;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #202256;
}
.CardOportunidadProfesional-Button-new-orange {
  height: 30px;
  width: 120px;
  background: transparent;
  border: 0.5px solid #F07130;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #202256;
}
.CardOportunidadProfesional-Button-new-orange:hover {
  background: #F07130;
  color: #FFFFFF;
}

.text-card-status-Close{
  color:red;
  font-size: 14px;
  font-weight: 400;
}

.text-card-status-Open{
  color: #24D054;
  font-size: 14px;
  font-weight: 400;
}

.text-card-info2{
  font-size: 14px;
  font-weight: 400;
  color: #989898;
}

.text-card-info3{
  font-size: 14px;
  font-weight: 400;
  color: #C5C5C5;
  margin:0px;
}

.stop-info2{
  margin-left: 5px;
  margin-right: 5px;
  color: #989898
}

.container-info-apli-fecha{
    display: flex;
    justify-content: space-evenly;
    width: 50%;
}

.NewCardOportunidad-mid-Container-rectangle{
 width: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 margin-bottom: 18px;
 margin-top: 15px;
}

.NewCardOportunidad-Image{
  object-fit: cover;
  height: 55px;
  width: 60px;
  border-radius: 8px;
}

.NewCardOportunidad-Image-rectangle{
  object-fit: cover;
  height: 175px;
  width: 206px;
  border-radius: 8px;
}

@media (max-width: 725px) {
  .NewCardOportunidad-Container-square1{
    margin: auto;
  }
}


.slider-home-container{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.slider-home{
    width: 90%;
    height: 100%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-home-row{
    width: 10%;
    margin-left: 26px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 26px;
}
.slider-home-arrow{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #C5C5C5;
}
.slider-home-arrow:hover{
    color: #202256;
}
.slide-home{
  opacity: 0;
  transition-duration: 1s ease;
}

.slide-home.active{
  opacity: 1;
  transition-duration: 1s;
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
}

.slide-home-inner{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.slide-home-inner-title{
  color: white;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 70px;
  margin-right: 70px;
  font-size: 2vw;
  font-family: Roboto;
  font-weight: 700;
}
.slide-home-inner-1{
  width: 70%;
  height: 132px;
  margin: 26px 26px 26px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: left;
}
.slide-home-inner-2{
  width: 30%;
  height: 132px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 941px) {
  .slider-home-row{
    display: none;
  }
}

@media (max-width: 740px) {
  .slider-home{
    margin: 0;
  }
.slide-home-inner-title{
  font-size: 20px;
}
  .slide-home-inner{
    padding: 0 10px;
  }
  .slide-home-inner{
    flex-direction: column;
  }
  .slide-home-inner-2{
    width: 100%;
  }
  .slide-home-inner-1{
    width: 100%;
    margin: 26px 0px 26px 0px; 
  }
}

.Profesional-Test-Card-container {
    display: flex;
    background-color: #FFFFFF;
    border-radius: 16px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 330px;
    margin-top: 22px;
    margin-right: 18px;
    padding: 17px 20px 8px 16px;
}

.Profesional-Test-Card-Sub-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    height: 78%;
    text-align: center;
}

.Profesional-Test-Card-Title {
    font-weight: 700;
    font-size: 14px;
    color: #393939;
}

.Profesional-Test-Card-P {
    font-weight: 300;
    font-size: 14px;
    color: #5D5D5D;
    margin-top: 10px;
}

.Profesional-Test-Card-sub-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
}
.Profesional-Test-Card-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    width: 90px;
    /*border-radius: 10px;*/
    /*background-color: #F2F2F2;*/
}

.Profesional-Test-Button {
    height: 30px;
    width: 120px;
    background: transparent;
    border: 0.5px solid #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
}


.body-profile-company{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 80px;
}

.title-body-profile{
    color: #202256;
    font-size: 40px;
    font-weight: 700;
    margin-left: 20px;
    margin-bottom: 0px;
}

.card-profile-company{
    display: flex;
    flex-direction: row;
}

.cards-info-profile-company{
    margin-left: 5%;
    width: 100%;
    margin-bottom: 25px;
}

.title-info-profile-company{
    color: #393939;
    font-size: 24px;
    font-weight: 700;
}

.subcards-info-profile-company{
    display: flex;   
}

@media (max-width: 768px) {
    .card-profile-company{
        flex-direction: column;
        align-items: center;
    }
    .cards-info-profile-company{
        margin-top: 30px;
        margin-left: 0px;
    }
    .subcards-info-profile-company{
        flex-direction: column;
    }
}
.container-newinfoCompany{   
    background: #FFFFFF;
    border-radius: 5px;      
    margin-top: 22px;    
    width: 70%;
}

.subcontainer-newinfoCompany{
    display: flex;
    flex-direction: column;
    height: 60%;
    justify-content: space-between;
    padding: 2em;  
}

.newprojects-company h1{   
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    display: flex;
    align-items: center;
    color: #F07130;
}

.newprojects-company h3,
.newinfo-company h3{   
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;   
    color: #393939;
}

.newinfo-company{
    display: flex;    
    width: 100%;
}

.newinfo-company p{   
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;   
    color: #5D5D5D;
    align-items: center;
}

.detalles-oportunidades p{
    color: #151515FC;
    font-size: 14px;
    font-weight: 700;
    margin: 1em 0 0 2em;
   

}

.detalles-oportunidades span{
    color: #5D5D5D;
    font-size: 14px;   
    margin-left: 2em;
}

.newinfo-subcontainer-company{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

@media (max-width: 768px) {
    .container-newinfoCompany{   
        width: 100%;
    }
}
.form-edit-profile-Company h3{
    font-weight: 700;
    font-size: 16px;    
    color: #393939;    
    /* line-height: 22px; */
}

.form-edit-profile-Company label{
    font-size: 14px;
    line-height: 20px;
    color: #393939;   
}

.form-edit-profile-Company p{
   color: #C5C5C5;
    font-size: 12px;
    /* line-height: 20px; */
    margin-bottom: 2em;
}

.form-edit-profile-Company input , .form-edit-profile-Company textarea{
    border: 0.5px solid #C5C5C5;
}

.buttonGuardar{
display:flex;
justify-content: flex-end;
margin-top: 1em;
}

.buttonGuardar button{
    position: relative;
    width: 105px;
    height: 24px;   
    color: white;
    background: #202256;
    border-radius: 5px;
  }


@media (max-width: 725px){
    .css-1uvkz21{
        width:350px !important;
        
    }
    .container-input-edit-profile-Company{
        min-width:90% !important;
    }
}  
.Perfil-company-container-cards{
  border-radius: 20px;
width: 324px;
min-height: 240px;
background-color: #ffffff;
font-size: .9em;
display:flex;
align-items: center;
justify-content: flex-start;
flex-direction: column;
margin-bottom:1em;
}

.Perfil-company-container-cards span{
width: 8.7em !important;
}

.porcentaje-company-container{
    font-size: .9em;
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.container-avatar-company{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.button-edit-avatar-company{
  background-color: #202256;
  color: white;
  border-radius: 5px;
  height: 2rem;
  width: 15.62rem;
  margin-top: 20px;
  margin-bottom: 5px;
  text-align: center;
  font-size: 14px;
}

.porcentaje-complete-company{
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top:4em;
}

.porcentaje-p-company{
  margin-right:.5em;
  color:#898989;
}

.porcentaje-text-company{
  color:#21224E
}

.button-edit-perfil-company{
  background-color: transparent;
  height: 35px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  color:#898989;
  margin-bottom: 1.5em;
}

.button-edit-perfil-company:hover{
  color: #202256;
}

.herre-company{
  color: #fbfbfb;
  width:80%;

}

.contact-data-company{
  color:#898989;
  font-size: .9em;
  margin-top: 1em;
}

.icon-cards-company{
  width:20px;
  height:20px;
  margin-top: -.2em;
  margin-right: 1em;
}

.container-card-plan{
  width: 321px;
  height: 131px;
  background: #202256;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.Subcontainer-CardPlan{
display: flex;
flex-direction: column;
height: 60%;
justify-content: space-between;
margin-left: 24px;
}

.Card-Title-Plan{
  font-weight: 700;
  font-size: 16px;
  color: #FFF;
}

.CardPlan-Button{
  background: #F07130;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  height: 35px;
  min-width: 170px;
}
.Container-CardDescripcion{
  background-color: #fff;
  width: 100%;
  height: 229px;
  margin-top: 32px;
  border-radius: 24px;
  display: flex;
  align-items: center;
}

.SubContainer-CardDescripcion{
  margin-inline: 61px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 60%;
}

.Title-Card-CardDescripcion{
  font-size: 16px;
  font-weight: 700;
  color: #393939;
}

@media (max-width: 768px) {
  .Container-CardDescripcion{
    height: 100%; 
  }

  .SubContainer-CardDescripcion{
    margin-inline: 0;
    height: 100%;
    padding: 1em;
  }
}



.container-cardlegal{
  background-color: #fff;  
  border-radius: 5px;      
  margin-top: 22px;  
  margin-left: 20px;
}

.subcontainer-cardlegal{
  display: flex;
  flex-direction: column;
  height: 60%;
  justify-content: space-between;  
  padding:2em
}

.subcontainer-cardlegal h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;   
  color: #5D5D5D;
}

.subcontainer-cardlegal span {  
  font-size: 14px;
  line-height: 20px;   
  color: #5D5D5D;
  font-weight: 400;
}

.card-legal-title{
  font-weight: 700;
    font-size: 16px;
    line-height: 20px;   
    color: #393939;
}

@media (max-width: 768px) {
  .container-cardlegal{
    margin-left: 0px;
  }
}
.dashOp-global-cont {
    padding-right: 100px;
    padding-left: 337px;
}

.container-dashOp-cards-cont{
    display: flex;
    justify-content: space-between;
}

.dashOp-cards-cont {
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    width: 70%;
}

.dashOp-cont-info-emp {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 98px;
}

.dashOp-cont-info-emp-serv {
    margin-left: 75px;
    width: 50%;
}

.dashOp-empresa {
    color: #5D5D5D;
    font-weight: 700;
    font-size: 16px;
}

.dashOp-text-sub {
    color: #5D5D5D;
    font-weight: 400;
    font-size: 14px;
}

.dashOp-met-text {
    font-weight: 400;
    font-size: 14px;
    color: #B0B0B0;
    margin-bottom: 52px;
}

.dashOp-adq-button {
    margin-top: 45px;
    width: 100%;
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 5px;
    color: #FFFFFF;
}

.dashOp-met-button {
    border: 1px solid #D6D6D6;
    background: transparent;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #D6D6D6;
    padding-right: 25px;
    padding-left: 25px;
    margin-bottom: 23px;
    margin-top: 17px;
}

.dashOp-salary {
    margin-top: 25px;
    color: #0B27E6;
    font-weight: 700;
    font-size: 16px;
}

.dashOp-solicitudes {
    color: #F07130;
    font-weight: 400;
    font-size: 14px;
}

.button-orange-dashOp {
    background-color: #F07130;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    height: 33px;
}

.dashOp-time {
    color: #B0B0B0;
    font-weight: 400;
    font-size: 14px;
}


.dashOp-subtitle {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
    margin-top: 35px;
}

.perfiles-de-prof-similares {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(330px, 2fr));
    justify-items: start;
}


.dashOp-btn-ver-mas {
    margin-top: 25px;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #5D5D5D;
}

.dashOp-title {
    font-weight: 700;
    font-size: 40px;
    color: #202256;
}

.dasOp-card {
    background: #FFFFFF;
    border-radius: 8px;
    /*width: 535px;*/
    /*height: 283px;*/
    width: 100%;
    padding: 33px 42px 16px 31px;
    margin-top: 22px;
    margin-bottom: 22px;
    margin-right: 75px;
}

.Sub-dasOp-card{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.dasOp-card-title {
    font-weight: 700;
    font-size: 14px;
    color: #5D5D5D;
}

.dasOp-card-desc {
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
    margin-top: 5px;
    list-style: disc;
    list-style-position: inside;
    word-break: break-all;
}
.legal-card-desc{
    font-size:14px;
    color: #5D5D5D;
    font-weight:600;
    margin-top: 5px;
}
.legal-information-company{
    text-transform: capitalize;
    margin-left: 5px;
    font-weight:400;
}

@media (max-width: 1024px) {
    .dashOp-cont-info-emp {
        flex-direction: column;
    }

    .dashOp-cont-info-emp-serv {
        margin-left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .dasOp-card {
        width: 100%;
        margin-right: 0;
        padding: 10px;
    }
}


@media (max-width: 1024px) {
    .dashOp-cards-cont {
        flex-direction: row;
        padding: 0;
    }
}

@media (max-width: 768px) {
    .dashOp-cards-cont {
        flex-direction: column;
        padding: 0;
        width: 100%;
    }
    .container-dashOp-cards-cont{
        flex-direction: column;
        align-items: center;
    }
    .Sub-dasOp-card{
        flex-direction: column;        
    }
}


@media (max-width: 1300px) {
    .dashOp-global-cont {
        padding-right: 50px;
        padding-left: 50px;
    }
}

.prof-list-global-cont {
    display: flex;
    width: 100%;
    height: 75px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.prof-list-rig-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.prof-list-rig-sub-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.prof-list-rig-sub-cont-visited {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    opacity: 0.5;
}

.prof-list-number {
    font-weight: 700;
    font-size: 12px;
    color: #C5C5C5;
    
    /*margin-right: 25px;*/
}

.prof-list-name {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.prof-list-job {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
}

.prof-list-card-image-filtro {
    /*filter: blur(3px);*/
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
    /*margin-right: 45px;*/
}

.prof-list-img {
    width: 55px;
    height: 55px;
    object-fit: cover;
    border-radius: 50%;
    margin: 15px;
}

.prof-list-ver-perfil {
    font-weight: 400;
    font-size: 14px;
    color: #202256;
    margin-right: 10px;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}
.prof-list-ver-perfil:hover {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%); 
    color: #202256; 
    font-weight: 500;
    stroke-width: 1.5px; 
}

.prof-list-ver-perfil svg{
    stroke: #202256;
}


.prof-list-ver-perfil-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.prof-list-compat {
    margin-top: 8px;
    /*margin-left: 5px;*/
    font-weight: 400;
    font-size: 12px;
    color: #F07130;
}

.prof-list-compat-cont {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.prof-list-button {
    margin-left: 20px;
    background: transparent;
    border: 1px solid #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #202256;
    padding-right: 20px;
    padding-left: 20px;
}

.prof-list-button-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.prof-list-but-selec {
    margin-left: 20px;
    background-color: #F07130;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    padding-right: 20px;
    padding-left: 20px;
}
.container-name-user{
    width: 300px;
}
@media (max-width: 1465px) {
    .prof-list-button-cont {
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        height: 150px;
    }
    .prof-list-but-selec {
        width: 100%;
        background-color: #F07130;
        border-radius: 5px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        padding-right: 20px;
        padding-left: 20px;
        margin: 0;
    }

    .prof-list-ver-perfil-cont {
        border: 1px solid #202256;
        width: 100%;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0;
    }
    .prof-list-global-cont {
        display: flex;
        width: 100%;
        height: 150px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

@media (max-width: 1024px) {
    .prof-list-global-cont {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        /*flex-direction: column;*/
        text-overflow: ellipsis;
    }

    .prof-list-name {
        text-overflow: ellipsis;
    }

    .prof-list-job {
        text-overflow: ellipsis;
    }

    .prof-list-rig-sub-cont {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .prof-list-rig-sub-cont-visited {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        opacity: 0.5;
    }
}

@media (max-width: 768px) {
    .prof-list-button-cont {
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        height: 150px;
    }
    .container-name-user{
        width:auto; 
    }
}

@media (max-width: 425px) {
    .prof-list-global-cont {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        padding: 5px;
        border-radius: 5px;
    }

    .prof-list-rig-sub-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
    }
    .prof-list-rig-sub-cont-visited {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
        opacity: 0.5;
    }

    .prof-list-button-cont {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100px;
    }

    .prof-list-but-selec {
        width: 100%;
        background-color: #F07130;
        border-radius: 5px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        padding-right: 20px;
        padding-left: 20px;
        margin: 0;
    }

    .prof-list-ver-perfil-cont {
        border: 1px solid #202256;
        width: 100%;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0;
    }
}
.cv-modal-button {
    border: 1px solid #202256;
    border-radius: 5px;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #202256;
    padding-right: 20px;
    padding-left: 20px;
    margin-left: 10px;
}

.cv-modal-button:hover {
    background: #202256;
    color: #fff;
    /* font-weight: 700; */
}

.cv-modal-linkedin-button {
    width: 190px;
    height: 35px;
    background: #0D0033;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px;
    color: #FFFFFF;
    margin-right: 15px;
}

.cv-modal-button-cont {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*justify-content: center;*/
}

.cv-modal-error {
    font-weight: 300;
    font-size: 12px;
    color: #E71C00;
    margin-bottom: 10px;
}

.cv-modal-message {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #5D5D5D;
}

.cv-modal-message-link {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #202256;
}

.cv-modal-button-cont2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cv-modal-cvgeneral-button {
    width: 190px;
    height: 35px;
    background: #D6D6D6;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px;
    color: #5D5D5D;
    margin-left: 15px;
}
@media (max-width: 425px) {
    .cv-modal-button{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .cv-modal-button{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.foot-but-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.foot-but-select {
    width: 100%;
    height: 33px;
    background-color: #F07130;
    border-radius: 5px;
    border: none;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
}

.aplic-card-glob-sub-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 23px;
}

.aplic-card-glob-sub-cont-visited{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 23px;
    opacity: 0.5;
}

.aplic-card-info-subcont {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.aplic-card-info-subcont-2 {
    flex-direction: column;
    justify-content: center;
}

.aplic-blob-num {
    font-weight: 700;
    font-size: 12px;
    color: #FFFFFF;
    margin-top: 9px;
}

.blob-cont-1 {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F07130;
    width: 33px;
    height: 33px;    
    position: relative;
    top: 20px;
}

.blob-cont-2 {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFBD6E;
    width: 33px;
    height: 33px;    
    position: relative;
    top: 20px;
}

.blob-cont-3 {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFDB99;
    width: 33px;
    height: 33px;
    position: relative;
    top: 20px;
}

.aplic-card-img {
    width: 81px;
    height: 81px;
    object-fit: cover;
    border-radius: 50%;
}

.aplic-card-img-filtro-2 {
    -webkit-filter: blur(3px);
            filter: blur(3px);
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
}

.aplic-card-img-filtro {
    /*filter: blur(3px);*/
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
}


.aplic-card-compat {
    /*margin-left: 5px;*/
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #F07130;
}

.aplic-card-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.aplic-card-name {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.aplic-card-name-ent {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
    display: none;
}

.aplic-card-job {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
}

.aplic-card-job-ent {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
    display: none;
}

.aplicantes-card-glo-cont {
    background: #FFFFFF;
    border-radius: 8px;
    margin: 12px;
    min-width: 297px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-right: 17px;
    padding-left: 20px;
}

.aplic-card-ver-perfil-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
; 
}
.aplic-card-ver-perfil:hover {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%); 
    color: #202256; 
    font-weight: 500;
    stroke-width: 1.5px; 
}

.aplic-card-ver-perfil svg{
    stroke: #202256;
}

.aplic-card-ver-perfil {
    font-weight: 400;
    font-size: 14px;
    color: #202256;
    margin-right: 10px;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

.aplic-card-button {
    background: transparent;
    border: 1px solid #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #202256;
    padding-right: 20px;
    padding-left: 20px;

}

.aplic-buttons-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .aplic-card-info-subcont {
        text-align: center;
    }

    .aplic-card-ver-perfil-cont {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 50%;
    }

    .aplic-card-name {
        font-weight: 700;
        font-size: 16px;
        color: #393939;
        display: none;
    }

    .aplic-card-name-ent {
        font-weight: 700;
        font-size: 16px;
        color: #393939;
        display: block;
    }

    .aplic-card-job {
        font-weight: 400;
        font-size: 12px;
        color: #5D5D5D;
        display: none;
    }

    .aplic-card-job-ent {
        font-weight: 400;
        font-size: 12px;
        color: #5D5D5D;
        display: block;
    }

    .aplic-card-glob-sub-cont {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: 23px;
    }
    .aplic-card-glob-sub-cont-visited{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: 23px;
        opacity: 0.5;
    }
}

@media (max-width: 425px) {

    .aplic-card-ver-perfil-cont {
        border: 1px solid #202256;
        border-radius: 5px;
        width: 100%;
        margin-bottom: 15px;
    }

    .aplic-card-name {
        font-weight: 700;
        font-size: 16px;
        color: #393939;
        display: block;
    }

    .aplic-card-name-ent {
        font-weight: 700;
        font-size: 16px;
        color: #393939;
        display: none;
    }

    .aplic-card-job {
        font-weight: 400;
        font-size: 12px;
        color: #5D5D5D;
        display: block;
    }

    .aplic-card-job-ent {
        font-weight: 400;
        font-size: 12px;
        color: #5D5D5D;
        display: none;
    }

    .aplic-card-glob-sub-cont {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 23px;
    }
    .aplic-card-glob-sub-cont-visited{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 23px;
        opacity: 0.5;
    }

    .aplic-buttons-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .aplicantes-card-glo-cont {
        background: #FFFFFF;
        border-radius: 8px;
        border: 2px solid #F07130;
        min-width: 100%;
        margin: 15px 0 0 0;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-right: 17px;
        padding-left: 20px;
    }
}

@media (max-width: 1024px) {
    .aplicantes-card-glo-cont {
        background: #FFFFFF;
        border-radius: 8px;
        margin: 15px 0 0 0;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-right: 17px;
        padding-left: 20px;
    }
}
.dashop-line-opt-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #D6D6D6;
    margin-top: 35px;
}

.dashop-tab-active {
    border-bottom: 3px solid #202256;
    font-weight: 700;
    font-size: 16px;
    color: #393939;
    margin-right: 150px;
    height: 40px;
    position: relative;
    top: 9px;
    cursor: pointer;
}

.dashop-tab-inactive {
    font-weight: 400;
    font-size: 16px;
    color: #393939;
    margin-right: 150px;
    height: 40px;
    position: relative;
    top: 9px;
    cursor: pointer;
}

.dashop-tab-number {
    font-weight: 400;
    font-size: 16px;
    color: #C5C5C5;
}

.dashop-tab-user-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 6px;
    padding: 10px;
}

.info-cont-test-select {
    display: flex;    
    align-items: center;
    justify-content: space-between;
    width: 30%;
}

.dashop-tab-cont {
    margin-top: 45px;
}

.dashop-tab-img-filtro {
    -webkit-filter: blur(3px);
            filter: blur(3px);
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
}

.dashop-tab-img-filtro-2 {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
}

.dashop-tab-img {
    width: 74px;
    height: 74px;
    object-fit: cover;
    border-radius: 50%;
}

.dashop-tab-sub-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dashop-tab-sub-sub-cont {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width:350px;
}

.dashop-tab-sub-sub-cont2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dashop-selection-buton{
    background-color: #F07130;
    border-radius: 5px;
    color: white;
    font-weight: 400;
    font-size: 14px;
    margin-right: 15px;
    margin-bottom: 10px;
}

.dashop-tab-name {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.dashop-tab-job {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
}

.dashop-tab-job2 {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
    margin:0;
}

.dashop-entrev {
    font-weight: 400;
    font-size: 14px;
    color: #E7BF00;
    margin:0
}

.dashop-entrev2 {
    font-weight: 400;
    font-size: 12px;
    color: #E7BF00;
    margin:0;
}


.dashop-selected {
    font-weight: 400;
    font-size: 14px;
    color: #24D054;
}

.dashop-entrev-subcont {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}


.dashop-entrev-compat {
    font-weight: 400;
    font-size: 14px;
    color: #F07130;
}

.aplic-card-ver-selec {
    margin-left: 20px;
    margin-right: 20px;
    background-color: #F07130;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    padding-right: 20px;
    padding-left: 20px;
}

.aplic-card-ver-perfil{
    display: flex;
    background-color: transparent;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 105px;
    color: #202256;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%)
}

.aplic-card-ver-perfil:hover {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%); 
   color: #202256; 
   font-weight: 500;
   stroke-width: 1.5px; 
}
.aplic-card-ver-perfil svg{
    stroke: #202256;
}

@media (max-width: 930px) {
    .buton-cont-2-cont {
        display: flex;
        height: 150px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .dashop-tab-user-cont {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .dashop-tab-sub-cont {
        flex-direction: column;
        align-items: center;
    }

    .dashop-tab-active {
        margin-right: 30px;
    }

    .dashop-tab-inactive {
        margin-right: 30px;
    }
    .info-cont-test-select {
       display: none;
    }
    .dashop-tab-sub-sub-cont{
        width:auto;
    }
}
.prof-sim-card-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 65px;
}

.prof-sim-card-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin: 15px;
}

.prof-sim-card-image-filtro {
    -webkit-filter: blur(3px);
            filter: blur(3px);
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
}

.prof-sim-card-name {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}

.prof-sim-card-job {
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.prof-sim-card-comp {
    font-weight: 400;
    font-size: 14px;
    color: #F07130;
}

.prof-sim-card-button {
    background: transparent;
    border: 1px solid #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #202256;
    padding-right: 20px;
    padding-left: 20px;
}
.Container-Card-Oportunidad{
  width: 324px;
  min-height: 520px;
  background: #202256;
  box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.Subcontainer-Card-Oportunidad{
  display: flex;
  flex-direction: column;
  height: 60%;
  justify-content: space-between;
  padding: 2em;  
}

.Title-Card-Oportunidad{
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  display: flex;
  align-items: center;
}

.Title-Card-Oportunidad span{
  margin-left: 0.2em;
}

.List-Habilidades-Info{ 
  font-size: 14px;
  color: #FFF;  
  list-style: disc;
  list-style-position: inside;
  margin-left: 2em;
}

.Text-Habilidades-Info{
  font-size: 14px;
  color: #FFF;  
  list-style: disc;
  list-style-position: inside;
  margin-left: 3em;
  margin-bottom: 0;
}

.H-erre{
  border-top: 2px solid #ffffff;
  margin: 14px 0px;
}
.oportunidades-linked-global-container-coally {
  width: 100%;
  padding-inline: 2.5%
}

.oportunidades-linked-container-inner{
  width: 100%;
  margin: 40px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.oportunidades-cards-container-linked-coally{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  height: 100%;
  padding-bottom: 5%;
}
.oportunidades-small-cards-container-linked{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 504px;
  align-self: flex-start;
  width: 100%;
  padding-right: 30px;
}

.paginate-oportunities-new-linked-coally{
  display: flex;
  justify-content: center;
  width: 100%;
}

.paginateStyleDashboard {
  display: flex;
  font-size: 1em;
  font-family: 'Poppins';
  color: #B0B0B0;
  padding: 0;
}

.paginateStyleDashboard li {
  padding: 8px;
  margin: 1em;
}

.oportunidades-big-cards-container-linked{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  max-height: 700px;
  width: calc(100% - 504px);
}

.container-search-oportunities{
  display: flex;
  flex-direction: row;
  justify-content: space-between;   
  width: 70%; 
  background-color: white;
  padding: 15px;
}

.search-oportunities-input-coally{
  background-color: transparent;
  height: 35px;
  width: 100%;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: #C5C5C5;
  text-indent: 25px;
 
}

.container-filters-oportunities{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 1.5em;
}

.text-filters-oportunities{
color: #B0B0B0;
font-size: 16px;
margin:0;
}

@media (max-width: 1400px) {
  .oportunidades-linked-global-container{
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 950px){
  .oportunidades-big-cards-container-linked{
    display: none;
  }
  .oportunidades-small-cards-container-linked{
    width: 100%;
    max-width: 100%;
    padding-right: 0;
  }
  .paginate-oportunities-new-linked{
    justify-content: center;
    align-items: center;
  }
  .container-search-oportunities{
    width: 100%;
    align-items: center;
  }
  .paginateStyleDashboard{
    font-size:12px
  }
  .container-filters-oportunities{
    flex-wrap: wrap;
  }
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 10px;
  border: 3px solid #ffffff;
  height: 10px;
}

.small-card-linked-container{
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.small-card-linked-container-active{
  display: flex;
  flex-direction: column;
  background-color: #202256;
  width: 100%;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.small-card-linked-image{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}

.small-card-linked-container-babag{
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
flex-direction: row;
}


.big-card-linked-container{
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /*overflow: auto;*/
  width: 100%;
  border-radius: 8px;
    -webkit-filter: drop-shadow(0px 7px 32px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 7px 32px rgba(0, 0, 0, 0.1));
}

.oportunidades-big-card-top-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 20px 0px 40px;
}

.oportunidades-big-card-top-image{
  display: flex;
  justify-content: center;
  align-self: flex-start;
}
.oportunidades-big-card-top-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 20px;
  width: calc(100% - 164px);
  padding-left: 20px;
}
.clipboard-opportunitie-card{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background-color: #f2f2f2;
    cursor: pointer;
    align-self: flex-start;
    transition: all 0.3s ease-in-out;
}
.oportunidades-big-card-body-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px 40px 20px 40px;
}
.oportunidades-big-card-body-inner{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 60%;
}
.oportunidades-big-card-body-inner-2{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 40%;
    padding: 0 30px 30px 30px;
}

@media (max-width: 1580px){
    .oportunidades-big-card-body-container{
        padding: 20px 0 20px 40px;
    }
}

@media (max-width: 768px) {
  .small-card-linked-container-babag{
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
}
.profesional-career-ia-container-row-chat{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.profesional-career-ia-container-career-chat{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.chat-ia-container-space-beetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-self: center;
}

.profesional-career-ia-chat-top{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 48rem;
    height: 100%;
    align-self: center;
    margin-bottom: 150px;
}
.profesional-career-ia-chat-bottom-input-background-blurred{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    z-index: 1;
}

.profesional-career-ia-chat-bottom-input-background-blurred::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-filter: blur(5px);
            filter: blur(5px);
}

.profesional-career-ia-chat-bottom-input-container{
    width: 48rem;
    background-color: rgb(32, 34, 86);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    position: fixed;
    bottom: 0;
    margin-bottom: 1rem;
}
.profesional-career-ia-chat-input{
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
}
.input-chat-ia-send-button{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 30px;
    height: 30px;
    margin-left: 1rem;
    cursor: pointer;
}
.send-button-chat-ia{
    color: #fff;
    font-size: 30px;
    transition:  all 0.3s ease-in-out;
}
.send-button-chat-ia:hover{
    color: #ee7919;
    transition: 0.3s;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.blank {
    border: none;
    border-bottom: 1px solid #fff;
    background-color: transparent;
    font-size: inherit;
    padding: 0;
    margin: 0;
    color: #fff;
}
.blank:focus {
    outline: none;
    color: #fff;
}

.chat-top-card-container{
    background-color: #65656b;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    padding: 20px;
    border-color: rgba(32,33,35,.5);
    border-radius: 10px 10px 0 0;
}


.fade-enter {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}
.fade-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.chat-bottom-card-container{
    background-color: rgb(32, 34, 86);
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    padding: 20px;
    border-color: rgba(32,33,35,.5);
    border-radius: 0 0 10px 10px;
}

.chat-top-card-container-left{
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    position: relative;
}
.chat-top-card-container-left-img{
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 3px;
}
.chat-top-card-container-right{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 20px;
    white-space: pre-wrap;
    text-align: left;
    vertical-align: middle;
    line-height: 1.5;
    word-spacing: 0.2em;
    color: white;
    font-size: 18px;
}
.chat-top-card-container-right-ia{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 20px;
    white-space: pre-wrap;
    text-align: left;
    vertical-align: middle;
    line-height: 1.5;
    word-spacing: 0.2em;
    color: white;
    font-size: 18px;
}
.profesional-career-ia-container-row-der-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    height: 100vh;
}
.profesional-career-ia-container-row-izq-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    height: 100vh;
}
.logo-coally-izq-chat-ia{
    opacity: 0.1;
    max-height: 150px;
    max-width: 150px;
}

.formation-link{
    color: #ee7919;
    text-decoration: none;
    font-weight: bold;
    transition: 0.3s;
}


@media screen and (max-width: 1340px) {
    .profesional-career-ia-container-row-izq-logo{
        display: none;
    }
    .profesional-career-ia-container-row-der-logo{
        display: none;
    }
}

@media screen and (max-width: 790px) {
    .profesional-career-ia-chat-top{
        width: 90%;
    }
    .profesional-career-ia-chat-bottom-input-container{
        width: 90%;
    }
    .profesional-career-ia-chat-input p {
        font-size: 16px !important;
    }
}

@font-face {
  font-family: Unilever;
  src: url(/static/media/UnileverShilling.234d2383.ttf);
}
.landing-unilever-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
}
.unilever-landing-footer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  background-color: #1F36C7;
  color: #fff;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 400;
  font-family: Unilever;
}
[data-aos="fade-out"] {
  opacity: 1;
  transition-property: opacity;
}
[data-aos="fade-out"].aos-animate {
  opacity: 0;
}
.landing-unilever-section-1{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat; 
  background-position:center;
  background-image:linear-gradient(90deg, #00076e 6%, rgba(255, 255, 255, 0) 48.83%), url(/static/media/unileverBG.8a0a59dd.png);
  mix-blend-mode: multiply;
}
.landing-unilever-section-1-container{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.landing-unilever-section-1-container-body{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row-reverse;
  width: 80%;
  height: 70%;
/*   margin-top: 4%; */
}
.landing-unilever-section-1-logo{
  width: 100%; 
  max-height: 112px;
  display:flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 30px;
  padding-right: 30px;
}
.landing-unilever-section-1-logo-bottom{
  width: 100%; 
  max-height: 112px;
  display:flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}
  .landing-unilever-body-scroll-container{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 2%;
}
.landing-unilever-logo{
  height:82px;
  width: 82px;
}
.layer-pedal-landing{
  /* style={{width:'50%', marginLeft: '-3%', height: 'auto'}} */
  width: 50%;
  margin-left: -3%;
  height: auto;
}

.landing-unilever-section-1-title-container{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  width: 60%;
  height: 100%;
}
.landing-unilever-section1-paraph-container{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 2%;
}
.scroll-container1{
  display: flex;
  position: absolute;
  right: 0;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 80px;
  -webkit-transform: translate(50%);
          transform: translate(50%);
  height: 50%;
  z-index: 5;
}
.scroll-container-3{
  display: flex;
  position: absolute;
  right: 0;
  justify-content: flex-end;
  align-items: flex-start;
  margin-right: 80px;
  height: 30%;
  z-index: 5;
}
.scroll-container-3 svg{
color:#005EEF !important;
}

.scroll-container{
  display: flex;
  position: absolute;
  right: 0;
  justify-content: flex-end;
  align-items: flex-start;
  margin-right: 80px;
  height: 30%;
  z-index: 5;
}
.landing-unilever-section1-button-container{
  margin-top: 20px;
}
.landing-unilever-section1-button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 48px;
  border-radius: 24px;
  background-color: #1F36C7;
  text-align: center;
}

.landing-unilever-section1-arrow-indicator{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  width: 80%;
  height: auto;
  margin-top: 2%;
  margin-bottom: 20px;
  z-index: 2;
}
.landing-unilever-section2-arrow-indicator{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 3%;
  margin-bottom: 20px;
  z-index: 2;
}
.landing-unilever-section3-arrow-indicator{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  width: 80%;
  height: 100%;
  margin-top: 3%;
  margin-bottom: 20px;
  z-index: 2;
}

.title-marcas-unilever{
font-family: 'Unilever';
font-size:56px;
color: #005EEF;
}

.landing-unilever-section1-arrow-social-media{
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-self: flex-end;
  align-items: flex-end;
  width: 30%;
  height: 80%;
}
.social-media-unilever-container{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  width: 100%;
  height: auto;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) -1.97%, rgba(11, 25, 129, 0.35) 15.45%, #0B1981 49.02%, rgba(11, 25, 129, 0.35) 77.81%, rgba(255, 255, 255, 0) 100%);
background-blend-mode: multiply;
}
.social-media-unilever-container2{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  width: 100%;
  height: 10%;
  margin-top: 10%;
  z-index: 2;
}

.gg-arrow-long-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  color: #fff;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform: scale(var(--ggs,1));
          transform: scale(var(--ggs,1));
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  box-shadow: inset 0 0 0 2px;
  height: 100px;
  width: 6px
}
.gg-arrow-long-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  color: #fff;
  width: 12px;
  height: 12px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  bottom: 0;
  left: -5px
}

.unilever-landing-firulete1{
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transform: translate(-10%, 165%);
          transform: translate(-10%, 165%);
  z-index: 1;
}
.unilever-landing-ring1{
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translate(0, 50%);
          transform: translate(0, 50%);
  z-index: 1;
  margin-left: 30px;
}
.unilever-landing-firulete2{
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translate(0%, 350%);
          transform: translate(0%, 350%);
  z-index: 1;
}
.unilever-landing-ring2{
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transform: translate(-15%, 250%);
          transform: translate(-15%, 250%);
  z-index: 1;
  margin-right: 30px;
}
.unilever-landing-ring3{
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translate(0, 800%);
          transform: translate(0, 800%);
  z-index: 1;
}
.unilever-landing-firulete3{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.unilever-landing-firulete4{
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.landing-unilever-section-2{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #1f36c7;
}
.landing-unilever-section-2-container-1{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 30%;
  margin-top: 5%;
}

.landing-unilever-section-2-container-1-title{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.landing-unilever-section-2-container-2{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 100%;
}
.landing-unilever-section-2-container-2-1{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
  place-content: flex-start;
  grid-gap: 30px;
  margin-top: 32px;
  width: 100%;
  justify-items: center;
  align-items: center;
  padding-bottom: 150px;
}
.landing-unilever-section-2-container-2-2{
  display: flex;
  justify-content: space-between;
  grid-gap: 80px;
  gap: 80px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}
.unilever-section-2-cards{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 346px;
  width: 286px;
  border-radius: 50px 0px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}
.unilever-section-2-card-inner{
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.unilever-section-2-cards:hover .unilever-section-2-card-inner{
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.unilever-section-2-card-front, .unilever-section-2-card-back{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.unilever-section-2-card-front{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 50px 0px;
  background-color: #005EEF;
  color: #fff;
}

.section-2-card-front-unilever-text{
  font-family: Unilever;
  font-weight: 900;
  color: #fff;
  font-size: 24px;
}

.section-2-card-front-unilever-paraph{
  font-family: Unilever;
  font-weight: 400;
  color: #fff;
  font-size: 20px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.unilever-section-2-card-back{
  width: 100%;
  height: 100%;
  border-radius: 50px 0px;
  background-color: #00d7c4; /* 00d7c4 */
  color: #fff;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}


.unilever-title-1{
  font-family: Unilever;
  font-weight: 600;
  font-size: 56px;
  color: #FFFFFF;
}
.unilever-title-2{
  font-family: Unilever;
  font-weight: 600;
  font-size: 56px;
  color: #FFFFFF;
}
.unilever-title-3{
  font-family: Unilever;
  font-weight: 600;
  font-size: 56px;
  color: #005EEF;
}

.unilever-subtitle-1{
  font-family: Unilever;
  width: 70%;
  margin-top: 45px;
}

.landing-unilever-section-3{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #1f36c7;
}
.landing-unilever-section-3-container{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 80%;
  height: 100%;
}

.landing-unilever-section-3-title-subcontainer{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 80%;
  height: 100%;
  margin-top: 5%;
}

.landing-unilever-section-3-container-1{
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;  
}
.landing-unilever-section-3-title-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: start;
}

.landing-unilever-section-3-container-2{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  margin-top: 5%;
  margin-left: 10%;
}

.landing-unilever-section-3-container-2-cards{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-content: flex-start;
  justify-items: center;
  align-items: flex-start;
  grid-gap: 70px;
  width: 50%;
  height: 100%;
  margin-top: 5%;
}
.unilever-section-3-container-2-cards-middle{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}
.landing-unilever-section-3-card{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 312px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #005EEF;
  border-radius: 16px;
}
.landing-unilever-section-3-card-container-1{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 50%;
  margin-top: 20px;
  padding: 40px;
  text-align: left;
}
.landing-unilever-section-3-circle{
  display: flex;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #252525;
  color: #fff;
  font-size: 50px;
  text-align: center;
  font-weight: 700;
}

.landing-unilever-section-4{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #F5F5F5;
}
.landing-unilever-section-4-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 100%;
  margin-top: 5%;
}
.landing-unilever-section-4-dropdowns-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 60%;
  height: 100%;
  margin-top: 5%;
  padding-bottom: 40px;
}
.landing-unilever-section-4-dropdowns-summary{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.empty-div-unilever:empty::before{
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\A0";
  height: 100%;
  width: 100%;
}
.landing-unilever-section-4-dropdowns-details-container{
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  background-color: #E0E0E0;
  width: 100%;
  height: 100%;
}
.landing-unilever-section-4-dropdowns{
  background-color: #1f36c7;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before{
  display: none;
}
.landing-unilever-section-5{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #F5F5F5;
  padding-bottom: 2%;
}
.landing-unilever-section-5-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 5%;
}
.landing-unilever-section-5-container-row{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
}
.landing-unilever-section-5-inner-1{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  width: 60%;
  height: 100%;
}
.landing-unilever-section-5-column-1-inner-1-title{
  font-family: Unilever;
  font-weight: 600;
  font-size: 25px;
  color: #FFFFFF;
}
.landing-unilever-section-5-column-1-inner-1-title1{
  font-family: Unilever;
  font-weight: 600;
  font-size: 25px;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
}
.landing-unilever-section-5-column-1{
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100vh;
  text-align: center;
  margin-right: 22px;
}
.landing-unilever-section-5-column-1-inner-1{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #1f36c7;
  margin-top: 5%;
  text-align: center;
  border-radius: 8px;
  height: 100%;
}
.landing-unilever-section-5-column-1-inner-2{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #00a889;
  margin-top: 5%;
  text-align: center;
  border-radius: 8px;
  height: 100%;
}
.landing-unilever-section-5-column-1-inner-3{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #1f36c7;
  margin-top: 5%;
  text-align: center;
  border-radius: 8px;
  height: 100%;
  padding: 50px;
}
.landing-unilever-section-5-column-2{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100vh;
  text-align: center;
  border-radius: 8px;
}
.landing-unilever-section-5-column-2-inner-1{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #ffe300;
  margin-top: 5%;
  text-align: center;
  border-radius: 8px;
  height: 100%;
  padding: 60px;
}
.landing-unilever-section-5-column-2-inner-2{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f752c7;
  margin-top: 5%;
  text-align: center;
  border-radius: 8px;
  height: 100%;
  padding: 60px;
}
.landing-unilever-section-5-column-2-inner-3{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #9c44c0;
  margin-top: 5%;
  text-align: center;
  border-radius: 8px;
  height: 100%;
}

.landing-unilever-section-5-inner-2{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 40%;
  height: 100%;
}
.landing-unilever-section-5-inner-2-inner-1{
  display: flex;
  justify-content: center;
  align-self: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1838px) {
  .unilever-landing-firulete3{
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

@media only screen and (max-width: 1732px) {
  .unilever-landing-ring3{
    -webkit-transform: translate(00, 850%);
            transform: translate(00, 850%);
  }
.unilever-landing-ring2{
  -webkit-transform: translate(-50%, 240%);
          transform: translate(-50%, 240%);
}
.unilever-landing-firulete2{
  -webkit-transform: translate(0, 450%);
          transform: translate(0, 450%);
}
.scroll-container1{
  margin: 10px;
}
.scroll-container{
  margin: 10px;
  -webkit-transform: translate(50%, 50%);
          transform: translate(50%, 50%);
}
}

@media only screen and (max-width: 1740px) {
  .landing-unilever-section1-arrow-social-media{
    width: 30%;
  }
}
@media only screen and (max-width: 1600px) {
  .landing-unilever-section-2-container-2-1{
    padding-bottom: 50px;
  }
  .landing-unilever-section-1-container{
  height:100vh;
  }
  .landing-unilever-section-3-container{
    flex-direction: column;
  }
  .landing-unilever-section-3-container-2{
    margin-left: 0;
  }
  .landing-unilever-section-3-title-container{
    flex-direction: row;
    align-items: center;
  }
  .unilever-landing-ring3{
    -webkit-transform: translate(0, 1000%);
            transform: translate(0, 1000%);
  }
  .landing-unilever-section-1{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .landing-unilever-section-5-container-row{
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 1740px) {
 .landing-unilever-section1-arrow-indicator{
  margin-top: 20px
 }
 .landing-unilever-section2-arrow-indicator{
  margin-top: 20px
 }
  .landing-unilever-section3-arrow-indicator{
    margin-top: 20px
  }
}
@media only screen and (max-width: 1500px) {
  .layer-pedal-landing{
    width: 70%;
  }
}
@media only screen and (max-width: 1490px) {
  .unilever-landing-firulete1{
    -webkit-transform: translate(-50%, 165%);
            transform: translate(-50%, 165%);
  }
}
@media only screen and (max-width: 1400px) {
  .landing-unilever-section1-arrow-social-media{
    width: 30%;
  }
  .unilever-landing-ring1{
    display: none;
  }
  .unilever-landing-firulete1{
    display: none;
  }
  .unilever-landing-firulete2{
    display: none;
  }
  .unilever-landing-ring2{
    display: none;
  }
  .unilever-landing-ring3{
    display: none;
  }
  .unilever-landing-firulete3{
    display: none;
  }
  .unilever-landing-firulete4{
    display: none;
  }
  .landing-unilever-section-5-inner-1{
    width: 100%;
  }
}
@media only screen and (max-width: 1071px) {
  .landing-unilever-section1-arrow-social-media{
    width: 35%;
  }
  .layer-pedal-landing{
    width: 80%;
  }
}
@media only screen and (max-width: 1000px) {
  .unilever-subtitle-1{
    width: 90%;
  }
  .scroll-container{
    margin: 10px;
  }
}
@media only screen and (max-width: 980px){
  .landing-unilever-section-3-container-1{
    align-items: center;
    width: 100%;
  }
  .coffe-girl-unilever{
  display: none;
}
.landing-unilever-section-5-inner-2-inner-1{
  display: none;
}
.landing-unilever-section-4-dropdowns-container{
  width: 90%;
}
}

@media only screen and (max-width: 923px){
  .landing-unilever-section-1-container-body{
    width: 95%;
  }
  .landing-unilever-section-1-title-container{
    width: 70%;
  }
  .landing-unilever-section1-arrow-social-media{
    width: 30%;
  }
  .landing-unilever-section1-arrow-indicator{
    width: 90%;
  }
}

@media only screen and (max-width: 900px){
  .scroll-container{
    margin: 10px;
  }
  .landing-unilever-section1-arrow-social-media{
    display: none;
  }
  .landing-unilever-section-1-title-container{
    width: 100%;
  }
  .layer-pedal-landing{
    width: 60%;
  }
}
@media only screen and (max-width: 848px){
  .landing-unilever-section-2-container-2-2{
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
  .landing-unilever-section-3-container-2-cards{
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .landing-unilever-container{
    overflow-x: hidden;
  }
  .layer-pedal-landing{
    width: 75%;
  }
  .landing-unilever-layer-top{
    width: 100%;
    height: 100%;
    max-width: 478px;
    max-height: 92px;
  }
  .unilever-subtitle-1{
    width: 90%;
    margin-top: 15px;
  }
  
  .landing-unilever-section-1{
    height: 100%;
    padding-left: 30px; 
    padding-right: 30px;
    background-position: 70%;
    background-image:linear-gradient(180deg, #00076e 6%, rgba(51, 51, 51, 0) 100%), url(/static/media/unileverBg-Mobile.2f7e500f.png);
  }
  .landing-unilever-section-2{
    padding-left: 30px; 
    padding-right: 30px;
  }
  .landing-unilever-section-1-container-body{
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    flex-direction:column;
  }
  .landing-unilever-body-scroll-container{
    margin-top: 40px;
  }
  .landing-unilever-section-1-title-container{
    width: 100%;
  }
  .landing-unilever-section1-paraph-container{
    width: 100%;
    height: 100%;
    margin-top: 2%;
  }
  .scroll-container, .scroll-container1, .scroll-container-3{
    display: none;
    margin-right: 0;
  }
  .landing-unilever-section1-button-container{
    margin-top: 20px;
  }
  .landing-unilever-section1-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 48px;
    border-radius: 24px;
    background-color: #1F36C7;
    text-align: center;
  }
  .landing-unilever-section-1-logo{
    width:100%;
  }
  .landing-unilever-section1-arrow-social-media{
    display: none;
  }
  .social-media-unilever-container{
    display: none;
  }
  .social-media-unilever-container2{
    display: none;
  }
  .landing-unilever-section1-arrow-indicator{
    margin-top: 40px;
    padding-bottom: 5%;
    width: 100%;
  }
  .landing-unilever-section2-arrow-indicator{
    margin-top: 40px;
  }
  .landing-unilever-section3-arrow-indicator{
    margin-top: 40px;
  }
  .gg-arrow-long-down {
    box-sizing: border-box;
    position: relative;
    display: block;
    color: #fff;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: scale(var(--ggs,1));
            transform: scale(var(--ggs,1));
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
    box-shadow: inset 0 0 0 2px;
    height: 100px;
    width: 6px;
    z-index: 100;
  }
  .landing-unilever-section-2-container-2{
    width: 100%;
  }
  .landing-unilever-section-3-container-2{
    margin-top: 10%;
  }
  .landing-unilever-section-3-circle{
    -webkit-transform: translate(-20%, -50%);
            transform: translate(-20%, -50%);
  }
}
@media only screen and (max-width: 768px) {
  .unilever-title-2{
    font-size: 7vw;
  }
  .unilever-title-3{
    font-size: 7vw;
  }
  .landing-unilever-section-1-container{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 10%;
  }
  .title-marcas-unilever{
    font-size: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .unilever-title-1{
    font-size: 8vw;
  }
  .landing-unilever-section-5-inner-1{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15%;
  }
  .landing-unilever-section-5-column-1{
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
  }
  .landing-unilever-section-5-column-2{
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .layer-pedal-landing{
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
.landing-unilever-section-1-container{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
}
@media only screen and (max-height: 790px) {
  .landing-unilever-section-1-container{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 2%;
  }
  }
  @media only screen and (max-height: 700px){
    .landing-unilever-section-5{
      padding-bottom: 40%;
    }
  }
.container-carrousel{
    width: 70%;
}

.Container-Logos-Aliados{
    width:100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;   
}

.Container-Logos-Aliados2{
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;   
}

.container-marcas-unilever{
    background-color:white ;
    width:100%;
    display: flex ;
    justify-content:flex-end;
    align-items:center; 
    height:160px;
}


@media(max-width:768px){
  
    .Container-Logos-Aliados{
        flex-direction: column;
       height:35vh;      
    }
    .logo-sena-responsive{
        width:450px
    }
    .Container-Logos-Aliados2{
        flex-direction: column;
        width:100%;
        height:35vh;
        margin-top: 20px;      
    }
    .container-marcas-unilever{
        flex-direction: column;       
        height:auto !important; 
        padding-bottom: 50px; 
        width:100vh;    
    }
}
.postulate-global-container {
    background: #005eef;
    overflow-x: hidden;
}

@font-face {
    font-family: Unilever;
    src: url(/static/media/UnileverShilling.234d2383.ttf);
  }

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  appearance: unset;
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  -webkit-appearance: unset;
          appearance: unset;
  -moz-appearance:textfield;
}

.postulate-ring1{
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translate(0%, 50%);
          transform: translate(0%, 50%);
  z-index: 1;
}
.postulate-firulete1{
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transform: translate(0%, 50%);
          transform: translate(0%, 50%);
  z-index: 1;
}
.postulate-ring2{
    position: absolute;
    left: 0;
    bottom: 0;
    -webkit-transform: translate(0%, 300%);
            transform: translate(0%, 300%);
    z-index: 1;
  }
  .postulate-firulete2{
    position: absolute;
    right: 0;
    bottom: 0;
    -webkit-transform: translate(0%, 400%);
            transform: translate(0%, 400%);
    z-index: 1;
  }

.postulate-global-sub-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.postulate-separator {
    width: 100%;
    height: 1px;
    border: 1px solid #FBFBFB;
    background: #FBFBFB;
    margin-bottom: 40px;
}

.postulate-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.postulate-footer {
    padding: 10px 0;
    background: #1F36C7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Unilever;
}

.postulate-section-1-container {
    margin-top: 5%;
    height: 30%;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.unilever-left-logo-container{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}
.unilever-left-logo-container-1{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.postulate-section-2-container {
    width: 40%;
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.postulate-freelo{
    width: 400px;
    height: 157px;
}

.postulate-section-2-sub-container {
    width: 100%;
    margin-bottom: 50px;
}

.postulate-section-2-sub-text {
    font-weight: 700;
    font-size: 20px;
    color: #FBFBFB;
    margin-bottom: 25px;
}
.postulate-section-sub-text-add {
    font-weight: 700;
    font-size: 20px;
    color: #FBFBFB;
}

.postulate-check-text {
    font-weight: 400;
    font-size: 20px;
    color: #FBFBFB;
}

.postulate-section-2-sub-button {
    background: #1F36C7;
    border-radius: 100px;
    width: 100px;
    height: 50px;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
}

.postulate-section-add-sub-button {
    background: #1F36C7;
    border-radius: 100px;
    width: 100px;
    height: 50px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
}

.postulate-section-2-sub-input {
    width: 100%;
    background-color: transparent;
    border: 1px solid #FBFBFB;
    border-radius: 25px;
    height: 35px;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 20px;
    color: #FBFBFB;
    text-indent: 20px;
}
.postulate-section-2-sub-input::-webkit-input-placeholder {
    color: #FBFBFB;
}
.postulate-section-2-sub-input:-ms-input-placeholder {
    color: #FBFBFB;
}
.postulate-section-2-sub-input::placeholder {
    color: #FBFBFB;
}
.postulate-section-2-sub-input-textarea {
    width: 100%;
    background-color: transparent;
    border: 1px solid #FBFBFB;
    border-radius: 25px;
    height: 150px;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 20px;
    color: #FBFBFB;
    text-indent: 20px;
    resize: none;
}
.postulate-section-2-sub-input-textarea::-webkit-input-placeholder {
    color: #FBFBFB;
}
.postulate-section-2-sub-input-textarea:-ms-input-placeholder {
    color: #FBFBFB;
}
.postulate-section-2-sub-input-textarea::placeholder {
    color: #FBFBFB;
}
.postulate-section-2-sub-input-date{
    background-color: #00d9c4;
    padding: 15px;
    color: #ffffff;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 5px;
    margin-bottom: 40px;
}


.postulate-section-2-sub-input-date::-webkit-calendar-picker-indicator {
    -webkit-filter: invert(1);
            filter: invert(1);
}


.postulate-section-sub-input-added{
    width: 100%;
    background-color: transparent;
    border: 1px solid #FBFBFB;
    border-radius: 25px;
    height: 35px;
    font-weight: 400;
    font-size: 20px;
    color: #FBFBFB;
    text-indent: 20px;
    margin-right: 10px;
}

.postulate-section-2-title {
    font-weight: 600;
    font-size: 56px;
    color: #FFFFFF;
    margin-bottom: 60px;
}

.postulate-header-text {
    font-weight: 400;
    font-size: 16px;
    color: #CFD3D6;
}



.postulate-section-3-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.postulate-section-3-sub-container {
    display: flex;
    flex-direction: row;
}

.postulate-section-3-global-container {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    align-self: flex-end;
}

.postulate-section-3-sub-sub-container {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
}
.postulate-delete-button{
    color: #FFFFFF;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
}
.postulate-delete-button:hover{
    color: #1F36C7;
}

.postulate-section-3-sub-sub-container-2 {
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-right: 30px;
}

.postulate-section-3-social {
    color: white;
    font-size: 20px;
    font-weight: 400;
}

.postulate-section-3-text {
    font-weight: 500;
    font-size: 20px;
    color: #CFD3D6;
}
.postulate-unilever-logo {
    width: 112px;
    height: 112px;
}

@media (max-width: 740px) {
    .postulate-section-2-title {
        font-weight: 600;
        font-size: 26px;
        color: #FFFFFF;
        margin-bottom: 60px;
    }
    .unilever-left-logo-container{
        width: 100%;
    }
}

@media (max-width: 1345px) {
    .postulate-section-3-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .postulate-section-3-sub-sub-container-2 {
        padding-top: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 50px;
        margin-right: 0;
    }
}


.css-checkbox { position: absolute; overflow: hidden; clip: rect(0 0 0 0); height: 1px; width: 1px; margin: -1px; padding: 0; border: 0; }
#inputPreview { display: flex; grid-gap: 20px; gap: 20px; justify-content: center;	}
.css-checkbox + label { position: relative; font-size: 14px; cursor: pointer; display: inline-flex; align-items: center; height: 24px; color: rgb(255, 255, 255); }
.css-checkbox + label::before { content: " "; display: inline-block; vertical-align: middle; margin-right: 3px; width: 22px; height: 22px; background-color: #005eef; border-width: 1px; border-style: solid; border-color: rgb(255, 255, 255); border-radius: 50px; box-shadow: none; }
.css-checkbox:checked + label::after { content: " "; background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNNTEyIDI1NkM1MTIgMzk3LjQgMzk3LjQgNTEyIDI1NiA1MTJDMTE0LjYgNTEyIDAgMzk3LjQgMCAyNTZDMCAxMTQuNiAxMTQuNiAwIDI1NiAwQzM5Ny40IDAgNTEyIDExNC42IDUxMiAyNTZ6Ii8+PC9zdmc+"); background-repeat: no-repeat; background-size: 10px 10px; background-position: center center; position: absolute; display: flex; justify-content: center; align-items: center; margin-left: 0px; left: -1px; top: 0px; text-align: center; background-color: transparent; font-size: 10px; height: 24px; width: 24px; }

.unilever-postulate-radio-section-container2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin-bottom: 5%;
}
.unilever-postulate-radio-section-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
}

@media (max-width: 1137px) {
    .postulate-section-3-sub-container {
        display: flex;
    }
}
@media only screen and (max-width: 1510px) {
    .postulate-section-3-container{
      flex-direction: column;
    }
    .postulate-section-3-sub-sub-container-2{
      margin-right: 0;
    }
  }
@media only screen and (max-width: 1366px) {
    .postulate-section-3-container{
      flex-direction: column;
    }
    .postulate-section-3-sub-sub-container-2{
      margin-right: 0;
    }
  }

@media (max-width: 1100px) {
    .postulate-global-sub-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .postulate-section-1-container {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .postulate-section-2-container {
        width: 100%;
        padding: 20px;
        margin-left: 0;
        margin-right: 0;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .postulate-section-3-global-container {
        height: 50px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .postulate-section-3-sub-sub-container-2 {
        padding-top: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 50px;
        margin-right: 30px;
    }
    .postulate-section-3-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .postulate-ring1{
        display: none;
    }
    .postulate-ring2{
        display: none;
    }
    .postulate-firulete1{
        display: none;
    }
    .postulate-firulete2{
        display: none;
    }
}

.home-unilever-profesional{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  width: 100%;
}
.home-unilever-profesional-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
}
.home-unilever-profesional-banner{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}
.home-unilever-profesional-inner-1{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 20%;
  align-self: flex-start;
}
.home-unilever-profesional-inner-1-1{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #D6D6D6;
}
.home-unilever-profesional-inner-1-2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  width: 100%;
  margin-top: 3%;
}
.home-profesional-filter-1{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 70%;
  margin-bottom: 20px;
}
.home-profesional-filter-2{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  justify-content: space-evenly;
  width: 30%;
  margin-bottom: 20px;
  cursor: pointer;
}
.filter-title-unilever{
  font-size: 14px;
  font-weight: 400;
  color: #5D5D5D;
}
.filter-title-unilever-1{
  font-size: 24px;
  font-weight: 700;
  color: #393939;
}
.filter-category-title{
  font-size: 16px;
  font-weight: 700;
  color: #393939;
}
.filter-container-inner{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
}

.home-unilever-profesional-inner-2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  margin-left: 2%;
  height: 100%;
  align-self: flex-start;
}
.home-unilever-profesional-inner-2-1-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.home-profesional-search-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.home-profesional-view-container{
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: 30px;
  border-bottom: 1px solid #D6D6D6;
  cursor: pointer;
}
.filter-category-inner{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
}
.Profesional-Home-unilever-container-top{
  width: 90%;
  display: flex;
  margin-left: 30px;
}
.home-unilever-profesional-inner-2-2-container{
  display: flex;
  align-items: flex-start;
  justify-items: center;
  width: 100%;
  margin-bottom: 20px;
  height: 1000px;
}
.home-unilever-profesional-inner-2-2-container-inner{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
  height: 100%;
}
.home-unilever-profesional-inner-2-2-container-grid{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
  place-content: flex-start;
  grid-gap: 10px;
  margin-top: 32px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
@media only screen and (max-width: 1500px){
  .home-profesional-view-container{
    width: 30%;
  }
.Profesional-Home-unilever-container-top{
  width: 70%;
  margin: 0;
}
}
@media only screen and (max-width: 1500px){
  .home-unilever-profesional-inner-1{
    display: none;
  }
  .home-unilever-profesional-inner-2{
    width: 100%;
    margin-left: 0;
  }

  .home-unilever-profesional-inner-2-2-container-grid{
    overflow: hidden;
    height: auto;
  }
}
.unilever-banner-profesional{
  height: 200px;
  width: 90%;
  background-image: url(/static/media/home-banner-unilever.a43c2192.png);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.unilever-banner-profesional-inner-1{
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.unilever-banner-profesional-inner-1-1{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.unilever-home-subtitle{
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  padding-left: 80px;
}
.unilever-home-title{
  font-weight: 700;
  font-size: 40px;
  color: #fff;
  padding-left: 80px;
}
.unilever-banner-profesional-inner-2{
  margin-right: 40px;
}

@media(max-width: 768px){
  .unilever-home-title{
    font-size: 26px;
  }
  .unilever-banner-profesional-inner-1-1{
    align-items: center;
  }
}
.Container-square-home-profesional-unilever{
  background: #FFFFFF;
  width: 324px;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column; 
  height: 250px;
  margin: 22px;
}

.image-profesional-home-unilever{
  object-fit: cover;
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
.card-home-unilever-profesional-mid{
  width: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  align-items: center;
  margin-bottom: 38px;
  padding-left: 25px;
}
.card-home-unilever-profesional-mid-section-3{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.card-home-unilever-profesional-mid-section-list{
  width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 28px;
}
.home-profesional-unilever-list-Container-rectangular{
  background: #FFFFFF;
  width: 100%;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 175px;
  margin-bottom: 22px;
  padding-bottom: 20px;
}
.unilever-home-profesional-mid-Container-inner-3-1{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.unilever-home-profesional-Top-Container-rectangle-inner-2-1{
  margin-top: 20px;
  width: 100%;
}
.unilever-home-profesional-Top-Container-rectangle-inner-2{
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.home-mentor-unilever-container-global {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.home-mentor-unilever-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 5%;
    width: 100%;
}

.home-mentor-unilever-sub-container {
    width: 74%;
}

/*filtro*/

.home-mentor-unilever-filtro-check-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.home-mentor-unilever-filtro-check-title{
    font-size: 14px;
    font-weight: 400;
    color: #5D5D5D;
}
.home-mentor-unilever-filtro {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 25px;
    width: 24%;
}

.home-mentor-unilever-filtro-titulo {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
}

.home-mentor-unilever-filtro-subtitulo {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.home-mentor-unilever-container-searchbar {
    background: #FFFFFF;
    border-radius: 8px;
    width: 85%;
    padding: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.home-mentor-unilever-button-searchbar {
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 35px;
    width: 105px;
}

.home-mentor-unilever-input-searchbar {
    background: #FFFFFF;
    border: none;
    height: 35px;
    width: 100%;
    text-indent: 40px;
}

.home-mentor-unilever-container-topbar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 30px;
}

.home-mentor-unilever-button-view {
    border: 1px solid #C5C5C5;
    border-radius: 8px;
    background-color: transparent;
    height: 35px;
    width: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-mentor-unilever-button-view-active {
    border: 1px solid #202256;
    border-radius: 8px;
    background-color: #202256;
    height: 35px;
    width: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-mentor-unilever-view-container {
    width: 110px;
}

.home-mentor-unilever-view-subcontainer {
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}



.feedback-unilever-banner{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    
}

.check{
    color: pink;
}
.uncheck{
    color: blue;
}


.font-bold-estudiante{
    font-weight: bold;
    /* position:absolute; */
    /* margin-left:97.5%;
    margin-top:-48px; */
}

.form-container-feedback{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 33px;
}

.container-feedback{
    display: flex;
    margin-top: 3%;
    width: 90%;
    flex-direction: column;
    border-radius: 8px;
    background-color: #ffffff;
    padding:33px 118px 76px;
}

/* form-puntuacion */

.form-puntuacion{
    border:1px solid white;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0px;  
}

.form-puntuacion .MuiSvgIcon-root{
    font-size: 50px;
    color: #202256;
     
}



.puntuacion-card{    
    display:flex;
    align-items: center;
   
}
.text-puntuacion{
    width:100%;
    
}

.form-puntuacion-title{
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    -webkit-filter: grayscale(90%);
            filter: grayscale(90%);

}

.form-puntuacion-selected{
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
}

.sub-title-feedback{
    font-weight: bold;
}

.feedback-descripcion{
    width: 100%;
    height: 188px;
    padding: 11px 34px 40.46px;
}

.button-container-feedback a{
    width: 216px;
    height: 33px;
    background-color: transparent;
    border:1px solid #202256;
    color:#202256;
    border-radius: 5px;
    padding:5.71px 30.38px 7.29px;
    margin-right:26px;
}

.button-container-feedback-send{
    width: 104.51px;
    height: 38px;
    color: white;
    background-color: #202256;
    border-radius: 5px;
    padding:4.71px 6.12px; 
}

.button-container-feedback{
    margin-top:51px;
    
}

.volver-mentoria{ 
    height: 38px;
    background: transparent;
    border: 0.5px solid #202256;
    border-radius: 5px;  
    margin-right: 26px; 
    margin-bottom: 20px;   
}



/* responsive */

@media (max-width: 768px) {
    .container-feedback{
        padding:33px 20px 76px;
    }

    .form-puntuacion{
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
}
.fc .fc-toolbar.fc-header-toolbar {
    display: flex;
    flex-direction: row;
    width: 65%;
    align-self: flex-start;
    margin-bottom: 5px;
}

th {
    background-color: #f9f9f9;
    border: #f9f9f9 !important;
    border-bottom: 1px solid #f9f9f9 !important;
    font-weight: normal;
}

.fc-scrollgrid-sync-inner {
    border-style: none !important;
}

.fc .fc-toolbar.fc-header-toolbar {
    border-style: none !important;
    width: 25%;
    margin-bottom: 20px;
}

.fc-toolbar-chunk {
    border: none !important;
}

.event-background {
    margin: 5px;
    width: 100%;
    background-color: #E5DCF7;
    border-left: 3px solid #202256;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    align-items: flex-start;
    padding: 5px;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
}

.event-mentor {
    font-weight: 300;
    font-size: 12px;
    color: #8875D1;
}

.event-background p {
    overflow: hidden;
}

.buttonUni-busqueda-mentor {
    background-color: #F07130;
    height: 37px;
    width: 200px;
    border-radius: 5px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-weight: normal;
    font-size: 12px;
}

.event-title {
    color: #202256;
    font-weight: 700;
    font-size: 12px;
}

.ContenedorUni-Search-Button-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.ContenedorUni-banner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
}

.img-bannerUni {
    width: 100%;
}

.ContenedorUni-Grande {
    width: 90%;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /*margin-left: 20%;*/
    margin: 5%;
    position: relative;
}

.buttonUni-acceso-meet {
    background-color: #f07130;
    height: 37px;
    width: 100%;
    border-radius: 5px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    margin-top: 20px;
}

.buttonUni-acceso-feedback {
    margin-top: 20px;
    background-color: #27165b;
    height: 37px;
    width: 100%;
    border-radius: 5px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    margin-top: 20px;
}

.titledetalleUni-acceso-meet {
    font-weight: 400;
    font-size: 16px;
    color: #393939;
}

.titlePrincipadetalleUni-acceso-meet {
    font-weight: 700;
    font-size: 20px;
    color: #393939;
}

.ContenedorUni-Search-Button-2 {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.ContenedorUni-busqueda {
    background-color: white;
    height: 35px;
    width: 330px;
    border-radius: 5px;
    border: none;
    font-size: 12px;

    text-indent: 25px;

}

.ContenedorUni-busqueda::-webkit-input-placeholder {
    color: #202256;
    margin-left: 13px;
}

.ContenedorUni-busqueda:-ms-input-placeholder {
    color: #202256;
    margin-left: 13px;
}

.ContenedorUni-busqueda::placeholder {
    color: #202256;
    margin-left: 13px;
}

.ContenedorUni-busqueda:focus {
    border-color: #202256;
    outline: none;
}

.Search-icon-uni {
    font-size: 50px;
    color: #202256;
    position: absolute;
    margin-top: 5px;


}

.fc .fc-view-harness-active > .fc-view {
    background-color: white !important;
}

.event-hour {
    color: #202256;
    font-size: 12px;
    font-weight: 400;
}

.camera-event-background {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: -18px;
}

.fc-col-header {
    font-weight: lighter !important;
    background: transparent !important;
    color: black;

    border-style: none;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
}

:root {
    --fc-event-bg-color: #E5DCF7;
    --fc-event-text-color: #8875D1;
    --fc-event-border-color: #E5DCF7;


}

@media (max-width: 1510px) {

}

@media (max-width: 1430px) {

}

@media (max-width: 1335px) {

}

@media (max-width: 1230px) {

}



@media (max-width: 1058px) {


    .ContenedorUni-busqueda {
        width: 250px;
    }
}

@media (max-width: 980px) {


    .ContenedorUni-busqueda {
        width: 200px;
    }
}

@media (max-width: 795px) {


    .ContenedorUni-busqueda {
        width: 150px;
    }

    .event-img {
        display: none;
    }
}

@media (max-width: 700px) {


    .ContenedorUni-busqueda {
        width: 150px;
    }

    .buttonUni-busqueda-mentor {
        height: 28px;
        width: 130px;
    }

    .event-img {
        display: none;
    }

    .fc .fc-toolbar.fc-header-toolbar{
        width: 100%;
    }

    .event-background{
        margin:0px;
        width:auto;
        border-left: 0px;
        flex-direction: row;
    }
    .camera-event-background{
            width:auto;
    }
}

@media (max-width: 435px) {

}

@media (max-width: 395px) {

    .ContenedorUni-busqueda {
        width: 120px;

    }

    .buttonUni-busqueda-mentor {
        height: 24px;
        width: 90px;

    }
}

/* @media (max-width: 640px){
  .event-img{
      display: none;
  }
  .buttonUni-busqueda-mentor{
  margin-left: 5%;
  }
  .ContenedorUni-busqueda{
      width: 20px;
  }

}
@media (max-width: 530px){
  .ContenedorUni-Search-Button-1{
      width: 20%;
  }
}
@media (max-width: 730px){
.ContenedorUni-Search-Button-1{
  display: flex;
  width: 70%;
  flex-direction: column;
}
}
@media (max-width: 425px) {
  .ContenedorUni-Search-Button-1{
      display: flex;
      width: 80%;
      flex-direction: column;
  }
  .ContenedorUni-Search-Button-2 {
      display: flex;
      width: 100%;
      flex-direction: column;

      margin-top: 10px;
  }
  .event-img{
      display: none;
  }

  .ContenedorUni-busqueda {
      background-color: transparent;
      height: 35px;
      width: 20%;
      border-radius: 5px;
      border-style: solid;
      border-width: 2px;
      border-color: #C5C5C5;
      text-indent: 25px;
      margin-bottom: 20px;
  }
}
@media (max-width: 930px) {
  .ContenedorUni-Search-Button-1{
      flex-direction: column;

      width: 400px;
      margin-left: 10%;
  }
  .buttonUni-busqueda-mentor{
margin-left: 70%;
  }
  .event-img{
      display: none;
  }
  .ContenedorUni-busqueda{
      width: 25%;
      margin-right: 10%;
  }
  .ContenedorUni-Search-Button-2{
      width: 100%;
      margin: auto;
  }
.buttonUni-busqueda-mentor{
  width: 90px;
}
}
*/

.popup-date-unilever-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-date-unilever-inner-1-1{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.popup-date-unilever-inner-1{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-date-unilever-inner-2{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.date-popup-container-big{
  width: 500px;
  height: 800px;
}

.day-tile{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #fe7c7c;
  background-color: #fe7c7c;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
 background: #f8f8fa;
 border-radius: 6px;
}
.react-calendar__tile--now {
  background: transparent !important;
 border-radius: 6px;
 font-weight: normal;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
 border-radius: 6px;
 font-weight: bold;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
 background: #f8f8fa;
}
.react-calendar__tile--active {
 background: #f07130 !important;
 font-weight: bold;
 color: white;
}
.react-calendar__tile:disabled{
    background: #656565;
    font-weight: normal;
    color: #f8f8fa;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
 background: #f07130 !important;
 color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
 background-color: #f8f8fa;
}

.unilever-buttons-hours-grid{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  place-items: center;
  align-items: center;
}
.unilever-select-button-hour{
  border: 1px solid #202256;
  border-radius: 8px;
  width: 100px;
  height: 30px;
  background-color: #E5DCF7;
  color: #202256;
}

.unilever-select-button-hour2{
  border: 1px solid #202256;
  border-radius: 8px;
  width: 100px;
  height: 30px;
  background-color: #202256;
  color: white;
}

.unilever-select-button-hour:hover{
  background-color: #f07130;
  color: white;
}

.unilever-select-button-hour2:hover{
  background-color: #f07130;
  color: white;
}

.unilever-select-button-hour-active{
  border: 1px solid #202256;
  width: 100px;
  height: 30px;
  background-color: #f07130;
  border-radius: 8px;
  color: white;
}
.admin-unilever-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
}
.admin-unilever-card-container{
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 40px;
    height: 80%;
}
.admin-unilever-container-graphs{
  width: 100%;
  display: flex;
    flex-direction: column;
  justify-items: center;
  align-items: center;
}
.admin-unilever-graphs-subcontainer{
  display: flex;
  flex-direction: column;
    margin-top: 5%;
}
.admin-unilever-graphs-column-container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}
.admin-unilever-graphs-row-container{
display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.mentor-users-card{
 display: flex;
  align-items: center;
    padding: 20px;
  justify-content: center;
    flex-direction: column;
    width: 250px;
    height: 60px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.mentor-graphs-card{
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: center;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.mentors-users-card-title{
    font-size: 16px;
    font-weight: 600;
    color: #697a8d;
    margin-bottom: 0;
  align-self: flex-start;
}

.mentors-users-card-quantity{
    font-size: 20px;
    font-weight: 600;
    color: #697a8d;
    margin-bottom: 0;
  align-self: center;
}


.Profesional-Home-Search-admin-unilever {
  background-color: transparent;
  height: 35px;
  width: 100%;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: #C5C5C5;
  text-indent: 25px;
  margin-left: 13px;
}
.Profesional-Home-Search-admin-unilever::-webkit-input-placeholder {
  color: #697a8d;
  margin-left: 13px;
}
.Profesional-Home-Search-admin-unilever:-ms-input-placeholder {
  color: #697a8d;
  margin-left: 13px;
}
.Profesional-Home-Search-admin-unilever::placeholder {
  color: #697a8d;
  margin-left: 13px;
}

.Profesional-Home-Search-admin-unilever:focus {
  border-color: #697a8d;
  outline: none;
}

.container-info-PopUP{
  display:flex;
  align-items: center;
  flex-direction: column;
}

.info-text-popup{ 
  margin-top: 10px;
  font-size:16px;
  width: 100%;
  text-align: center;
}

.pagination-admin{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.info-pagination-admin, .info-pagination-admin h3{
  display: flex;
  flex-direction: row;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom:0px;
  align-items: center;
}

.container-download-info, .container-download-info h3{
  display: flex;
  flex-direction: row;
  justify-content: space-between;  
  align-items: center; 
  width:40%;
  font-size : 16px; 
  margin-bottom:0px;
}
.Admin-Layout-Cont{
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background: #F5F5F5;
}
.Admin-Layout-Sub-Cont{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: #F5F5F5;
}
.Sidebar-Cont-admin {
  background: #fe7c7c;
  width: 237px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all 0.5s ease;
}

.Sidebar-Cont-admin-2{
  background: #FFFFFF;
  width: 237px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all 0.5s ease;
}

.Sidebar-Cont2-admin {
  background: #FFFFFF;
  width: 237px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.Sidebar-Logo-admin {
  width: 100px;
  margin-bottom: 60px;
}

.Sidebar-Sub-Cont-1-admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 72px;
}

.Sidebar-Sub-Cont-2-admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 35px;
}



.Sidebar-Sub-Cont-But-2-admin {
  padding-left: 28.15px;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #C5C5C5;
  width: 90%;
  height: 55px;
  background: transparent;
  margin-bottom: 1.5em;
  margin-left: 50px;
}
.Sidebar-Sub-Cont-But-2-admin:hover{
  text-decoration: none;
  color:#C5C5C5;
} 

.Sidebar-Sub-Cont-But-admin {
  padding-left: 28.15px;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #C5C5C5;
  width: 90%;
  height: 55px;
  background: transparent;  
  margin-left: 50px;
}

.Sidebar-Sub-Cont-But-active-admin {

  padding-left: 28.15px;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #202256;
  width: 90%;
  height: 55px;
  background: #F9F9F9;
  margin-left: 50px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
      
}
.Sidebar-Sub-Cont-But-admin:hover{
  text-decoration: none;
  color:#C5C5C5;
}

.Sidebar-Sub-Cont-But-active-admin:hover{
  text-decoration: none;
  color:#202256;
}

.Sidebar-Sub-Cont-Img-admin {
  margin-right: 15px;
}

.Sidebar-Sub-Cont-2-Special-But-admin {
  background: #F07130;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  height: 35px;
  width: 170px;
  margin-bottom: 50px;
}


@media (max-width: 1300px) {

      .Sidebar-Cont-admin-2{
        background: #fe7c7c;
        transition: 0.5s;
      }

     .Sidebar-Cont2-admin{
      /* display:none; */
      background-color: #202256;
     }

     .Sidebar-Sub-Cont-But-active-admin {

      padding-left: 28.15px;
      font-weight: 400;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      color: #202256;
      width: 80%;
      height: 55px;
      background: #F9F9F9;
      margin-left: 47px;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
          
  }
  .Sidebar-Cont-admin {
      display: none;
  }

}

.card-info-Cantidad{
  width: 320px;
  height: 170px;
  border-radius: 10px;
  display: flex;        
  padding: 20px;
  color: white;
}

.card-info-Cantidad-Icon{
  display: flex;
  align-items: center;
}

.card-info-Cantidad-container{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 4%;
}

.card-info-Cantidad-title{
  font-size: 20px;
  font-weight: 500; 
  text-align: center;   
}

.card-info-Cantidad-container-2{
  height: 100%;
  display: flex;
  align-items: flex-end;

}

.card-info-Cantidad-value{
  font-size: 48px;
  font-weight: 600;    
}
.perfil-prof-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 80px;
}

.perfil-prof-titulo {
    margin-left: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #202256;
}

.perfil-prof-subtitulo {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
    margin-top: 40px;
}

.perfil-prof-footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
    place-content: center;
    grid-gap: 30px;
    margin-top: 32px;
    width: 100%;
}

.perfil-prof-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
}

.perfil-prof-body-izq {
    width: 24%;
    min-height: 10em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.perfil-prof-body-der {
    width: 74%;
}

.perfil-prof-nombre {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
}

.perfil-prof-empleo {
    font-weight: 400;
    font-size: 24px;
    color: #5D5D5D;
}

.perfil-prof-sub-cont {
    margin-top: 30px;
    background: #FFFFFF;
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.04);
    border-radius: 24px;
    padding: 30px 60px 45px 60px;
}

.perfil-prof-sub-subtitulo-coally {
    font-size: 16px;
    font-weight: 700;
    color: #393939;   
}

.perfil-prof-textos {
    text-align: justify;
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.perfil-prof-sub-subtitulo-edu-trabajo {
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.perfil-prof-sub-subtitulo-edu-trabajo2 {
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.perfil-prof-sub-subtitulo-edu-trabajo3 {
    font-weight: 400;
    font-size: 14px;
    color: #C5C5C5;
}

.container-hv-tutorial{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.perfil-tutorial-button {
    font-weight: 700;
    font-size: 14px;
    color: #202256;
    background-color: transparent;
    border: none;
}

.perfil-tutorial-button:hover {
    color: #202256;
    background-color: transparent;
    border: none;
    text-decoration: underline;
}

.perfil-trab-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.perfil-cv-input-contenedor-global {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: 30px;
}

.perfil-cv-advise {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
}

.perfil-cv-advise2 {
    font-weight: 700;
    font-size: 12px;
    color: #202256;
}

.perfil-cv-button-group {
    display: flex;
    /*width: 600px;*/
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}

.perfil-cv-subir {
    margin: 5px;
    background: #202256;
    border-radius: 5px;
    height: 30px;
    width: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.perfil-cv-cancel {
    margin: 5px;
    background: #D6D6D6;
    border-radius: 5px;
    height: 30px;
    width: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.perfil-cv-input-title {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}
.perfil-cv-input-title2 {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-top:10px;
}

.perfil-cv-input-subtitle {
    font-weight: 400;
    font-size: 16px;
    color: #A5A5A5;
}

.perfil-cv-input-contenedor {
    cursor: pointer;
    width: 265px;
    height: 85px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: #E6E6E6;
    border: 1px dashed #989898;
    border-radius: 14px;
    margin-right: 30px;
}

.perfil-cv-input-subcontenedor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #202256;
    border-radius: 14px;
    width: 55px;
    height: 55px;
}


.perfil-cv-input-contenedor2 {
    width: 265px;
    height: 85px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: transparent;
    border: 1px solid #202256;
    border-radius: 14px;
}

.perfil-cv-input-subtitle2 {
    font-weight: 400;
    font-size: 16px;
    color: #202256;
}

.perfil-cv-input-subcontenedor2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F7F2FF;
    border-radius: 14px;
    width: 55px;
    height: 55px;
}


@media (max-width: 699px) {
    .perfil-cv-button-group {
        display: flex;
        width: 100%;
        height: 70px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }

    .perfil-cv-subir {
        background: #202256;
        border-radius: 5px;
        height: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
    }

    .perfil-cv-cancel {
        background: #D6D6D6;
        border-radius: 5px;
        height: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
        color: #5D5D5D;
    }
}

@media (max-width: 500px) {
    .perfil-cv-input-subcontenedor {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #202256;
        border-radius: 14px;
        width: 55px;
        height: 55px;
        margin-right: 20px;
    }
    .perfil-trab-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}

@media (max-width: 1550px) {
    .perfil-prof-body-izq {
        width: 38%;
        min-height: 10em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .perfil-prof-body-der {
        width: 58%;
    }
    .perfil-cv-input-contenedor-global {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .perfil-cv-input-contenedor {
        cursor: pointer;
        width: 265px;
        height: 85px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: #E6E6E6;
        border: 1px dashed #989898;
        border-radius: 14px;
        margin-right: 0px
    }

    .perfil-cv-input-contenedor2 {
        width: 265px;
        height: 85px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: transparent;
        border: 1px solid #202256;
        border-radius: 14px;
    }
}

@media screen and (max-width: 950px) {
    .perfil-prof-body-izq {
        width: 100%;
        min-height: 10em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .perfil-prof-body-der {
        width: 100%;
    }

    .perfil-prof-body {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
    }

    .perfil-prof-body-izq {
        margin-left: -.5em;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
    }

    .perfil-cv-input-contenedor {
        cursor: pointer;
        width: 100%;
        height: 85px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: #E6E6E6;
        border: 1px dashed #989898;
        border-radius: 14px;
    }

    .perfil-cv-input-contenedor2 {
        width: 100%;
        height: 85px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: transparent;
        border: 1px solid #202256;
        border-radius: 14px;
    }

}


.home-unilever-banner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
}

.container-buttons-uni {
    display: flex;
    margin-top: 3%;
    width: 90%;
}

.button-home-unilever {
    width: 324px;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #fff;
    border-radius: 8px;
    margin-right: 30px;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}

.button-home-unilever:hover {
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .container-buttons-uni {
        flex-direction: column;
        align-items: center;
        margin-top: 5%;
    }

    .button-home-unilever {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }
}

/*marcelo css*/

.home-unilever-subtitle {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
}

.home-unilever-carrusel-global-container {
    width: 90%;
}

.home-unilever-carrusel-top {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
    width: 100%;
}

.home-unilever-carrusel-sub-container {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
}

.home-unilever-arrow-button {
    background-color: transparent;
}

@media (max-width: 600px) {
    .home-unilever-carrusel-top {
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        width: 100%;
    }
}
.CarruselCards-global-container {
    background: #FFFFFF;
    border-radius: 8px;
    min-width: 436px;
    height: 337px;
    margin-right: 40px;
    margin-bottom: 40px;
}

.CarruselCards-global-title {
    font-weight: 700;
    font-size: 20px;
    color: #393939;
    margin-bottom: 30px;
}

.CarruselCards-global-desc {
    font-weight: 400;
    font-size: 16px;
    color: #252525;
}

.CarruselCards-image-container {
    background-position: top;
    height: 150px;
    border-radius: 8px 8px 0 0;
    background-repeat: no-repeat;
    background-size: cover;
}
.CarruselCards-video-container{
    background-position: top;
    height: 100%;
    width: 100%;
    border-radius: 8px 8px 0 0;
    background-repeat: no-repeat;
    background-size: cover;
}

/*.CarruselCards-image {
    object-fit: cover;
    width: 100%;
    height: 150px;
    border-radius: 8px 8px 0 0;
}*/

.CarruselCards-sub-container {
    padding: 20px;
}
.unilever-banner-profesional2{
  height: 200px;
  width: 90%;
  background-image: url("https://www.bancolombia.com/wcm/connect/www.bancolombia.com-26918/50a8e180-ff59-460e-84a0-c4df8badea07/01.+HERO_ED_FINANCIERA_DESK+%281%29.png?MOD=AJPERES&CACHEID=ROOTWORKSPACE.Z18_K9HC1202P864E0Q30449MS3000-50a8e180-ff59-460e-84a0-c4df8badea07-npf4Qyt");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.unilever-banner-profesional-inner-1{
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.unilever-banner-profesional-inner-1-1{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.unilever-home-subtitle{
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  padding-left: 80px;
}
.unilever-home-title{
  font-weight: 700;
  font-size: 40px;
  color: #fff;
  padding-left: 80px;
}
.unilever-banner-profesional-inner-2{
  margin-right: 40px;
}

@media(max-width: 768px){
  .unilever-home-title{
    font-size: 26px;
  }
  .unilever-banner-profesional-inner-1-1{
    align-items: center;
  }
}

.loginadmin {
  color: #697a8d;
  height: 100vh;
  padding-top: 10vh;
  margin: auto;
  width: 90%;
}

.loginadmin__form {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  padding: 50px 0px;
}

.loginadmin__input::-webkit-input-placeholder {
  color: #697a8d;
}

.loginadmin__input:-ms-input-placeholder {
  color: #697a8d;
}

.loginadmin__input::placeholder {
  color: #697a8d;
}

.loginadmin__label {
  display: flex;
  flex-direction: column;
}

.loginadmin__logo {
  display: block;
  margin: auto;
  width: 120px;
}

.loginadmin__button {
  align-self: center;
  background-color: #fe7c7c;
  height: 33px;
  width: 118px;
  border-radius: 5px;
  color: #FFFFFF;
  text-align: center;
}

@media (min-width: 576px) {
  .loginadmin {
    width: 60%;
  }
}

@media (min-width: 992px) {
  .loginadmin {
    width: 30%;
  }
}


.card-grupo-preguntas {
    background: #FFFFFF;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px 20px;
}

.card-grupo-preguntas2 {
    background: #FFFFFF;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.input-crear-oportunidad {
    width: 100%;
    background: #FFFFFF;
    height: 40px;
    border: 1px solid #C5C5C5;
    text-indent: 5px;
}
.input-crear-oportunidad::-webkit-input-placeholder {
    color: #C5C5C5;
}
.input-crear-oportunidad:-ms-input-placeholder {
    color: #C5C5C5;
}
.input-crear-oportunidad::placeholder {
    color: #C5C5C5;
}

.text-crear-oportunidad {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    min-height: 160px;
}

.item-crear-oportunidad {
    font-weight: 400;
    font-size: 14px;
    color: #151515;
}

.title-crear-oportunidad {
    font-weight: 700;
    font-size: 14px;
    color: #151515;
    margin-top: 10px;
    margin-bottom: 10px;
}

.contenedor-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 14px;
    color: #151515;
}

.submit-button-crear-oportunidad {
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    height: 40px;
    color: #FFFFFF;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
}

.Obligatorio {
    color: #da781d;
    font-weight: 600;
    font-size: 18px;
}

.banner-crear-oportunidad-st {
    font-weight: 600;
    font-size: 18px;
}

.new-empresa-home-banner{
    width: 100%;
    height: 158px;
    padding: 10px;
    border-radius: 8px 8px 0 0;
    /* background-image: url(https://coally.com/wp-content/uploads/2022/03/banners-landing-1-2.png); */
    background-image: url(/static/media/BannerHome.8f9af2e2.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    text-shadow: black 1px 0 10px;
}

.Range-Ability-Form-Create{   
    margin-left: 10px;   
}

/**/

.control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 16px;
}
.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.control_indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #e6e6e6;
    border: 0px solid #000000;
    border-radius: undefinedpx;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: #cccccc;
}

.control input:checked ~ .control_indicator {
    background: #da781d;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: #0e6647d;
}
.control input:disabled ~ .control_indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: block;
}
.control-radio .control_indicator {
    border-radius: 50%;
}

.control-radio .control_indicator:after {
    left: 0px;
    top: 0px;
    height: 0px;
    width: 0px;
    border-radius: 50%;
    background: #ffffff;
    transition: background 250ms;
}
.control-radio input:disabled ~ .control_indicator:after {
    background: #7b7b7b;
}

.button-add-language{
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 40px;
    width: 170px;
}

.input-add-language{
    width: 25%;
    background: #FFFFFF;
    height: 40px;
    border: 1px solid #C5C5C5;
    text-indent: 5px;
}

.input-add-resp{
    width: 80%;
    background: #FFFFFF;
    height: 40px;
    border: 1px solid #C5C5C5;
    text-indent: 5px;
}

.button-add-resp{
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 40px;
    width: 170px;
}



@media (max-width: 768px) {
    .input-add-language{
        width: 50%;
    }
}

@media(max-width: 620px){
    .new-empresa-home-banner{
        height: 100% !important;
    }
}

.bootcamp-contenedor-title {
    width: 100%;
    margin-bottom: 50px;
}

.bootcamp-subtitle {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
}
.item-inactive {
    background-color: #C5C5C5;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    text-align: center;
}

.item-inactive-indigo {
    background-color: #202256;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    text-align: center;
}

.item-inactive-orange {
    background-color: #F07130;;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    text-align: center;
}

.item-inactive-aura {
    background-color: #E6E6E6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 85px;
    height: 85px;
    text-align: center;
    border-radius: 50%;
    margin-top: -42.5px;
}

.item-inactive-aura-indigo {
    background-color: #8875D1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 85px;
    height: 85px;
    text-align: center;
    border-radius: 50%;
    margin-top: -42.5px;
}

.item-inactive-aura-orange {
    background-color: #FE994A;;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 85px;
    height: 85px;
    text-align: center;
    border-radius: 50%;
    margin-top: -42.5px;
}

.item-inactive2 {
    background-color: #C5C5C5;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    text-align: center;
    text-overflow-ellipsis: 1;
}

.item-inactive2-indigo {
    background-color: #202256;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    text-align: center;
    text-overflow-ellipsis: 1;
}

.item-inactive2-indigo-mini {
    background-color: #202256;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.item-inactive-orange-mini {
    background-color: #F07130;;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.item-inactive-indigo-mini {
    background-color: #202256;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.item-inactive-aura-indigo-mini {
    background-color: #8875D1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
}

.item-inactive-aura-orange-mini {
    background-color: #FE994A;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
}


.item-inactive3 {
    background-color: transparent;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    text-align: center;
    text-overflow-ellipsis: 1;
}


.container-snake-1 {
    display: flex;
    justify-content: space-between;
    margin-right: 3%;
    margin-left: 5%;
    height: 210px;
    padding-left: 0;
    padding-right: 5%;
    border-radius: 0 10px 10px 0;
    border-top: solid 1px #989898;
    border-right: solid 1px #989898;
    border-bottom: solid 1px #989898;
}

.container-snake-LINE {
    display: flex;
    justify-content: space-between;
   /* margin-right: 3%;
    margin-left: 5%;*/
    height: 210px;
    padding-left: 0;
    /*padding-right: 5%;*/
    border-radius: 0 10px 10px 0;
    border-top: solid 1px #989898;
    /*border-right: solid 1px #989898;*/
    /*border-bottom: solid 1px #989898;*/
}

.container-snake-2 {
    display: flex;
    margin-top: -1px;
    justify-content: space-between;
    margin-right: 5%;
    margin-left: 3%;
    height: 210px;
    padding-left: 5%;
    padding-right: 3%;
    border-radius: 10px 0 0 10px;
    border-top: solid 1px #989898;
    border-left: solid 1px #989898;
    border-bottom: solid 1px #989898;
}

.container-snake-3 {
    display: flex;
    margin-top: -1px;
    justify-content: space-between;
    margin-right: 5%;
    margin-left: 3%;
    height: 200px;
    padding-left: 5%;
    padding-right: 0;
}

.item-inactive-text {
    margin-top: 140px;
    font-weight: 250;
    font-size: 11px;
    color: #C5C5C5;
}
 .item-title-responsive-list{
     text-align: center;
     color: rgba(21, 21, 21, 0.99);
     font-weight: 700;
     font-size: 14px;
     /*line-height: 0;*/
     margin-bottom: 0;
 }

.item-title-responsive-list-number{
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 14px;
    /*line-height: 0;*/
    margin: 0;
}

.item-title-responsive-list-number-snake{
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 16px;
    /*line-height: 0;*/
    margin: 0;
}
.hoja {
  height: 21cm;
  width: 29.7cm;
  border: 1px solid #D3D3D3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.marco {
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 92%;
  height: 89%;
}

.imagen {
  position: absolute;
  top: 40px;
  left: 40px;
  margin-top: -1px;
  margin-left: -1px;
}

.contenedor-firmas {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 30px;
  /*height: 100%;*/
}

.contenedor-firmas2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 100px;
}

.underline {
  width: 100%;
  height: 1px;
  background-color: black;
  margin-top: -15px;
  margin-bottom: 30px;
}

.fecha {
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
}

.subline-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  margin-top: -25px;
  text-align: center;
}

.subline-text2 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: -10px;
  text-align: center;
}

.contenedor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 100px;
}

.detalle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.detalle2 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: -20px;
}

.estudiante {
  font-weight: 700;
  font-size: 45px;
  font-style: italic;
}

.bootcamp-title {
  font-weight: 400;
  font-size: 30px;
}

.titulo-certif {
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  /*text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);*/
  margin-top: -5px;
}
.bootcamp-home-administracion-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0 50px 5% 50px;
}

.bootcamp-home-administracion-banner{
    width: 100%;
    height: 150px;
    padding: 10px;
    border-radius: 8px;
    /* background-image: url(https://coally.com/wp-content/uploads/2022/03/banners-landing-1-2.png); */
    background-image: url(/static/media/businessbanner.52d5f844.png);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: white;
    text-align: center;
    flex-direction: row;
}

.bootcamp-home-administracion-description-card{
    text-align: center;
    display: flex;
    align-self: flex-start;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 2%;
}

.bootcamp-home-administracion-videos-cards{
    display: grid;
    margin-bottom: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
    width: 100%;
    place-content: center;
    grid-gap: 20px;
    justify-items: center;
    align-items: center;
    padding-top: 2%;
}

.bootcamp-home-administracion-cursos-buttons{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    margin-top: 2%;
}

.bootcamp-home-administracion-button-courses{
    width: 150px;
    height: 33px;
    border-radius: 10px;
    background-color: #202256;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    transition: all 0.3s ease;
}

.bootcamp-home-welcome-video{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.bootcamp-home-administracion-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;    
}

/*de aca para abajo es mio*/

.bootcamp-home-administracion-titles{
    font-weight: 700;
    font-size: 24px;
    display: flex;
    align-items: flex-end;
    color: #393939;
}

.bootcamp-home-administracion-desc{
    font-weight: 400;
    font-size: 14px;
    color: #C5C5C5;
}

.bootcamp-home-administracion-home-subcont1{
    margin-top: 65px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.bootcamp-home-administracion-home-subcont1-1{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    margin: 0 10px;
}

.bootcamp-home-administracion-footer{
    padding: 10px;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 45px 0;
    background: #FFFFFF;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

@media screen and (max-width: 1681px) {
    .bootcamp-home-administracion-home-subcont1{
        flex-direction: column;
    }
    .bootcamp-home-administracion-home-subcont1-1{
        width: 100%;
        margin-bottom: 20px;
    }
}


@media screen and (max-width: 1300px) {
    .bootcamp-home-administracion-container{
        padding: 0;
    }
}

@media screen and (max-width: 1000px) {
    .bootcamp-home-administracion-description-card{
        flex-direction: column;
    }
}

@media screen and (max-width: 450px) {
    .bootcamp-home-administracion-banner{
        justify-content: center;
    }
    .bootcamp-home-administracion-buttons{
        width: 95%;
    }
}
.welcome-courses-card-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 324px;
    height: 300px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 10%;
    transition: all 0.3s ease;
}

.welcome-courses-card-container:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: 0.5s;
}

.welcome-courses-card-container:hover .welcome-courses-card-title-container {
    background-color: #202256;
    transition: 0.5s;
}

.welcome-courses-card-container:hover .card-course-bootcamp-title {
    color: white;
    transition: 0.5s;
}

.card-course-bootcamp-descr {
    font-size: 12px;
    color: #393939;
    text-align: justify;
    margin: 10px 5px;
    align-self: center;
    transition: all 0.5s;
}

.welcome-courses-card-button{
    font-weight: 400;
    width: 125px;
    height: 30px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #202256;
    margin-bottom: 10px;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #202256;
}

.welcome-courses-card-container:hover .card-course-bootcamp-descr {
    color: white;
    transition: 0.5s;
}

.welcome-courses-card-container:hover .welcome-courses-card-button {
    color: white;
    transition: 0.5s;
    border: 1px solid white;
}

.welcome-courses-card-title-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    transition: all 0.3s ease;
    border-radius: 0 0 8px 8px;
}

.card-course-bootcamp-title {
    font-size: 18px;
    font-weight: bold;
    color: #393939;
    text-align: center;
    padding: 10px;
    align-self: center;
    transition: all 0.5s;
    margin-bottom: 5px;
}

.bootcamp-modal-precourses-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.bootcamp-modal-precourses-card-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90px;
    align-items: center;
    justify-content: space-between;
}
.bootcamp-modal-precourses-card-title-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
    padding: 0 10px 0 10px;
}
.bootcamp-modal-precourses-card-title{
    font-size: 1em;
    font-weight: bold;
    color: #393939;
    text-align: left;
    padding: 10px;
    align-self: flex-start;
    transition: all 0.5s;
}

.bootcamp-modal-precourses-card-image-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
}

.bootcamp-modal-precourses-card-image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #393939;

}

.bootcamp-modal-precourses-card-image-2{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #27165b;
    border: 1px solid #393939;
}
.bootcamp-modal-precourses-card-video-button{
    width: 118px;
    height: 33px;
    border-radius: 10px;
    background-color: #202256;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    transition: all 0.3s ease;
}

.bootcamp-modal-precourses-card-video-button:hover{
    background-color: #F07130;
    transition: 0.5s;
}

.bootcamp-modal-precourses-card-file-button{
    width: 118px;
    height: 45px;
    border-radius: 10px;
    background-color: #202256;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    transition: all 0.3s ease;
}

.bootcamp-modal-precourses-card-file-button:hover{
    background-color: #F07130;
    transition: 0.5s;
}
.Bootcamp-Test-Card-container {
    display: flex;
    background-color: #FFFFFF;
    border-radius: 16px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 330px;
    margin-top: 22px;
    margin-right: 18px;
    padding: 17px 20px 8px 16px;
}

.Bootcamp-Test-Card-container:hover {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25);
    border: 1px solid #202256;
    cursor: pointer;
  }
  

.Bootcamp-Test-Card-sub-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 15px;
}

.Bootcamp-Test-Card-Title{
    font-weight: 700;
    font-size: 14px;
    color: #393939;
}

.Bootcamp-Test-Card-P{
    font-weight: 300;
    font-size: 14px;
    color: #5D5D5D;
    margin-top: 10px;
}
.container-tests{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.container-back-tests{
    width: 100%;
    color: #202256;
    display: flex;
    margin-bottom: 5%;
    align-items: center;
    justify-content: space-between;
}

.Bootcamp-Subcontainer-Tests{
    width:100%;
    height: 500px;
    overflow-y: scroll;
}

.container-global-bootcamp{
     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.container-div-bootcamp{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 50%;
    min-width: 500px;   
    background-color: white;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 10%) 0px 7px 32px;
}

.container-form-bootcamp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.button-close-form-bootcamp{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #202256;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    margin-top: 9px;
    margin-left: 11px;
    background-color: transparent;
    border: 2px solid #202256;
}

.div-title-form-bootcamp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    width: 60%;
    minWidth: 500px;
}

.button-close-form-bootcamp:hover{
    background-color: #202256;
    color: white;
}

.checkbox-bootcamp{
    display: flex;
    justify-content: space-between;
    width: 25%;
    margin-bottom:1em;
}

.checkbox-bootcamp label{
    margin:0px;
    display: flex;
    align-items: center;
}

.button-submit-bootcamp{
    width: 25%;
    height: 30px;
    border-radius: 5px;
    background-color: #202256;
    color: white;
    border: none;
    margin-bottom: 1em;
}

@media screen and (max-width: 768px) {
    .container-div-bootcamp{
        width: 95%;
        min-width: auto;
    }
    .div-title-form-bootcamp{
        min-width:auto;
        width:100%;
    }
}
.card-grupo-preguntas {
    background: #FFFFFF;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px 20px;
}

.card-grupo-preguntas2 {
    background: #FFFFFF;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.input-crear-oportunidad {
    width: 100%;
    background: #FFFFFF;
    height: 40px;
    border: 1px solid #C5C5C5;
    text-indent: 5px;
}
.input-crear-oportunidad::-webkit-input-placeholder {
    color: #C5C5C5;
}
.input-crear-oportunidad:-ms-input-placeholder {
    color: #C5C5C5;
}
.input-crear-oportunidad::placeholder {
    color: #C5C5C5;
}

.text-crear-oportunidad {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    min-height: 160px;
}

.item-crear-oportunidad {
    font-weight: 400;
    font-size: 14px;
    color: #151515;
}

.title-crear-oportunidad {
    font-weight: 700;
    font-size: 14px;
    color: #151515;
    margin-top: 10px;
    margin-bottom: 10px;
}

.contenedor-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 14px;
    color: #151515;
}

.submit-button-crear-oportunidad {
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    height: 40px;
    color: #FFFFFF;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
}

.Obligatorio {
    color: #da781d;
    font-weight: 600;
    font-size: 18px;
}

.banner-crear-oportunidad-st {
    font-weight: 600;
    font-size: 18px;
}

.new-empresa-home-banner{
    width: 100%;
    height: 158px;
    padding: 10px;
    border-radius: 8px 8px 0 0;
    /* background-image: url(https://coally.com/wp-content/uploads/2022/03/banners-landing-1-2.png); */
    background-image: url(/static/media/BannerHome.8f9af2e2.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    text-shadow: black 1px 0 10px;
}



/**/

.control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 16px;
}
.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.control_indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #e6e6e6;
    border: 0px solid #000000;
    border-radius: undefinedpx;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: #cccccc;
}

.control input:checked ~ .control_indicator {
    background: #da781d;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: #0e6647d;
}
.control input:disabled ~ .control_indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: block;
}
.control-radio .control_indicator {
    border-radius: 50%;
}

.control-radio .control_indicator:after {
    left: 0px;
    top: 0px;
    height: 0px;
    width: 0px;
    border-radius: 50%;
    background: #ffffff;
    transition: background 250ms;
}
.control-radio input:disabled ~ .control_indicator:after {
    background: #7b7b7b;
}

.button-add-language{
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 40px;
    width: 170px;
}

.input-add-language{
    width: 25%;
    background: #FFFFFF;
    height: 40px;
    border: 1px solid #C5C5C5;
    text-indent: 5px;
}

.input-add-resp{
    width: 80%;
    background: #FFFFFF;
    height: 40px;
    border: 1px solid #C5C5C5;
    text-indent: 5px;
}

.button-add-resp{
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 40px;
    width: 170px;
}

@media (max-width: 768px) {
    .input-add-language{
        width: 50%;
    }
}
.Container-Global-Pruebas{
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
}

.title-pruebas-tec{
    color: #202256;
    font-size: 40px;
    font-weight: 700;
}
.subtitle-pruebas-tec{
    color:  #393939;
    font-size: 24px;
    font-weight: 700;
}
.textarea-pruebas-tec{
    width: 100%;
    height: 100px;
    border: 1px solid #202256;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    color: #202256;
    resize: vertical;
}

.container-add-test{
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    
}

.text-add-test{    
    text-align: end;
    font-size: 20px;
    color: #F07130;
}

.button-cerrar-popup{    
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 184px;
    height: 28px;
    font-size: 16px; 
    background: #202256;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;     
}

.button-Abrir-popup{    
    display: flex;
    justify-content: center;
    align-items: center;
    width: 184px;
    height: 40px;
    font-size: 16px; 
    background: #F07130;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;     
}

.upload-test-input-contenedor{
    border: 0.5px solid #202256;
    border-radius: 5px;   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 160px;
}

.text-popup-upload{
    font-size: 14px;
    color: #202256;
    margin: 0;
}

.container-popup-prueba{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    flex-direction: column;
}



@media (max-width: 768px) {
    .Container-Global-Pruebas{
        padding-left: 0px;
        padding-right: 0px;
    }
    .container-popup-prueba{
        padding:20px;
    }
    .css-7wek2j{
        width:380px !important;
    }
}
.navbar-sidebar-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    /*    este z index podría ser 1 pero es 4 por el calendario lo pisa*/

}

.navbar-userInfo-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 105px;
    right: 0;
    z-index: 10;
}

.navbar-userInfo-sub-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /*width: 100%;*/
    /*height: 100%;*/
    position: absolute;
    /*top: 105px;*/
    right: 150px;
    z-index: 1;


}

.navbar-close-button {
    background-color: transparent;
    margin-left: 180px;
    margin-top: 10px;
    position: relative;
    color: #f9f9f9;
    font-weight: 300;
    font-size: 40px;
    z-index: 2;
}

.navbar-close-button2 {
    display: flex;
    background-color: white;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: solid 1px black;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 15%;
}

.navbar-sidebar-container2 {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    background: #E6E6E6;
    mix-blend-mode: normal;
    opacity: 0.8;
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
}

.navbar-global-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: all 0.5s ease;
}

.navbar-container {
    height: 100px;
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.navbar-container2 {
    height: 100px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.navbar-linea {
    height: 1px;
    width: 95%;
    background-color: #D6D6D6;
}

.navbar-container-sub {
    width: 180px;
    margin-right: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /*    esto con la campanta comentada es space between*/
}

.navbar-orange-circle-universitie {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
}

.navbar-container-sub-sub {
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    justify-content: space-between;
    width: 80px;
    /*margin-right: 60px;*/
    /*    esto de arriba con la campana se tiene que descomentar*/
}

.navbar-container-sub-sub2 {
    cursor: pointer;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 40px;
    height: 20px;
    margin-left: 60px;
}

.lang-active {
    font-weight: 700;
    font-size: 18px;
    color: #202256;
    background-color: transparent;
}

.lang-unactive {
    font-weight: 300;
    font-size: 18px;
    color: #C5C5C5;
    background-color: transparent;
}




.navbar-linea-vert {
    background-color: #D6D6D6;
    width: 1px;
    height: 60px;
}

.burguer-tile {
    height: 2px;
    width: 100%;
    background-color: #202256;
}

@media (max-width: 1300px) {
    .navbar-container-sub-sub2 {
        display: flex;
    }

    .navbar-container {
        height: 100px;
        width: 100%;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

@media (max-width: 430px) {
    .navbar-container-sub-sub2 {
        cursor: pointer !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: space-between !important;
        width: 40px !important;
        height: 20px !important;
        margin-left: 0 !important;
    }
    .navbar-container-sub {
        width: 180px !important;
        margin-right: 0 !important;
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: space-evenly !important;
    }
    .navbar-userInfo-sub-container {
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        z-index: 1 !important;
        position: static !important;
        position: initial !important;
        right: 0 !important;
        top: 0 !important;
    }
}
@media (max-width: 425px) {
    .navbar-linea-vert {
        display: none;
    }

    .navbar-container-sub {
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 10%;

    }

    .navbar-container-sub-sub2 {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 40px;
        height: 20px;
        margin-left: 10%;
    }

    .navbar-container-sub-sub {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 80px;
        margin-right: 20%;
        /*    esto de arriba con la campana se tiene que descomentar*/
    }

    .navbar-userInfo-sub-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 90%;
        position: absolute;
        right: 0;
        z-index: 1;
    }
}

.contenedor-imagen-company-nav {
    width: 55px;
    height: 55px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contenedor-imagen-company-nav img {
    width: 100%;
}


.Sidebar-Cont {
    background: #e0023c;
    width: 237px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}
.Sidebar-Cont2 {
    background: #FFFFFF;
    width: 237px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.Sidebar-Logo {
    width: 180px;
    margin-bottom: 60px;
}

.Sidebar-Sub-Cont-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 72px;
}

.Sidebar-Sub-Cont-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 35px;
}



.Sidebar-Sub-Cont-But-2 {
    padding-left: 28.15px;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    width: 90%;
    height: 55px;
    background: transparent;
    margin-bottom: 1.5em;
    margin-left: 50px;
}
.Sidebar-Sub-Cont-But-2:hover{
    text-decoration: none;
    color:white;
} 

.Sidebar-Sub-Cont-But {
    padding-left: 28.15px;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    width: 90%;
    height: 55px;
    background: transparent;  
    margin-left: 50px;
}

.Sidebar-Sub-Cont-But-active {
  
    padding-left: 28.15px;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #202256;
    width: 90%;
    height: 55px;
    background: #F9F9F9;
    margin-left: 50px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
        
}
.Sidebar-Sub-Cont-But:hover{
    text-decoration: none;
    color:white;
}

.Sidebar-Sub-Cont-But-active:hover{
    text-decoration: none;
    color:#202256;
}

.Sidebar-Sub-Cont-Img {
    margin-right: 15px;
}

.Sidebar-Sub-Cont-2-Special-But {
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 35px;
    width: 170px;
    margin-bottom: 50px;
}


@media (max-width: 1300px) {
    
       .Sidebar-Cont2{
        /* display:none; */
        background-color: #202256;
       }

       .Sidebar-Sub-Cont-But-active {
  
        padding-left: 28.15px;
        font-weight: 400;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: #202256;
        width: 80%;
        height: 55px;
        background: #F9F9F9;
        margin-left: 47px;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
            
    }
    .Sidebar-Cont {
        display: none;
    }

}
.SidebarCV-Button {
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 35px;
    width: 170px;
    margin-bottom: 50px;
}

.SidebarCV-cont {
    width: 100%;
}

.SidebarCV-Err {
    margin-top: 15px;
    color: #5D5D5D;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}

.SidebarCV-LI {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-LI-2 {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E5DCF7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
}

.SidebarCV-LI-Err{
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E71C00;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV {
    display: flex;
    cursor: pointer;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV-disabled {
    display: flex;
    cursor: not-allowed !important;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #868686;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-Sub-Cont {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 45px;
}

.SidebarCV-Sub-Text {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #5D5D5D;
}

.SidebarCV-Sub-Cont-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 45px;
}
.SidebarCV-Button {
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 35px;
    width: 170px;
    margin-bottom: 50px;
}

.SidebarCV-Button-Dis {
    cursor: not-allowed !important;
    background: #D6D6D6;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    height: 35px;
    width: 170px;
    margin-bottom: 50px;
}

.SidebarCV-cont {
    width: 100%;
}

.SidebarCV-Err {
    margin-top: 15px;
    color: #5D5D5D;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}

.SidebarCV-LI {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-LI-2 {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E5DCF7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
}

.SidebarCV-LI-Err{
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E71C00;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV {
    display: flex;
    cursor: pointer;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV-disabled {
    display: flex;
    cursor: not-allowed !important;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #868686;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-Sub-Cont {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 45px;
}

.SidebarCV-Sub-Text {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #5D5D5D;
}

.SidebarCV-Sub-Cont-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 45px;
}
.UserInfo-global-container {
    background: #FFFFFF;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 5px;
   
}

.UserInfo-name {
    margin-top: 15px;
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.UserInfo-separator {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 90%;
    height: 1px;
    background-color: #E5E5E5;
}

.UserInfo-button-pricing {
    color: #202256;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #202256;
    border-radius: 5px;
    width: 190px;
}
.UserInfo-button-pricing:hover{
    background: #202256;
    color: #FFFFFF;
}

.UserInfo-plan{
    font-weight: 700;
    font-size: 14px;
    color: #202256;
}

.UserInfo-image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 140px;
    
    border-radius: 50%;
    margin-bottom: 30px;
}
.UserInfo-image-container div div{
    
    margin-left:.1em;
}

.container-viewProfile{
    min-width: 105px;
    color: #202256;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);   
}
.container-viewProfile:hover {   
   -webkit-filter: grayscale(0%);   
           filter: grayscale(0%); 
   color: #202256; 
   font-weight: 500;
   stroke-width: 1.5px; 
}

.container-viewProfile svg{
    stroke: #202256;
}





a:hover{
    cursor:pointer;
}

@media (max-width: 425px) {
    .UserInfo-global-container {
        width: 100%;
    }
}




/* Card de consultor */

.container-card-data{
    
    padding: 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}


.Data_usuario{
   
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top:-1em;
    
    width:-webkit-fit-content;
    
    width:-moz-fit-content;
    
    width:fit-content;
    padding: 5px;
}

.contenedor-iner-data{
    
    width:100%;
    margin-bottom:30px;
    margin-left:20px;
}

.nombre{
    font-weight: bold;
    margin-bottom:2em;
}

.TituloDesc{
 color:#3d3c60;
 font-weight: bold;
}

.TextoInterno{
color:#737373;
margin-top: -1em;
}

.Idioma{
    
    display:flex;
    align-items:center;
    justify-content: flex-start;
    
}

.Idioma p{
    margin-right: .5em;
    
}
.herre{
    color: #ececec;
    width:100%;
    /* margin-left:5%; */
}

.img-company-perfil{
    border-radius: 50%;
    width:138.94px;
    height: 138.94px;
    background-color: #f07130;
}



a:hover{
    cursor:pointer;
}

@media (max-width: 425px) {
    .UserInfo-global-container {
        width: 100%;
    }
}




/* Card de consultor */

.container-card-data{
    
    padding: 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}


.Data_usuario{
   
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top:-1em;
    
    width:-webkit-fit-content;
    
    width:-moz-fit-content;
    
    width:fit-content;
    padding: 5px;
}

.contenedor-iner-data{
    
    width:100%;
    margin-bottom:30px;
    margin-left:20px;
}

.nombre{
    font-weight: bold;
    margin-bottom:2em;
}

.TituloDesc{
 color:#3d3c60;
 font-weight: bold;
}

.TextoInterno{
color:#737373;
margin-top: -1em;
}

.Idioma{
    
    display:flex;
    align-items:center;
    justify-content: flex-start;
    
}

.Idioma p{
    margin-right: .5em;
    
}
.herre{
    color: #ececec;
    width:100%;
    /* margin-left:5%; */
}

.img-company-perfil{
    border-radius: 50%;
    width:138.94px;
    height: 138.94px;
    background-color: #f07130;
}
.sectioncards-card1-home {
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2rem;
}

.section-three-home {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.section-one {
    display: flex;
    justify-content: space-between;
}

.input-contenedor {
    background-color: #f6f6f5;
    border-radius: 10px;
    padding: 3px 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 1rem;
}

.input {
    border: none;
    border-style: none;
    box-shadow: none;
    flex: 1 1;
    padding: 10px;
    background: transparent;
    outline: none;
    font-size: 1rem;
    color: #c4c4c4;
}

.section-two {
    display: flex;
    margin-top: 1rem;
}

.section-two p {
    color: #c4c4c4;
    font-size: 1rem;
    padding: 0;
    margin: 0 0.2rem;
}

.section-two p:last-child {
    color: black;
}


.section-three-home .cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.section-three-home .card {
    width: 20rem;
    box-shadow: -2px 2px 7px -3px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -2px 2px 7px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -2px 2px 7px -3px rgba(0, 0, 0, 0.75);
    padding: 1rem;
    border-radius: 10px;
    margin-right: 2rem;
    height: 100%;
}

.section-three-home .card .card-header {
    height: 5rem;
    margin-bottom: 1rem;
    background-color: #f3f3f3;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.section-three-home .card .card-body a {
    color: #c4c4c4;
}

.section-three-home .card .card-footer {
    margin-top: 1rem;
}

.section-three-home .card .card-footer button {
    font-size: 1rem;
    padding: 0.3rem 0.6rem;
    font-weight: 550;
    box-shadow: none;
    border: none;
}

.section-three-home .card2 {
    display: flex;
    background-color: #e6e7e8;
    border-radius: 10px;
    padding: 3rem 5rem;
}

.section-three-home .card2 .card-item-left {
    margin-right: 1rem;
}

.section-three-home .card2 .card-item-rigth {
    margin-left: 1rem;
}

.section-four {
    margin-top: 2rem;
}

.section-four .tab-header {
    border-bottom: 1px solid #52575c;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section-four .tab-header a {
    text-decoration: none;
    cursor: pointer;
}

.section-four .tab {
    margin-right: 15rem;
}

.section-four .tab-header .tab:last-child {
    flex: 1 1;
}

.section-four .tab-active {
    border-bottom: 5px solid #52575c;
}

.section-four .tab-body {
    padding: 1rem;
}

.section-four .tab-body .card-body {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    align-items: center;
    box-shadow: -1px 0px 13px -7px rgba(0, 0, 0, 0.75);
    margin-bottom: 1rem;
}

.section-four .tab-body .card-body .card-item-one {
    height: 5rem;
    width: 5rem;
    background-color: #f3f3f3;
    margin-right: 1.5rem;
    border-radius: 10px;
}

.section-four .tab-body .card-body .card-item-two {
    flex: 1 1;
}

.section-four .tab-body .card-body .card-item-three {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13%;
}

.section-four .tab-body .card-body .card-item-three a {
    color: #c4c4c4;
}

.sectioncards-cards {
    display: flex;
    width: 100%;
}

.card2 {
    width: 25%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.contenedor-oportunidad {
    display: flex;
    z-index: 100;
}

.input {
    margin-top: 0 !important;
}

.contenedor-formacion-oportunidades {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start

}

.contenedor-oportunidades {
    width: 613px;
    margin-right: 33px;
}

.card-contenedor {
    display: flex;
    flex-direction: row;
    background-color: white;
    height: 144px;
    width: 100%;
    margin-bottom: 21px;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 8px;
}

@media (max-width: 1100px) {
    .contenedor-formacion-oportunidades {
        flex-direction: column-reverse;
        align-items: center

    }

    .contenedor-oportunidades {
        width: 90vw;
        margin-right: 0;
    }
}

.card-contenedor-div-img {
    display: flex;
}

.card-contenedor-img-img {
    object-fit: cover;
    border-radius: 8px;
    width: 140px;
    height: 144px;
}

.card-contenedor-habilidades {
    height: 48px;
}

.card-contenedor-botones {
    display: flex;
    margin-top: -2%;
    width: 100%;
    flex-direction: row-reverse;
}

.videos-contenedor {
    margin-top: 2rem;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-evenly;
}

.card-formacion-contenedor {
    width: 360px;
    height: 407px;
    max-width: 360px;
    max-height: 407px;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    margin-bottom: 51px;
}

.selector-home-consultor-style {
    background-color: transparent;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    height: 35px;
    border-color: #C5C5C5;
    width: 33.33%;
    margin-bottom: 10px;
}

.selector-home-consultor-style2 {
    background-color: transparent;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    height: 35px;
    border-color: #C5C5C5;
    width: 40%;
    margin-bottom: 10px;
}

.input-home-consultor-style {
    color: black;
    padding-left: 10%;
    background-color: transparent;
    height: 35px;
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: #C5C5C5;
    margin-bottom: 10px;
}

.searchabar-style {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 725px) {
    .selector-home-consultor-style {
        width: 100%;
    }
    .selector-home-consultor-style2 {
        width: 100%;
    }

    .input-home-consultor-style {
        width: 100%;
    }

    .card-contenedor {
        flex-direction: column;
        padding: 0px 10px 10px 10px;
        height: 100%;
    }

    .searchabar-style {
        flex-direction: column;
    }

    .card-contenedor-div-img {
        justify-content: center;
    }

    .card-contenedor-img-img {
        width: 100%;
    }

    .card-contenedor-habilidades {
        height: 100%;
    }

    .tertiary-button-card {
        margin-right: 0 !important
    }

    .card-contenedor-botones {
        justify-content: space-around;
    }

    .paginateStyle li {
        padding: 0 !important;
        margin: 0.4em !important;
    }

    .paginateStyle {
        width: 100% !important;
        display: flex !important;
        justify-content: space-between !important;
        padding: 0 !important;
        align-items:center;
    }

    .videos-contenedor {
        flex-direction: column;
        justify-content: space-evenly;
    }

    .videos-contenedor iframe {
        width: 87vw;
    }

    .card-formacion-contenedor {
        width: 100%;
    }
}
.home-card-body {
    box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.1);
}

/*botón primario*/
.primary-button-card {
    background-color: #202256;
    border-radius: 5px;
    width: 140px;
    height: 28px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins';
}


.primary-button-card:hover {
    font-weight: 700;
    color: white;
}

.primary-button-card:active {
    font-weight: 700;
    color: white;
    background-color: #0D0033;
}

/*botón terciario*/
.tertiary-button-card {
    background-color: transparent;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    color: #C5C5C5;
    width: 80px;
    height: 20px;
    margin-right: 170px;
}

.tertiary-button-card:hover {
    color: #151515;
}

.primary-button-card:active {
    color: #151515;
}

.box-tertiary-button-card {
    background-color: transparent;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    color: #C5C5C5;
    width: 80px;
    height: 20px;
}

.box-tertiary-button-card:hover {
    color: #151515;
}

.box-primary-button-card {
    background-color: #202256;
    border-radius: 5px;
    width: 87px;
    height: 28px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins';
}

.box-primary-button-card:hover {
    font-weight: 700;
    color: white;
}

.box-primary-button-card:active {
    font-weight: 700;
    color: white;
    background-color: #0D0033;
}

.Profesional-Home-Contenedor-Global {
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
}

.Profesional-Home-container-inner {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
}

.Profesional-Home-Contenedor-1-new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.Profesional-Home-Contenedor-2-new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.Profesional-Home-Contenedor-3-new {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 32px;
}

.Profesional-Home-Contenedor-3-inner-1 {
    width: 70%;
    display: flex;
    margin-left: 30px;
    background-color: white;
    padding: 15px;
}

.Profesional-Home-Contenedor-3-inner-2 {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 30px;
    border-bottom: 1px solid #D6D6D6;
    cursor: pointer;
}

.Profesional-Home-Contenedor-3-inner-2-1 {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #C5C5C5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}

.Profesional-Home-Contenedor-3-inner-2-1-active {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #202256;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}

.Profesional-Home-Contenedor-3-inner-2-2 {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #C5C5C5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 18px;
    margin-bottom: 15px;
    cursor: pointer;
}

.Profesional-Home-Contenedor-3-inner-2-2-active {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #202256;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 18px;
    margin-bottom: 15px;
    cursor: pointer;
}

.Profesional-Home-Contenedor-4-new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 32px;
    width: 100%;
}

.Profesional-Home-Contenedor-4-new-grid {
    /* auto grid columns */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
    place-content: center;
    grid-gap: 30px;
    margin-top: 32px;
    width: 100%;
}

.new-home-profesiona-bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    width: 100%;
    padding-bottom: 40px;
}

.new-home-profesional-bottom-container-carousel {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

}

.new-home-profesional-bottom-container-carousel-title {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.new-home-profesional-bottom-container-carousel-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 32px;
}

.new-home-profesional-bottom-container-tour {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 32px;

    background-color: #33307F;
    border-radius: 20px;
}

.tour-button-container {
    padding: 20px;
    width: 100%;
}

.tour-button-activate {
    border-radius: 5px;
    background-color: #FFFFFF;
    color: #202256;
    font-size: 14px;
    font-weight: 400;
    height: 32px;
    width: 175px;
}

.tour-circle-container {
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    bottom: 0;
}

/* .tour-circle{
    width: 100px;
     height: 10px;
     background: #202256;
     border-radius: 100px 0 20px 0;
     -moz-border-radius: 100px 0 20px 0;
     -webkit-border-radius: 100px 0 20px 0;
} */


.Profesional-Home-Contenedor-Videos {
    width: 100%;
    place-content: center;
    align-content: center;
    align-items: center;
    justify-content: start;
}


.Profesional-Home-Title {
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 15px;
    color: #202256;
}

.Profesional-Home-SubTitle {
    /*font-style: normal;*/
    font-weight: 700;
    font-size: 16px;
    color: #5D5D5D;
}

.Profesional-Home-Video {
    /*object-fit: contain;*/
    /*height: 213px;*/
    width: 70%;
    margin-right: 20px;
    cursor: pointer;
}

.Profesional-Home-Video-Secundario {
    display: flex;
    justify-content: center;
    align-items: center;
    /*height: 213px;*/
    width: 70%;
    border-radius: 10px;
}

.Home-Profesional-Resultados {
    font-weight: 300;
    font-size: 16px;
    color: #C5C5C5;
}

.Profesional-Home-Sub-Sub-Contenedor-Videos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-content: space-evenly;
    justify-content: space-evenly;

}

.play-button {
    background: #3b3b3b;
}

.button-Profesional-Home {
    background: #F9F9F9;
    border: 0.5px solid #202256;
    border-radius: 5px;
    width: 303px;
    height: 28px;
    margin-right: 1em;
    color: #202256;
    font-size: 14px;
}

.button-Profesional-Home:hover {
    background: #202256;
    color: #FFFFFF;
}

.Profesional-Home-SubTitle-2 {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.Profesional-Home-Search {
    background-color: transparent;
    height: 35px;
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: #C5C5C5;
    text-indent: 25px;
    margin-left: 13px;
}

.Profesional-Home-Search::-webkit-input-placeholder {
    color: #202256;
    margin-left: 13px;
}

.Profesional-Home-Search:-ms-input-placeholder {
    color: #202256;
    margin-left: 13px;
}

.Profesional-Home-Search::placeholder {
    color: #202256;
    margin-left: 13px;
}

.Profesional-Home-Search:focus {
    border-color: #202256;
    outline: none;
}

/* .profesional-Home-Search::-ms-clear {
    color: #202256 !important;
    display: none;
} */


.Profesional-Home-Search-Container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.Profesional-Home-Search-Selector {
    background-color: transparent;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    height: 35px;
    border-color: #C5C5C5;
    width: 100%;
    margin-bottom: 10px;
    text-indent: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.lupa {
    font-size: 50px;
    color: #202256;
    position: absolute;
    margin-top: 5px;
    margin-left: 5px;
}

.Profesional-Home-Search-Selector:focus {
    border-color: #202256 !important;
    color: #202256 !important;
    outline: none;
}

.cant-filtro {
    position: absolute;
    margin-left: -30px;
    margin-top: 10px;
    background-color: rgb(32, 34, 86);
    width: 17px;
    height: 17px;
    color: white;
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    border-radius: 100%;
}


.Home-Profesional-Test-Container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
    place-content: center;
    grid-gap: 30px;
    margin-top: 32px;
    width: 100%;
}

/* @media (max-width: 1025px) {
    .Profesional-Home-Search-Container {
        flex-direction: column;
        align-items: center;
    }

    .Profesional-Home-Search {
        width: 100%;
        text-indent: 5px;
    }

    .Profesional-Home-Search-Selector {
        width: 100%;
        margin-top: 10px;
        text-indent: 5px;
    }

    .lupa {
        color: transparent;
    }
    .cant-filtro{
        display: none;
    }

    .Profesional-Home-Contenedor-Global {
        padding-left: 0;
        padding-right: 0;
    }
} */
.contenedor-fix-1 {
    margin-left: calc(237px + 98px);
    margin-right: 20px;
}

.home-profesional-ai-button {
    border: none;
    border-radius: 8px;
    background-color: #33307f;
    color: white;
    font-size: 16px;
    width: 200px;
    height: 40px;
    margin-left: 30px;
    transition: 0.5s;
}

.home-profesional-ai-button:hover {
    background-color: #ee7919;
    transition: 0.5s;
}

@media (max-width: 1420px) {
    .new-home-profesiona-bottom-container {
        flex-direction: column;
    }

    .new-home-profesional-bottom-container-carousel {
        width: 100%;
    }
}

@media (max-width: 1300px) {
    .contenedor-fix-1 {
        margin-left: 20px;
    }

    .new-home-profesiona-bottom-container {
        flex-direction: row;
    }
}

@media (max-width: 1200px) {
    .new-home-profesiona-bottom-container {
        flex-direction: column;
    }

    .new-home-profesional-bottom-container-carousel {
        width: 100%;
    }

    .new-home-profesional-bottom-container-tour {
        width: 80%;
        margin-left: 0;
    }
}

@media (max-width: 425px) {
    .Profesional-Home-Search-Container {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 10px;
    }

    .Profesional-Home-Search {
        background-color: transparent;
        height: 35px;
        width: 100%;
        border-radius: 5px;
        border-style: solid;
        border-width: 2px;
        border-color: #C5C5C5;
        text-indent: 25px;
        margin-bottom: 20px;
    }

    .Profesional-Home-Search-Selector {
        background-color: transparent;
        border-radius: 5px;
        border-style: solid;
        border-width: 2px;
        height: 35px;
        border-color: #C5C5C5;
        width: 100%;
        margin-bottom: 10px;
        text-indent: 25px;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .subcontenedor-filtros {
        width: 100% !important;
    }

}

@media (max-width: 740px) {
    .new-home-profesional-bottom-container-carousel-container {
        margin-bottom: 32px;
        margin-right: 0;
    }
}

@media (max-width: 930px) {
    .Profesional-Home-Contenedor-Global {
        padding-left: 0;
        padding-right: 0;
    }

    .Profesional-Home-Contenedor-Videos {
        display: revert !important;
    }

    .Profesional-Home-Contenedor-Videos img {
        width: 298px !important;
    }

    .Profesional-Home-Sub-Sub-Contenedor-Videos {
        flex-direction: column;
        align-items: center;
    }

    .Profesional-Home-Video-Secundario {
        justify-content: normal;
    }

    .Profesional-Home-Contenedor-3-new {
        flex-direction: column;
        justify-content: center;
    }

    .Profesional-Home-Contenedor-3-inner-1 {
        width: 100%;
        margin: auto;
    }

    .Profesional-Home-Contenedor-3-inner-2 {
        width: 100%;
        margin: auto;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .Profesional-Home-Video {
        margin-bottom: 18px;
        width: auto !important;
    }

    .css-0 iframe {
        width: 330px !important;
        height: 330px;
        margin-left: 2em;
    }

    .popupcalifacionsubpop {
        display: revert !important;
    }

    .progres-cv, .progres-cv button {
        display: revert !important;
        margin: 0 auto !important;
    }

    .button-Profesional-Home {
        margin-bottom: 1em !important;
    }

    .css-14jnub0 {
        width: 360px !important;
    }

    .cant-filtro {
        display: none;
    }

    .container-opp {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .new-home-empresa-videos-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


.NewCardOportunidad-Container-rectangular{
  background: #FFFFFF;
  width: 100%;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 175px;
  margin-bottom: 22px;
}
.NewCardOportunidad-Container-square{
  background: #FFFFFF;
  width: 350px;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column; 
  height: 350px;
  margin: 22px;
}


.NewCardOportunidad-Top-Container{
 width: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 margin-bottom: 15px;
 margin-top: 25px;
}
.NewCardOportunidad-Top-Container-rectangle{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.NewCardOportunidad-Top-Container-rectangle-inner-1{
  margin-left: 34px;
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.NewCardOportunidad-Top-Container-rectangle-inner-1-1{
  width: 100%;
  display: flex;
  height: 55px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  margin-top: 20px;
}

.NewCardOportunidad-Top-subContainer-1{
 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.NewCardOportunidad-Top-subContainer-2{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 60px;
}

.NewCardOportunidad-Top-Container-rectangle-inner-1-2{
  width: 100%;
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.NewCardOportunidad-Top-subContainer-3{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.NewCardOportunidad-Top-subContainer-4{
  width: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  margin-top: 8px;
  word-wrap: break-word;
  font-size: 14px;
  color: #5D5D5D;
  text-align: left;
  font-weight: 400;
  height: 50px;
}

.NewCardOportunidad-Top-Container-rectangle-inner-2{
  margin-right: 34px;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.NewCardOportunidad-Top-Container-rectangle-inner-2-1{
  margin-top: 20px;
}
.NewCardOportunidad-Top-Container-rectangle-inner-2-2{
  margin-bottom: 25px;
}

.NewCardOportunidad-mid-Container{
  width: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  align-items: center;
  margin-bottom: 38px;
  margin-top: 15px;
  margin-left: 25px;
}
.NewCardOportunidad-mid-Container-inner-1{
  width: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
}
.NewCardOportunidad-mid-Container-inner-2{
  width: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
  word-wrap: break-word;
  padding-right: 40px;
  font-size: 14px;
  color: #5D5D5D;
  text-align: left;
  font-weight: 400;
  height: 50px;
}

.NewCardOportunidad-mid-Container-inner-3{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
}
.NewCardOportunidad-mid-Container-inner-3-1{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.NewCardOportunidad-mid-Container-inner-3-2{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
}

.CardOportunidadProfesional-Button-new {
  height: 30px;
  width: 120px;
  background: transparent;
  border: 0.5px solid #202256;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #202256;
}
.CardOportunidadProfesional-Button-new:hover {
  background: #202256;
  color: #FFFFFF;
}

.text-card-status-Close{
  color:red;
  font-size: 14px;
  font-weight: 400;
}

.text-card-status-Open{
  color: #24D054;
  font-size: 14px;
  font-weight: 400;
}

.text-card-info2{
  font-size: 14px;
  font-weight: 400;
  color: #989898;
}

.text-card-info3{
  font-size: 14px;
  font-weight: 400;
  color: #C5C5C5;
  margin:0px;
}

.stop-info2{
  margin-left: 5px;
  margin-right: 5px;
  color: #989898
}

.container-info-apli-fecha{
    display: flex;
    justify-content: space-evenly;
    width: 50%;
}

.NewCardOportunidad-mid-Container-rectangle{
 width: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 margin-bottom: 18px;
 margin-top: 15px;
}

.NewCardOportunidad-Image{
  object-fit: cover;
  height: 55px;
  width: 60px;
  border-radius: 8px;
}

.NewCardOportunidad-Image-rectangle{
  object-fit: cover;
  height: 175px;
  width: 206px;
  border-radius: 8px;
}

@media (max-width: 725px) {
  .NewCardOportunidad-Container-square{
    margin: auto;
  }
}
.oportunidades-linked-global-container {
    width: 100%;
    padding-left: 100px;
}

.oportunidades-linked-container-inner{
  width: 100%;
  margin: 40px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.oportunidades-cards-container-linked{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-bottom: 5%;
}
.oportunidades-small-cards-container-linked{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 504px;
  align-self: flex-start;
  width: 100%;
  padding-right: 30px;
}

.paginate-oportunities-new-linked{
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.oportunidades-big-cards-container-linked{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  max-height: 700px;
  width: calc(100% - 504px);
}

.container-search-oportunities{
  display: flex;
  flex-direction: row;
  justify-content: space-between;   
  width: 70%; 
  background-color: white;
  padding: 15px;
}

.search-oportunities-input{
  background-color: transparent;
  height: 35px;
  width: 100%;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: #C5C5C5;
  text-indent: 25px;
  margin-left: 13px;
}


@media (max-width: 1400px) {
  .oportunidades-linked-global-container{
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 950px){
  .oportunidades-big-cards-container-linked{
    display: none;
  }
  .oportunidades-small-cards-container-linked{
    width: 100%;
    max-width: 100%;
    padding-right: 0;
  }
  .paginate-oportunities-new-linked{
    justify-content: center;
    align-items: center;
  }
  .container-search-oportunities{
    width: 100%;
    align-items: center;
  }
}
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 10px;
  border: 3px solid #ffffff;
  height: 10px;
}

.small-card-linked-container{
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  width: 100%;
  transition: all 0.3s ease-in-out;
    margin-top: 10px;
    border-radius: 10px;
    -webkit-filter: drop-shadow(0px 7px 32px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 7px 32px rgba(0, 0, 0, 0.1));
}

.small-card-linked-container-active{
    display: flex;
    flex-direction: column;
    background-color: #202256;
    width: 100%;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    margin-top: 10px;
    border-radius: 10px;
    -webkit-filter: drop-shadow(0px 7px 32px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 7px 32px rgba(0, 0, 0, 0.1));
}

.small-card-linked-image{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}

.big-card-linked-container{
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /*overflow: auto;*/
  width: 100%;
  border-radius: 8px;
}

.oportunidades-big-card-top-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 20px 0px 40px;
}

.oportunidades-big-card-top-image{
  display: flex;
  justify-content: center;
  align-self: flex-start;
}
.oportunidades-big-card-top-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 20px;
  width: calc(100% - 164px);
  padding-left: 20px;
}
.clipboard-opportunitie-card{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background-color: #f2f2f2;
    cursor: pointer;
    align-self: flex-start;
    transition: all 0.3s ease-in-out;
}
.oportunidades-big-card-body-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px 40px 20px 40px;
}
.oportunidades-big-card-body-inner{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 60%;
}
.oportunidades-big-card-body-inner-2{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 40%;
    padding: 0 30px 30px 30px;
}

@media (max-width: 1580px){
    .oportunidades-big-card-body-container{
        padding: 20px 0 20px 40px;
    }
}

.profesional-career-ia-container-row-chat{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.profesional-career-ia-container-career-chat{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.chat-ia-container-space-beetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-self: center;
}

.profesional-career-ia-chat-top{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 48rem;
    height: 100%;
    align-self: center;
    margin-bottom: 150px;
}
.profesional-career-ia-chat-bottom-input-background-blurred{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    z-index: 1;
}

.profesional-career-ia-chat-bottom-input-background-blurred::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-filter: blur(5px);
            filter: blur(5px);
}

.profesional-career-ia-chat-bottom-input-container{
    width: 48rem;
    background-color: rgb(32, 34, 86);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    position: fixed;
    bottom: 0;
    margin-bottom: 1rem;
}
.profesional-career-ia-chat-input{
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
}
.input-chat-ia-send-button{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 30px;
    height: 30px;
    margin-left: 1rem;
    cursor: pointer;
}
.send-button-chat-ia{
    color: #fff;
    font-size: 30px;
    transition:  all 0.3s ease-in-out;
}
.send-button-chat-ia:hover{
    color: #ee7919;
    transition: 0.3s;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.blank {
    border: none;
    border-bottom: 1px solid #fff;
    background-color: transparent;
    font-size: inherit;
    padding: 0;
    margin: 0;
    color: #fff;
}
.blank:focus {
    outline: none;
    color: #fff;
}

.chat-top-card-container{
    background-color: #65656b;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    padding: 20px;
    border-color: rgba(32,33,35,.5);
    border-radius: 10px 10px 0 0;
}


.fade-enter {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}
.fade-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.chat-bottom-card-container{
    background-color: rgb(32, 34, 86);
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    padding: 20px;
    border-color: rgba(32,33,35,.5);
    border-radius: 0 0 10px 10px;
}

.chat-top-card-container-left{
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    position: relative;
}
.chat-top-card-container-left-img{
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 3px;
}
.chat-top-card-container-right{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 20px;
    white-space: pre-wrap;
    text-align: left;
    vertical-align: middle;
    line-height: 1.5;
    word-spacing: 0.2em;
    color: white;
    font-size: 18px;
}
.chat-top-card-container-right-ia{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 20px;
    white-space: pre-wrap;
    text-align: left;
    vertical-align: middle;
    line-height: 1.5;
    word-spacing: 0.2em;
    color: white;
    font-size: 18px;
}
.profesional-career-ia-container-row-der-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    height: 100vh;
}
.profesional-career-ia-container-row-izq-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    height: 100vh;
}
.logo-coally-izq-chat-ia{
    opacity: 0.1;
    max-height: 150px;
    max-width: 150px;
}


@media screen and (max-width: 1340px) {
    .profesional-career-ia-container-row-izq-logo{
        display: none;
    }
    .profesional-career-ia-container-row-der-logo{
        display: none;
    }
}

@media screen and (max-width: 790px) {
    .profesional-career-ia-chat-top{
        width: 90%;
    }
    .profesional-career-ia-chat-bottom-input-container{
        width: 90%;
    }
    .profesional-career-ia-chat-input p {
        font-size: 16px !important;
    }
}

.perfil-prof-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 80px;
}

.perfil-prof-titulo {
    margin-left: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #202256;
}

.perfil-prof-subtitulo {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
    margin-top: 40px;
}

.perfil-prof-footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
    place-content: center;
    grid-gap: 30px;
    margin-top: 32px;
    width: 100%;
}

.perfil-prof-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
}

.perfil-prof-body-izq {
    width: 24%;
    min-height: 10em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.perfil-prof-body-der {
    width: 74%;
}

.perfil-prof-nombre {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
}

.perfil-prof-empleo {
    font-weight: 400;
    font-size: 24px;
    color: #5D5D5D;
}

.perfil-prof-sub-cont {
    margin-top: 30px;
    background: #FFFFFF;
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.04);
    border-radius: 24px;
    padding: 30px 60px 45px 60px;
}

.perfil-prof-sub-subtitulo {
    font-size: 16px;
    font-weight: 700;
    color: #393939;
    margin-bottom: 20px;
}

.perfil-prof-textos {
    text-align: justify;
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.perfil-prof-sub-subtitulo-edu-trabajo {
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.perfil-prof-sub-subtitulo-edu-trabajo2 {
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.perfil-prof-sub-subtitulo-edu-trabajo3 {
    font-weight: 400;
    font-size: 14px;
    color: #C5C5C5;
}

.perfil-edu-container {

}

.perfil-trab-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.perfil-cv-input-contenedor-global {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: 30px;
}

.perfil-cv-advise {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
}

.perfil-cv-advise2 {
    font-weight: 700;
    font-size: 12px;
    color: #202256;
}

.perfil-cv-button-group {
    display: flex;
    /*width: 600px;*/
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}

.perfil-cv-subir {
    margin: 5px;
    background: #202256;
    border-radius: 5px;
    height: 30px;
    width: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.perfil-cv-cancel {
    margin: 5px;
    background: #D6D6D6;
    border-radius: 5px;
    height: 30px;
    width: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.perfil-cv-input-title {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}
.perfil-cv-input-title2 {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-top:10px;
}

.perfil-cv-input-subtitle {
    font-weight: 400;
    font-size: 16px;
    color: #A5A5A5;
}

.perfil-cv-input-contenedor {
    cursor: pointer;
    width: 265px;
    height: 85px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: #E6E6E6;
    border: 1px dashed #989898;
    border-radius: 14px;
    margin-right: 30px;
}

.perfil-cv-input-subcontenedor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #202256;
    border-radius: 14px;
    width: 55px;
    height: 55px;
}


.perfil-cv-input-contenedor2 {
    width: 265px;
    height: 85px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: transparent;
    border: 1px solid #202256;
    border-radius: 14px;
}

.perfil-cv-input-subtitle2 {
    font-weight: 400;
    font-size: 16px;
    color: #202256;
}

.perfil-cv-input-subcontenedor2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F7F2FF;
    border-radius: 14px;
    width: 55px;
    height: 55px;
}


@media (max-width: 699px) {
    .perfil-cv-button-group {
        display: flex;
        width: 100%;
        height: 70px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }

    .perfil-cv-subir {
        background: #202256;
        border-radius: 5px;
        height: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
    }

    .perfil-cv-cancel {
        background: #D6D6D6;
        border-radius: 5px;
        height: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
        color: #5D5D5D;
    }
}

@media (max-width: 500px) {
    .perfil-cv-input-subcontenedor {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #202256;
        border-radius: 14px;
        width: 55px;
        height: 55px;
        margin-right: 20px;
    }
    .perfil-trab-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}

@media (max-width: 1550px) {
    .perfil-prof-body-izq {
        width: 38%;
        min-height: 10em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .perfil-prof-body-der {
        width: 58%;
    }
    .perfil-cv-input-contenedor-global {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .perfil-cv-input-contenedor {
        cursor: pointer;
        width: 265px;
        height: 85px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: #E6E6E6;
        border: 1px dashed #989898;
        border-radius: 14px;
        margin-right: 0px
    }

    .perfil-cv-input-contenedor2 {
        width: 265px;
        height: 85px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: transparent;
        border: 1px solid #202256;
        border-radius: 14px;
    }
}

@media screen and (max-width: 950px) {
    .perfil-prof-body-izq {
        width: 100%;
        min-height: 10em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .perfil-prof-body-der {
        width: 100%;
    }

    .perfil-prof-body {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
    }

    .perfil-prof-body-izq {
        margin-left: -.5em;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
    }

    .perfil-cv-input-contenedor {
        cursor: pointer;
        width: 100%;
        height: 85px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: #E6E6E6;
        border: 1px dashed #989898;
        border-radius: 14px;
    }

    .perfil-cv-input-contenedor2 {
        width: 100%;
        height: 85px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: transparent;
        border: 1px solid #202256;
        border-radius: 14px;
    }

}


.Perfil-container-cards{
border-radius: 20px;
width: 324px;
min-height: 430px;
background-color: #ffffff;
font-size: .9em;
display:flex;
align-items: center;
justify-content: flex-start;
flex-direction: column;
margin-bottom:1em;

}


.editar-foto-perfil{
    border:1px solid white;
    width:30px;
    height: 30px;
    background-color: #ec7332;
    position:absolute;
    margin-top:-70px;
    margin-left:60px;
    border-radius: 50%;
    display:flex;
    align-items: center;
    justify-content: center;
}


.editar-foto-perfil button{
    background-color: transparent;
}

.avatar-element-perfil{
    position:absolute;
}

.Avatar-camara-perfil{

}

.nidiea button{
    background-color:#ec7332 ;
}


.Perfil-container-cards span{
width: 8.7em !important;
}

.porcentaje-complete{
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top:4em;

}

.porcentaje-editar-container{
    font-size: .9em;

    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.porcentaje-text{
    color:#21224e;
}
.porcentaje-p{
    margin-right:.5em;
    color:#898989;
}



.link-edit-image img{
    width:20px;
    height:20px;
    margin-top: -.2em;
    margin-left: 30px;

}

.link-edit-image :hover{
    cursor:pointer;
    color:#898989;
}
.link-edit-image{
    color:#898989;
    height: 33px;
    padding: 13px 5px;
    display:flex;
    align-items:center;
    justify-content: space-between;

}

.herre{
    color: #fbfbfb;
    width:80%;

}

.icon-cards{
    width:20px;
    height:20px;
    margin-top: -.2em;
    margin-right: 1em;
}

.contact-data{

    color:#898989;
    font-size: .9em;
}



/* Card Oportunidad e idiomas */

.innerCards{
    width:75%;
}
.oportunidad-cantidad{
  margin-top: 3em;
  /* border:1px solid black; */
  width: 80%;
}

.title-op{
    font-weight: bolder;

}

.text-contador{
    font-size:2em;
    color:#ec7332;
    font-weight: bolder;
}

.redes-cont{
    background-color: #fbfbfb;
    width: 85%;
    height:3em;
    display:flex;
    align-items:center;
    justify-content: flex-start;
    margin-bottom: 1em;
    border-radius: 10em;

}

.redes-cont div{

    width:46px;
    height: 49px;
    border-radius: 18px;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-right: .5em;
}

.redes-cont p{
    margin-top: 1.5em;
}

.TitleOp{

    width:94%;
    margin-top:-30px;
    margin-bottom: 10px;
    margin-left: 28.41px;
    padding:1em;
}


#face{
    background-color:#4166d8 ;
}

#drib{
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

#ln{
    background-color: #0073af;
}

#ytb{
    background-color: #ef0806;
}

/* responsive */


@media screen and (max-width: 500px){

    .porcentaje-editar-container{

        width: 80%;
    }

}

.MuiStepLabel-active {
    color: black;
    font-weight: 700 !important;
    font-size: 22px !important;
    text-transform: uppercase;
}

.MuiStepLabel-label {
    color: black;
    text-transform: uppercase;
    font-size: 18px !important;
}

.MuiButton-containedSecondary {
    color: #fff;
    background-color: #F46036 !important;
}

.MuiOutlinedInput-inputMarginDense {
    padding-top: 10.5px;
    padding-bottom: 5px !important;
}

.ConsultorForm-container{
    min-width: 300px;
    max-width: 700px;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    margin: 0 auto;
    text-align: center;
    background-color: white;
    min-height: 400px;
    border-radius: 2%;
    box-sizing: border-box;
    overflow: auto;
    
}

/* titulo */
.ConsultorForm-tittle{
    font-size: 25px;
    font-weight: 700;
    margin-top: 2rem;
    text-align: center;
    text-transform: uppercase;
}

.ConsultorForm_container-form{
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 5rem;
    padding: 40px 35px;
    background-color: #f6f6f6;
}
.consultor-cv-button-form{
    width: 200px; 
    height: 25px; 
    color: #FFFFFF; 
    background-color: #202256; 
    border-radius: 5px;
    transition: 400ms;
}
.consultor-cv-button-form:hover{
    background-color: #F07130;
    color: #FFFFFF;
}
.ConsultorForm-input{
    width: 100%;
    border: none;
    border-bottom: 1px solid;
    outline-style: none;
    font-size: 15px;
    margin-top: 4%;
    padding: 12px;
    box-sizing: border-box;
    background-color: transparent;
}
.ConsultorForm-label{
    padding-top: 2rem;
    text-align: left;
    font-size: 15px;
    color: #757575; 
}
.css-2b097c-container {
    position: relative;
    box-sizing: border-box;
    padding: 0px 10px 20px 0px;
    margin-top: 1em;
}

.ConsultorForm-input:focus {
    border-bottom: 2px solid var(--main-yellow-findally);
    outline: none;
} 

.ConsultorForm-button{
    padding: 8px;
    color: white;
    background-color: #f46036;
    border-style: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 600;
    text-transform: uppercase;
}

.ConsultorForm-label-activity{
    padding-top: 1rem;
    text-align: left;
    font-size: 15px;
    color: #757575;   
}

.ConsultorForm-select-activity{
    padding: 5px;
    margin-top: 10px;
    margin-left: 10px;
    border-color: #cccccc;
    border-radius: 2%;
    color: #757575;
}

.grapgrid{  
    grid-gap: 10px;
  }
  .buttondetalleoportunidad{
    padding: 0.2rem 0.7rem;
    height: 28px;
    left: 650px;
    top: 411px;
    color:white;
    background: #c5c5c5;
    border-radius: 5px;
    transition: 400ms;
  }
  .buttondetalleoportunidadchecked:hover{
    font-weight: bold;
    transition: 400ms;
    background: #F07130;
  }
  .buttondetalleoportunidadchecked{
    padding: 0.2rem 0.7rem;
    height: 28px;
    left: 650px;
    top: 411px;
    color:white;
    background: #202256;
    border-radius: 5px;
    transition: 400ms;
  }
  .textootroasoportunidades{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 36px;

display: flex;
align-items: center;


color: #393939;
  }
  .titulooportunidadesdetalle{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 40px;

display: flex;
align-items: center;

color: #202256;
  }
  .contedor-carta-recomendados-aplicar{    
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 300px;
    max-width: 297px;
    min-width: 70%;
    margin-bottom: 21px;
    margin-top: 10px;
    margin-left: 20px;
    border-radius: 8px;
  }
  .contenedor-primero-aplicar{    
    width: 100%;
    display: flex;
    padding-top: 20px;
    grid-gap: 0rem;
    gap: 0rem;
    align-items: flex-start;
    padding-bottom: 20px;
    margin: 4em;
  }

  .img-primero-aplicar{
    margin-bottom: 1rem;
    width: 20%;
    height: 10%;
      max-height: 10%;
        max-width: 20%;
    border-radius: 8px;
    object-fit: fill;
  }
  .ifo-primero-aplicar{
    width: 40%;
    margin-left: 10%;
  }

  .tittle-popup-applied{
    margin-bottom: 0px 
  }

  @media (max-width:620px){
  .BodyWeb{
    padding: 0 1rem !important;
    }

    .img-primero-aplicar{
      margin-bottom: 1rem;
      width: 80%;
      height: 40%;
      border-radius: 8px;
      object-fit: fill;
    }
    .ifo-primero-aplicar{
      width: 80%;
    }
    .contenedor-primero-aplicar{    
        flex-direction: column;
        margin: 0;
      }
      .contedor-carta-recomendados-aplicar{
        margin-left: 0;
        max-width: 270px;
      }
      .contedor-carta-recomendados-aplicar div img{
        width: 270px !important;
      }
      .contedor-carta-recomendados-aplicar div {
        width: 270px !important;
      }
      .contedor-carta-recomendados-aplicar div div{
        width: 270px !important;
      }
      .titulooportunidadesdetalle{
        font-size: 20px;
        margin:0px !important;
      }
  }

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    margin:0px !important;
  }

  .oportunity-scroll {
    height: 190px;
    overflow: auto;
    overflow-y: scroll;
  scrollbar-color: orange rgb(189, 189, 189);
  scrollbar-width: thin;
  }
  .oportunity-scroll::-webkit-scrollbar-track
  {
      background-color: rgb(189, 189, 189);
  }
  
  .oportunity-scroll::-webkit-scrollbar
  {
      width: 5px;
      background-color: #F5F5F5;
  }
  
  .oportunity-scroll::-webkit-scrollbar-thumb
  {
      background-color: orange;
      border-radius: 10px;
  }

  .infoOportunity{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-left: 4em;
  }

  .infoOportunity-left{
    width: 46%;
    overflow-wrap: break-word;
    margin-right: 3em;
    margin-left: -30px;
  }

  .infoOportunity-ritgh{
    width: 40%;
    margin-left: 0;
  }

  @media (max-width:520px){
    .infoOportunity{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-left: 0;
    }
  
    .infoOportunity-left{
      width: 100%;
      margin-bottom: 1em;
      margin-right: 0;
      margin-left: 0;
    }
  
    .infoOportunity-ritgh{
      width: 100%;
    }
    .container-other-opp{
      align-items: center;
    }
    .contenedor-primero-aplicar img{
      align-self: center;
    }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 400;
  }
  
  .popup-inner {
    position: absolute;
    display: flex;
    top: 7%;
    padding: 10px;
    background: #1bb96d;
    width: 40%;
    align-items: center;
    color: white;
    height: auto;
    border-radius: 8px;
    justify-content: space-evenly;
  }
  
  .popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  
  .close-btn-pop{
    /* padding: 7px; */
    background-color: white;
    color: #7dbe6a;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 12px;
    border-style: none;
    border-radius: 5px;
    text-transform: uppercase;
  }
.CardOportunidadProfesional-Container {
    background: #FFFFFF;
    width: 100%;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    height: 144px;
    margin-bottom: 22px;
}

.CardOportunidadProfesional-Image {
    object-fit: cover;
    height: 144px;
    width: 144px;
    border-radius: 8px 0 0 8px;
}

.CardOportunidadProfesional-Sub-Container {
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
}

.CardOportunidadProfesional-Button {
    height: 30px;
    width: 140px;
    background: transparent;
    border: 1px solid #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
    transition: all 0.3s ease;
}
.CardOportunidadProfesional-Button:hover {
    background: #202256;
    color: #FFFFFF;
    transition: all 0.3s ease;
}

.CardOportunidadProfesional-Button-applied{
    height: 30px;
    width: 140px;
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    transition: all 0.3s ease;
}

.CardOportunidadProfesional-Button-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    width: 140px;
    background: transparent;
    border: none;
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.CardOportunidadProfesional-Button-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}

.CardOportunidadProfesional-Salario {
    color: #0B27E6;
    font-weight: 700;
    font-size: 14px;
    margin-top: 10px;
}

.CardOportunidadProfesional-Titulo {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.CardOportunidadProfesional-Descripcion {
    font-weight: 300;
    font-size: 14px;
    color: #5D5D5D;
    margin: 0;
    padding: 0;
}

.CardOportunidadProfesional-Descripcion-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CardOportunidadProfesional-Data-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.CardOportunidadProfesional-Salario-Null {
    font-weight: 300;
    font-size: 14px;
    margin-top: 10px;
}

@media (max-width: 1000px) {
    .CardOportunidadProfesional-Descripcion {
        display: none;
    }
}

@media (max-width: 725px){
    .CardOportunidadProfesional-Container{
        height:auto;
    }
    .CardOportunidadProfesional-Sub-Container{
        display:revert;
    }
    .CardOportunidadProfesional-Button-Container{
       justify-content: normal;
        margin-bottom: 1em;
    }
    .CardOportunidadProfesional-Image{
        height: 100%;
    }
}
.DashboardCardContainer {
    background: #FFFFFF;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.DashboardCardContainerChildren{
    width: 100%;
}

.DashboardCardContainerTitle {
    background-color: #dbdbdb;
    width: 100%;
    border-radius: 8px 8px 0 0;
    padding: 5px;
}

.DashboardCardTitle {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
}

.card-info-dashboard{
    width: 320px;
    height: 170px;
    border-radius: 10px;
    display: flex;        
    padding: 20px;
    color: white;
}

.card-info-dashboard-Icon{
    display: flex;
    align-items: center;
}

.card-info-dashboard-container{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 4%;
}

.card-info-dashboard-title{
    font-size: 20px;
    font-weight: 500; 
    text-align: center;   
}

.card-info-dashboard-container-2{
    height: 100%;
    display: flex;
    align-items: flex-end;

}

.card-info-dashboard-value{
    font-size: 48px;
    font-weight: 600;    
}
.bootcamp-home-administracion-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0 50px 5% 50px;
}

.bootcamp-home-administracion-banner{
    width: 100%;
    height: 150px;
    padding: 10px;
    border-radius: 8px;
    /* background-image: url(https://coally.com/wp-content/uploads/2022/03/banners-landing-1-2.png); */
    background-image: url(/static/media/businessbanner.52d5f844.png);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: white;
    text-align: center;
    flex-direction: row;
}

.bootcamp-home-administracion-description-card{
    text-align: center;
    display: flex;
    align-self: flex-start;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 2%;
}

.bootcamp-home-administracion-videos-cards{
    display: grid;
    margin-bottom: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
    width: 100%;
    place-content: center;
    grid-gap: 20px;
    justify-items: center;
    align-items: center;
    padding-top: 2%;
}

.bootcamp-home-administracion-cursos-buttons{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    margin-top: 2%;
}

.bootcamp-home-administracion-button-courses{
    width: 150px;
    height: 33px;
    border-radius: 10px;
    background-color: #202256;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    transition: all 0.3s ease;
}

.bootcamp-home-welcome-video{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.bootcamp-home-administracion-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;    
}

/*de aca para abajo es mio*/

.bootcamp-home-administracion-titles{
    font-weight: 700;
    font-size: 24px;
    display: flex;
    align-items: flex-end;
    color: #393939;
}

.bootcamp-home-administracion-desc{
    font-weight: 400;
    font-size: 14px;
    color: #C5C5C5;
}

.bootcamp-home-administracion-home-subcont1{
    margin-top: 65px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.bootcamp-home-administracion-home-subcont1-1{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    margin: 0 10px;
}

.bootcamp-home-administracion-footer{
    padding: 10px;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 45px 0;
    background: #FFFFFF;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

@media screen and (max-width: 1681px) {
    .bootcamp-home-administracion-home-subcont1{
        flex-direction: column;
    }
    .bootcamp-home-administracion-home-subcont1-1{
        width: 100%;
        margin-bottom: 20px;
    }
}


@media screen and (max-width: 1300px) {
    .bootcamp-home-administracion-container{
        padding: 0;
    }
}

@media screen and (max-width: 1000px) {
    .bootcamp-home-administracion-description-card{
        flex-direction: column;
    }
}

@media screen and (max-width: 450px) {
    .bootcamp-home-administracion-banner{
        justify-content: center;
    }
    .bootcamp-home-administracion-buttons{
        width: 95%;
    }
}
.welcome-courses-card-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 324px;
    height: 300px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 10%;
    transition: all 0.3s ease;
}

.welcome-courses-card-container:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: 0.5s;
}

.welcome-courses-card-container:hover .welcome-courses-card-title-container {
    background-color: #202256;
    transition: 0.5s;
}

.welcome-courses-card-container:hover .card-course-bootcamp-title {
    color: white;
    transition: 0.5s;
}

.card-course-bootcamp-descr {
    font-size: 12px;
    color: #393939;
    text-align: justify;
    margin: 10px 5px;
    align-self: center;
    transition: all 0.5s;
}

.welcome-courses-card-button{
    font-weight: 400;
    width: 125px;
    height: 30px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #202256;
    margin-bottom: 10px;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #202256;
}

.welcome-courses-card-container:hover .card-course-bootcamp-descr {
    color: white;
    transition: 0.5s;
}

.welcome-courses-card-container:hover .welcome-courses-card-button {
    color: white;
    transition: 0.5s;
    border: 1px solid white;
}

.welcome-courses-card-title-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    transition: all 0.3s ease;
    border-radius: 0 0 8px 8px;
}

.card-course-bootcamp-title {
    font-size: 18px;
    font-weight: bold;
    color: #393939;
    text-align: center;
    padding: 10px;
    align-self: center;
    transition: all 0.5s;
    margin-bottom: 5px;
}

.bootcamp-modal-precourses-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.bootcamp-modal-precourses-card-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90px;
    align-items: center;
    justify-content: space-between;
}
.bootcamp-modal-precourses-card-title-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
    padding: 0 10px 0 10px;
}
.bootcamp-modal-precourses-card-title{
    font-size: 1em;
    font-weight: bold;
    color: #393939;
    text-align: left;
    padding: 10px;
    align-self: flex-start;
    transition: all 0.5s;
}

.bootcamp-modal-precourses-card-image-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
}

.bootcamp-modal-precourses-card-image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #393939;

}

.bootcamp-modal-precourses-card-image-2{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #27165b;
    border: 1px solid #393939;
}
.bootcamp-modal-precourses-card-video-button{
    width: 118px;
    height: 33px;
    border-radius: 10px;
    background-color: #202256;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    transition: all 0.3s ease;
}

.bootcamp-modal-precourses-card-video-button:hover{
    background-color: #F07130;
    transition: 0.5s;
}

.bootcamp-modal-precourses-card-file-button{
    width: 118px;
    height: 45px;
    border-radius: 10px;
    background-color: #202256;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    transition: all 0.3s ease;
}

.bootcamp-modal-precourses-card-file-button:hover{
    background-color: #F07130;
    transition: 0.5s;
}
.Bootcamp-Test-Card-container {
    display: flex;
    background-color: #FFFFFF;
    border-radius: 16px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 330px;
    margin-top: 22px;
    margin-right: 18px;
    padding: 17px 20px 8px 16px;
}

.Bootcamp-Test-Card-container:hover {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25);
    border: 1px solid #202256;
    cursor: pointer;
  }
  

.Bootcamp-Test-Card-sub-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 15px;
}

.Bootcamp-Test-Card-Title{
    font-weight: 700;
    font-size: 14px;
    color: #393939;
}

.Bootcamp-Test-Card-P{
    font-weight: 300;
    font-size: 14px;
    color: #5D5D5D;
    margin-top: 10px;
}
.container-tests{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.container-back-tests{
    width: 100%;
    color: #202256;
    display: flex;
    margin-bottom: 5%;
    align-items: center;
    justify-content: space-between;
}

.Bootcamp-Subcontainer-Tests{
    width:100%;
    height: 500px;
    overflow-y: scroll;
}
.body-card-oportunidad {
    background-color: white;
    width: 100%;
    height: 150px;
    border-radius: 8px;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    margin: 8px;
    display: flex;

}

.img-card-oportunidad {
    border-radius: 8px 0 0 8px;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.titulo-card-oportunidad {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}

.descripcion-card-oportunidad {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #5D5D5D;
}

.boton-vermas-card-oportunidad {
    background-color: transparent;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    color: #C5C5C5;
}

.boton-vermas-card-oportunidad:hover {
    color: #151515;
}

.boton-editar-card-oportunidad:hover {
    cursor: pointer;
}

@media (max-width:625px){
    .descripcion-card-oportunidad{
        display: none;
    }
    .applicant-company-card-home{
        display: none;
    }
}

.new-empresa-home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
}

.new-empresa-home-banner {
    width: 100%;
    height: 158px;
    /* background-image: url(https://coally.com/wp-content/uploads/2022/03/banners-landing-1-2.png); */
    background-image: url(/static/media/BannerHome.8f9af2e2.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-empresa-home-banner2 {
    width: 100%;
    height: 158px;
    /* background-image: url(https://coally.com/wp-content/uploads/2022/03/banners-landing-1-2.png); */
    background-image: url(/static/media/openai.6edf75ae.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-empresa-home-banner-text {
    margin-left: 41px;
}

.new-empresa-home-banner-button {
    margin-right: 6rem;
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 135px;
    height: 28px;
    background: transparent;
    border: 1px solid #FFF;
    border-radius: 5px;
}

.new-empresa-home-banner-button2 {
    margin-right: 6rem;
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 135px;
    height: 28px;
    background: #F07130;
    border-radius: 5px;
}

.new-empresa-home-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;

}

.new-empresa-home-recommendation {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 64%;
    height: 100%;
}

.new-empresa-home-test-title {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    width: 30%;
    height: 30px;
}

.new-empresa-home-body-tests {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 2fr));
    grid-gap: 10px;
    margin-top: 10px;
}

.paginateStyle {
    display: flex;
    font-size: 1em;
    font-family: 'Poppins';
    color: #B0B0B0
}

.nextB {
    color: #202256
}

.previB {
    color: #202256
}

.paginateStyle li {
    padding: 8px;
    margin: 1em;
}

.pageAct {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #202256 !important;
    width: 40px !important;
    border-radius: 100% !important;
    color: #FFF !important;
}

.pageB {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    width: 40px;
    border-radius: 100%;
    color: #202256;
}

.contenedor-formacion-oportunidades-new {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 2%;
    width: 100%;
}

.new-home-empresa-statistics-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 300px;
    height: 100%;
}

.new-home-company-save-op-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 40px;
    padding: 10px;
    margin-bottom: 50px;
}

.new-home-empresa-statistics-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 50%;
    margin-top: 5%;
    margin-right: 10px;
}

.new-home-empresa-statistics-body-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    height: 50%;
    margin-left: 10px;
    margin-bottom: 10px;
}

.new-home-empresa-statistics-arrows {
    max-width: 20px;
}

.new-home-published-opportunities {
    width: 60%;
    margin-right: 33px;
}

.new-home-empresa-statistics-arrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
}

.new-home-empresa-statistics-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.new-home-empresa-videos-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 3fr));
    grid-gap: 10px;
    width: 100%;
}

.navigate-arrow-statistics {
    color: #202256;
    cursor: pointer;
}

.navigate-arrow-statistics:hover {
    color: #F07130;
}

@media (max-width: 1100px) {
    .contenedor-formacion-oportunidades-new {
        flex-direction: column-reverse;
        align-items: center
    }

    .new-empresa-home-recommendation {
        width: 100%;
    }

    .new-home-published-opportunities {
        width: 100%;
        margin-right: 0px;
    }

    .new-home-empresa-statistics-container {
        width: 100%;
    }

    .new-empresa-home-banner {
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .new-empresa-home-banner2 {
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .new-empresa-home-banner-button {
        margin-right: 0px;
        margin-top: 0px;
    }
    .new-empresa-home-banner-button2 {
        margin-right: 0px;
        margin-top: 0px;
    }
}

@media(max-width: 1550px){
    .new-empresa-home-banner2{
        border-radius: 8px;
    }
}
@media (max-width: 950px) {
    .pageAct {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        background-color: #202256 !important;
        width: 40px !important;
        height: 40px !important;
        border-radius: 50% !important;
        color: #FFF !important;
    }

    .pageB {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFF;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #202256;
    }

    .nextB {
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .previB {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.card-2contenedor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-radius: 10%;
    padding: 2rem;
    width: 90%;
}

.card-2cartas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sectioncards-card1-empresa {
    width: 100%;
    display: flex;
    grid-row-gap: 2rem;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    justify-content: space-evenly;
    display: flex;
}

.sectioncards-card2-empresa {
    display: grid;
    place-content: center;
    place-items: center;
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    width: 100%;
}

.sectioncards-card3-empresa {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    width: 100%;
    background-color: #e6e7e8;
}

.section-two-empresa {
    width: 100%;
    margin-top: 1rem;
    padding-right: 5rem;
    padding-bottom: 1rem;
}

.cards2 {
    width: 100%;
    height: 500px;
}

.section-two-empresa .card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-content: center;
    align-items: center;
    height: 95px;
    border-radius: 8px;
    transition: 400ms;
}

.card-body-2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 150%;
}

.card-body-content {
    margin-left: 2rem;
    width: 100%;
}

.card-body-content-title {
    font-size: 1.25rem;
    width: 100%;
    font-weight: 700;
    font-family: Poppins;
    color: #000;
    transition: 400ms;
}

.card-body-content-text {
    margin-bottom: 1rem;
    color: #5D5D5D;
    font-family: Poppins;
    font-weight: 300;
    transition: 400ms;
}

.card-body-content-text2 {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 1rem;
    color: #24D054;
    font-family: Poppins;
    font-weight: 300;
    transition: 400ms;
}

/*  hovers */
.section-two-empresa .card:hover {
    background-color: #202256;
    transition: 400ms;
}

.section-two-empresa .card:hover .card-body-content .card-body-content-title {
    color: white;
    transition: 400ms;
}

.section-two-empresa .card:hover .card-body-content .card-body-content-text {
    color: white;
    transition: 400ms;
}

.section-three-empresa {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

}

.section-three-empresa .cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.section-three-empresa .card {
    width: 20rem;
    padding: 1rem;
    border-radius: 10px;
    margin-right: 2rem;
    height: 100%;
}

.section-three-empresa .card .card-header {
    height: 5rem;
    margin-bottom: 1rem;
    background-color: #f3f3f3;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.section-three-empresa .card .card-body a {
    color: #c4c4c4;
}

.section-three-empresa .card .card-footer {
    margin-top: 1rem;
}

.section-three-empresa .card .card-footer button {
    font-size: 1rem;
    padding: 0.3rem 0.6rem;
    font-weight: 550;
    box-shadow: none;
    border: none;
}

.section-three-empresa .card2 {
    display: flex;
    background-color: #e6e7e8;
    border-radius: 10px;
    padding: 3rem 5rem;
}

.section-three-empresa .card2 .card-item-left {
    margin-right: 1rem;
}

.section-three-empresa .card2 .card-item-rigth {
    margin-left: 1rem;
}

.fuenteestadoproyecto {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 2.7vw;
    color: #393939;
}

.card-v2-op {
    display: flex;
    flex-direction: row;
    height: 89px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    margin-top: 31px;
    justify-content: space-between;
    align-items: center;
    padding-right: 31px;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}

.card-v2-title {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    font-family: 'Poppins'
}

.card-v2-subtitle {
    font-size: 14px;
    font-weight: 300;
    color: #5D5D5D;
    font-family: 'Poppins'
}

.card-v2-resposive-applicant{
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 70px;
}

.card-v2-resposive-image{
    width: 214px;
    height: 144px;
    border-radius: 8px 0 0 8px;
    object-fit: cover;
}

.card-v2-subtitle-green {
    margin-left: 10px;
    padding-top: 15px;
    font-size: 14px;
    font-weight: 300;
    color: #24D054;
    font-family: 'Poppins'
}
.card-v2-detalle {
    font-size: 14px;
    font-weight: 400;
    color: #C5C5C5;
    font-family: 'Poppins'
}

.card-v2-op:hover {
    background-color: #202256;
    transition: 400ms;
    margin-left: 25px;
    color: white;
}

.card-v2-op:hover .card-v2-title {
    color: white;
    transition: 400ms;
}

.card-v2-op:hover .card-v2-subtitle {
    color: white;
    transition: 400ms;
}

.card-v2-espera:hover .card-v2-detalle {
    color: white;
    transition: 400ms;
}
.card-v2-op-div{
    flex-direction: row;
    display: flex;
}
.card-v2-op-img{    
    width: 124px;
    height: 89px;
    object-fit: cover;
    border-radius: 8px 0px 0px 8px;
}
.input-card-v2-op{    
    color: black;
    padding-left: 2.5rem;
    background-color: transparent;
    width: 55vw;
    height: 35px;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(197, 197, 197);
    margin-bottom: 10px;
}
@media (max-width:625px){

    .card-v2-resposive-image{
        width: 114px;
        height: 144px;
        border-radius: 8px 0 0 8px;
        object-fit: cover;
    }

    .card-v2-resposive-applicant{
        display: none;
    }
    .card-v2-resposive-desc {
        display: none;
    }
    .card-v2-op{
        flex-direction: column;
        height: -webkit-max-content;
        height: max-content;
        padding-right:0
    }
    .card-v2-op-div{
        flex-direction: column !important;
        height: -webkit-max-content;
        height: max-content;
        width: 100%;
    }
    .card-v2-op-img{
        width: 100%;
    }
    .fuenteestadoproyecto {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 2.7vw;
        color: #393939;
    }
    .font-top{
        font-size: 20px;
    }
}
.dashOp-global-cont {
    padding-right: 100px;
    padding-left: 337px;
}

.dashOp-cards-cont {
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
}

.dashOp-cont-info-emp {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 98px;
}

.dashOp-cont-info-emp-serv {
    margin-left: 75px;
    width: 50%;
}

.dashOp-empresa {
    color: #5D5D5D;
    font-weight: 700;
    font-size: 16px;
}

.dashOp-text-sub {
    color: #5D5D5D;
    font-weight: 400;
    font-size: 14px;
}

.dashOp-met-text {
    font-weight: 400;
    font-size: 14px;
    color: #B0B0B0;
    margin-bottom: 52px;
}

.dashOp-adq-button {
    margin-top: 45px;
    width: 100%;
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 5px;
    color: #FFFFFF;
}

.dashOp-met-button {
    border: 1px solid #D6D6D6;
    background: transparent;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #D6D6D6;
    padding-right: 25px;
    padding-left: 25px;
    margin-bottom: 23px;
    margin-top: 17px;
}

.dashOp-salary {
    margin-top: 25px;
    color: #0B27E6;
    font-weight: 700;
    font-size: 16px;
}

.dashOp-solicitudes {
    color: #F07130;
    font-weight: 400;
    font-size: 14px;
}

.dashOp-time {
    color: #B0B0B0;
    font-weight: 400;
    font-size: 14px;
}


.dashOp-subtitle {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
    margin-top: 35px;
}

.perfiles-de-prof-similares {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(330px, 2fr));
    justify-items: start;
}

.dashOp-btn-ver-mas {
    margin-top: 25px;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #5D5D5D;
}

.dashOp-title {
    font-weight: 700;
    font-size: 40px;
    color: #202256;
}

.dasOp-card {
    background: #FFFFFF;
    border-radius: 8px;
    /*width: 535px;*/
    /*height: 283px;*/
    width: 100%;
    padding: 33px 42px 16px 31px;
    margin-top: 22px;
    margin-bottom: 22px;
    margin-right: 75px;
}

.dasOp-card-title {
    font-weight: 700;
    font-size: 14px;
    color: #5D5D5D;
}

.dasOp-card-desc {
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
    margin-top: 5px;
    list-style: disc;
    list-style-position: inside;
    word-break: break-all;
}
.legal-card-desc{
    font-size:14px;
    color: #5D5D5D;
    font-weight:600;
    margin-top: 5px;
}
.legal-information-company{
    text-transform: capitalize;
    margin-left: 5px;
    font-weight:400;
}

@media (max-width: 1024px) {
    .dashOp-cont-info-emp {
        flex-direction: column;
    }

    .dashOp-cont-info-emp-serv {
        margin-left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .dasOp-card {
        width: 100%;
        margin-right: 0;
        padding: 10px;
    }
}


@media (max-width: 1024px) {
    .dashOp-cards-cont {
        flex-direction: row;
        padding: 0;
    }
}

@media (max-width: 768px) {
    .dashOp-cards-cont {
        flex-direction: column;
        padding: 0;
    }
}


@media (max-width: 1300px) {
    .dashOp-global-cont {
        padding-right: 50px;
        padding-left: 50px;
    }
}

.prof-list-global-cont {
    display: flex;
    width: 100%;
    height: 75px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.prof-list-rig-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.prof-list-rig-sub-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.prof-list-number {
    font-weight: 700;
    font-size: 12px;
    color: #C5C5C5;
    
    /*margin-right: 25px;*/
}

.prof-list-name {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.prof-list-job {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
}

.prof-list-card-image-filtro {
    /*filter: blur(3px);*/
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
    /*margin-right: 45px;*/
}

.prof-list-img {
    width: 55px;
    height: 55px;
    object-fit: cover;
    border-radius: 50%;
    margin: 15px;
}

.prof-list-ver-perfil {
    font-weight: 400;
    font-size: 14px;
    color: #202256;
    margin-right: 10px;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}
.prof-list-ver-perfil:hover {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%); 
    color: #202256; 
    font-weight: 500;
    stroke-width: 1.5px; 
}

.prof-list-ver-perfil svg{
    stroke: #202256;
}


.prof-list-ver-perfil-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.prof-list-compat {
    margin-top: 8px;
    /*margin-left: 5px;*/
    font-weight: 400;
    font-size: 12px;
    color: #F07130;
}

.prof-list-compat-cont {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.prof-list-button {
    margin-left: 20px;
    background: transparent;
    border: 1px solid #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #202256;
    padding-right: 20px;
    padding-left: 20px;
}

.prof-list-button-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.prof-list-but-selec {
    margin-left: 20px;
    background-color: #F07130;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    padding-right: 20px;
    padding-left: 20px;
}
@media (max-width: 1465px) {
    .prof-list-button-cont {
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        height: 150px;
    }
    .prof-list-but-selec {
        width: 100%;
        background-color: #F07130;
        border-radius: 5px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        padding-right: 20px;
        padding-left: 20px;
        margin: 0;
    }

    .prof-list-ver-perfil-cont {
        border: 1px solid #202256;
        width: 100%;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0;
    }
    .prof-list-global-cont {
        display: flex;
        width: 100%;
        height: 150px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

@media (max-width: 1024px) {
    .prof-list-global-cont {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        /*flex-direction: column;*/
        text-overflow: ellipsis;
    }

    .prof-list-name {
        text-overflow: ellipsis;
    }

    .prof-list-job {
        text-overflow: ellipsis;
    }

    .prof-list-rig-sub-cont {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

@media (max-width: 768px) {
    .prof-list-button-cont {
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        height: 150px;
    }
}

@media (max-width: 425px) {
    .prof-list-global-cont {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        padding: 5px;
        border-radius: 5px;
    }

    .prof-list-rig-sub-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
    }

    .prof-list-button-cont {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100px;
    }

    .prof-list-but-selec {
        width: 100%;
        background-color: #F07130;
        border-radius: 5px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        padding-right: 20px;
        padding-left: 20px;
        margin: 0;
    }

    .prof-list-ver-perfil-cont {
        border: 1px solid #202256;
        width: 100%;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0;
    }
}
.cv-modal-button {
    border: 1px solid #202256;
    border-radius: 5px;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #202256;
    padding-right: 20px;
    padding-left: 20px;
    margin-left: 10px;
}

.cv-modal-button:hover {
    background: #202256;
    color: #fff;
    /* font-weight: 700; */
}

.cv-modal-linkedin-button {
    width: 190px;
    height: 35px;
    background: #0D0033;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px;
    color: #FFFFFF;
    margin-right: 15px;
}

.cv-modal-button-cont {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*justify-content: center;*/
}

.cv-modal-error {
    font-weight: 300;
    font-size: 12px;
    color: #E71C00;
    margin-bottom: 10px;
}

.cv-modal-message {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #5D5D5D;
}

.cv-modal-message-link {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #202256;
}

.cv-modal-button-cont2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cv-modal-cvgeneral-button {
    width: 190px;
    height: 35px;
    background: #D6D6D6;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px;
    color: #5D5D5D;
    margin-left: 15px;
}
@media (max-width: 425px) {
    .cv-modal-button{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .cv-modal-button{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.foot-but-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.foot-but-select {
    width: 100%;
    height: 33px;
    background-color: #F07130;
    border-radius: 5px;
    border: none;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
}

.aplic-card-glob-sub-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 23px;
}

.aplic-card-info-subcont {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.aplic-card-info-subcont-2 {
    flex-direction: column;
    justify-content: center;
}

.aplic-blob-num {
    font-weight: 700;
    font-size: 12px;
    color: #FFFFFF;
    margin-top: 9px;
}

.blob-cont-1 {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F07130;
    width: 33px;
    height: 33px;    
    position: relative;
    top: 20px;
}

.blob-cont-2 {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFBD6E;
    width: 33px;
    height: 33px;    
    position: relative;
    top: 20px;
}

.blob-cont-3 {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFDB99;
    width: 33px;
    height: 33px;
    position: relative;
    top: 20px;
}

.aplic-card-img {
    width: 81px;
    height: 81px;
    object-fit: cover;
    border-radius: 50%;
}

.aplic-card-img-filtro-2 {
    -webkit-filter: blur(3px);
            filter: blur(3px);
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
}

.aplic-card-img-filtro {
    /*filter: blur(3px);*/
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
}


.aplic-card-compat {
    /*margin-left: 5px;*/
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #F07130;
}

.aplic-card-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.aplic-card-name {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.aplic-card-name-ent {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
    display: none;
}

.aplic-card-job {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
}

.aplic-card-job-ent {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
    display: none;
}

.aplicantes-card-glo-cont {
    background: #FFFFFF;
    border-radius: 8px;
    margin: 12px;
    min-width: 297px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-right: 17px;
    padding-left: 20px;
}

.aplic-card-ver-perfil-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
; 
}
.aplic-card-ver-perfil:hover {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%); 
    color: #202256; 
    font-weight: 500;
    stroke-width: 1.5px; 
}

.aplic-card-ver-perfil svg{
    stroke: #202256;
}

.aplic-card-ver-perfil {
    font-weight: 400;
    font-size: 14px;
    color: #202256;
    margin-right: 10px;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

.aplic-card-button {
    background: transparent;
    border: 1px solid #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #202256;
    padding-right: 20px;
    padding-left: 20px;

}

.aplic-buttons-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .aplic-card-info-subcont {
        text-align: center;
    }

    .aplic-card-ver-perfil-cont {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 50%;
    }

    .aplic-card-name {
        font-weight: 700;
        font-size: 16px;
        color: #393939;
        display: none;
    }

    .aplic-card-name-ent {
        font-weight: 700;
        font-size: 16px;
        color: #393939;
        display: block;
    }

    .aplic-card-job {
        font-weight: 400;
        font-size: 12px;
        color: #5D5D5D;
        display: none;
    }

    .aplic-card-job-ent {
        font-weight: 400;
        font-size: 12px;
        color: #5D5D5D;
        display: block;
    }

    .aplic-card-glob-sub-cont {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: 23px;
    }
}

@media (max-width: 425px) {

    .aplic-card-ver-perfil-cont {
        border: 1px solid #202256;
        border-radius: 5px;
        width: 100%;
        margin-bottom: 15px;
    }

    .aplic-card-name {
        font-weight: 700;
        font-size: 16px;
        color: #393939;
        display: block;
    }

    .aplic-card-name-ent {
        font-weight: 700;
        font-size: 16px;
        color: #393939;
        display: none;
    }

    .aplic-card-job {
        font-weight: 400;
        font-size: 12px;
        color: #5D5D5D;
        display: block;
    }

    .aplic-card-job-ent {
        font-weight: 400;
        font-size: 12px;
        color: #5D5D5D;
        display: none;
    }

    .aplic-card-glob-sub-cont {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 23px;
    }

    .aplic-buttons-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .aplicantes-card-glo-cont {
        background: #FFFFFF;
        border-radius: 8px;
        border: 2px solid #F07130;
        min-width: 100%;
        margin: 15px 0 0 0;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-right: 17px;
        padding-left: 20px;
    }
}

@media (max-width: 1024px) {
    .aplicantes-card-glo-cont {
        background: #FFFFFF;
        border-radius: 8px;
        margin: 15px 0 0 0;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-right: 17px;
        padding-left: 20px;
    }
}
.dashop-line-opt-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #D6D6D6;
    margin-top: 35px;
}

.dashop-tab-active {
    border-bottom: 3px solid #202256;
    font-weight: 700;
    font-size: 16px;
    color: #393939;
    margin-right: 150px;
    height: 40px;
    position: relative;
    top: 9px;
    cursor: pointer;
}

.dashop-tab-inactive {
    font-weight: 400;
    font-size: 16px;
    color: #393939;
    margin-right: 150px;
    height: 40px;
    position: relative;
    top: 9px;
    cursor: pointer;
}

.dashop-tab-number {
    font-weight: 400;
    font-size: 16px;
    color: #C5C5C5;
}

.dashop-tab-user-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 6px;
    padding: 10px;
}

.dashop-tab-cont {
    margin-top: 45px;
}

.dashop-tab-img-filtro {
    -webkit-filter: blur(3px);
            filter: blur(3px);
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
}

.dashop-tab-img-filtro-2 {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
}

.dashop-tab-img {
    width: 74px;
    height: 74px;
    object-fit: cover;
    border-radius: 50%;
}

.dashop-tab-sub-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dashop-tab-sub-sub-cont {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width:350px;
}

.dashop-tab-sub-sub-cont2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dashop-selection-buton{
    background-color: #F07130;
    border-radius: 5px;
    color: white;
    font-weight: 400;
    font-size: 14px;
    margin-right: 15px;
    margin-bottom: 10px;
}

.dashop-tab-name {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.dashop-tab-job {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
}

.dashop-entrev {
    font-weight: 400;
    font-size: 14px;
    color: #E7BF00;
}

.dashop-selected {
    font-weight: 400;
    font-size: 14px;
    color: #24D054;
}

.dashop-entrev-subcont {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}


.dashop-entrev-compat {
    font-weight: 400;
    font-size: 14px;
    color: #F07130;
}

.aplic-card-ver-selec {
    margin-left: 20px;
    margin-right: 20px;
    background-color: #F07130;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    padding-right: 20px;
    padding-left: 20px;
}

.aplic-card-ver-perfil{
    display: flex;
    background-color: transparent;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 105px;
    color: #202256;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%)
}

.aplic-card-ver-perfil:hover {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%); 
   color: #202256; 
   font-weight: 500;
   stroke-width: 1.5px; 
}
.aplic-card-ver-perfil svg{
    stroke: #202256;
}

@media (max-width: 930px) {    
    .buton-cont-2-cont {
        display: flex;
        height: 150px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .dashop-tab-user-cont {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .dashop-tab-sub-cont {
        flex-direction: column;
        align-items: center;
    }

    .dashop-tab-active {
        margin-right: 30px;
    }

    .dashop-tab-inactive {
        margin-right: 30px;
    }
    .dashop-tab-sub-sub-cont{
        width:auto;
    }
}
.prof-sim-card-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 65px;
}

.prof-sim-card-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin: 15px;
}

.prof-sim-card-image-filtro {
    -webkit-filter: blur(3px);
            filter: blur(3px);
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
}

.prof-sim-card-name {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}

.prof-sim-card-job {
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.prof-sim-card-comp {
    font-weight: 400;
    font-size: 14px;
    color: #F07130;
}

.prof-sim-card-button {
    background: transparent;
    border: 1px solid #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #202256;
    padding-right: 20px;
    padding-left: 20px;
}
/**/
.profile-empresa-global-cont {
    margin-left: calc(237px + 98px);
    margin-right: 20px;
}

.profile-title-cont {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
}

.subcont-profile-empresa {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
}

.cont-card-info {
    width: 50%;
}

.profle-empresa-infoprincipal-cont {
    display: flex;
    /*align-items: center;*/
    justify-content: space-between;
    flex-direction: row;
}

.subcont-profile-empresa-summary {
    margin-left: 40px;
    margin-right: 50px;
}

/**/

@media (max-width: 1300px) {
    .profile-empresa-global-cont {
        margin-left: 20px;
    }
}


@media (max-width: 725px) {
    .subcont-profile-empresa {
        flex-direction: column;
        align-items:center;
    }

    .cont-card-info {
        width: 100%;
    }
    .container-infoCompany{
        margin-right: 0px !important;
    }
}

@media (max-width: 620px) {
    .profle-empresa-infoprincipal-cont {
        flex-direction: column;
    }

    .subcont-profile-empresa-summary {
        margin-left: 0px;
        margin-right: 0px;
    }
    .resp-image {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 35px;
    }
}
.container-infoCompany{   
    background: #FFFFFF;
    border-radius: 5px;      
    margin-top: 22px;
    margin-right: 3em;
    padding: 3em;
}

.projects-company h1{   
    font-weight: 700;
font-size: 40px;
line-height: 50px;
display: flex;
align-items: center;
color: #F07130;
}

.projects-company h3,
.info-company h3{   
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;   
    color: #202256;
}

.info-company{
width:50%;
}

.info-company p{   
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;   
    color: #5D5D5D;
    align-items: center;
}

.detalles-oportunidades p{
    color: #151515FC;
    font-size: 14px;
    font-weight: 700;
    margin: 1em 0 0 2em;
   

}

.detalles-oportunidades span{
    color: #5D5D5D;
    font-size: 14px;   
    margin-left: 2em;
}
.form-edit-profile-Company h3{
    font-weight: 700;
    font-size: 16px;    
    color: #393939;    
    /* line-height: 22px; */
}

.form-edit-profile-Company label{
    font-size: 14px;
    line-height: 20px;
    color: #393939;   
}

.form-edit-profile-Company p{
   color: #C5C5C5;
    font-size: 12px;
    /* line-height: 20px; */
    margin-bottom: 2em;
}

.form-edit-profile-Company input , .form-edit-profile-Company textarea{
    border: 0.5px solid #C5C5C5;
}

.buttonGuardar{
display:flex;
justify-content: flex-end;
margin-top: 1em;
}

.buttonGuardar button{
    position: relative;
    width: 105px;
    height: 24px;   
    color: white;
    background: #202256;
    border-radius: 5px;
  }


@media (max-width: 725px){
    .css-1uvkz21{
        width:350px !important;
        
    }
    .container-input-edit-profile-Company{
        min-width:90% !important;
    }
}  
.top-part{
    width: calc(100vw - 237px);
}

.container-prof {
    width: 95%;
    margin: 4rem auto; 
}

.info-section {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.main-section-info {
    margin-left: 3%;
     width: 90%; 
     display: flex;
}
.first-container {
    width: 74vw;
}
.card-container-info {
    background-color: white;
    width: 40%;
    border-radius: 5px;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    padding: 44px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.about .extracto-info {
    font-family: "Poppins";
    color: #5D5D5D;
    font-weight: 300;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    word-wrap: break-word;
}

@media(max-width: 1300px) {
 .container-prof {
    max-width: revert !important;   
 }
 .top-part{
    width:100% !important;
    margin-right: auto !important;
 }
}

@media (max-width:725px) {
    .container-prof {
     display: contents !important; ;
     justify-content: center !important;
    }
    .top-part {
        display: contents !important;
        justify-content: center !important;
    }
    /*.title-top {*/
    /*    margin-top: 3em !important;*/
    /*    margin-bottom: 2em !important;   */
    /*    margin-left: 1em !important;  */
    /*}*/
    .first-container{
       width: 100vw;
       display: contents !important;     
    }
    .info-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .info-section .info-section-avatar {
        margin-bottom: 1em !important;
    }
    .info-section .about {
        width: 360px;
       /*  margin-right: 60em; */
        margin-top: 3em;
        display: contents !important; ;
     justify-content: center !important;
    }
    .info-section .card-container-info {
        width: 90%;
        margin-top: 2em;
    }
    .info-section .stars-rating {
        margin-right: 5em !important;
    }
    .about .extracto-info {
        width: 320px !important;
    }
    .main-section-info {
        margin-left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}
.cardSector-container{
    width: 220px;
    height: 220px;
    border-radius: 8px;
    background-size: cover;
}

.cardSector-subContainer{
    display: flex;
    align-items: flex-end;
    height: 100%;
    margin-left: 20px;
}

.cardSector-title{
    color: #fff;
    font-weight: 700;
    font-size: 18px;
}

.cardSector-cant{
    color: #fff;    
    font-size: 16px;
}
.cardHabilidades-Container{
    width: 100%;
    height: 71px;
    baclground-color: #fff;
    box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.cardHabilidades-SubContainer{
    display: flex;
    align-items: center;
    margin-left: 20px;
    width: 100%;
    justify-content: space-between;
}

.cardHabilidades-title{
    font-size: 20px;
    font-weight: 700;
    color: #393939;
    width: 100%;
    margin:0;
}

.cardHabilidades-cant{
    font-size: 14px;   
    color: #5D5D5D;    
}

.cardHabilidades-button{
    width: 190px;
    height: 40px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;    
}

@media (max-width: 768px){
    .cardHabilidades-SubContainer{       
        align-items: flex-start;
    }
    .cardHabilidades-title{
        font-size: 16px;
        
    }
    .cardHabilidades-SubContainer{
        margin-left: 10px;
    }
    .cardHabilidades-button{
        width:70px;
        font-size: 12px;
        align-items: flex-end;
    }
    .cardHabilidades-button svg{
        display:none;
    }
}
.contenedor-formacion {
    background-color: #F5F5F5;
    display: flex;
    z-index: 100;
}

.formacion-title{
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    color: #202256;
    margin-bottom: 10px;
}

.formacion-search-responsive {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.formacion-cards-videos{
    display: flex;
    align-items: flex-end;
}
.formacion-card-new-video{
    padding-right: 20px;
}

.formacion-cards{
    display: flex;
    justify-content: flex-start;
    margin-top: 2%;
    width: 100%;
    overflow-x: hidden;
}

@media (max-width: 768px) {
    .formacion-card-new-video{
        padding-right: 0px;
    }
    .formacion-cards-videos{
        flex-direction: column;
        align-items: center;
    }
    .formacion-search-responsive  {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .formacion-cards{
        overflow-x: scroll;
    }
}

.buttoooon{
  padding-left: .5em;
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 14px;
  text-decoration: underline;

}
.input-register-new input{

  width: 348px;
  height: 41px;

  font-style: normal;
  font-weight: 400;

  padding-left: 22px;
  font-size: 16px;
  border: 1px solid #C5C5C5;
  margin-left: 3em;
  margin-right: 3em;
}
.input-register-new input::-webkit-input-placeholder{
  color: #C5C5C5;

}
.input-register-new input:-ms-input-placeholder{
  color: #C5C5C5;

}
.input-register-new input::placeholder{
  color: #C5C5C5;

}
.input-register-new input:focus{
  color: #636161;
  padding-left: 22px;
  outline: 0.5px solid #E7BF00;
}

.register-forgot-pass{
  color: #E7BF00;
  font-size: 14px;
  font-weight: 400;
  margin-top: 2%;
  cursor: pointer;
}
.register-forgot-pass:hover{
  color: #D4B000;
}
.register-button-new{
  background: #202256;
  border-radius: 5px;
  width: 100%;
  height: 33px;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: none;
}
.register-register-new{
  color: #202256;
  font-size: 14px;
  font-weight: bold;
  margin-top: 1%;
  cursor: pointer;
 margin-left: .5em;
  width: 6.5em;
  justify-content: flex-start;
}
.register-register-new:hover{
  color: #0B27E6;
}

.b-contain *,
.b-contain *::before,
.b-contain *::after {
  box-sizing: content-box !important;
}

.b-contain input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.b-contain span {
  line-height: 1.5;
  font-size: 1rem;
  font-family: inherit;
}

.b-contain {
  display: table;
  position: relative;
  padding-left: 1rem;
  cursor: pointer;
  margin-bottom: 0.4rem;


}

.b-contain input[type="checkbox"] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background: #f1f5f9;
  transition: background 250ms;
  border: 1px solid #94a3b8;
  border-radius: 3.21rem;
}

.b-contain input[type="radio"] ~ .b-input {
  position: absolute;
  top: 5px;
  left: 0;
  height: 0.80rem;
  width: 0.80rem;
  background: #f1f5f9;
  transition: background 250ms;
  border: 1px solid #94a3b8;
  border-radius: 4.15rem;
}

.b-contain input[type="checkbox"] ~ .b-input::after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 0.3rem;
  height: 0.6rem;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transition: background 250ms;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.b-contain input[type="radio"] ~ .b-input::after {
  content: "";
  position: absolute;
  display: none;
  top: 3px;
  left: 3px;
  width: 0.40rem;
  height: 0.40rem;
  border-radius: 3rem;
  background: rgb(32, 34, 86);
  transition: background 250ms;
}

.b-contain input[type="checkbox"]:disabled ~ .b-input::after {
  border-color: #ffffff;
}

.b-contain input:checked ~ .b-input::after {
  display: block;
}

.b-contain:hover input[type="checkbox"]:not([disabled]) ~ .b-input,
.b-contain input[type="checkbox"]:focus ~ .b-input {
  background: #e2e8f0;
  border-color: #64748b;
}

.b-contain:hover input[type="radio"]:not([disabled]) ~ .b-input,
.b-contain input[type="radio"]:focus ~ .b-input {
  background: #e2e8f0;
  border-color: #64748b;
}

.b-contain input:focus ~ .b-input {
  box-shadow: 0 0 0 2px rgb(32, 34, 86);
}
.cropContainer{
  position: relative;
  width: 100%;
    height: 200px;
  background: #333;
}

@media(max-width: 1368px){
  .container-register{
    height: auto !important;
  }
  .container-register p{
margin-bottom: 0.5em !important;
  }
}

@media(max-width: 725px) {
  .terms-condition {
    width: auto !important;
    margin-left: 0 !important;
  }
}

.row {
  display: flex;
  flex-flow: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: relative;
  left: -1%;
  height: 100vh;
  width: 100%;
}

.row h1 {
  font-family: Poppins;
  text-align: center;
}

.card {
  border-radius: 5%;
  /*background-color: white;*/
  width: 35%;
  height: 45%;
}

.otp {
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
}

.otp input {
  margin: 5px;
  height: 35px;
  width: 40px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-family: Poppins;
  font-size: 1.2rem;
  background: #c8c8c8;
}

.body {
  border-radius: 5%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  color: white;
}

.body button {
  background-color: #202256;
  color: white;
  border-radius: 5%;
  margin-top: 2%;
  font-size: 16px;
  width: 25%;
  height: 50px;
}

.otp-button-new{
  background: #202256;
  border-radius: 5px;
  width: 300px;
  height: 33px;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: none;
}
.masterclass-card-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 324px;
    height: 240px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 10%;
    transition: all 0.3s ease;
}

.masterclass-card-container:hover{
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: 0.5s;
}

.masterclass-card-container:hover .masterclass-card-title-container{
    background-color: #202256;
    transition: 0.5s;
}

.masterclass-card-container:hover .card-masterclass-title{
    color: white;
    transition: 0.5s;
}

.masterclass-card-title-container{
    height: 140px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    background-color: white;
    transition: all 0.3s ease;
    border-radius: 0 0 8px 8px;
}

.card-masterclass-title{
    font-size: 1.5em;
    font-weight: bold;
    color: #393939;
    text-align: center;
    padding: 10px;
    align-self: center;
    transition: all 0.5s;
}


.bootcampSoste-home-welcome-video{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.home-unilever-banner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
}

.container-buttons-uni {
    display: flex;
    margin-top: 3%;
    width: 90%;
}

.button-home-unilever {
    width: 324px;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #fff;
    border-radius: 8px;
    margin-right: 30px;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}

.button-home-unilever:hover {
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .container-buttons-uni {
        flex-direction: column;
        align-items: center;
        margin-top: 5%;
    }

    .button-home-unilever {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }
}

/*marcelo css*/

.home-unilever-subtitle {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
}

.home-unilever-carrusel-global-container {
    width: 90%;
}

.home-unilever-carrusel-top {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
    width: 100%;
}

.home-unilever-carrusel-sub-container {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
}

.home-unilever-arrow-button {
    background-color: transparent;
}

@media (max-width: 600px) {
    .home-unilever-carrusel-top {
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        width: 100%;
    }
}
.CarruselCards-global-container {
    background: #FFFFFF;
    border-radius: 8px;
    min-width: 436px;
    height: 337px;
    margin-right: 40px;
    margin-bottom: 40px;
}

.CarruselCards-global-title {
    font-weight: 700;
    font-size: 20px;
    color: #393939;
    margin-bottom: 30px;
}

.CarruselCards-global-desc {
    font-weight: 400;
    font-size: 16px;
    color: #252525;
}

.CarruselCards-image-container {
    background-position: top;
    height: 150px;
    border-radius: 8px 8px 0 0;
    background-repeat: no-repeat;
    background-size: cover;
}
.CarruselCards-video-container{
    background-position: top;
    height: 100%;
    width: 100%;
    border-radius: 8px 8px 0 0;
    background-repeat: no-repeat;
    background-size: cover;
}

/*.CarruselCards-image {
    object-fit: cover;
    width: 100%;
    height: 150px;
    border-radius: 8px 8px 0 0;
}*/

.CarruselCards-sub-container {
    padding: 20px;
}
.popup-date-unilever-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-date-unilever-inner-1-1{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.popup-date-unilever-inner-1{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-date-unilever-inner-2{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.date-popup-container-big{
  width: 500px;
  height: 800px;
}

.day-tile{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #fe7c7c;
  background-color: #fe7c7c;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
 background: #f8f8fa;
 border-radius: 6px;
}
.react-calendar__tile--now {
  background: transparent !important;
 border-radius: 6px;
 font-weight: normal;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
 border-radius: 6px;
 font-weight: bold;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
 background: #f8f8fa;
}
.react-calendar__tile--active {
 background: #f07130 !important;
 font-weight: bold;
 color: white;
}
.react-calendar__tile:disabled{
    background: #656565;
    font-weight: normal;
    color: #f8f8fa;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
 background: #f07130 !important;
 color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
 background-color: #f8f8fa;
}

.unilever-buttons-hours-grid{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  place-items: center;
  align-items: center;
}
.unilever-select-button-hour{
  border: 1px solid #202256;
  border-radius: 8px;
  width: 100px;
  height: 30px;
  background-color: #E5DCF7;
  color: #202256;
}

.unilever-select-button-hour2{
  border: 1px solid #202256;
  border-radius: 8px;
  width: 100px;
  height: 30px;
  background-color: #202256;
  color: white;
}

.unilever-select-button-hour:hover{
  background-color: #f07130;
  color: white;
}

.unilever-select-button-hour2:hover{
  background-color: #f07130;
  color: white;
}

.unilever-select-button-hour-active{
  border: 1px solid #202256;
  width: 100px;
  height: 30px;
  background-color: #f07130;
  border-radius: 8px;
  color: white;
}
.fc .fc-toolbar.fc-header-toolbar {
    display: flex;
    flex-direction: row;
    width: 65%;
    align-self: flex-start;
    margin-bottom: 5px;
}

th {
    background-color: #f9f9f9;
    border: #f9f9f9 !important;
    border-bottom: 1px solid #f9f9f9 !important;
    font-weight: normal;
}

.fc-scrollgrid-sync-inner {
    border-style: none !important;
}

.fc .fc-toolbar.fc-header-toolbar {
    border-style: none !important;
    width: 25%;
    margin-bottom: 20px;
}

.fc-toolbar-chunk {
    border: none !important;
}

.event-background {
    margin: 5px;
    width: 100%;
    background-color: #E5DCF7;
    border-left: 3px solid #202256;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    align-items: flex-start;
    padding: 5px;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
}

.event-mentor {
    font-weight: 300;
    font-size: 12px;
    color: #8875D1;
}

.event-background p {
    overflow: hidden;
}

.buttonUni-busqueda-mentor {
    background-color: #F07130;
    height: 37px;
    width: 200px;
    border-radius: 5px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-weight: normal;
    font-size: 12px;
}

.event-title {
    color: #202256;
    font-weight: 700;
    font-size: 12px;
}

.ContenedorUni-Search-Button-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.ContenedorUni-banner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
}

.img-bannerUni {
    width: 100%;
}

.ContenedorUni-Grande {
    width: 90%;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /*margin-left: 20%;*/
    margin: 5%;
    position: relative;
}

.buttonUni-acceso-meet {
    background-color: #f07130;
    height: 37px;
    width: 100%;
    border-radius: 5px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    margin-top: 20px;
}

.buttonUni-acceso-feedback {
    margin-top: 20px;
    background-color: #27165b;
    height: 37px;
    width: 100%;
    border-radius: 5px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    margin-top: 20px;
}

.titledetalleUni-acceso-meet {
    font-weight: 400;
    font-size: 16px;
    color: #393939;
}

.titlePrincipadetalleUni-acceso-meet {
    font-weight: 700;
    font-size: 20px;
    color: #393939;
}

.ContenedorUni-Search-Button-2 {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.ContenedorUni-busqueda {
    background-color: white;
    height: 35px;
    width: 330px;
    border-radius: 5px;
    border: none;
    font-size: 12px;

    text-indent: 25px;

}

.ContenedorUni-busqueda::-webkit-input-placeholder {
    color: #202256;
    margin-left: 13px;
}

.ContenedorUni-busqueda:-ms-input-placeholder {
    color: #202256;
    margin-left: 13px;
}

.ContenedorUni-busqueda::placeholder {
    color: #202256;
    margin-left: 13px;
}

.ContenedorUni-busqueda:focus {
    border-color: #202256;
    outline: none;
}

.Search-icon-uni {
    font-size: 50px;
    color: #202256;
    position: absolute;
    margin-top: 5px;


}

.fc .fc-view-harness-active > .fc-view {
    background-color: white !important;
}

.event-hour {
    color: #202256;
    font-size: 12px;
    font-weight: 400;
}

.camera-event-background {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: -18px;
}

.fc-col-header {
    font-weight: lighter !important;
    background: transparent !important;
    color: black;

    border-style: none;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
}

:root {
    --fc-event-bg-color: #E5DCF7;
    --fc-event-text-color: #8875D1;
    --fc-event-border-color: #E5DCF7;


}

@media (max-width: 1510px) {

}

@media (max-width: 1430px) {

}

@media (max-width: 1335px) {

}

@media (max-width: 1230px) {

}



@media (max-width: 1058px) {


    .ContenedorUni-busqueda {
        width: 250px;
    }
}

@media (max-width: 980px) {


    .ContenedorUni-busqueda {
        width: 200px;
    }
}

@media (max-width: 795px) {


    .ContenedorUni-busqueda {
        width: 150px;
    }

    .event-img {
        display: none;
    }
}

@media (max-width: 700px) {


    .ContenedorUni-busqueda {
        width: 150px;
    }

    .buttonUni-busqueda-mentor {
        height: 28px;
        width: 130px;
    }

    .event-img {
        display: none;
    }

    .fc .fc-toolbar.fc-header-toolbar{
        width: 100%;
    }

    .event-background{
        margin:0px;
        width:auto;
        border-left: 0px;
        flex-direction: row;
    }
    .camera-event-background{
            width:auto;
    }
}

@media (max-width: 435px) {

}

@media (max-width: 395px) {

    .ContenedorUni-busqueda {
        width: 120px;

    }

    .buttonUni-busqueda-mentor {
        height: 24px;
        width: 90px;

    }
}

/* @media (max-width: 640px){
  .event-img{
      display: none;
  }
  .buttonUni-busqueda-mentor{
  margin-left: 5%;
  }
  .ContenedorUni-busqueda{
      width: 20px;
  }

}
@media (max-width: 530px){
  .ContenedorUni-Search-Button-1{
      width: 20%;
  }
}
@media (max-width: 730px){
.ContenedorUni-Search-Button-1{
  display: flex;
  width: 70%;
  flex-direction: column;
}
}
@media (max-width: 425px) {
  .ContenedorUni-Search-Button-1{
      display: flex;
      width: 80%;
      flex-direction: column;
  }
  .ContenedorUni-Search-Button-2 {
      display: flex;
      width: 100%;
      flex-direction: column;

      margin-top: 10px;
  }
  .event-img{
      display: none;
  }

  .ContenedorUni-busqueda {
      background-color: transparent;
      height: 35px;
      width: 20%;
      border-radius: 5px;
      border-style: solid;
      border-width: 2px;
      border-color: #C5C5C5;
      text-indent: 25px;
      margin-bottom: 20px;
  }
}
@media (max-width: 930px) {
  .ContenedorUni-Search-Button-1{
      flex-direction: column;

      width: 400px;
      margin-left: 10%;
  }
  .buttonUni-busqueda-mentor{
margin-left: 70%;
  }
  .event-img{
      display: none;
  }
  .ContenedorUni-busqueda{
      width: 25%;
      margin-right: 10%;
  }
  .ContenedorUni-Search-Button-2{
      width: 100%;
      margin: auto;
  }
.buttonUni-busqueda-mentor{
  width: 90px;
}
}
*/

.masterclass-card-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 324px;
    height: 240px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 10%;
    transition: all 0.3s ease;
}

.masterclass-card-container:hover{
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: 0.5s;
}

.masterclass-card-container:hover .masterclass-card-title-container{
    background-color: #202256;
    transition: 0.5s;
}

.masterclass-card-container:hover .card-masterclass-title{
    color: white;
    transition: 0.5s;
}

.masterclass-card-title-container{
    height: 140px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    background-color: white;
    transition: all 0.3s ease;
    border-radius: 0 0 8px 8px;
}

.card-masterclass-title{
    font-size: 1.5em;
    font-weight: bold;
    color: #393939;
    text-align: center;
    padding: 10px;
    align-self: center;
    transition: all 0.5s;
}


.bootcampSoste-home-welcome-video{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.cardSector-container{
    width: 220px;
    height: 220px;
    border-radius: 8px;
    background-size: cover;
}

.cardSector-subContainer{
    display: flex;
    align-items: flex-end;
    height: 100%;
    margin-left: 20px;
}

.cardSector-title{
    color: #fff;
    font-weight: 700;
    font-size: 18px;
}

.cardSector-cant{
    color: #fff;    
    font-size: 16px;
}
.cardHabilidades-Container{
    width: 100%;
    height: 71px;
    baclground-color: #fff;
    box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.cardHabilidades-SubContainer{
    display: flex;
    align-items: center;
    margin-left: 20px;
    width: 100%;
    justify-content: space-between;
}

.cardHabilidades-title{
    font-size: 20px;
    font-weight: 700;
    color: #393939;
    width: 100%;
    margin:0;
}

.cardHabilidades-cant{
    font-size: 14px;   
    color: #5D5D5D;    
}

.cardHabilidades-button{
    width: 190px;
    height: 40px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;    
}

@media (max-width: 768px){
    .cardHabilidades-SubContainer{       
        align-items: flex-start;
    }
    .cardHabilidades-title{
        font-size: 16px;
        
    }
    .cardHabilidades-SubContainer{
        margin-left: 10px;
    }
    .cardHabilidades-button{
        width:70px;
        font-size: 12px;
        align-items: flex-end;
    }
    .cardHabilidades-button svg{
        display:none;
    }
}
.contenedor-formacion {
    background-color: #F5F5F5;
    display: flex;
    z-index: 100;
}

.formacion-title{
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    color: #202256;
    margin-bottom: 10px;
}

.formacion-search-responsive {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.formacion-cards-videos{
    display: flex;
    align-items: flex-end;
}
.formacion-card-new-video{
    padding-right: 20px;
}

.formacion-cards{
    display: flex;
    justify-content: flex-start;
    margin-top: 2%;
    width: 100%;
    overflow-x: hidden;
}

@media (max-width: 768px) {
    .formacion-card-new-video{
        padding-right: 0px;
    }
    .formacion-cards-videos{
        flex-direction: column;
        align-items: center;
    }
    .formacion-search-responsive  {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .formacion-cards{
        overflow-x: scroll;
    }
}

.buttoooon{
  padding-left: .5em;
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 14px;
  text-decoration: underline;

}
.input-register-new input{

  width: 348px;
  height: 41px;

  font-style: normal;
  font-weight: 400;

  padding-left: 22px;
  font-size: 16px;
  border: 1px solid #C5C5C5;
  margin-left: 3em;
  margin-right: 3em;
}
.input-register-new input::-webkit-input-placeholder{
  color: #C5C5C5;

}
.input-register-new input:-ms-input-placeholder{
  color: #C5C5C5;

}
.input-register-new input::placeholder{
  color: #C5C5C5;

}
.input-register-new input:focus{
  color: #636161;
  padding-left: 22px;
  outline: 0.5px solid #E7BF00;
}

.register-forgot-pass{
  color: #E7BF00;
  font-size: 14px;
  font-weight: 400;
  margin-top: 2%;
  cursor: pointer;
}
.register-forgot-pass:hover{
  color: #D4B000;
}
.register-button-new{
  background: #202256;
  border-radius: 5px;
  width: 100%;
  height: 33px;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: none;
}
.register-register-new{
  color: #202256;
  font-size: 14px;
  font-weight: bold;
  margin-top: 1%;
  cursor: pointer;
 margin-left: .5em;
  width: 6.5em;
  justify-content: flex-start;
}
.register-register-new:hover{
  color: #0B27E6;
}

.b-contain *,
.b-contain *::before,
.b-contain *::after {
  box-sizing: content-box !important;
}

.b-contain input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.b-contain span {
  line-height: 1.5;
  font-size: 1rem;
  font-family: inherit;
}

.b-contain {
  display: table;
  position: relative;
  padding-left: 1rem;
  cursor: pointer;
  margin-bottom: 0.4rem;


}

.b-contain input[type="checkbox"] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background: #f1f5f9;
  transition: background 250ms;
  border: 1px solid #94a3b8;
  border-radius: 3.21rem;
}

.b-contain input[type="radio"] ~ .b-input {
  position: absolute;
  top: 5px;
  left: 0;
  height: 0.80rem;
  width: 0.80rem;
  background: #f1f5f9;
  transition: background 250ms;
  border: 1px solid #94a3b8;
  border-radius: 4.15rem;
}

.b-contain input[type="checkbox"] ~ .b-input::after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 0.3rem;
  height: 0.6rem;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transition: background 250ms;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.b-contain input[type="radio"] ~ .b-input::after {
  content: "";
  position: absolute;
  display: none;
  top: 3px;
  left: 3px;
  width: 0.40rem;
  height: 0.40rem;
  border-radius: 3rem;
  background: rgb(32, 34, 86);
  transition: background 250ms;
}

.b-contain input[type="checkbox"]:disabled ~ .b-input::after {
  border-color: #ffffff;
}

.b-contain input:checked ~ .b-input::after {
  display: block;
}

.b-contain:hover input[type="checkbox"]:not([disabled]) ~ .b-input,
.b-contain input[type="checkbox"]:focus ~ .b-input {
  background: #e2e8f0;
  border-color: #64748b;
}

.b-contain:hover input[type="radio"]:not([disabled]) ~ .b-input,
.b-contain input[type="radio"]:focus ~ .b-input {
  background: #e2e8f0;
  border-color: #64748b;
}

.b-contain input:focus ~ .b-input {
  box-shadow: 0 0 0 2px rgb(32, 34, 86);
}
.cropContainer{
  position: relative;
  width: 100%;
    height: 200px;
  background: #333;
}

@media(max-width: 1368px){
  .container-register{
    height: auto !important;
  }
  .container-register p{
margin-bottom: 0.5em !important;
  }
}

@media(max-width: 725px) {
  .terms-condition {
    width: auto !important;
    margin-left: 0 !important;
  }
}

.SidebarCV-Button {
    background: #1f1561;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 35px;
    width: 170px;
    margin-bottom: 50px;
}

.SidebarCV-cont {
    width: 100%;
}

.SidebarCV-Err {
    margin-top: 15px;
    color: #5D5D5D;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}

.SidebarCV-LI {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-LI-2 {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E5DCF7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
}

.SidebarCV-LI-Err{
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E71C00;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV {
    display: flex;
    cursor: pointer;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV-disabled {
    display: flex;
    cursor: not-allowed !important;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #868686;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-Sub-Cont {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 45px;
}

.SidebarCV-Sub-Text {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #5D5D5D;
}

.SidebarCV-Sub-Cont-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 45px;
}
.SidebarCV-Button {
    background: #1f1561;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 35px;
    width: 170px;
    margin-bottom: 50px;
}

.SidebarCV-Button-Dis {
    cursor: not-allowed !important;
    background: #D6D6D6;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    height: 35px;
    width: 170px;
    margin-bottom: 50px;
}

.SidebarCV-cont {
    width: 100%;
}

.SidebarCV-Err {
    margin-top: 15px;
    color: #5D5D5D;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}

.SidebarCV-LI {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-LI-2 {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E5DCF7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
}

.SidebarCV-LI-Err{
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E71C00;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV {
    display: flex;
    cursor: pointer;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV-disabled {
    display: flex;
    cursor: not-allowed !important;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #868686;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-Sub-Cont {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 45px;
}

.SidebarCV-Sub-Text {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #5D5D5D;
}

.SidebarCV-Sub-Cont-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 45px;
}
.SidebarCV-Button {
    background: #1f1561;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 35px;
    width: 170px;
    margin-bottom: 50px;
}

.SidebarCV-cont {
    width: 100%;
}

.SidebarCV-Err {
    margin-top: 15px;
    color: #5D5D5D;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}

.SidebarCV-LI {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-LI-2 {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E5DCF7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
}

.SidebarCV-LI-Err{
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 185px;
    background: #E71C00;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV {
    display: flex;
    cursor: pointer;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-CV-disabled {
    display: flex;
    cursor: not-allowed !important;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    width: 120px;
    background: #868686;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.SidebarCV-Sub-Cont {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 45px;
}

.SidebarCV-Sub-Text {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #5D5D5D;
}

.SidebarCV-Sub-Cont-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 45px;
}
.container-dashboard {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: #304758;
}

.container-dashboard-glass {
    height: 90%;
    width: 90%;
    border-radius: 8px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 30px;

}

.dashboard{
    display: flex;
    flex-direction: column;
    margin: 30px;
}

.tables-dashboard{
    display: flex;
    flex-direction: row;
    align-self: center;
}

.list-projects{
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 40%;
    border-radius: 0.7rem;
    flex: 1 1;
    position: relative;
    align-self: center;
    margin-top: 15px;
    margin-bottom: 5px;
}

.only-h3{
    border-radius: 35px;
    margin-top: 15px;
    align-self: center;
    text-align: center;
    width: 70px;
    height: 70px;
}

.chartContainer{
    display: flex;
    justify-content: center;
}

.button-add-cv-form{
  height: 40px;
  width: 30%;
  background-color: #27165b;
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.container-eliminar-experiencia{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.button-eliminar-experiencia{
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}

@media (max-width: 768px) {
  .button-add-cv-form{
    width: 100%;
  }
}
html {
  height: 100%;
}

:root {
  --main-purple-findally: #540e87;
  --main-yellow-findally: #f5cc00;
  --main-orange-findally: #feae3b;
  
    /* Colors: */
    --color-naranja:#F46036;
    --color-morado:#540E87;
    --color-morado-medio: #6A448B;
    --color-morado-oscuro: #463371;
    --color-azul: #212156;
    --color-negro:#393939;
    --color-amarillo: #FF982A;
    --color-blanco: #FFFFFF;
    --color-text-gris:#707070;
    --color-gris-ligth : #EAEAEA;
    --color-gris-medio: #464545;
    --color-verde:#40a832;
    
    /* Font/text values */
    --font-raleway: 'Raleway';
    --font-primary: 'Poppins';
    --font-ubuntu:'Ubuntu';
    --font-segundary:'Helvetica';
    --font-weight-bold: bold;
    --font-weight-normal: normal;
    --font-size-regular: 14px;
    --font-size-minimo:12px;
    --font-size-item:16px;
    --font-size-item-grande:18px;
    --font-size-cuerpo: 18px;
    --font-size-titulo-segundario: 26px;
    --font-size-titulo: 32px;
    --character-spacing-0: 0px;
    --line-spacing-38: 38px;
    --line-spacing-46: 46px;
    --line-spacing-59: 59px;
    --line-spacing-94: 94px; 
    }
    

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.container{
  width: 95%;
  margin: 4rem auto;
  /* max-width: 1440px; */
}

h3{
  font-size: 1.2rem;
}

h4{
  font-size: 1.2rem;
}
body {
  height: 100%;
  font-family: 'Poppins';
  font-family: var(--font-primary);
  background-color: #f9f9f9;
}
.input-btn{
  background-color: #f46036;
  border-radius: 0.25rem !important;
  border: none;
  font-size: 13px;
  padding: .5rem;
  cursor: pointer;
  box-sizing: border-box;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
}
.input-text{
  background: #ffffff00;
  color: #a7a3a3;
  width: 80%;
  font-size: medium;
  border: none;
  border-bottom: 2px solid !important;
  border-color: black !important;
  outline-style: none;
  box-sizing: border-box;
}
.Header-lang{
  background: transparent;
  z-index: 2;
  position: absolute;
  left: 90%;
  top: 5%;
}
a {
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  color: inherit;
}
button {
  text-decoration: none;
  outline: none;
  cursor: pointer;
  border: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: inherit;
}
.security-space{
  margin: 1rem;
}
.etiqueta{
  background-color: #21204B;
  color: #ffffff;
  font-size: 12px;
}
.security-space-min{
  margin: .5rem 0rem;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
.rounded{
  border-radius: 1rem;
}

.AppFormContainer{
    background-size: cover;
    height: 100%;
    min-height: 100vh;
    padding-top: 3rem;
    box-sizing: content-box;
    background-position: center;
    background-repeat: no-repeat;
}

.containerForm{
  margin:0;
}

.MuiOutlinedInput-root {
  position: relative;
  height: 50px;
  border-radius: 4px;
}

.volver-legal{
  background: #212156 ;
  text-transform: uppercase;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.volver-legal2{
  FONT-SIZE: 1.5vw;
  font-weight: 900;
  background-color: #f1f4f6;
  padding-top: 2% !important;
  padding-bottom: 5px !important;
  padding-left: 4%;

}
.flex-sp-bt{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Header-lang{
  top:3% !important;
}

.volver-demo{
  background: white !important;
  text-align: right;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.mediumfont{
  /* medium*/
  font-family:'Poppins', sans-serif !important;
  font-weight: 500 !important;
}
.semiboldfont{
  /* semibold*/
  font-family:'Poppins', sans-serif !important;
  font-weight: 600 !important;
}
.boldfont{
  /* bold*/
  font-family:'Poppins', sans-serif !important;
  font-weight: 700 !important;
}
.btn-more-pro{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  padding: 9px 25px;
  border-radius: 30px;
  background-color: #f07130;
}
.Ejemplo-con-dos-lineas-de-texto-corrido {
  padding: 0 0 1px;
  font-family:'Poppins', sans-serif !important;
  font-size: 20px;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.6px;
  text-align: left;
  color: #2e2e31;
}
.Texto-corrido {
  font-family:'Poppins', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.28px;
  text-align: left;
  color: #696a7c;
}
.sombrahover:hover{
background-color:rgb(228, 228, 228) !important;
cursor: pointer;
}
.grap{  
  grid-column-gap: 3%;
}
.margin0{
  margin: 0 !important;
}
