.soporte{
  padding-top: 50px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 5em;
}

.soporte-contactUs {
    background-color: #FFFFFF;  
    padding: 2%;    
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width:449px;
    height: 338px;  
    border-radius: 8px;
  }
  
.soporte-contactUs p {
    margin-top: 2em;
    margin-bottom: 10%;
    text-align: center; 
    color: #5D5D5D;
  }
.soporte-contactUs li {
  padding:1em;
  }
.soporte-contactUs span{
    margin-left:10px;    
  }

.container-form{
    width:442px;
    height: 456px;
    background-color: #FFFFFF;
  }

.config-form{
    display: flex;
    flex-direction: column;
    justify-content: center;       
    padding: 1.5em;
    overflow: auto;
    width: 100%;
    border-radius: 8px;
  }

  .title-form-contact{
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #5D5D5D;
  }

  .select-contact-us{
    border: 0.5px solid #202256;
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    height: 33px;
    width: 184px;  
    color:#202256; 
  }

.text-contact_us{ 
    border: 0.1px solid #a5a2a26e;
    padding: 3%;
    font-size: 12.5px;
    margin-bottom: 2em;
    resize: none;
    margin-top: 2em;
    height:174px;
}

.button-contact_us{
    background-color: #202256;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;   
  }


@media (max-width:725px) {
    .soporte{
        flex-direction: column;
        align-items: center;
    }
    .soporte-contactUs{
        margin-bottom: 2em;
        width:auto !important;
    }
    .container-form{
        width: 100%;
    }
}