.masterclass-card-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 324px;
    height: 240px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 10%;
    transition: all 0.3s ease;
}

.masterclass-card-container:hover{
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    transform: scale(1.05);
    transition: 0.5s;
}

.masterclass-card-container:hover .masterclass-card-title-container{
    background-color: #202256;
    transition: 0.5s;
}

.masterclass-card-container:hover .card-masterclass-title{
    color: white;
    transition: 0.5s;
}

.masterclass-card-title-container{
    height: 140px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    background-color: white;
    transition: all 0.3s ease;
    border-radius: 0 0 8px 8px;
}

.card-masterclass-title{
    font-size: 1.5em;
    font-weight: bold;
    color: #393939;
    text-align: center;
    padding: 10px;
    align-self: center;
    transition: all 0.5s;
}

