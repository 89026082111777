.postulate-global-container {
    background: #005eef;
    overflow-x: hidden;
}

@font-face {
    font-family: Unilever;
    src: url("../../assets/font/UnileverShilling.ttf");
  }

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  appearance: unset;
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  appearance: unset;
  -moz-appearance:textfield;
}

.postulate-ring1{
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(0%, 50%);
  z-index: 1;
}
.postulate-firulete1{
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(0%, 50%);
  z-index: 1;
}
.postulate-ring2{
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(0%, 300%);
    z-index: 1;
  }
  .postulate-firulete2{
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(0%, 400%);
    z-index: 1;
  }

.postulate-global-sub-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.postulate-separator {
    width: 100%;
    height: 1px;
    border: 1px solid #FBFBFB;
    background: #FBFBFB;
    margin-bottom: 40px;
}

.postulate-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.postulate-footer {
    padding: 10px 0;
    background: #1F36C7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Unilever;
}

.postulate-section-1-container {
    margin-top: 5%;
    height: 30%;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.unilever-left-logo-container{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}
.unilever-left-logo-container-1{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.postulate-section-2-container {
    width: 40%;
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.postulate-freelo{
    width: 400px;
    height: 157px;
}

.postulate-section-2-sub-container {
    width: 100%;
    margin-bottom: 50px;
}

.postulate-section-2-sub-text {
    font-weight: 700;
    font-size: 20px;
    color: #FBFBFB;
    margin-bottom: 25px;
}
.postulate-section-sub-text-add {
    font-weight: 700;
    font-size: 20px;
    color: #FBFBFB;
}

.postulate-check-text {
    font-weight: 400;
    font-size: 20px;
    color: #FBFBFB;
}

.postulate-section-2-sub-button {
    background: #1F36C7;
    border-radius: 100px;
    width: 100px;
    height: 50px;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
}

.postulate-section-add-sub-button {
    background: #1F36C7;
    border-radius: 100px;
    width: 100px;
    height: 50px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
}

.postulate-section-2-sub-input {
    width: 100%;
    background-color: transparent;
    border: 1px solid #FBFBFB;
    border-radius: 25px;
    height: 35px;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 20px;
    color: #FBFBFB;
    text-indent: 20px;
}
.postulate-section-2-sub-input::placeholder {
    color: #FBFBFB;
}
.postulate-section-2-sub-input-textarea {
    width: 100%;
    background-color: transparent;
    border: 1px solid #FBFBFB;
    border-radius: 25px;
    height: 150px;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 20px;
    color: #FBFBFB;
    text-indent: 20px;
    resize: none;
}
.postulate-section-2-sub-input-textarea::placeholder {
    color: #FBFBFB;
}
.postulate-section-2-sub-input-date{
    background-color: #00d9c4;
    padding: 15px;
    color: #ffffff;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 5px;
    margin-bottom: 40px;
}


.postulate-section-2-sub-input-date::-webkit-calendar-picker-indicator {
    filter: invert(1);
}


.postulate-section-sub-input-added{
    width: 100%;
    background-color: transparent;
    border: 1px solid #FBFBFB;
    border-radius: 25px;
    height: 35px;
    font-weight: 400;
    font-size: 20px;
    color: #FBFBFB;
    text-indent: 20px;
    margin-right: 10px;
}

.postulate-section-2-title {
    font-weight: 600;
    font-size: 56px;
    color: #FFFFFF;
    margin-bottom: 60px;
}

.postulate-header-text {
    font-weight: 400;
    font-size: 16px;
    color: #CFD3D6;
}



.postulate-section-3-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.postulate-section-3-sub-container {
    display: flex;
    flex-direction: row;
}

.postulate-section-3-global-container {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    align-self: flex-end;
}

.postulate-section-3-sub-sub-container {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
}
.postulate-delete-button{
    color: #FFFFFF;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
}
.postulate-delete-button:hover{
    color: #1F36C7;
}

.postulate-section-3-sub-sub-container-2 {
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-right: 30px;
}

.postulate-section-3-social {
    color: white;
    font-size: 20px;
    font-weight: 400;
}

.postulate-section-3-text {
    font-weight: 500;
    font-size: 20px;
    color: #CFD3D6;
}
.postulate-unilever-logo {
    width: 112px;
    height: 112px;
}

@media (max-width: 740px) {
    .postulate-section-2-title {
        font-weight: 600;
        font-size: 26px;
        color: #FFFFFF;
        margin-bottom: 60px;
    }
    .unilever-left-logo-container{
        width: 100%;
    }
}

@media (max-width: 1345px) {
    .postulate-section-3-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .postulate-section-3-sub-sub-container-2 {
        padding-top: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 50px;
        margin-right: 0;
    }
}


.css-checkbox { position: absolute; overflow: hidden; clip: rect(0 0 0 0); height: 1px; width: 1px; margin: -1px; padding: 0; border: 0; }
#inputPreview { display: flex; gap: 20px; justify-content: center;	}
.css-checkbox + label { position: relative; font-size: 14px; cursor: pointer; display: inline-flex; align-items: center; height: 24px; color: rgb(255, 255, 255); }
.css-checkbox + label::before { content: " "; display: inline-block; vertical-align: middle; margin-right: 3px; width: 22px; height: 22px; background-color: #005eef; border-width: 1px; border-style: solid; border-color: rgb(255, 255, 255); border-radius: 50px; box-shadow: none; }
.css-checkbox:checked + label::after { content: " "; background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNNTEyIDI1NkM1MTIgMzk3LjQgMzk3LjQgNTEyIDI1NiA1MTJDMTE0LjYgNTEyIDAgMzk3LjQgMCAyNTZDMCAxMTQuNiAxMTQuNiAwIDI1NiAwQzM5Ny40IDAgNTEyIDExNC42IDUxMiAyNTZ6Ii8+PC9zdmc+"); background-repeat: no-repeat; background-size: 10px 10px; background-position: center center; position: absolute; display: flex; justify-content: center; align-items: center; margin-left: 0px; left: -1px; top: 0px; text-align: center; background-color: transparent; font-size: 10px; height: 24px; width: 24px; }

.unilever-postulate-radio-section-container2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin-bottom: 5%;
}
.unilever-postulate-radio-section-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
}

@media (max-width: 1137px) {
    .postulate-section-3-sub-container {
        display: flex;
    }
}
@media only screen and (max-width: 1510px) {
    .postulate-section-3-container{
      flex-direction: column;
    }
    .postulate-section-3-sub-sub-container-2{
      margin-right: 0;
    }
  }
@media only screen and (max-width: 1366px) {
    .postulate-section-3-container{
      flex-direction: column;
    }
    .postulate-section-3-sub-sub-container-2{
      margin-right: 0;
    }
  }

@media (max-width: 1100px) {
    .postulate-global-sub-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .postulate-section-1-container {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .postulate-section-2-container {
        width: 100%;
        padding: 20px;
        margin-left: 0;
        margin-right: 0;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .postulate-section-3-global-container {
        height: 50px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .postulate-section-3-sub-sub-container-2 {
        padding-top: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 50px;
        margin-right: 30px;
    }
    .postulate-section-3-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .postulate-ring1{
        display: none;
    }
    .postulate-ring2{
        display: none;
    }
    .postulate-firulete1{
        display: none;
    }
    .postulate-firulete2{
        display: none;
    }
}
