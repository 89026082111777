.oportunidades-linked-global-container {
    width: 100%;
    padding-left: 100px;
}

.oportunidades-linked-container-inner{
  width: 100%;
  margin: 40px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.oportunidades-cards-container-linked{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-bottom: 5%;
}
.oportunidades-small-cards-container-linked{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 504px;
  align-self: flex-start;
  width: 100%;
  padding-right: 30px;
}

.paginate-oportunities-new-linked{
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.oportunidades-big-cards-container-linked{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  max-height: 700px;
  width: calc(100% - 504px);
}

.container-search-oportunities{
  display: flex;
  flex-direction: row;
  justify-content: space-between;   
  width: 70%; 
  background-color: white;
  padding: 15px;
}

.search-oportunities-input{
  background-color: transparent;
  height: 35px;
  width: 100%;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: #C5C5C5;
  text-indent: 25px;
  margin-left: 13px;
}


@media (max-width: 1400px) {
  .oportunidades-linked-global-container{
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 950px){
  .oportunidades-big-cards-container-linked{
    display: none;
  }
  .oportunidades-small-cards-container-linked{
    width: 100%;
    max-width: 100%;
    padding-right: 0;
  }
  .paginate-oportunities-new-linked{
    justify-content: center;
    align-items: center;
  }
  .container-search-oportunities{
    width: 100%;
    align-items: center;
  }
}