.unilever-banner-profesional{
  height: 200px;
  width: 90%;
  background-image: url("../../../assets/imagenes/home-banner-unilever.png");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.unilever-banner-profesional-inner-1{
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.unilever-banner-profesional-inner-1-1{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.unilever-home-subtitle{
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  padding-left: 80px;
}
.unilever-home-title{
  font-weight: 700;
  font-size: 40px;
  color: #fff;
  padding-left: 80px;
}
.unilever-banner-profesional-inner-2{
  margin-right: 40px;
}

@media(max-width: 768px){
  .unilever-home-title{
    font-size: 26px;
  }
  .unilever-banner-profesional-inner-1-1{
    align-items: center;
  }
}