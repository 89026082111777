.bootcamp-modal-precourses-card-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90px;
    align-items: center;
    justify-content: space-between;
}
.bootcamp-modal-precourses-card-title-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
    padding: 0 10px 0 10px;
}
.bootcamp-modal-precourses-card-title{
    font-size: 1em;
    font-weight: bold;
    color: #393939;
    text-align: left;
    padding: 10px;
    align-self: flex-start;
    transition: all 0.5s;
}

.bootcamp-modal-precourses-card-image-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
}

.bootcamp-modal-precourses-card-image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #393939;

}

.bootcamp-modal-precourses-card-image-2{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #27165b;
    border: 1px solid #393939;
}
.bootcamp-modal-precourses-card-video-button{
    width: 118px;
    height: 33px;
    border-radius: 10px;
    background-color: #202256;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    transition: all 0.3s ease;
}

.bootcamp-modal-precourses-card-video-button:hover{
    background-color: #F07130;
    transition: 0.5s;
}

.bootcamp-modal-precourses-card-file-button{
    width: 118px;
    height: 45px;
    border-radius: 10px;
    background-color: #202256;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    transition: all 0.3s ease;
}

.bootcamp-modal-precourses-card-file-button:hover{
    background-color: #F07130;
    transition: 0.5s;
}