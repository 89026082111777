@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700italic,700,900italic,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.generic_price_table{
  background-color: .0eded;
}

/*PRICE COLOR CODE START*/

.card_container_pricing{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  width: 300px;
  height: 580px;
  border-radius: 8px;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}
.card_container_pricing2{
  display: flex;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  background-color: #202256;
  width: 300px;
  height: 580px;
  border-radius: 8px;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}
.card_container_pricing3{
  display: flex;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  background-color: #F07130;
  width: 300px;
  height: 580px;
  border-radius: 8px;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}
.card_pricing_content{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}
.card_pricing_content_header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}
.card_title_tag{
  margin-top: 31px;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 700;
}
.card_pricing_tag_title{
  margin-bottom: 30px;
  text-align: center;
}
.card_pricing_tag_title2{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 30px;
  text-align: center;
}
.card_price_text{
  font-family: 'Roboto', sans-serif;

  font-size: 36px;
  font-weight: 700;
}
.card_price_text2{
  font-family: 'Roboto', sans-serif;

  font-size: 30px;
  font-weight: 700;
}
.card_price_text3{
  font-family: 'Roboto', sans-serif;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
}
.card_pricing_content_body{
  margin-left: 24px;
  margin-right: 24px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 60%;
  margin: 0;
  padding: 0;
}
.card_pricin_button_epayco{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100px;
}
.card_pricin_button_epayco1{
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 191px;
  height: 28px;
  background: none;
  border: 1px solid #D6D6D6;
  border-radius: 5px;
}
.card_pricin_button_epayco2{
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 191px;
  height: 28px;
  background: #F07130;
  border-radius: 5px;
}
.card_pricin_button_epayco3{
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 191px;
  height: 28px;
  background: #202256;
  border-radius: 5px;
}
.card_pricin_button_epayco1 button{
  background: none;
  border: none;
}
.card_pricing_body_inner{
  margin-left: 24px;
  margin-top: 20px;
}
.card_pricing_body_inner span{
  width: 100%;
}
.generic_price_table .generic_content{
  width: 297px;
  height: 600px;
  background-color: #fff;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}
.generic_price_table .generic_content .generic_head_price{
  background-color: #f6f6f6;
}

.generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg{
  border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
}

.generic_price_table .generic_content .generic_head_price .generic_head_content .head span{
  color: #525252;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign{
    color: #414141;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency{
    color: #393939;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent{
    color: #414141;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .month{
    color: #414141;
}

.generic_price_table .generic_content .generic_feature_list ul li{  
  color: #a7a7a7;
}

.generic_price_table .generic_content .generic_feature_list ul li span{
  color: #414141;
}


.generic_price_table .generic_content .generic_price_btn a{
  border: 1px solid #202256; 
    color: #202256;
} 

.generic_price_table .generic_content.active .generic_head_price .generic_head_content .head_bg,
.generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg{
  border-color: #202256 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #202256;
  color: #fff;
}

.generic_feature_container_list{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

}

.generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head span,
.generic_price_table .generic_content.active .generic_head_price .generic_head_content .head span{
  color: #fff;
}

.generic_price_table .generic_content:hover .generic_price_btn a,
.generic_price_table .generic_content.active .generic_price_btn a{
  background-color: #202256;
  color: #fff;
} 
.generic_price_table{
  margin: 50px 0 50px 0;
    font-family: 'Raleway', sans-serif;
}

.row-pricing{
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  flex-direction: row;
}

/*PRICE BODY CODE START*/

.generic_price_table .generic_content{
  
  text-align: center;
}

.generic_price_table .generic_content .generic_head_price {
  margin: 0 0 20px 0;
}

.generic_price_table .generic_content .generic_head_price .generic_head_content{
  margin: 0 0 50px 0;
}

.generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg{
    border-style: solid;
    border-width: 90px 1411px 23px 399px;
  position: absolute;
}

.generic_price_table .generic_content .generic_head_price .generic_head_content .head{
  padding-top: 40px;
  position: relative;
  z-index: 1;
}

.generic_price_table .generic_content .generic_head_price .generic_head_content .head span{
    font-family: "Raleway",sans-serif;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 2px;
    margin: 0;
    margin-top: 31px;
    padding: 0;
    text-transform: uppercase;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag{
  padding: 0 0 20px;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .price{
  display: block;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign{
    display: inline-block;
    font-family: "Lato",sans-serif;
    font-size: 24px;
    margin-top: 31px;
    font-weight: 400;
    vertical-align: middle;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency{
  
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    margin-top: 31px;
    font-weight: 700;
    letter-spacing: -2px;
    line-height: 36px;
    padding: 0;
    vertical-align: middle;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent{
    display: inline-block;
    font-family: "Lato",sans-serif;
    font-size: 24px;
    font-weight: 400;
    vertical-align: bottom;
}

.generic_price_table .generic_content .generic_head_price .generic_price_tag .month{
    font-family: "Lato",sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 3px;
    vertical-align: bottom;
}

.generic_price_table .generic_content .generic_feature_list ul{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
}

.generic_price_table .generic_content .generic_feature_list ul li{
  font-family: "Lato",sans-serif;
  margin-left: 5%;
  margin-right: 5%;
  text-align: start;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 18px;
  padding: 15px 0;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table .generic_content .generic_feature_list ul li:hover{
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;

}
.generic_price_table .generic_content .generic_feature_list ul li .fa{
  padding: 0 10px;
}
.generic_price_table .generic_content .generic_price_btn{
  margin: 20px 0 32px;
}

.generic_price_table .generic_content .generic_price_btn a{
    border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-border-radius: 50px;
    display: inline-block;
    font-family: "Lato",sans-serif;
    font-size: 18px;
    outline: medium none;
    padding: 12px 30px;
    text-decoration: none;
    text-transform: uppercase;
}

.generic_price_table .generic_content,
.generic_price_table .generic_content:hover,
.generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg,
.generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg,
.generic_price_table .generic_content .generic_head_price .generic_head_content .head h2,
.generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head h2,
.generic_price_table .generic_content .price,
.generic_price_table .generic_content:hover .price,
.generic_price_table .generic_content .generic_price_btn a,
.generic_price_table .generic_content:hover .generic_price_btn a{
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
} 
@media (max-width: 320px) { 
}

@media (max-width: 767px) {
  .generic_price_table .generic_content{
    margin-bottom:75px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .generic_price_table .col-md-3{
    float:left;
    width:50%;
  }
  
  .generic_price_table .col-md-4{
    float:left;
    width:50%;
  }
  
  .generic_price_table .generic_content{
    margin-bottom:75px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
}
@media (min-width: 1200px) {
}
.generic_price_table_home{
   font-family: 'Raleway', sans-serif;
}

.text-center h1,
.text-center h1 a{
  color: .7885CB;
  font-size: 30px;
  font-weight: 300;
  text-decoration: none;
}
.demo-pic{
  margin: 0 auto;
}
.demo-pic:hover{
  opacity: 0.7;
}

.generic_price_table_home ul{
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: table;
}
.generic_price_table_home li{
  float: left;
}
.generic_price_table_home li + li{
  margin-left: 10px;
  padding-bottom: 10px;
}
.generic_price_table_home li a{
  display: block;
  width: 50px;
  height: 50px;
  font-size: 0px;
}
.generic_price_table_home .blue{
  background: #3498DB;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table_home .emerald{
  background: #202256;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table_home .grey{
  background: #7F8C8D;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table_home .midnight{
  background: #34495E;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table_home .orange{
  background: #E67E22;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table_home .purple{
  background: #9B59B6;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table_home .red{
  background: #E74C3C;
  transition:all 0.3s ease-in-out 0s;
}
.generic_price_table_home .turquoise{
  background: #1ABC9C;
  transition: all 0.3s ease-in-out 0s;
}

.generic_price_table_home .blue:hover,
.generic_price_table_home .emerald:hover,
.generic_price_table_home .grey:hover,
.generic_price_table_home .midnight:hover,
.generic_price_table_home .orange:hover,
.generic_price_table_home .purple:hover,
.generic_price_table_home .red:hover,
.generic_price_table_home .turquoise:hover{
  border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  transition: all 0.3s ease-in-out 0s;
}
.generic_price_table_home .divider{
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
}
.generic_price_table_home .divider span{
  width: 100%;
  display: table;
  height: 2px;
  background: #ddd;
  margin: 50px auto;
  line-height: 2px;
}
.generic_price_table_home .itemname{
  text-align: center;
  font-size: 50px ;
  padding: 50px 0 20px ;
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
  text-decoration: none;
    font-weight: 300;
}
.generic_price_table_home .itemnametext{
    text-align: center;
    font-size: 20px;
    padding-top: 5px;
    text-transform: uppercase;
    display: inline-block;
}
.generic_price_table_home .footer{
  padding:40px 0;
}

.price-heading{
    text-align: center;
}
.price-heading h1{
  color: .666;
  margin: 0;
  padding: 0 0 50px 0;
}
.demo-button {
    background-color: #333333;
    color: #ffffff;
    display: table;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 50px;
    outline-color: -moz-use-text-color;
    outline-style: none;
    outline-width: medium ;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
}
.bottom_btn{
  background-color: #333333;
    color: #ffffff;
    display: table;
    font-size: 28px;
    margin: 60px auto 20px;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
}
.demo-button:hover{
  background-color: .666;
  color: #FFF;
  text-decoration:none;
  
}
.bottom_btn:hover{
  background-color: #666;
  color: #FFF;
  text-decoration:none;
}

@media (max-width: 375px) {
  .row-pricing{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -30px;
    flex-direction: row;
  }
  .card_container_pricing{
    margin-bottom: 25px;
  }
  .card_container_pricing2{
    margin-bottom: 25px;
  }
}