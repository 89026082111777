.register-empresa {
  background-size: cover;
  box-sizing: content-box;
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/* register form */
.register__container__form {
  display: flex;
  flex-direction: column;
}

.register__form-radio {
  display: flex;
  justify-content: space-evenly;
  margin-top: 9%;
  margin-bottom: 5%;
}

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid;
  outline-style: none;
  margin-top: 4%;
  padding: 12px;
  box-sizing: border-box;
}

.input:focus {
  border-bottom: 2px solid var(--main-yellow-findally);
  outline: none;
}

.register__form-conditions {
  font-size: smaller;
  width: 95%;
  margin: 0 auto;
  margin-top: 1%;
  margin-bottom: 4%;
}

.register__form-social {
  margin-bottom: 6%;
}

.register__form-social-img {
  text-align: center;
  margin-left: 20px;
  align-self: center;
  border-radius: 3px;
}

.register__form-social-btn-fg {
  display: inline-flex;
  border-radius: 20px;
  width: 120px;
  height: 36px;
  margin-right: 5px;
  color: white;
  text-decoration: none;
  border: 1px solid;
  background-color: #3b5998;
}

.register__form-social-btn-lin {
  display: inline-flex;
  margin-top: 1%;
  border-radius: 20px;
  width: 120px;
  margin-right: 5px;
  height: 36px;
  text-decoration: none;
  color: white;
  border: 1px solid;
  background-color: #2867b2;
}

.register__form-social-btn-go {
  display: inline-flex;
  width: 120px;
  height: 36px;
  margin-top: 10px;
  border-radius: 20px;
  text-decoration: none;
  color: black;
  border: 1px solid;
}

.register__form-social-txt {
  margin: auto;
}

.register__form__button {
  background-color: var(--main-yellow-findally);
  width: 135px;
  height: 39px;
  margin: 5px auto;
  margin-bottom: 3%;
  border-radius: 1.25rem;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  color: black;
  font-size: large;
  font-weight: 700;
  text-decoration: none;
}

.register__form__button:hover {
  border: 1px solid blueviolet;
}

.register__form__button:focus {
  border: 2px solid var(--main-purple-findally);
}

input[type="radio"].css-checkbox {
  display: none;
}
input[type="radio"].css-checkbox + label.css-label {
  padding-left: 1.6875rem;
  height: 1.12rem;
  display: inline-block;
  line-height: 1.12rem;
  background-repeat: no-repeat;
  background-position: 0 0;
  vertical-align: middle;
  cursor: pointer;
}
input[type="radio"].css-checkbox:checked + label.css-label {
  background-position: 0 -1.1rem;
}
label.css-label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*media querys*/
@media (max-width: 1050px) {
  .register__container {
    padding: 3%;
    box-sizing: border-box;
    width: 50%;
    margin: 0 auto;
    overflow: auto;
  }
}

@media (max-width: 700) {
  .register {
    display: block;
    margin: 0 auto;
  }
}
@media (max-width: 500px) {
  .register__container {
    width: 50%;
    margin: 0;
    margin-right: 0;
  }
  .register_form-social-txt {
    display: none;
  }
  .register__form-social-img {
    width: 20px;
    margin-left: 20px;
  }
  .register__form-social-btn-go {
    width: fit-content;
    margin-top: 0px;
    width: 50%;
  }
  .register__form-social-btn-lin {
    width: fit-content;
    width: 50%;
  }
  .register__form-social-btn-fg {
    width: fit-content;
    width: 50%;
  }
}

@media (max-width: 450px) {
  .register {
    display: inline;
  }
  .register__container {
    width: 100%;
    height: max-content;
    padding: 30px;
    min-width: 250px;
  }
}
