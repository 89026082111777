.admin-unilever-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
}
.admin-unilever-card-container{
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 40px;
    height: 80%;
}
.admin-unilever-container-graphs{
  width: 100%;
  display: flex;
    flex-direction: column;
  justify-items: center;
  align-items: center;
}
.admin-unilever-graphs-subcontainer{
  display: flex;
  flex-direction: column;
    margin-top: 5%;
}
.admin-unilever-graphs-column-container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}
.admin-unilever-graphs-row-container{
display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.mentor-users-card{
 display: flex;
  align-items: center;
    padding: 20px;
  justify-content: center;
    flex-direction: column;
    width: 250px;
    height: 60px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.mentor-graphs-card{
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: center;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.mentors-users-card-title{
    font-size: 16px;
    font-weight: 600;
    color: #697a8d;
    margin-bottom: 0;
  align-self: flex-start;
}

.mentors-users-card-quantity{
    font-size: 20px;
    font-weight: 600;
    color: #697a8d;
    margin-bottom: 0;
  align-self: center;
}


.Profesional-Home-Search-admin-unilever {
  background-color: transparent;
  height: 35px;
  width: 100%;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: #C5C5C5;
  text-indent: 25px;
  margin-left: 13px;
}
.Profesional-Home-Search-admin-unilever::placeholder {
  color: #697a8d;
  margin-left: 13px;
}

.Profesional-Home-Search-admin-unilever:focus {
  border-color: #697a8d;
  outline: none;
}

.container-info-PopUP{
  display:flex;
  align-items: center;
  flex-direction: column;
}

.info-text-popup{ 
  margin-top: 10px;
  font-size:16px;
  width: 100%;
  text-align: center;
}

.pagination-admin{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.info-pagination-admin, .info-pagination-admin h3{
  display: flex;
  flex-direction: row;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom:0px;
  align-items: center;
}

.container-download-info, .container-download-info h3{
  display: flex;
  flex-direction: row;
  justify-content: space-between;  
  align-items: center; 
  width:40%;
  font-size : 16px; 
  margin-bottom:0px;
}