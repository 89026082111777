.container-cardlegal{
  background-color: #fff;  
  border-radius: 5px;      
  margin-top: 22px;  
  margin-left: 20px;
}

.subcontainer-cardlegal{
  display: flex;
  flex-direction: column;
  height: 60%;
  justify-content: space-between;  
  padding:2em
}

.subcontainer-cardlegal h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;   
  color: #5D5D5D;
}

.subcontainer-cardlegal span {  
  font-size: 14px;
  line-height: 20px;   
  color: #5D5D5D;
  font-weight: 400;
}

.card-legal-title{
  font-weight: 700;
    font-size: 16px;
    line-height: 20px;   
    color: #393939;
}

@media (max-width: 768px) {
  .container-cardlegal{
    margin-left: 0px;
  }
}