.home-card-body {
    box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.1);
}

/*botón primario*/
.primary-button-card {
    background-color: #202256;
    border-radius: 5px;
    width: 140px;
    height: 28px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins';
}


.primary-button-card:hover {
    font-weight: 700;
    color: white;
}

.primary-button-card:active {
    font-weight: 700;
    color: white;
    background-color: #0D0033;
}

/*botón terciario*/
.tertiary-button-card {
    background-color: transparent;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    color: #C5C5C5;
    width: 80px;
    height: 20px;
    margin-right: 170px;
}

.tertiary-button-card:hover {
    color: #151515;
}

.primary-button-card:active {
    color: #151515;
}

.box-tertiary-button-card {
    background-color: transparent;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    color: #C5C5C5;
    width: 80px;
    height: 20px;
}

.box-tertiary-button-card:hover {
    color: #151515;
}

.box-primary-button-card {
    background-color: #202256;
    border-radius: 5px;
    width: 87px;
    height: 28px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins';
}

.box-primary-button-card:hover {
    font-weight: 700;
    color: white;
}

.box-primary-button-card:active {
    font-weight: 700;
    color: white;
    background-color: #0D0033;
}