.unilever-banner-profesional2{
  height: 200px;
  width: 90%;
  background-image: url("https://www.bancolombia.com/wcm/connect/www.bancolombia.com-26918/50a8e180-ff59-460e-84a0-c4df8badea07/01.+HERO_ED_FINANCIERA_DESK+%281%29.png?MOD=AJPERES&CACHEID=ROOTWORKSPACE.Z18_K9HC1202P864E0Q30449MS3000-50a8e180-ff59-460e-84a0-c4df8badea07-npf4Qyt");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.unilever-banner-profesional-inner-1{
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.unilever-banner-profesional-inner-1-1{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.unilever-home-subtitle{
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  padding-left: 80px;
}
.unilever-home-title{
  font-weight: 700;
  font-size: 40px;
  color: #fff;
  padding-left: 80px;
}
.unilever-banner-profesional-inner-2{
  margin-right: 40px;
}

@media(max-width: 768px){
  .unilever-home-title{
    font-size: 26px;
  }
  .unilever-banner-profesional-inner-1-1{
    align-items: center;
  }
}
