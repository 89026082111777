.dashop-line-opt-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #D6D6D6;
    margin-top: 35px;
}

.dashop-tab-active {
    border-bottom: 3px solid #202256;
    font-weight: 700;
    font-size: 16px;
    color: #393939;
    margin-right: 150px;
    height: 40px;
    position: relative;
    top: 9px;
    cursor: pointer;
}

.dashop-tab-inactive {
    font-weight: 400;
    font-size: 16px;
    color: #393939;
    margin-right: 150px;
    height: 40px;
    position: relative;
    top: 9px;
    cursor: pointer;
}

.dashop-tab-number {
    font-weight: 400;
    font-size: 16px;
    color: #C5C5C5;
}

.dashop-tab-user-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 6px;
    padding: 10px;
}

.dashop-tab-cont {
    margin-top: 45px;
}

.dashop-tab-img-filtro {
    filter: blur(3px);
    user-select: none;
    pointer-events: none;
}

.dashop-tab-img-filtro-2 {
    user-select: none;
    pointer-events: none;
}

.dashop-tab-img {
    width: 74px;
    height: 74px;
    object-fit: cover;
    border-radius: 50%;
}

.dashop-tab-sub-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dashop-tab-sub-sub-cont {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width:350px;
}

.dashop-tab-sub-sub-cont2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dashop-selection-buton{
    background-color: #F07130;
    border-radius: 5px;
    color: white;
    font-weight: 400;
    font-size: 14px;
    margin-right: 15px;
    margin-bottom: 10px;
}

.dashop-tab-name {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.dashop-tab-job {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
}

.dashop-entrev {
    font-weight: 400;
    font-size: 14px;
    color: #E7BF00;
}

.dashop-selected {
    font-weight: 400;
    font-size: 14px;
    color: #24D054;
}

.dashop-entrev-subcont {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}


.dashop-entrev-compat {
    font-weight: 400;
    font-size: 14px;
    color: #F07130;
}

.aplic-card-ver-selec {
    margin-left: 20px;
    margin-right: 20px;
    background-color: #F07130;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    padding-right: 20px;
    padding-left: 20px;
}

.aplic-card-ver-perfil{
    display: flex;
    background-color: transparent;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 105px;
    color: #202256;
    filter: grayscale(100%)
}

.aplic-card-ver-perfil:hover {
    filter: grayscale(0%); 
   color: #202256; 
   font-weight: 500;
   stroke-width: 1.5px; 
}
.aplic-card-ver-perfil svg{
    stroke: #202256;
}

@media (max-width: 930px) {    
    .buton-cont-2-cont {
        display: flex;
        height: 150px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .dashop-tab-user-cont {
        height: fit-content;
    }

    .dashop-tab-sub-cont {
        flex-direction: column;
        align-items: center;
    }

    .dashop-tab-active {
        margin-right: 30px;
    }

    .dashop-tab-inactive {
        margin-right: 30px;
    }
    .dashop-tab-sub-sub-cont{
        width:auto;
    }
}