.contact-us{
  background-color: hsl(0, 0%, 98%);
  padding-top: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 5em;
}

.info {
  background-color: #212156;
  width: 30%;
  height: max-content;
  padding: 2%;
  margin-left: 20%;
  font-size: 16px;
  border-radius: 15px 15px 15px 15px;
}

.info p {
  margin-top: 1em;
  margin-bottom: 10%;
  margin-left: 1em;
  font-size: 16px;
  color: #ffffff;
}
.iconos-lista-contact{
  font-size:30px;
   color: #FF5925; 
   margin-Right: 20px

}
.lista-contact-coally-contenedor{
  background-color: rgba(253, 253, 253, 0.137);
  width: 100%;
  padding: 5%;
  margin-top: 5px;
  display: flex;
  border-radius: 10px;
}
.mensaje-directo-forma{
  display: flex;
  justify-content: space-between;
}

.highlight-word-contact{
  color: #FF5925;
  margin-left: 5px;
  font-weight: 600;
}

.list-contact-coally{
  font-size: 16px;
  color: #393939;
  text-align: center;
  margin-left: -20px;
}

.title_form_contact{
  color: #050931;
  font-size: 19px;
  font-weight: 700;
  text-align: start;
  margin-bottom: 1%;
}

.button_contact_us{
  background: #212156 0% 0% no-repeat padding-box;
  box-shadow: 4px 3px 6px #00000029;
  color: white;
  border-radius: 4px;
  padding: 0.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.button_div_contact{
  text-align: center;
  display: flex;
}

.input_contact_us{
  background-color: rgba(0, 0, 0, 0);
  font-size: 12.5px;    
  width: 100%;
  margin-top: 4%;
  padding: 3%;
  border: 0.1px solid #a5a2a26e;
}
.input_contact_us_2{
  background-color: rgba(0, 0, 0, 0);
  font-size: 12.5px;
  width: 45%;
  margin-top: 4%;
  padding: 3%;
  border: 0.1px solid #a5a2a26e;
}

.text_contact_us{
  background-color: rgba(0, 0, 0, 0);
  border: 0.1px solid #a5a2a26e;
  padding-bottom: 4em;
  font-size: 12.5px;
  margin-bottom: 2em;
  resize: none;
  margin-top: 2em;
}

.icon {
  color: #ffffff;
  margin-right: 2%;
  margin-top: 2%;
}

.form {
  width: 55%;
  padding-right: 12%;
  margin-right: 10%;
  padding-top: 2%;
}

.direct_message{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-left: 5%;
  padding: 10px;
  overflow: auto;
  width: 100%;
}

.input {
  width: 100%;
  margin-top: 4%;
  padding: 4%;
  border: none;
  border-bottom: 0.1px solid #707070;
  font-size: 14px;
}

.textarea {
  width: 100%;
  margin-top: 8%;
  border: 2px solid #707070;
  font-size: 14px;
}

.button {
  background-color: #540E87;
  width: 17%;
  padding: 2%;
  margin-left: 40%;
  margin-top: 5%;
  border-radius: 10px;
  font: normal normal bold 14px/20px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
}
.formulariocrear .MuiInputBase-root{
  border: none !important;
  width: 99%;
}
.MuiInput-underline:before{
  border: none !important;
}
.MuiSelect-select.MuiSelect-select{
  border-radius: 10px;
}
.MuiSelect-select.MuiSelect-select:active{
  border-radius: 10px;
  background-color: white;
}
.MuiSelect-select.MuiSelect-select:focus{
  border-radius: 10px;
  background-color: white;
}
.audioclase{
  position: absolute;
  display: flex;
  background-color: white;
}
.audioclase:hover{
opacity: 0;
}
.audioclasehover{
  display: flex;
}
.input_create_project_new_form{
  width: 99% !important;
}
audio {
    width: 99%;
    height: 45px;
    margin-left: 3%;
    margin-top: 0;
    margin-bottom: 5%;
  }
  audio::-webkit-media-controls-enclosure {
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    border: 5px solid #f4f5fb;
}
.textareaclass{
  line-height: initial !important;
  height: 100px !important;
  resize: none !important;
  margin-top: 0 !important;
}

.MuiStepper-root{    
  padding: 2rem !important;
}
.css-10mg1vw-MuiStepper-root{    
  padding: 2rem !important;
}