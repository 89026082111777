.footer_bg_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10em 0 10em;
}
.footer_bg_container2{
    background-image: url(https://coally.com/wp-content/uploads/2021/08/fondo-perfiles-consultor.png);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 10em 0 10em;
}

.footer-general{
    background-color: white;
}

.border_bottom__footer{
    display: flex;
    height: 6vh;
    justify-content: center;
}

.footer-copyright{
    color: white;
    font-size: 12px;
    margin-right: 1em;
    height: 70%;
    margin-top: 10px;
}

.footer_bg_container h3{
    font-size: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ff5925;
}

.coAlly_data__footer{
    padding-top: 10vh;
    padding-bottom: 8vh;
    display: flex;
    color: #696a7c;
    flex-direction: column;
}

.coAlly_data__footer__socials{
    padding-top: 10vh;
    padding-bottom: 8vh;
    display: flex;
    flex-direction: column;
}

.coAlly_data__footer__socials h3{
    justify-content: center;
    text-align: center;
}

.container__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
