.confidencialidad{
    margin-top: 2em;
    padding: 2% 15% 0% 15%;
    margin-bottom: 4em;
}

.confif-location-date{
    text-align: right;
    margin-bottom: 1em;
}

.tittle-confificado{
    margin-top: 3em;
    margin-bottom: 2em;
    text-align: center;
}

.conf-rep-legal{
    display: inline-block;
}

.conf-company-name{
    display: inline-block;
}

.conf-company{
    display: inline-block;
}

.conf-nit-company{
    display: inline-block;
}

.conf-firm{
    margin-top: 3em;
    margin-bottom: 2em;
}

.conf-desc-in{
    margin-top: 2em;
    margin-bottom: 2em;
}

.conf-personal-data{
    text-align: justify;
    margin-top: 1em;
    margin-bottom: 2em;
    font-size: 15px;
}

.conf-tittle-consid{
    margin-top: 1em;
    margin-bottom: 1em;
}

.conf-tittle-sec{
    text-align: justify;
    text-align: center;
    font-size: 19px;
}

.conf-description{
    text-align: justify;
    font-size: 15px;
}

.conf-content-contract{
    text-align: justify;
    font-size: 15px;
}

.doc-button-pdf{
    padding: 5px;
    color: #f46036;
    background-color: #ffffff;
    border: 1px solid #f46036;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 500;
    text-transform: uppercase;
}

.div-button-pdf-gen{
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    margin-bottom: 20px;
}