.cardSector-container{
    width: 220px;
    height: 220px;
    border-radius: 8px;
    background-size: cover;
}

.cardSector-subContainer{
    display: flex;
    align-items: flex-end;
    height: 100%;
    margin-left: 20px;
}

.cardSector-title{
    color: #fff;
    font-weight: 700;
    font-size: 18px;
}

.cardSector-cant{
    color: #fff;    
    font-size: 16px;
}