.button-add-cv-form{
  height: 40px;
  width: 30%;
  background-color: #27165b;
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.container-eliminar-experiencia{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.button-eliminar-experiencia{
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}

@media (max-width: 768px) {
  .button-add-cv-form{
    width: 100%;
  }
}