.CompanyForm-input-one {
    background-color: rgb(255, 255, 255);
    font-family: 'Roboto';
    width: 100%;
    font-size: 15px !important;
    margin-left: 8px !important;
    border-color: #cccccc !important;
    border-radius: 4px !important;
}

.MuiSelect-select[multiple] {
    height: auto;
    z-index: 1 !important;
    background: white !important;
    margin-bottom: 6em;
}

.MuiSelect-select.MuiSelect-select {
    border: solid 1px #202256;
    padding-left: 20px;
    border-radius: 5px;
}

.MuiStepIcon-completed {
    color: #202256 !important;
}

.MuiStepIcon-active {
    color: red !important;
}

.jss3 {
    width: 1000px !important;
}

.Companies-CP-container {
    max-width: 1080px;
    margin: 0 auto;
}

.CompanyForm-label-otra {
    background-color: rgb(255, 255, 255);
    font-family: 'Roboto';
    width: 100%;
    font-size: 15px !important;
    margin-left: 8px !important;
    border-color: #cccccc !important;
    border-radius: 4px !important;
}

.CompanyForm-label-comments {
    background-color: rgb(255, 255, 255);
    font-family: 'Roboto';
    width: 100%;
    font-size: 15px !important;
    margin-left: 8px !important;
    margin-bottom: 15px;
    border-color: #cccccc !important;
    border-radius: 4px !important;
    margin-top: 10px;
}

.CompanyForm-button-submit {
    padding: 8px;
    color: white;
    background-color: #f46036;
    border-style: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 600;
    text-transform: uppercase;
}

/*Unregister form styles*/

label + .MuiInput-formControl {
    margin-top: 4em !important;
}

/*end*/

/*Create new form*/

.MuiButton-containedPrimary {
    color: #fff;
    background-color: #ff5925;
}

.select-area-create-project {
    display: flex;
}

.col-one-create-project {
    width: 60%;
}

.col-two-create-project {
    width: 60%;
}

.col-two-create-project3 {
    width: 45%;
}

.col-two-create-project3 .MuiInputBase-root {
    margin-right: 20rem !important;
    margin-top: 9% !important;
}

.radio-button-form {
    display: inline-block !important;
}

.input_create_project_new_form_sec {
    width: 40%;
    height: 45px;
    background: #fff;
    color: #555;
    /*     line-height: 50px;
     */
    border: 2px solid #c8c8c8;
    font-size: 16px;
    margin-bottom: 25px;
    margin-left: 2%;
    margin-top: 9%;
    padding-right: 5px !important;
}

.p-question-create-project {
    text-align: left;
    font-size: 13.5px;
    color: #555;
}

.message-alert-create-project {
    font-size: 13px;
    color: rgb(255, 255, 255);
    text-align: center;
    padding-top: 1em;
    padding-left: 5em;
    padding-right: 5em;
}

.paymet-section-buttons {
    width: 90%;
}

.input_create_project_new_form {
    width: 100%;
    height: 41px;
    /*padding-left: 20px;*/
    background: transparent;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    color: #414153;
    font-size: 14px;
    margin-bottom: 25px;
    margin-left: 3%;
    margin-top: 4%;
    padding-right: 15px !important;
}

.input_create_project_new_form:focus {
    outline: 1px solid #0B27E6;
}

.tittle-question-create-project {
    text-align: left;
    margin-left: 0.3em;
    margin-top: 1.5em;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: rgba(21, 21, 21, 0.99);
}

.creeate-project-form-container {
    border-radius: 7px;
    min-width: 500px;
    overflow-x: hidden !important;
    max-width: 1150px;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    margin: 0 auto;
    text-align: center;
    background-color: white;
    min-height: 400px;
    box-sizing: border-box;
    overflow: auto;
}

.label-confindential-project {
    margin-right: 20px;
    margin-bottom: 15px;
    margin-top: 10px;
}

.title_form_create_project {
    font-size: 29px;
    color: #23224c;
    margin-bottom: 1em;
    margin-top: 2em;
    font-weight: 700;
    text-transform: uppercase !important;
    font-weight: 700;
}

.section-altert-content-payment {
    min-width: 500px;
    max-width: 1000px;
    margin-top: 10px;
    margin: 0 auto;
    text-align: center;
    background-color: #333333;
    box-sizing: border-box;
    overflow: auto;
}

.button_create_project {
    background: #f37328 0% 0% no-repeat padding-box;
    box-shadow: 4px 3px 6px #00000029;
    color: white;
    border-radius: 4px;
    padding: 0.5em;
    margin-bottom: 2em;
    margin-left: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.button-add {
    background: #23224c 0% 0% no-repeat padding-box;
    box-shadow: 4px 3px 6px #00000029;
    color: white;
    border-radius: 4px;
    padding: 0.5em;
    margin-bottom: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border: none;
}

.button-add {
    transition-duration: 0.4s;
}

.button-add:hover {
    background-color: #191838 !important;
    color: white;
}

.button-add:active {
    background-color: #191838 !important;
    color: white;
}

.button-remove {
    background: #f37328 0% 0% no-repeat padding-box;
    box-shadow: 4px 3px 6px #00000029;
    color: white;
    border-radius: 4px;
    padding: 0.5em;
    margin-bottom: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border: none;
}

.button-remove {
    transition-duration: 0.4s;
}

.button-remove:hover {
    background-color: #be571b !important;
    color: white;
}

.button-remove:active {
    background-color: #be571b !important;
    color: white;
}

.buttons-add-dynamic-input {
    margin-top: 1em;
    text-align: left;
    justify-content: left;
    display: flex;
}

.tittle-section-question {
    display: flex;
    font-size: 14px !important;
    margin-left: 1em;
}

.tittle-section-question2 {
    display: flex;
    font-size: 14px !important;
}

.tittle-section-question2 .MuiInputBase-root {
    margin-top: 1000% !important;
}

.number-question-form {
    width: 30px;
    height: 30px;
    background: transparent linear-gradient(180deg, #540E87 0%, #FF2300 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    color: white;
    border-radius: 100px;
    padding-top: 0.2em;
    margin-top: 1.1em;
    margin-left: 0.6em;
    font-size: 16.5px;
}

.section-tittle-create-project-form {
    background: white;
    padding: 18px 25px;
    text-align: left;
}

.tittle-create-project-form {
    color: black;
    font-size: 17px;
    font-weight: 900;
}

.section-tittle-out-form {
    background: white;
    padding: 22px 29px;
    text-align: left;
}

.tittle-out-form {
    color: black;
    font-size: 22px;
    font-weight: 900;
}

.select-create-project {
    background: transparent;
    width: 100%;
    height: 45px;
    border: 1px solid #202256;
    color: #202256 !important;
}

.MuiInputBase-root {
    background: #fff;
    color: #555;
    border: 2px solid #c8c8c8;
    height: 45px !important;
    margin: 4% 3% !important;
}

.create-company-form {
    /* background-image: url(/static/media/fondo-formularios.ee3709f4.png); */
    background-size: cover;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 88%;
}

.MuiStepper-root-offi {
    /*padding: 2rem !important;*/
    width: 100%;
    margin-top: 0;
    /*margin-bottom: -160px;*/
    height: 0;
}

.MuiStepConnector-horizontal {
}

.MuiStep-horizontal {
    background-color: white;
    /*margin-top: -40px;*/
    margin-bottom: 0;
}

.MuiStepLabel-root {
    background-color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #C5C5C5;
    text-align: center;
}

.Mui-active {
 color: red;
}

.Mui-completed {
    color: #202256;
}

.MuiStepLabel-label {
    font-family: Poppins !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #202256 !important;
    text-transform: capitalize;
}

.botoncerrarpopuppro {
    display: flex;
    color: white;
    align-content: center;
    justify-content: center;
    border: 2px solid white;
    width: 30px;
    height: 25px;
    padding-bottom: 25px;
    border-radius: 5px;
}

.contadordepreguntas {
    color: #202256;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}

.css-10mg1vw-MuiStepper-root {
}

.btn-box {
    text-align: center;
    margin-top: 1em;
}

.col-one-buttons-dynamic-input {
    width: 45%;
    text-align: center;
}

.col-two-buttons-dynamic-input {
    width: 45%;
    text-align: center;
}

.button_div_create_project {
    text-align: center;
}

/*end new form*/

.MuiStepLabel-label.MuiStepLabel-active {
    font-size: var(--font-size-item-grande) !important;
    font-weight: 700 !important;
}

.MuiStepLabel-label {
    font-size: var(--font-size-item-grande) !important;
}

.css-1t4i7pk-MuiButtonBase-root-MuiButton-root {
    background-color: #F46036 !important;
    box-shadow: 2px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
}

.MuiStepper-root {
    /*padding: 10vmin !important;*/
}

.MuiInput-root {
    width: 100%;
}


.option-btn {
    background-color: var(--color-gris-ligth);
    color: var(--color-gris-medio);
    width: max-content;
    padding: 10px;
    margin: 5px;
}

.MuiFormControl-root {
    width: 90%;
    padding: 5px;
}

.step-tittle {
    font-size: var(--font-size-item);
    color: var(--color-text-gris);
    text-align: center;
    padding: 3vmin;
}

.MuiButton-root.Mui-disabled {
    background-color: var(--color-gris-ligth) !important;
    color: black !important;
}

.makeStyles-button-2 {
    background: #F46036 0% 0% no-repeat padding-box !important;
    box-shadow: 2px 3px 6px #00000029 !important;
    color: black;
    border-radius: 8px !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
    width: 1.8em;
    height: 1.8em;
    border-radius: 50px;
    color: transparent !important;
    background: transparent linear-gradient(180deg, #23224c 0%, #23224c 100%) 0% 0% no-repeat padding-box;
    /* background: transparent linear-gradient(180deg, #540E87 0%, #FF2300 100%) 0% 0% no-repeat padding-box; */
}

.css-1wm3cm5-MuiSvgIcon-root-MuiStepIcon-root.MuiStepIcon-completed {
    top: 129px;
    left: 180px;
    width: 35px;
    height: 33px;
    color: white;
    background: transparent linear-gradient(180deg, #540E87 0%, #FF2300 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    box-shadow: 2px 3px 6px #00000029;
    border-radius: 50%;
}

.css-d0ukip-MuiButtonBase-root-MuiButton-root {
    border-style: solid;
    border-width: 1px;
    border-color: black;
    color: black;
    box-shadow: 2px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    padding-left: 30px;
    padding-right: 30px;
}


.MuiStepper-vertical {
    flex-direction: column;
    margin-left: 50px;
}

.css-1yudz7t-MuiTypography-root-MuiStepLabel-label.Mui-active {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 900;
    font-size: 24px !important;
    opacity: 1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.css-gga68z-MuiInputBase-input {
    padding-right: 100px !important;
    min-width: 500px !important;

}


.css-1t4i7pk-MuiButtonBase-root-MuiButton-root {
    background-color: #F46036 !important;
    box-shadow: 2px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
}

.css-1wm3cm5-MuiSvgIcon-root-MuiStepIcon-root.MuiStepIcon-completed {
    top: 129px;
    left: 180px;
    width: 35px;
    height: 33px;
    color: white;
    background: transparent linear-gradient(180deg, #540E87 0%, #FF2300 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 50%;
}

.css-d0ukip-MuiButtonBase-root-MuiButton-root {
    border-style: solid;
    border-width: 1px;
    border-color: black;
    color: black;
    box-shadow: 2px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    padding-left: 30px;
    padding-right: 30px;
}

.makeStyles-root-21 {
    max-width: 1000px !important;
}

.css-1yudz7t-MuiTypography-root-MuiStepLabel-label.Mui-active {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 900;
    font-size: 30px;
    opacity: 1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.col-two-create-project2 {
    width: 100%;
    display: flex;
    /* position: relative; */
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.formulariocrear .MuiInputBase-root {
    background: transparent !important;
}

.MuiSelect-select.MuiSelect-select {
    background: white !important;
}

.button_create_projectform {
    width: 111px;
    height: 28px;
    font-style: normal;
    background: #202256 0% 0% no-repeat padding-box;
    color: white;
    font-weight: 400;
    font-size: 14px;
    border-radius: 5px;
    transition: all 0.25s ease-in-out 0s;
    margin-bottom: 28px;
}

.button_create_projectform:hover {
    font-weight: 700;
}

.button_create_projectform2 {
    background: transparent 0% 0% no-repeat padding-box;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
    border: 1px solid #202256;
    width: 111px;
    height: 28px;
    border-radius: 5px;
    transition: all 0.25s ease-in-out 0s;
    margin-bottom: 28px;
}

.button_create_projectform2:hover {
    background: #202256 0% 0% no-repeat padding-box;
    color: white;
}

.audioo {

    margin-left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.input_create_project_new_form23 {
    height: 100%;
    padding-left: 20px;
    background: transparent;
    color: #414153;
    line-height: 50px;
    border: none;
    font-size: 14px;
    margin-bottom: 25px;
    margin-top: 25px;
    border-radius: 6px;
    padding-right: 15px !important;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}

.input_create_project_new_form23:focus {
    outline: 1px solid #0B27E6;
}
