.kps_form{
    margin-top: 2em;
    padding: 2% 15% 0% 15%;
    margin-bottom: 4em;
}

.kps{
    font-size: 15px;
}

.kps-location-date{
    text-align: right;
    margin-bottom: 1em;
}

.tittle-kps{
    margin-top: 3em;
    margin-bottom: 2em;
    font-size: 19px !important;
    text-align: center;
}

.kps-tittle-sec{
    display: inline-block;
}

.kps-rep-legal{
    display: inline-block;
}

.kps-company-name{
    display: inline-block;
}

.kps-company{
    display: inline-block;
}

.kps-nit-company{
    display: inline-block;
}

.kps-firm{
    margin-top: 3em;
    margin-bottom: 2em;
}