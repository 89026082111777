.hoja {
  height: 21cm;
  width: 29.7cm;
  border: 1px solid #D3D3D3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.marco {
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 92%;
  height: 89%;
}

.imagen {
  position: absolute;
  top: 40px;
  left: 40px;
  margin-top: -1px;
  margin-left: -1px;
}

.contenedor-firmas {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 30px;
  /*height: 100%;*/
}

.contenedor-firmas2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 100px;
}

.underline {
  width: 100%;
  height: 1px;
  background-color: black;
  margin-top: -15px;
  margin-bottom: 30px;
}

.fecha {
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
}

.subline-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  margin-top: -25px;
  text-align: center;
}

.subline-text2 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: -10px;
  text-align: center;
}

.contenedor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 100px;
}

.detalle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.detalle2 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: -20px;
}

.estudiante {
  font-weight: 700;
  font-size: 45px;
  font-style: italic;
}

.bootcamp-title {
  font-weight: 400;
  font-size: 30px;
}

.titulo-certif {
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  /*text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);*/
  margin-top: -5px;
}