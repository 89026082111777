.item-inactive {
    background-color: #C5C5C5;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    text-align: center;
}

.item-inactive-indigo {
    background-color: #202256;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    text-align: center;
}

.item-inactive-orange {
    background-color: #F07130;;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    text-align: center;
}

.item-inactive-aura {
    background-color: #E6E6E6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 85px;
    height: 85px;
    text-align: center;
    border-radius: 50%;
    margin-top: -42.5px;
}

.item-inactive-aura-indigo {
    background-color: #8875D1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 85px;
    height: 85px;
    text-align: center;
    border-radius: 50%;
    margin-top: -42.5px;
}

.item-inactive-aura-orange {
    background-color: #FE994A;;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 85px;
    height: 85px;
    text-align: center;
    border-radius: 50%;
    margin-top: -42.5px;
}

.item-inactive2 {
    background-color: #C5C5C5;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    text-align: center;
    text-overflow-ellipsis: 1;
}

.item-inactive2-indigo {
    background-color: #202256;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    text-align: center;
    text-overflow-ellipsis: 1;
}

.item-inactive2-indigo-mini {
    background-color: #202256;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.item-inactive-orange-mini {
    background-color: #F07130;;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.item-inactive-indigo-mini {
    background-color: #202256;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.item-inactive-aura-indigo-mini {
    background-color: #8875D1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
}

.item-inactive-aura-orange-mini {
    background-color: #FE994A;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
}


.item-inactive3 {
    background-color: transparent;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    text-align: center;
    text-overflow-ellipsis: 1;
}


.container-snake-1 {
    display: flex;
    justify-content: space-between;
    margin-right: 3%;
    margin-left: 5%;
    height: 210px;
    padding-left: 0;
    padding-right: 5%;
    border-radius: 0 10px 10px 0;
    border-top: solid 1px #989898;
    border-right: solid 1px #989898;
    border-bottom: solid 1px #989898;
}

.container-snake-LINE {
    display: flex;
    justify-content: space-between;
   /* margin-right: 3%;
    margin-left: 5%;*/
    height: 210px;
    padding-left: 0;
    /*padding-right: 5%;*/
    border-radius: 0 10px 10px 0;
    border-top: solid 1px #989898;
    /*border-right: solid 1px #989898;*/
    /*border-bottom: solid 1px #989898;*/
}

.container-snake-2 {
    display: flex;
    margin-top: -1px;
    justify-content: space-between;
    margin-right: 5%;
    margin-left: 3%;
    height: 210px;
    padding-left: 5%;
    padding-right: 3%;
    border-radius: 10px 0 0 10px;
    border-top: solid 1px #989898;
    border-left: solid 1px #989898;
    border-bottom: solid 1px #989898;
}

.container-snake-3 {
    display: flex;
    margin-top: -1px;
    justify-content: space-between;
    margin-right: 5%;
    margin-left: 3%;
    height: 200px;
    padding-left: 5%;
    padding-right: 0;
}

.item-inactive-text {
    margin-top: 140px;
    font-weight: 250;
    font-size: 11px;
    color: #C5C5C5;
}
 .item-title-responsive-list{
     text-align: center;
     color: rgba(21, 21, 21, 0.99);
     font-weight: 700;
     font-size: 14px;
     /*line-height: 0;*/
     margin-bottom: 0;
 }

.item-title-responsive-list-number{
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 14px;
    /*line-height: 0;*/
    margin: 0;
}

.item-title-responsive-list-number-snake{
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 16px;
    /*line-height: 0;*/
    margin: 0;
}