.tyc_form {
  width: 1000px;
 
}

.section {
  padding: 3%;
  text-align: justify;
}

.title {
  padding: 1%;
  font-weight: 600;
}

.titulo-preguntas{
font-weight: 600;
text-decoration: underline;
text-transform: uppercase;
font-size: 17px;
}

.content, .sub-content {
  padding: 1%;
}
.titulo-legal{
background: white;
color: black;
text-align: center;
padding-top:1em;
font-size: 18px;
padding-bottom:1em;
}

.tyc{
background-color: hsl(0, 0%, 98%);
display: flex;
}
.box {
width: 100%;
background-color:  #00000000;

}
.box .box-item {
border-bottom: 1px solid #d8d8d8 !important;
color: #050931;
}
.box:hover .box-item:hover {
border-bottom: 1px solid #d8d8d8 !important;
color: #F46036A6;
content: 'hola';
}
.box .title {
margin: 0;
padding: 10px;
}
.box-item-content{
height: 0;
overflow: hidden;
color: #7E7E7E;
margin: 0;
padding-left: 20px;
transition: 0.5s;
}
ul{
list-style: none;
}
ul li{
padding-top: 5px;
}
.box:hover .box-item:hover .box-item-content {
height: 90px;
}