.prof-list-global-cont {
    display: flex;
    width: 100%;
    height: 75px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.prof-list-rig-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.prof-list-rig-sub-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.prof-list-number {
    font-weight: 700;
    font-size: 12px;
    color: #C5C5C5;
    
    /*margin-right: 25px;*/
}

.prof-list-name {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.prof-list-job {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
}

.prof-list-card-image-filtro {
    /*filter: blur(3px);*/
    user-select: none;
    pointer-events: none;
    /*margin-right: 45px;*/
}

.prof-list-img {
    width: 55px;
    height: 55px;
    object-fit: cover;
    border-radius: 50%;
    margin: 15px;
}

.prof-list-ver-perfil {
    font-weight: 400;
    font-size: 14px;
    color: #202256;
    margin-right: 10px;
    filter: grayscale(100%);
}
.prof-list-ver-perfil:hover {
    filter: grayscale(0%); 
    color: #202256; 
    font-weight: 500;
    stroke-width: 1.5px; 
}

.prof-list-ver-perfil svg{
    stroke: #202256;
}


.prof-list-ver-perfil-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.prof-list-compat {
    margin-top: 8px;
    /*margin-left: 5px;*/
    font-weight: 400;
    font-size: 12px;
    color: #F07130;
}

.prof-list-compat-cont {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.prof-list-button {
    margin-left: 20px;
    background: transparent;
    border: 1px solid #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #202256;
    padding-right: 20px;
    padding-left: 20px;
}

.prof-list-button-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.prof-list-but-selec {
    margin-left: 20px;
    background-color: #F07130;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    padding-right: 20px;
    padding-left: 20px;
}
@media (max-width: 1465px) {
    .prof-list-button-cont {
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        height: 150px;
    }
    .prof-list-but-selec {
        width: 100%;
        background-color: #F07130;
        border-radius: 5px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        padding-right: 20px;
        padding-left: 20px;
        margin: 0;
    }

    .prof-list-ver-perfil-cont {
        border: 1px solid #202256;
        width: 100%;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0;
    }
    .prof-list-global-cont {
        display: flex;
        width: 100%;
        height: 150px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

@media (max-width: 1024px) {
    .prof-list-global-cont {
        height: fit-content;
        /*flex-direction: column;*/
        text-overflow: ellipsis;
    }

    .prof-list-name {
        text-overflow: ellipsis;
    }

    .prof-list-job {
        text-overflow: ellipsis;
    }

    .prof-list-rig-sub-cont {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

@media (max-width: 768px) {
    .prof-list-button-cont {
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        height: 150px;
    }
}

@media (max-width: 425px) {
    .prof-list-global-cont {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        padding: 5px;
        border-radius: 5px;
    }

    .prof-list-rig-sub-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
    }

    .prof-list-button-cont {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100px;
    }

    .prof-list-but-selec {
        width: 100%;
        background-color: #F07130;
        border-radius: 5px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        padding-right: 20px;
        padding-left: 20px;
        margin: 0;
    }

    .prof-list-ver-perfil-cont {
        border: 1px solid #202256;
        width: 100%;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0;
    }
}