.home-unilever-profesional{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  width: 100%;
}
.home-unilever-profesional-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
}
.home-unilever-profesional-banner{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}
.home-unilever-profesional-inner-1{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 20%;
  align-self: flex-start;
}
.home-unilever-profesional-inner-1-1{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #D6D6D6;
}
.home-unilever-profesional-inner-1-2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  width: 100%;
  margin-top: 3%;
}
.home-profesional-filter-1{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 70%;
  margin-bottom: 20px;
}
.home-profesional-filter-2{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  justify-content: space-evenly;
  width: 30%;
  margin-bottom: 20px;
  cursor: pointer;
}
.filter-title-unilever{
  font-size: 14px;
  font-weight: 400;
  color: #5D5D5D;
}
.filter-title-unilever-1{
  font-size: 24px;
  font-weight: 700;
  color: #393939;
}
.filter-category-title{
  font-size: 16px;
  font-weight: 700;
  color: #393939;
}
.filter-container-inner{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
}

.home-unilever-profesional-inner-2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  margin-left: 2%;
  height: 100%;
  align-self: flex-start;
}
.home-unilever-profesional-inner-2-1-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.home-profesional-search-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.home-profesional-view-container{
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: 30px;
  border-bottom: 1px solid #D6D6D6;
  cursor: pointer;
}
.filter-category-inner{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
}
.Profesional-Home-unilever-container-top{
  width: 90%;
  display: flex;
  margin-left: 30px;
}
.home-unilever-profesional-inner-2-2-container{
  display: flex;
  align-items: flex-start;
  justify-items: center;
  width: 100%;
  margin-bottom: 20px;
  height: 1000px;
}
.home-unilever-profesional-inner-2-2-container-inner{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
  height: 100%;
}
.home-unilever-profesional-inner-2-2-container-grid{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
  place-content: flex-start;
  grid-gap: 10px;
  margin-top: 32px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
@media only screen and (max-width: 1500px){
  .home-profesional-view-container{
    width: 30%;
  }
.Profesional-Home-unilever-container-top{
  width: 70%;
  margin: 0;
}
}
@media only screen and (max-width: 1500px){
  .home-unilever-profesional-inner-1{
    display: none;
  }
  .home-unilever-profesional-inner-2{
    width: 100%;
    margin-left: 0;
  }

  .home-unilever-profesional-inner-2-2-container-grid{
    overflow: hidden;
    height: auto;
  }
}