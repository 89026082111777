.container_body_metho{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10vh;
}

.sub_header{
    display: flex;
    flex-direction: column;
}

.metho-details-containercopy{
    display: flex;
    flex-direction:row-reverse;
    justify-content: space-between;
}

.formulariopopup_izquierda::-webkit-scrollbar {
    -webkit-appearance: none;
}

.formulariopopup_izquierda::-webkit-scrollbar:vertical {
    width:10px;
}

.formulariopopup_izquierda::-webkit-scrollbar-button:increment,.formulariopopup_izquierda::-webkit-scrollbar-button {
    display: none;
} 

.formulariopopup_izquierda::-webkit-scrollbar:horizontal {
    height: 10px;
}

.formulariopopup_izquierda::-webkit-scrollbar-thumb {
    background-color: #21204B;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.formulariopopup_izquierda::-webkit-scrollbar-track {
    border-radius: 10px;  
}
.btn_formulario_popup{    
    color: white;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    padding: 4px;
    border-radius: 5px;
}
.metho-details_editcopy{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    overflow: scroll;
    max-height: 635px;
    color: #212156;
    overflow-x: hidden; 
    border-radius: 0px;
}
.metho-details_editcopy2{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 26.5%; 
    margin-top: 2%;
}

  
.metho-details_editcopy::-webkit-scrollbar {
    -webkit-appearance: none;
}

.metho-details_editcopy::-webkit-scrollbar:vertical {
    width:11px;
}

.metho-details_editcopy::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
    display: none;
} 

.metho-details_editcopy::-webkit-scrollbar:horizontal {
    height: 10px;
}

.metho-details_editcopy::-webkit-scrollbar-thumb {
    background-color: #454545;
    border-radius: 20px;
    border: 2px solid #454545;
}

.metho-details_editcopy::-webkit-scrollbar-track {
    border-radius: 10px;  
    background-color: #c1c1c4;
}

  

  .sub_header{
    margin-left:10%
  }

  .tittle-project-methodology_edit{
      font-size:30px;
      font-weight:bold;
    margin-top:2%
  }

  .metho-details-tittle-p_subtittle_edit{
      font-size:15px;
      font-weight:600;
      margin-top:-10px;
      margin-bottom:20px
  }

  .metho-details-list-definition-project_edit{
    font-size:15px;
    font-weight:bold;
    margin-top:10%;
    margin-left:7%
  }

.video_archivos__containercopy{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 71%;
    margin-top: 2%;
    margin-left: 1px;
    margin-right: 1%;
    border-radius: 0px;
    padding-bottom: 30px;
}
.icon_list_methocopy{    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
/* .icon_list_methocopy path {
} */

.metho-details-list_edit{
    position: relative;
    justify-content: center;
    margin-left: 5%;
    margin-top:0px
}

.metho-label-new:hover{
    color: #474545;
    background-color: #c1c1c0;
}

.metho-label-new:active{
    color: #474545;
    background-color: #c1c1c0;
}

.subcontainer_btn_list_metho:hover{
    color: #474545;
    background-color: #c1c1c0;
}
.subcontainer_btn_list_metho:active{
    color: #474545;
    background-color: #c1c1c0;
}

.metho-details-list_edit button{
    background-color: transparent;
    margin-top: 10px;
}

.subcontainer_list_metho{
    height: 80px;
}

.metho-details-btn_edit{
    position: relative;
    text-align: left;
    width: 95%;
    justify-content: center;
}

.subcontainer_btn_list_metho{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.text_list_metho{
    margin-left: 10px;
    cursor: pointer;
    font-weight: 600;
    color: #44434f;
    text-transform: capitalize;
}
@keyframes crescendo {
    0%   {transform: scale(.8);}
    100% {transform: scale(1.5);}
  }



  .checkboxestilo{
    background-color: #8e8f9f;
    border-radius: 50%;
    cursor: pointer;
    height: 6px;
    min-width: 6px;
    margin: 0;
    z-index: 2;
    top: 0;
    width: 6px;
    min-height: 6px;
  }
  .checkboxestilo:after{
/*     border: 2px solid #212156;
 */    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(
-45deg);
    width: 12px;
  }
  .checkboxestilo2{
    visibility: hidden;
  }
  .checkboxestilo3{
    position: absolute;
    left: -100vw;
  }
  .checkboxestilo4{
    position: absolute;
    left: -100vw;
  }
  .checkboxestilo2:checked + .checkboxestilo{
    background-color: #1bb96d;
    border-color: #ffffff;
  }
  .checkboxestilo2:checked + .checkboxestilo::after{
    opacity: 1;
  }
  .checkboxestilo3:checked + .deliverablesclasproject{
    display: none;
  }
  .checkboxestilo4:checked + .labelfinalproject{
    display: none;
  }







  .linea_ht{
    width: 0px !important;
    position: absolute !important;
    height: 200% !important;
    display: none;
    background-color: rgba(255, 255, 255, 0) !important;
    border: solid !important;
    z-index: 1;
    border-radius: 100%;
    color: #ffffff00;
  }
  .fondo_methodology{
      width: 100%;
      height: 100vh;
      position: fixed;
      z-index: -1;
  }

  /* //////////etapascopy.css */
  .metho-details-content-Done {
    padding: 10px 10px;
    margin: .4rem;
    width: 150;
    background: transparent;
    background-color: var(--color-amarillo);
    color: white;
    border-radius: 10px;
    outline: none;
    font-size: 14px;
    text-align: center;
}
.metho-details-content{    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.placeholder-task-metho{
    text-align: start;
    color: #a4a4a4;
    padding-top: 1em;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
}

.metho-details-content-video{
    width:98%;
    height:max-content;
    margin-top:1%
}

.metho-details-content-description-title{
    font-size: 17px;
    text-align: left;
    width:90%;
    margin-left:5%;
    font-weight:600;
    color: #3A3A3A
}

.metho-details-content-description{
    font-size: 15px;
    text-align: left;
    width:98%;
    font-weight:500;
    color: #3A3A3A
}
.btn_mensaje5-tarea{
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: #f47227;
    margin-top: 10px; 
    margin: 0.5% 2%;
    border: solid 1px white;   
    padding: 4px;
    border-radius: 5px;
}


.comments-text {
    margin-bottom: 23px;
    min-height: 50px;
    height: fit-content;
    width: 100%;
}

.comments-text-inputcopy{
    margin-bottom: 23px;
    background-color: #F8FAFB;
    min-height: 50px;
    height: -moz-fit-content;
    border: none !important;
    border-style: none !important;
    text-align: center;
    font-weight: 300;
    font-size: 15px;
    resize: none;
    text-transform: uppercase;
    height: fit-content;
    width: 100%;
}

.tarea-container-methocopy{
    background-color: #F8FAFB;
    margin-top: 1.5em;
    border-radius: 5px;
    width:90%;
    padding: 0.5% 2.5% 0.5% 0.5%;
    margin-left:5%;
    margin-bottom:40px
}

.upload_file_css{
    width:90%;
    margin-left:5%
}