.cv-modal-button {
    border: 1px solid #202256;
    border-radius: 5px;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #202256;
    padding-right: 20px;
    padding-left: 20px;
    margin-left: 10px;
}

.cv-modal-button:hover {
    background: #202256;
    color: #fff;
    /* font-weight: 700; */
}

.cv-modal-linkedin-button {
    width: 190px;
    height: 35px;
    background: #0D0033;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px;
    color: #FFFFFF;
    margin-right: 15px;
}

.cv-modal-button-cont {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*justify-content: center;*/
}

.cv-modal-error {
    font-weight: 300;
    font-size: 12px;
    color: #E71C00;
    margin-bottom: 10px;
}

.cv-modal-message {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #5D5D5D;
}

.cv-modal-message-link {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #202256;
}

.cv-modal-button-cont2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cv-modal-cvgeneral-button {
    width: 190px;
    height: 35px;
    background: #D6D6D6;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px;
    color: #5D5D5D;
    margin-left: 15px;
}
@media (max-width: 425px) {
    .cv-modal-button{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .cv-modal-button{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}