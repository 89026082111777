.Perfil-company-container-cards{
  border-radius: 20px;
width: 324px;
min-height: 240px;
background-color: #ffffff;
font-size: .9em;
display:flex;
align-items: center;
justify-content: flex-start;
flex-direction: column;
margin-bottom:1em;
}

.Perfil-company-container-cards span{
width: 8.7em !important;
}

.porcentaje-company-container{
    font-size: .9em;
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.container-avatar-company{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.button-edit-avatar-company{
  background-color: #202256;
  color: white;
  border-radius: 5px;
  height: 2rem;
  width: 15.62rem;
  margin-top: 20px;
  margin-bottom: 5px;
  text-align: center;
  font-size: 14px;
}

.porcentaje-complete-company{
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top:4em;
}

.porcentaje-p-company{
  margin-right:.5em;
  color:#898989;
}

.porcentaje-text-company{
  color:#21224E
}

.button-edit-perfil-company{
  background-color: transparent;
  height: 35px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  color:#898989;
  margin-bottom: 1.5em;
}

.button-edit-perfil-company:hover{
  color: #202256;
}

.herre-company{
  color: #fbfbfb;
  width:80%;

}

.contact-data-company{
  color:#898989;
  font-size: .9em;
  margin-top: 1em;
}

.icon-cards-company{
  width:20px;
  height:20px;
  margin-top: -.2em;
  margin-right: 1em;
}
