.gUpMat {
    height: 60% !important;
    background-color: #463371 !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    color: rgb(255 255 255 / 87%) !important;
}

.table-proyects{
    margin: 0% 5% 5% 5%;
}

.g-users-section{
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: #540E87A6;
}

.g-users-tittle{
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
    color: white;
}

.img-support-user{
    height: 50px;
    width: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
}