.line-spinner {
  width: 64px;
  height: 64px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-right: 4px solid #F07130;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
