.MuiStepLabel-active {
    color: black;
    font-weight: 700 !important;
    font-size: 22px !important;
    text-transform: uppercase;
}

.MuiStepLabel-label {
    color: black;
    text-transform: uppercase;
    font-size: 18px !important;
}

.MuiButton-containedSecondary {
    color: #fff;
    background-color: #F46036 !important;
}

.MuiOutlinedInput-inputMarginDense {
    padding-top: 10.5px;
    padding-bottom: 5px !important;
}

.ConsultorForm-container{
    min-width: 300px;
    max-width: 700px;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    margin: 0 auto;
    text-align: center;
    background-color: white;
    min-height: 400px;
    border-radius: 2%;
    box-sizing: border-box;
    overflow: auto;
    
}

/* titulo */
.ConsultorForm-tittle{
    font-size: 25px;
    font-weight: 700;
    margin-top: 2rem;
    text-align: center;
    text-transform: uppercase;
}

.ConsultorForm_container-form{
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 5rem;
    padding: 40px 35px;
    background-color: #f6f6f6;
}
.consultor-cv-button-form{
    width: 200px; 
    height: 25px; 
    color: #FFFFFF; 
    background-color: #202256; 
    border-radius: 5px;
    transition: 400ms;
}
.consultor-cv-button-form:hover{
    background-color: #F07130;
    color: #FFFFFF;
}
.ConsultorForm-input{
    width: 100%;
    border: none;
    border-bottom: 1px solid;
    outline-style: none;
    font-size: 15px;
    margin-top: 4%;
    padding: 12px;
    box-sizing: border-box;
    background-color: transparent;
}
.ConsultorForm-label{
    padding-top: 2rem;
    text-align: left;
    font-size: 15px;
    color: #757575; 
}
.css-2b097c-container {
    position: relative;
    box-sizing: border-box;
    padding: 0px 10px 20px 0px;
    margin-top: 1em;
}

.ConsultorForm-input:focus {
    border-bottom: 2px solid var(--main-yellow-findally);
    outline: none;
} 

.ConsultorForm-button{
    padding: 8px;
    color: white;
    background-color: #f46036;
    border-style: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 600;
    text-transform: uppercase;
}

.ConsultorForm-label-activity{
    padding-top: 1rem;
    text-align: left;
    font-size: 15px;
    color: #757575;   
}

.ConsultorForm-select-activity{
    padding: 5px;
    margin-top: 10px;
    margin-left: 10px;
    border-color: #cccccc;
    border-radius: 2%;
    color: #757575;
}

.grapgrid{  
    grid-gap: 10px;
  }
  .buttondetalleoportunidad{
    padding: 0.2rem 0.7rem;
    height: 28px;
    left: 650px;
    top: 411px;
    color:white;
    background: #c5c5c5;
    border-radius: 5px;
    transition: 400ms;
  }
  .buttondetalleoportunidadchecked:hover{
    font-weight: bold;
    transition: 400ms;
    background: #F07130;
  }
  .buttondetalleoportunidadchecked{
    padding: 0.2rem 0.7rem;
    height: 28px;
    left: 650px;
    top: 411px;
    color:white;
    background: #202256;
    border-radius: 5px;
    transition: 400ms;
  }
  .textootroasoportunidades{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 36px;

display: flex;
align-items: center;


color: #393939;
  }
  .titulooportunidadesdetalle{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 40px;

display: flex;
align-items: center;

color: #202256;
  }
  .contedor-carta-recomendados-aplicar{    
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 300px;
    max-width: 297px;
    min-width: 70%;
    margin-bottom: 21px;
    margin-top: 10px;
    margin-left: 20px;
    border-radius: 8px;
  }
  .contenedor-primero-aplicar{    
    width: 100%;
    display: flex;
    padding-top: 20px;
    gap: 0rem;
    align-items: flex-start;
    padding-bottom: 20px;
    margin: 4em;
  }

  .img-primero-aplicar{
    margin-bottom: 1rem;
    width: 20%;
    height: 10%;
      max-height: 10%;
        max-width: 20%;
    border-radius: 8px;
    object-fit: fill;
  }
  .ifo-primero-aplicar{
    width: 40%;
    margin-left: 10%;
  }

  .tittle-popup-applied{
    margin-bottom: 0px 
  }

  @media (max-width:620px){
  .BodyWeb{
    padding: 0 1rem !important;
    }

    .img-primero-aplicar{
      margin-bottom: 1rem;
      width: 80%;
      height: 40%;
      border-radius: 8px;
      object-fit: fill;
    }
    .ifo-primero-aplicar{
      width: 80%;
    }
    .contenedor-primero-aplicar{    
        flex-direction: column;
        margin: 0;
      }
      .contedor-carta-recomendados-aplicar{
        margin-left: 0;
        max-width: 270px;
      }
      .contedor-carta-recomendados-aplicar div img{
        width: 270px !important;
      }
      .contedor-carta-recomendados-aplicar div {
        width: 270px !important;
      }
      .contedor-carta-recomendados-aplicar div div{
        width: 270px !important;
      }
      .titulooportunidadesdetalle{
        font-size: 20px;
        margin:0px !important;
      }
  }

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    margin:0px !important;
  }

  .oportunity-scroll {
    height: 190px;
    overflow: auto;
    overflow-y: scroll;
  scrollbar-color: orange rgb(189, 189, 189);
  scrollbar-width: thin;
  }
  .oportunity-scroll::-webkit-scrollbar-track
  {
      background-color: rgb(189, 189, 189);
  }
  
  .oportunity-scroll::-webkit-scrollbar
  {
      width: 5px;
      background-color: #F5F5F5;
  }
  
  .oportunity-scroll::-webkit-scrollbar-thumb
  {
      background-color: orange;
      border-radius: 10px;
  }

  .infoOportunity{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-left: 4em;
  }

  .infoOportunity-left{
    width: 46%;
    overflow-wrap: break-word;
    margin-right: 3em;
    margin-left: -30px;
  }

  .infoOportunity-ritgh{
    width: 40%;
    margin-left: 0;
  }

  @media (max-width:520px){
    .infoOportunity{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-left: 0;
    }
  
    .infoOportunity-left{
      width: 100%;
      margin-bottom: 1em;
      margin-right: 0;
      margin-left: 0;
    }
  
    .infoOportunity-ritgh{
      width: 100%;
    }
    .container-other-opp{
      align-items: center;
    }
    .contenedor-primero-aplicar img{
      align-self: center;
    }
}