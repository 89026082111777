.profesional-career-ia-container-row-chat{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.profesional-career-ia-container-career-chat{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.chat-ia-container-space-beetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-self: center;
}

.profesional-career-ia-chat-top{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 48rem;
    height: 100%;
    align-self: center;
    margin-bottom: 150px;
}
.profesional-career-ia-chat-bottom-input-background-blurred{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    z-index: 1;
}

.profesional-career-ia-chat-bottom-input-background-blurred::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(5px);
}

.profesional-career-ia-chat-bottom-input-container{
    width: 48rem;
    background-color: rgb(32, 34, 86);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    position: fixed;
    bottom: 0;
    margin-bottom: 1rem;
}
.profesional-career-ia-chat-input{
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
}
.input-chat-ia-send-button{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 30px;
    height: 30px;
    margin-left: 1rem;
    cursor: pointer;
}
.send-button-chat-ia{
    color: #fff;
    font-size: 30px;
    transition:  all 0.3s ease-in-out;
}
.send-button-chat-ia:hover{
    color: #ee7919;
    transition: 0.3s;
    transform: scale(1.1);
}
.blank {
    border: none;
    border-bottom: 1px solid #fff;
    background-color: transparent;
    font-size: inherit;
    padding: 0;
    margin: 0;
    color: #fff;
}
.blank:focus {
    outline: none;
    color: #fff;
}

.chat-top-card-container{
    background-color: #65656b;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    padding: 20px;
    border-color: rgba(32,33,35,.5);
    border-radius: 10px 10px 0 0;
}


.fade-enter {
    opacity: 0;
    transform: scale(0.9);
}
.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.chat-bottom-card-container{
    background-color: rgb(32, 34, 86);
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    padding: 20px;
    border-color: rgba(32,33,35,.5);
    border-radius: 0 0 10px 10px;
}

.chat-top-card-container-left{
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    position: relative;
}
.chat-top-card-container-left-img{
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 3px;
}
.chat-top-card-container-right{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 20px;
    white-space: pre-wrap;
    text-align: left;
    vertical-align: middle;
    line-height: 1.5;
    word-spacing: 0.2em;
    color: white;
    font-size: 18px;
}
.chat-top-card-container-right-ia{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 20px;
    white-space: pre-wrap;
    text-align: left;
    vertical-align: middle;
    line-height: 1.5;
    word-spacing: 0.2em;
    color: white;
    font-size: 18px;
}
.profesional-career-ia-container-row-der-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    height: 100vh;
}
.profesional-career-ia-container-row-izq-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    height: 100vh;
}
.logo-coally-izq-chat-ia{
    opacity: 0.1;
    max-height: 150px;
    max-width: 150px;
}


@media screen and (max-width: 1340px) {
    .profesional-career-ia-container-row-izq-logo{
        display: none;
    }
    .profesional-career-ia-container-row-der-logo{
        display: none;
    }
}

@media screen and (max-width: 790px) {
    .profesional-career-ia-chat-top{
        width: 90%;
    }
    .profesional-career-ia-chat-bottom-input-container{
        width: 90%;
    }
    .profesional-career-ia-chat-input p {
        font-size: 16px !important;
    }
}
