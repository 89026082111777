#paymentWidgetContainerCard {
    max-width: 820px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border-color: #23224c;
}

#paymentSelectForm {
    display: flex;
    flex-direction: column;
    text-align: center;
}

#paymentArea {
    padding-top:0px; 
    width:100%;
}

.paymennequi{
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}
.paymennequi2{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}
.caja2paymen{
    background: #fff;
    width: 50%;
    border: solid 1px #23224c;
    padding: 20px;
    position: relative;
    border-radius: .25rem;
}
.butonesnequi{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.caja2paymen2{
    background: #fff;
    width: 50%;
    border: solid 1px #23224c;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: .25rem;
}
.inputnequi{
    width: 100%;
    padding: .375rem .75rem;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    background-color: #fff;
}
.pnequi{
    margin: 1rem 0px 0px 0px;
}
.inputnequi:focus{
    outline:#23224c;
    border:solid 3px #5c5aeb80;
}
.btnnequi{
    background: #23224c;
    color: white;
    padding: 10px 20px 10px 20px;
    border-radius: .25rem;
    margin-top: 1rem;
}
/* /////////////////// */
/* /////////////////// */
/* /////////////////// */
@keyframes ldio-ft58je84is8 {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  .ldio-ft58je84is8 div {
    left: 145.825px;
    top: 84.425px;
    position: absolute;
    animation: ldio-ft58je84is8 linear 0.8928571428571428s infinite;
    background: #f47128;
    width: 15.35px;
    height: 27.63px;
    border-radius: 7.675px / 9.3942px;
    transform-origin: 7.675px 69.075px;
  }.ldio-ft58je84is8 div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.8370535714285714s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(2) {
    transform: rotate(22.5deg);
    animation-delay: -0.7812499999999999s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(3) {
    transform: rotate(45deg);
    animation-delay: -0.7254464285714285s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(4) {
    transform: rotate(67.5deg);
    animation-delay: -0.6696428571428571s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(5) {
    transform: rotate(90deg);
    animation-delay: -0.6138392857142857s;
    background: #21204b;
  }.ldio-ft58je84is8 div:nth-child(6) {
    transform: rotate(112.5deg);
    animation-delay: -0.5580357142857142s;
    background: #21204b;
  }.ldio-ft58je84is8 div:nth-child(7) {
    transform: rotate(135deg);
    animation-delay: -0.5022321428571428s;
    background: #21204b;
  }.ldio-ft58je84is8 div:nth-child(8) {
    transform: rotate(157.5deg);
    animation-delay: -0.4464285714285714s;
    background: #21204b;
  }.ldio-ft58je84is8 div:nth-child(9) {
    transform: rotate(180deg);
    animation-delay: -0.39062499999999994s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(10) {
    transform: rotate(202.5deg);
    animation-delay: -0.33482142857142855s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(11) {
    transform: rotate(225deg);
    animation-delay: -0.2790178571428571s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(12) {
    transform: rotate(247.5deg);
    animation-delay: -0.2232142857142857s;
    background: #f47128;
  }.ldio-ft58je84is8 div:nth-child(13) {
    transform: rotate(270deg);
    animation-delay: -0.16741071428571427s;
    background: #21204b;
  }.ldio-ft58je84is8 div:nth-child(14) {
    transform: rotate(292.5deg);
    animation-delay: -0.11160714285714285s;
    background: #21204b;
  }.ldio-ft58je84is8 div:nth-child(15) {
    transform: rotate(315deg);
    animation-delay: -0.055803571428571425s;
    background: #21204b;
  }.ldio-ft58je84is8 div:nth-child(16) {
    transform: rotate(337.5deg);
    animation-delay: 0s;
    background: #21204b;
  }
  .loadingio-spinner-spinner-9bz1nhdfjp {
    width: 307px;
    height: 307px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-ft58je84is8 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
  }
  .ldio-ft58je84is8 div { box-sizing: content-box; }
/* /////////////////// */
/* /////////////////// */
/* /////////////////// */
  .C,
  .a,
  .r,
  .g{color:#ef732d ;font-size: 2rem;}
  .a2,
  .n,
  .d,
  .o,
  .punto1,
  .punto2,
  .punto3{color:#222046;font-size: 2rem;}
  .C {animation: C  1s infinite;opacity: 0;}
  .a {animation: a  1s infinite;animation-delay: 0.07s;opacity: 0;}
  .r {animation: r  1s infinite;animation-delay: 0.14s;opacity: 0;}
  .g {animation: g  1s infinite;animation-delay: 0.21s;opacity: 0;}
  .a2{animation: a2 1s infinite;animation-delay: 0.38s;opacity: 0;}
  .n {animation: n  1s infinite;animation-delay: 0.55s;opacity: 0;}
  .d {animation: d  1s infinite;animation-delay: 0.62s;opacity: 0;}
  .o {animation: o  1s infinite;animation-delay: 0.69s;opacity: 0;}
  .punto1{animation: punto1 1s infinite;animation-delay: 0.76s;opacity: 0;}
  .punto2{animation: punto2 1s infinite;animation-delay: 0.82s;opacity: 0;}
  .punto3{animation: punto3 1s infinite;animation-delay: 0.90s;opacity: 0;}
  @keyframes C {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes a {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes r {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes g {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes a2{0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes n {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes d {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes o {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes punto1 {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes punto2 {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}
  @keyframes punto3 {0%{ opacity: 0 } 50%{ opacity: 1 } 100%{ opacity: 0 }}