.form-edit-profile-Company h3{
    font-weight: 700;
    font-size: 16px;    
    color: #393939;    
    /* line-height: 22px; */
}

.form-edit-profile-Company label{
    font-size: 14px;
    line-height: 20px;
    color: #393939;   
}

.form-edit-profile-Company p{
   color: #C5C5C5;
    font-size: 12px;
    /* line-height: 20px; */
    margin-bottom: 2em;
}

.form-edit-profile-Company input , .form-edit-profile-Company textarea{
    border: 0.5px solid #C5C5C5;
}

.buttonGuardar{
display:flex;
justify-content: flex-end;
margin-top: 1em;
}

.buttonGuardar button{
    position: relative;
    width: 105px;
    height: 24px;   
    color: white;
    background: #202256;
    border-radius: 5px;
  }


@media (max-width: 725px){
    .css-1uvkz21{
        width:350px !important;
        
    }
    .container-input-edit-profile-Company{
        min-width:90% !important;
    }
}  