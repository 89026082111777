.container-dashboard {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: #304758;
}

.container-dashboard-glass {
    height: 90%;
    width: 90%;
    border-radius: 8px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 30px;

}

.dashboard{
    display: flex;
    flex-direction: column;
    margin: 30px;
}

.tables-dashboard{
    display: flex;
    flex-direction: row;
    align-self: center;
}

.list-projects{
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 40%;
    border-radius: 0.7rem;
    flex: 1;
    position: relative;
    align-self: center;
    margin-top: 15px;
    margin-bottom: 5px;
}

.only-h3{
    border-radius: 35px;
    margin-top: 15px;
    align-self: center;
    text-align: center;
    width: 70px;
    height: 70px;
}

.chartContainer{
    display: flex;
    justify-content: center;
}
