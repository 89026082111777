.Bootcamp-Test-Card-container {
    display: flex;
    background-color: #FFFFFF;
    border-radius: 16px;
    height: fit-content;
    min-width: 330px;
    margin-top: 22px;
    margin-right: 18px;
    padding: 17px 20px 8px 16px;
}

.Bootcamp-Test-Card-container:hover {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25);
    border: 1px solid #202256;
    cursor: pointer;
  }
  

.Bootcamp-Test-Card-sub-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 15px;
}

.Bootcamp-Test-Card-Title{
    font-weight: 700;
    font-size: 14px;
    color: #393939;
}

.Bootcamp-Test-Card-P{
    font-weight: 300;
    font-size: 14px;
    color: #5D5D5D;
    margin-top: 10px;
}