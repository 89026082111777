.Profesional-Test-Card-container {
    display: flex;
    background-color: #FFFFFF;
    border-radius: 16px;
    height: fit-content;
    min-width: 330px;
    margin-top: 22px;
    margin-right: 18px;
    padding: 17px 20px 8px 16px;
}

.Profesional-Test-Card-Sub-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    height: 78%;
    text-align: center;
}

.Profesional-Test-Card-Title {
    font-weight: 700;
    font-size: 14px;
    color: #393939;
}

.Profesional-Test-Card-P {
    font-weight: 300;
    font-size: 14px;
    color: #5D5D5D;
    margin-top: 10px;
}

.Profesional-Test-Card-sub-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
}
.Profesional-Test-Card-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    width: 90px;
    /*border-radius: 10px;*/
    /*background-color: #F2F2F2;*/
}

.Profesional-Test-Button {
    height: 30px;
    width: 120px;
    background: transparent;
    border: 0.5px solid #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
}

