.prof-sim-card-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 65px;
}

.prof-sim-card-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin: 15px;
}

.prof-sim-card-image-filtro {
    filter: blur(3px);
    user-select: none;
    pointer-events: none;
}

.prof-sim-card-name {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}

.prof-sim-card-job {
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.prof-sim-card-comp {
    font-weight: 400;
    font-size: 14px;
    color: #F07130;
}

.prof-sim-card-button {
    background: transparent;
    border: 1px solid #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #202256;
    padding-right: 20px;
    padding-left: 20px;
}