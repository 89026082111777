.container_header__resume{
  height:400px;
  width: 100%;
  text-align: -webkit-center;
}

.header_resume{
 width: 100%;
 position: absolute;
 background-color: white;
}

.perfil-resume {
    width: 100%;
    background-color: transparent !important; 
  }
  .perfil-header-resume {
    background-color: transparent;
    justify-content: center;
    padding-bottom: 3em;
  }
  
  .perfil__header-container-resume {
    width: 100%;
  }
  .perfil__header__container-img-resume {
    position: relative;
  }
  
  .perfil__header-profile-resume {
    text-align: left;
    display: flex;
    margin-left: 3%;
    padding-top: 22vh;
  }
  
  .perfil__header__profile-txt-resume {
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    margin-left: 20%;
    margin-top: 20px;
    z-index: 3;
  }
  .logo_resume_derecha{    
    position: absolute;
    width: 28%;
    z-index: -1;
    right: 0;
    margin-top: 13vh;
  }
  .perfil__header__profile-txt-resume2 {
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
    margin-left: 20%;
    margin-top: 20px;
    z-index: 3;
  }

  .perfil__header__profile-profesion-txt-resume{
      color: white;
      font-weight: 200;
      font-size: 16px;
      text-transform: uppercase;
  }
  .perfil__header-profile-img-resume {
    border: 1px solid;
    padding: 10px;
    width: 125px;
    margin-bottom: 10px;
    margin-top: -3em;
    background-color: transparent;
  }
  
  .general-resume-tittle{    
    height: max-content;
    width: 28%;
    padding-left: 2em;
    position: relative;
    padding-right: 2em;
    padding-top: 2em;
    margin-top: 80px;
    color: white !important;
    right: 0;
    display: flex;
    justify-content: center;
  } 

    .id1{
      margin-left:-36px;
      font-size:15px
    }

  .general-resume-content{   
    min-height: 600px;
    width: 65%;
    padding-top: 2em;
    margin-top: 80px;
    margin-bottom: 40px;
  }

  .tittle-left-resume{
    font-size: 19px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: left;
  }
  .tittle-left-resume2{
    font-size: 19px;
    font-weight: 400;
    color: white;
    text-transform: uppercase;
    padding: 1rem 5rem 1rem 1rem;
    display: flex;
    justify-content: flex-start;
    background-color: #212156;
  }

  .tittle-rigth-one-resume{
    color: #ffffff;
    background-color: #212156;
    font-size: 19px;
    padding: 1rem 5rem 1rem 1rem;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 1.5em;
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }

  .titles_right_column{
    display:flex;
    flex-direction:row;
    border:solid 1px #f47227;
    margin-bottom: 2vh;
    justify-content: center;
    padding: 0.5rem 0rem 0.5rem 0rem;
  }

  .titles_right_column h4{
    margin: 0;
  }

  .content-one-experience{
    margin-top:17px
  }

  .description-left-resume{
      text-align: left;
      font-size:14px;
      width: 100%
  }

  .calificacion_resume{
    display:flex;
    flex-direction:row;
  }

  .list-left-resume{
      margin-bottom: 10px;
  }

  .content-resume-columns{
    display: flex;
    flex-direction: row-reverse;
    width: 97%;
    margin-left: 3%;
    justify-content: space-between;
  }

  .experience-content-resume{
    margin-bottom: 0.3em;
    font-size:15px;
    color: #344863;
    margin-left:5px;
    font-weight:500
    }
  
  .institute_form{
    font-size:15px;
    margin-left: 30px;
    color:#344863;
  }

  .experience_form{
    font-size:14px;
    margin-left: 30px;
    width:90%;
    color: #7f8083;
  }
  
  .education-content-resume{
    margin-bottom: 0.3em;
    margin-left:5px;
    font-size:15px;
    color: #7f8083;
    width:90%
    }

  @media screen and (max-width:630px){
    .perfil-resume{
        width: 100%;
        grid-template-columns: 1fr;
    }
  }
.lineamedioperfil{
  margin-top: 6vh;
}
  @media (min-width:1919px) {
    
.perfil__header-profile-resume{
  text-align: left;
  display: flex;
  margin-left: 3%;
  padding-top: 9vh;
}
.lineamedioperfil{
  margin-top: 3.5vh;
}
.logo_resume_derecha{
  position: absolute;
    width: 28%;
    z-index: -1;
    right: 0;
    margin-top: 3vh;
}
}
  @media (min-width:1819px) {
    
.perfil__header-profile-resume{
  text-align: left;
  display: flex;
  margin-left: 3%;
  padding-top: 16vh;
}
.lineamedioperfil{
  margin-top: 4.5vh;
}
.logo_resume_derecha{
  position: absolute;
    width: 28%;
    z-index: -1;
    right: 0;
    margin-top: 3vh;
}
}
    
  