.top-part{
    width: calc(100vw - 237px);
}

.container-prof {
    width: 95%;
    margin: 4rem auto; 
}

.info-section {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.main-section-info {
    margin-left: 3%;
     width: 90%; 
     display: flex;
}
.first-container {
    width: 74vw;
}
.card-container-info {
    background-color: white;
    width: 40%;
    border-radius: 5px;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    padding: 44px;
    height: fit-content;
}
.about .extracto-info {
    font-family: "Poppins";
    color: #5D5D5D;
    font-weight: 300;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    word-wrap: break-word;
}

@media(max-width: 1300px) {
 .container-prof {
    max-width: revert !important;   
 }
 .top-part{
    width:100% !important;
    margin-right: auto !important;
 }
}

@media (max-width:725px) {
    .container-prof {
     display: contents !important; ;
     justify-content: center !important;
    }
    .top-part {
        display: contents !important;
        justify-content: center !important;
    }
    /*.title-top {*/
    /*    margin-top: 3em !important;*/
    /*    margin-bottom: 2em !important;   */
    /*    margin-left: 1em !important;  */
    /*}*/
    .first-container{
       width: 100vw;
       display: contents !important;     
    }
    .info-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .info-section .info-section-avatar {
        margin-bottom: 1em !important;
    }
    .info-section .about {
        width: 360px;
       /*  margin-right: 60em; */
        margin-top: 3em;
        display: contents !important; ;
     justify-content: center !important;
    }
    .info-section .card-container-info {
        width: 90%;
        margin-top: 2em;
    }
    .info-section .stars-rating {
        margin-right: 5em !important;
    }
    .about .extracto-info {
        width: 320px !important;
    }
    .main-section-info {
        margin-left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}