.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 400;
  }
  
  .popup-inner {
    position: absolute;
    display: flex;
    top: 7%;
    padding: 10px;
    background: #1bb96d;
    width: 40%;
    align-items: center;
    color: white;
    height: auto;
    border-radius: 8px;
    justify-content: space-evenly;
  }
  
  .popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  
  .close-btn-pop{
    /* padding: 7px; */
    background-color: white;
    color: #7dbe6a;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 12px;
    border-style: none;
    border-radius: 5px;
    text-transform: uppercase;
  }