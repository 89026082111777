.support_ticket{
  background-color: hsla(0, 0%, 100%, 0);
  padding-top: 50px;
  display: flex;
  z-index: 1;
}
.bottom-blur-advance{
  position: absolute;
  background-size: cover;
  background: #212156;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.bottom-blur-advance2{
  position: absolute;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}


.volver-legal{
  background: #212156;
  color: white;
  text-align: left;
  font-size: 18px;
  padding-top: 13px;
  padding-bottom: 13px;
}
.ejemplo-imagen{
  position: relative;
  width: 100%;
  height:100%;
  background-color: rgb(0, 0, 0);
  margin-right: 20px;
  border-radius: 10px;
}
.volver-leg-icon{
margin-left: 15px;
}
.text-form-right{
  height: 100%;
  width:40%;
  margin-left: 9em;
  margin-top: 1em;
}
.support_form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fffffff6;
  width: 900px;
  height: 550px;
  margin: 17%;
  margin-top: 5%;
  border-radius: 10px;
  padding: 40px;
  box-sizing: border-box;
  position: absolute;
}

.content-contact-support-info{
  color: #212156;
}

.support_form2{
  display: flex;
  flex-direction: column;
  background-color: #fffffff6;
  width: 700px;
  height: max-content;
  margin-top: 0;
  margin-left: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  position: absolute;
}

.input_support {
  width: 80%;
  margin-top: 4%;
  border-radius: 5px;
  outline: none;
  padding: 2%;
  border: 1px solid #d9d9d9;
  font-size: 16px;
}

.textarea-support{
  width: 80%;
  height: 50%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  resize: none;
  outline-style: none;
  margin-top: 2%;
  padding: 2em;
}

.textarea {
  width: 100%;
  height: 50%;
  outline-style: none;
  margin-top: 4%;
  padding: 7% !important;
  margin-left: 0!important;
  border-style: none !important ;
  background-color: #aaa9a981;
  border-radius: 50px !important;
  font-size: 14px;
  resize: none;
}

.category-label {
  padding: 3%;
  color: #393939;
}

.category-options{
  font-size: 13px;
}

.title_form_ticket {
  color: #ffffff !important;
  margin-bottom: 10%;
  text-transform: uppercase;
  font-weight: 300;
}

.button_support{
  background-color: #212156;
  width: 30%; 
  text-transform: uppercase;
  padding: 2%;  
  margin-top: 5%;
  border-radius: 10px;
  font: normal normal bold 14px/20px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
}
.contenedor-indo{
  display: flex;
  
}
.info- {
  border: 4px solid #212156;  
  width: 30%;
  height: 440px;
  padding: 2%;
  margin-top: 3.5%;
  margin-left: 15%;
  font-size: 16px;
  border-radius: 15px ; 
}
