@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap');

html {
  height: 100%;
}

:root {
  --main-purple-findally: #540e87;
  --main-yellow-findally: #f5cc00;
  --main-orange-findally: #feae3b;
  
    /* Colors: */
    --color-naranja:#F46036;
    --color-morado:#540E87;
    --color-morado-medio: #6A448B;
    --color-morado-oscuro: #463371;
    --color-azul: #212156;
    --color-negro:#393939;
    --color-amarillo: #FF982A;
    --color-blanco: #FFFFFF;
    --color-text-gris:#707070;
    --color-gris-ligth : #EAEAEA;
    --color-gris-medio: #464545;
    --color-verde:#40a832;
    
    /* Font/text values */
    --font-raleway: 'Raleway';
    --font-primary: 'Poppins';
    --font-ubuntu:'Ubuntu';
    --font-segundary:'Helvetica';
    --font-weight-bold: bold;
    --font-weight-normal: normal;
    --font-size-regular: 14px;
    --font-size-minimo:12px;
    --font-size-item:16px;
    --font-size-item-grande:18px;
    --font-size-cuerpo: 18px;
    --font-size-titulo-segundario: 26px;
    --font-size-titulo: 32px;
    --character-spacing-0: 0px;
    --line-spacing-38: 38px;
    --line-spacing-46: 46px;
    --line-spacing-59: 59px;
    --line-spacing-94: 94px; 
    }
    

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.container{
  width: 95%;
  margin: 4rem auto;
  /* max-width: 1440px; */
}

h3{
  font-size: 1.2rem;
}

h4{
  font-size: 1.2rem;
}
body {
  height: 100%;
  font-family: var(--font-primary);
  background-color: #f9f9f9;
}
.input-btn{
  background-color: #f46036;
  border-radius: 0.25rem !important;
  border: none;
  font-size: 13px;
  padding: .5rem;
  cursor: pointer;
  box-sizing: border-box;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
}
.input-text{
  background: #ffffff00;
  color: #a7a3a3;
  width: 80%;
  font-size: medium;
  border: none;
  border-bottom: 2px solid !important;
  border-color: black !important;
  outline-style: none;
  box-sizing: border-box;
}
.Header-lang{
  background: transparent;
  z-index: 2;
  position: absolute;
  left: 90%;
  top: 5%;
}
a {
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  color: inherit;
}
button {
  text-decoration: none;
  outline: none;
  cursor: pointer;
  border: none;
  width: fit-content;
  color: inherit;
}
.security-space{
  margin: 1rem;
}
.etiqueta{
  background-color: #21204B;
  color: #ffffff;
  font-size: 12px;
}
.security-space-min{
  margin: .5rem 0rem;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
.rounded{
  border-radius: 1rem;
}

.AppFormContainer{
    background-size: cover;
    height: 100%;
    min-height: 100vh;
    padding-top: 3rem;
    box-sizing: content-box;
    background-position: center;
    background-repeat: no-repeat;
}

.containerForm{
  margin:0;
}

.MuiOutlinedInput-root {
  position: relative;
  height: 50px;
  border-radius: 4px;
}

.volver-legal{
  background: #212156 ;
  text-transform: uppercase;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.volver-legal2{
  FONT-SIZE: 1.5vw;
  font-weight: 900;
  background-color: #f1f4f6;
  padding-top: 2% !important;
  padding-bottom: 5px !important;
  padding-left: 4%;

}
.flex-sp-bt{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Header-lang{
  top:3% !important;
}

.volver-demo{
  background: white !important;
  text-align: right;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.mediumfont{
  /* medium*/
  font-family:'Poppins', sans-serif !important;
  font-weight: 500 !important;
}
.semiboldfont{
  /* semibold*/
  font-family:'Poppins', sans-serif !important;
  font-weight: 600 !important;
}
.boldfont{
  /* bold*/
  font-family:'Poppins', sans-serif !important;
  font-weight: 700 !important;
}
.btn-more-pro{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 9px 25px;
  border-radius: 30px;
  background-color: #f07130;
}
.Ejemplo-con-dos-lineas-de-texto-corrido {
  padding: 0 0 1px;
  font-family:'Poppins', sans-serif !important;
  font-size: 20px;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.6px;
  text-align: left;
  color: #2e2e31;
}
.Texto-corrido {
  font-family:'Poppins', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.28px;
  text-align: left;
  color: #696a7c;
}
.sombrahover:hover{
background-color:rgb(228, 228, 228) !important;
cursor: pointer;
}
.grap{  
  grid-column-gap: 3%;
}
.margin0{
  margin: 0 !important;
}