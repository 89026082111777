.contenedorcrearOportunidadNew {
 /*  max-width: 772px; */ 
  position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
  height: 419px;
 /*  background-color: #fff; */
  border-radius: 8px;
  overflow: auto;
  box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.1)

}

#eng{
  padding:2em;
}
.contenedorcrearOportunidadNew2 {
/* max-width: 772px;  */
position: absolute;
  top: 50%;
  left: 50%;
 /*  transform: translate(-50%, -50%); */
height: 100%;
/* background-color: #fff; */
border-radius: 8px;
overflow: auto;
box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.1)

}

.contenedorcrearOportunidadNew3 {
max-width: 772px; 
position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
height: 419px;
background-color: #fff;
border-radius: 8px;
overflow: auto;
box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.1)

}

/* ChooseType */

.contenedorChooseType {
display: flex; 
align-items: flex-start; 
flex-direction: column;

}

.contenedorChooseType .contenedorChooseType_title {
font-family: 'Poppins', sans-serif;
font-size: 14px;
font-weight: 700;
margin-bottom: 20px;
margin-left: -150px;

}
.contenedorChooseType
.chooseTypeRadio {
margin-left: -105px;
}

.chooseTypeButton {
display: flex;
width: 100%;
justify-content: space-between;
padding: 1rem 3rem 0rem 3rem;
align-items: center;
}

/* Modalidad   */

.contenedorModalidad {
display: flex; 
align-items: flex-start; 
flex-direction: column;

}
.modalidadRadio {
display: flex;
flex-direction: row;
}

.stepCounter {
font-family: 'Poppins', sans-serif;
font-size: 12px;
font-weight: 400;
}

/* setAnonimo */

.contenedorSetAnonimo {
display: flex; 
align-items: flex-start; 
flex-direction: column;
}

.contenedorSetAnonimo .contenedorSetAnonimo_title {
font-family: 'Poppins', sans-serif;
font-size: 14px;
font-weight: 700;
margin-bottom: 20px;
margin-left: -240px;

}
.setAnonimoRadio {
margin-left: -225px;
}

 /* Abilities  */

 .MuiInputBase-root{
  flex:auto !important;
} 

/* Duration */

.contenedorDuration {
display: flex; 
align-items: flex-start; 
flex-direction: column;
margin-left: 50px;
}
.contenedorDuration .contenedorDuration_title {
font-family: 'Poppins', sans-serif;
font-size: 14px;
font-weight: 700;
margin-bottom: 20px;
margin-left: -150px;

}
.contenedorDuration .durationRadio {
margin-left: -95px;
}

/* Presupuesto */

.contenedorPresupuesto {
display: flex;
align-items: flex-start;
flex-direction: column;
margin-left: -100px;
}
.contenedorPresupuesto .selectCurrency {
border: 0.5px solid rgb(197, 197, 197);
width: 80%;
height: 30px;
background: transparent;
margin-left: 10px;
margin-bottom: 10px;
}
.contenedorPresupuesto .contenedorPresupuesto_input {
border: 0.5px solid rgb(197, 197, 197);
width: 400px;
height: 41px;
margin-left: 10px;
}

.contenedorOportunityInfo {
display: flex;
align-items: flex-start; 
flex-direction: column; 
margin-bottom: 50px; 
}
.contenedorOportunityInfo .contenedorOportunityInfo_input {
border: 0.5px solid #C5C5C5;
width: 568px; 
height: 41px;
 margin-left: 10px;
}
.contenedorOportunityInfo .contenedorOportunityInfo_TextArea {
border: 0.5px solid #C5C5C5;
width: 568px;
height: 170px;
margin-left: 10px;
}
.contenedorOportunityInfo .contenedorOportunityInfo_title {
font-family: 'Poppins'; 
font-weight: 700; 
font-size: 14px; 
margin-bottom: 20px; 
margin-left: -5px;
}

/* external */
.contenedorExternal_input {
border: 0.5px solid #C5C5C5;
width: 568px;
height: 41px;
margin-left: 10px;
}

.contenedorHabilidad {
margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 80px;
}

.button_siguiente_create_B {
background: transparent 0% 0% no-repeat padding-box;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #202256;
  border: 1px solid #202256;
  width: 111px;
  height: 28px;
  border-radius: 5px;
  transition: all 0.25s ease-in-out 0s;
  margin-bottom: 28px;
}

.button_siguiente_create_C {
  background: transparent 0% 0% no-repeat padding-box;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
    border: 1px solid transparent;
    width: 111px;
    height: 28px;
    border-radius: 5px;
    transition: all 0.25s ease-in-out 0s;
    margin-bottom: 28px;
  }

.button_siguiente_create {
  background: #202256;;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #ffff;
    border: 1px solid #202256;
    width: 111px;
    height: 28px;
    border-radius: 5px;
    transition: all 0.25s ease-in-out 0s;
    margin-bottom: 28px;
  }

  .button_siguiente_create:disabled {
    background-color: '#C5C5C5';
    color: '#ffff';
    border: none;
    pointer-events: none;
  }

  .button_finish_project {
    background: #f37328 0% 0% no-repeat padding-box;
    box-shadow: 4px 3px 6px #00000029;
    color: white;
    border-radius: 4px;
    padding: 0.5em;
    margin-bottom: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
}
.contenedorCompanyInfo{
  display: flex; 
  flex-direction: column; 
  margin-top:1em; 
}

.contenedorCompanyInfo_title{
  font-family: Poppins;
   font-weight: 700;
    font-size: 14px;
     margin-bottom: 10px;
      margin-left: -5px;
}

.radio_unchecked_icon{
  /* width: '16px', height: '16px', color: '#C5C5C5' */
  width: 16px;
  height: 16px;
  color: #C5C5C5;
}

.radio_checked_icon{
  /* color: '#F07130', width: '16px', height: '16px' */
  color: #F07130;
  width: 16px;
  height: 16px;
}

.contenedorCompanyInfo_input{
width:300px;
border: 0.5px solid #C5C5C5
}


    @media (max-width: 768px) {
      .radio_unchecked_icon{
        width: 25px;
        height: 25px;
      }
      .radio_checked_icon{
        width: 25px;
        height: 25px;
      }
      .contenedorChooseType {
        align-items: center;
      }
      .contenedorChooseType .contenedorChooseType_title {
        margin-left: 0;
      }
      .contenedorChooseType
      .chooseTypeRadio {
        margin-left: 0;
      }
      .chooseTypeButton {
        padding: 1rem 1rem 0rem 1rem;
        flex-direction: column-reverse;
        margin-top: 20px;
      }

      .contenedorModalidad {
        align-items: center;
      }
      .modalidadRadio {
        flex-direction: column;
      }
      .contenedorSetAnonimo {
        align-items: center;
      }
      .contenedorSetAnonimo .contenedorSetAnonimo_title {
        margin-left: 0;
      }
      .setAnonimoRadio {
        margin-left: -100px;
      }
      .contenedorDuration {
        align-items: center;
      }
      .contenedorDuration .contenedorDuration_title {
        margin-left: 0;
      }
      .contenedorDuration .durationRadio {
        margin-left: -50px;
      }
      .contenedorPresupuesto {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 50px;
        margin-top: 10%;
        margin-left: 0px;
      }
      .contenedorPresupuesto .selectCurrency {
        border: 0.5px solid rgb(197, 197, 197);
        width: 150px;
        height: 30px;
        background: transparent;
        margin-bottom: 20px;
        margin-left: 0px;
      }
      .contenedorPresupuesto .contenedorPresupuesto_input {
        border: 0.5px solid rgb(197, 197, 197);
        width: 100%;
        height: 41px;
        margin-left: 0px;
      }
      .contenedorOportunityInfo {
        align-items: center;
        margin-left: 0;
      }
      .contenedorOportunityInfo .contenedorOportunityInfo_title {
        margin-left: 5px;
      }
      .contenedorOportunityInfo .contenedorOportunityInfo_input {
        border: 0.5px solid #C5C5C5;
        width: 95%;
        height: 41px;
        margin-left: 0px;
      }

      .contenedorOportunityInfo .contenedorOportunityInfo_TextArea {
        border: 0.5px solid #C5C5C5;
        width: 95%;
        height: 170px;
        margin-left: 10px;
      }

      .contenedorSetAnonimo .contenedorSetAnonimo_title {
        margin-left: 0;
        width: 100%;
      }

      .contenedorExternal_input {
        border: 0.5px solid #C5C5C5;
        width: 95%;
        height: 41px;
        margin-left: 0px;
      }

      .contenedorHabilidad {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 0px;
      }
      .css-185td0z ,.css-13arzbu, .css-1a94s3b{
        height:auto !important;
        padding:2em !important;
      }
      .stepCounter {
        margin-left: 10px;
        margin-right: 10px;
      }
      .button_siguiente_create {
        margin-bottom: 10px;
        width: 200px;
        height: 35px;
      }
      .button_siguiente_create_B {
        margin-bottom: 10px;
        width: 200px;
        height: 35px;
      }
      .button_siguiente_create_C {
        display: none;
      }
    }