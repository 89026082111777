.Container-Card-Oportunidad{
  width: 324px;
  min-height: 520px;
  background: #202256;
  box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.Subcontainer-Card-Oportunidad{
  display: flex;
  flex-direction: column;
  height: 60%;
  justify-content: space-between;
  padding: 2em;  
}

.Title-Card-Oportunidad{
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  display: flex;
  align-items: center;
}

.Title-Card-Oportunidad span{
  margin-left: 0.2em;
}

.List-Habilidades-Info{ 
  font-size: 14px;
  color: #FFF;  
  list-style: disc;
  list-style-position: inside;
  margin-left: 2em;
}

.Text-Habilidades-Info{
  font-size: 14px;
  color: #FFF;  
  list-style: disc;
  list-style-position: inside;
  margin-left: 3em;
  margin-bottom: 0;
}

.H-erre{
  border-top: 2px solid #ffffff;
  margin: 14px 0px;
}