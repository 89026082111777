.img-demo-content{
    height: 544px;
    width: 1315px;
    margin-bottom: 2.5em;
}

.carrusel-demo{
    justify-content: center;
    text-align: center;
    text-align: center;
    background-color: white;
}
  
.swiper-pagination-bullet {
    width: 30px !important;
    height: 30px !important;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    color: white !important;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
}
  
  .swiper-pagination-bullet-active {
    color: #fff !important;
    background: #8C00DF !important;
  }
  

  @media (max-width: 700) {
    .img-demo-content{
        height: 100px;
        width: 248px;
        margin-bottom: 3em;
    }
  
  }

  @media (max-width: 500) {
    .img-demo-content{
        height: 100px;
        width: 248px;
        margin-bottom: 3em;
    }
  
  }

  @media (max-width: 450) {
    .img-demo-content{
        height: 100px;
        width: 248px;
        margin-bottom: 3em;
    }
  
  }