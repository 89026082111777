.MuiAppBar-colorPrimary {
    color: #fff;
    background-color: #202256 !important;
}

.header__display-menu_admin{
    display: none;
    margin-left: -120px;
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: 0px 2px 10px #00000029;
    padding: 5px;
    background-color: white;
    position: absolute;
    width: 220px;
    z-index: 100;
    min-width: 170px;
    font-size: 13px;
}