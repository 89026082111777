.cardHabilidades-Container{
    width: 100%;
    height: 71px;
    baclground-color: #fff;
    box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.cardHabilidades-SubContainer{
    display: flex;
    align-items: center;
    margin-left: 20px;
    width: 100%;
    justify-content: space-between;
}

.cardHabilidades-title{
    font-size: 20px;
    font-weight: 700;
    color: #393939;
    width: 100%;
    margin:0;
}

.cardHabilidades-cant{
    font-size: 14px;   
    color: #5D5D5D;    
}

.cardHabilidades-button{
    width: 190px;
    height: 40px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;    
}

@media (max-width: 768px){
    .cardHabilidades-SubContainer{       
        align-items: flex-start;
    }
    .cardHabilidades-title{
        font-size: 16px;
        
    }
    .cardHabilidades-SubContainer{
        margin-left: 10px;
    }
    .cardHabilidades-button{
        width:70px;
        font-size: 12px;
        align-items: flex-end;
    }
    .cardHabilidades-button svg{
        display:none;
    }
}