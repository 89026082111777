.CardOportunidadProfesional-Container {
    background: #FFFFFF;
    width: 100%;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    height: 144px;
    margin-bottom: 22px;
}

.CardOportunidadProfesional-Image {
    object-fit: cover;
    height: 144px;
    width: 144px;
    border-radius: 8px 0 0 8px;
}

.CardOportunidadProfesional-Sub-Container {
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
}

.CardOportunidadProfesional-Button {
    height: 30px;
    width: 140px;
    background: transparent;
    border: 1px solid #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #202256;
    transition: all 0.3s ease;
}
.CardOportunidadProfesional-Button:hover {
    background: #202256;
    color: #FFFFFF;
    transition: all 0.3s ease;
}

.CardOportunidadProfesional-Button-applied{
    height: 30px;
    width: 140px;
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    transition: all 0.3s ease;
}

.CardOportunidadProfesional-Button-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    width: 140px;
    background: transparent;
    border: none;
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.CardOportunidadProfesional-Button-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}

.CardOportunidadProfesional-Salario {
    color: #0B27E6;
    font-weight: 700;
    font-size: 14px;
    margin-top: 10px;
}

.CardOportunidadProfesional-Titulo {
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.CardOportunidadProfesional-Descripcion {
    font-weight: 300;
    font-size: 14px;
    color: #5D5D5D;
    margin: 0;
    padding: 0;
}

.CardOportunidadProfesional-Descripcion-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CardOportunidadProfesional-Data-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.CardOportunidadProfesional-Salario-Null {
    font-weight: 300;
    font-size: 14px;
    margin-top: 10px;
}

@media (max-width: 1000px) {
    .CardOportunidadProfesional-Descripcion {
        display: none;
    }
}

@media (max-width: 725px){
    .CardOportunidadProfesional-Container{
        height:auto;
    }
    .CardOportunidadProfesional-Sub-Container{
        display:revert;
    }
    .CardOportunidadProfesional-Button-Container{
       justify-content: normal;
        margin-bottom: 1em;
    }
    .CardOportunidadProfesional-Image{
        height: 100%;
    }
}