.Company-Test-Card-container {
    display: flex;
    background-color: #FFFFFF;
    border-radius: 16px;
    height: fit-content;
    min-width: 330px;
    max-width: 460px;
    margin-top: 22px;
    margin-right: 18px;
    padding: 17px 20px 8px 16px;    
}

.Company-Test-Card-container:hover {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25);
    border: 1px solid #202256;
    cursor: pointer;
}

.Company-Test-Card-Title {
    font-weight: 700;
    font-size: 14px;
    color: #393939;
}

.Company-Test-Card-P {
    font-weight: 300;
    font-size: 14px;
    color: #5D5D5D;
    margin-top: 10px;
}

.Company-Test-Card-sub-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
}
.Company-Test-Card-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    width: 90px;
    /*border-radius: 10px;*/
    /*background-color: #F2F2F2;*/
}
.Company-Test-Popup-button{
    background-color: transparent;
    height: 28px;
    width: 141px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    font-weight: 400;
    color: #202256;
}
.Company-Test-Popup-button:hover{
    background-color: #202256;
    color: #FFFFFF;
}