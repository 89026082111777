.container-card-plan{
  width: 321px;
  height: 131px;
  background: #202256;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.Subcontainer-CardPlan{
display: flex;
flex-direction: column;
height: 60%;
justify-content: space-between;
margin-left: 24px;
}

.Card-Title-Plan{
  font-weight: 700;
  font-size: 16px;
  color: #FFF;
}

.CardPlan-Button{
  background: #F07130;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  height: 35px;
  min-width: 170px;
}