.card-2contenedor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-radius: 10%;
    padding: 2rem;
    width: 90%;
}

.card-2cartas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sectioncards-card1-empresa {
    width: 100%;
    display: flex;
    grid-row-gap: 2rem;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    justify-content: space-evenly;
    display: flex;
}

.sectioncards-card2-empresa {
    display: grid;
    place-content: center;
    place-items: center;
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    width: 100%;
}

.sectioncards-card3-empresa {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    width: 100%;
    background-color: #e6e7e8;
}

.section-two-empresa {
    width: 100%;
    margin-top: 1rem;
    padding-right: 5rem;
    padding-bottom: 1rem;
}

.cards2 {
    width: 100%;
    height: 500px;
}

.section-two-empresa .card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-content: center;
    align-items: center;
    height: 95px;
    border-radius: 8px;
    transition: 400ms;
}

.card-body-2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 150%;
}

.card-body-content {
    margin-left: 2rem;
    width: 100%;
}

.card-body-content-title {
    font-size: 1.25rem;
    width: 100%;
    font-weight: 700;
    font-family: Poppins;
    color: #000;
    transition: 400ms;
}

.card-body-content-text {
    margin-bottom: 1rem;
    color: #5D5D5D;
    font-family: Poppins;
    font-weight: 300;
    transition: 400ms;
}

.card-body-content-text2 {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 1rem;
    color: #24D054;
    font-family: Poppins;
    font-weight: 300;
    transition: 400ms;
}

/*  hovers */
.section-two-empresa .card:hover {
    background-color: #202256;
    transition: 400ms;
}

.section-two-empresa .card:hover .card-body-content .card-body-content-title {
    color: white;
    transition: 400ms;
}

.section-two-empresa .card:hover .card-body-content .card-body-content-text {
    color: white;
    transition: 400ms;
}

.section-three-empresa {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

}

.section-three-empresa .cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.section-three-empresa .card {
    width: 20rem;
    padding: 1rem;
    border-radius: 10px;
    margin-right: 2rem;
    height: 100%;
}

.section-three-empresa .card .card-header {
    height: 5rem;
    margin-bottom: 1rem;
    background-color: #f3f3f3;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.section-three-empresa .card .card-body a {
    color: #c4c4c4;
}

.section-three-empresa .card .card-footer {
    margin-top: 1rem;
}

.section-three-empresa .card .card-footer button {
    font-size: 1rem;
    padding: 0.3rem 0.6rem;
    font-weight: 550;
    box-shadow: none;
    border: none;
}

.section-three-empresa .card2 {
    display: flex;
    background-color: #e6e7e8;
    border-radius: 10px;
    padding: 3rem 5rem;
}

.section-three-empresa .card2 .card-item-left {
    margin-right: 1rem;
}

.section-three-empresa .card2 .card-item-rigth {
    margin-left: 1rem;
}

.fuenteestadoproyecto {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 2.7vw;
    color: #393939;
}

.card-v2-op {
    display: flex;
    flex-direction: row;
    height: 89px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    margin-top: 31px;
    justify-content: space-between;
    align-items: center;
    padding-right: 31px;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
}

.card-v2-title {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    font-family: 'Poppins'
}

.card-v2-subtitle {
    font-size: 14px;
    font-weight: 300;
    color: #5D5D5D;
    font-family: 'Poppins'
}

.card-v2-resposive-applicant{
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 70px;
}

.card-v2-resposive-image{
    width: 214px;
    height: 144px;
    border-radius: 8px 0 0 8px;
    object-fit: cover;
}

.card-v2-subtitle-green {
    margin-left: 10px;
    padding-top: 15px;
    font-size: 14px;
    font-weight: 300;
    color: #24D054;
    font-family: 'Poppins'
}
.card-v2-detalle {
    font-size: 14px;
    font-weight: 400;
    color: #C5C5C5;
    font-family: 'Poppins'
}

.card-v2-op:hover {
    background-color: #202256;
    transition: 400ms;
    margin-left: 25px;
    color: white;
}

.card-v2-op:hover .card-v2-title {
    color: white;
    transition: 400ms;
}

.card-v2-op:hover .card-v2-subtitle {
    color: white;
    transition: 400ms;
}

.card-v2-espera:hover .card-v2-detalle {
    color: white;
    transition: 400ms;
}
.card-v2-op-div{
    flex-direction: row;
    display: flex;
}
.card-v2-op-img{    
    width: 124px;
    height: 89px;
    object-fit: cover;
    border-radius: 8px 0px 0px 8px;
}
.input-card-v2-op{    
    color: black;
    padding-left: 2.5rem;
    background-color: transparent;
    width: 55vw;
    height: 35px;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(197, 197, 197);
    margin-bottom: 10px;
}
@media (max-width:625px){

    .card-v2-resposive-image{
        width: 114px;
        height: 144px;
        border-radius: 8px 0 0 8px;
        object-fit: cover;
    }

    .card-v2-resposive-applicant{
        display: none;
    }
    .card-v2-resposive-desc {
        display: none;
    }
    .card-v2-op{
        flex-direction: column;
        height: max-content;
        padding-right:0
    }
    .card-v2-op-div{
        flex-direction: column !important;
        height: max-content;
        width: 100%;
    }
    .card-v2-op-img{
        width: 100%;
    }
    .fuenteestadoproyecto {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 2.7vw;
        color: #393939;
    }
    .font-top{
        font-size: 20px;
    }
}