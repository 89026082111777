.perfil-prof-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 80px;
}

.perfil-prof-titulo {
    margin-left: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #202256;
}

.perfil-prof-subtitulo {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
    margin-top: 40px;
}

.perfil-prof-footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
    place-content: center;
    grid-gap: 30px;
    margin-top: 32px;
    width: 100%;
}

.perfil-prof-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
}

.perfil-prof-body-izq {
    width: 24%;
    min-height: 10em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.perfil-prof-body-der {
    width: 74%;
}

.perfil-prof-nombre {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
}

.perfil-prof-empleo {
    font-weight: 400;
    font-size: 24px;
    color: #5D5D5D;
}

.perfil-prof-sub-cont {
    margin-top: 30px;
    background: #FFFFFF;
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.04);
    border-radius: 24px;
    padding: 30px 60px 45px 60px;
}

.perfil-prof-sub-subtitulo {
    font-size: 16px;
    font-weight: 700;
    color: #393939;
    margin-bottom: 20px;
}

.perfil-prof-textos {
    text-align: justify;
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.perfil-prof-sub-subtitulo-edu-trabajo {
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.perfil-prof-sub-subtitulo-edu-trabajo2 {
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.perfil-prof-sub-subtitulo-edu-trabajo3 {
    font-weight: 400;
    font-size: 14px;
    color: #C5C5C5;
}

.perfil-edu-container {

}

.perfil-trab-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.perfil-cv-input-contenedor-global {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: 30px;
}

.perfil-cv-advise {
    font-weight: 400;
    font-size: 12px;
    color: #5D5D5D;
}

.perfil-cv-advise2 {
    font-weight: 700;
    font-size: 12px;
    color: #202256;
}

.perfil-cv-button-group {
    display: flex;
    /*width: 600px;*/
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}

.perfil-cv-subir {
    margin: 5px;
    background: #202256;
    border-radius: 5px;
    height: 30px;
    width: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.perfil-cv-cancel {
    margin: 5px;
    background: #D6D6D6;
    border-radius: 5px;
    height: 30px;
    width: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
}

.perfil-cv-input-title {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}
.perfil-cv-input-title2 {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-top:10px;
}

.perfil-cv-input-subtitle {
    font-weight: 400;
    font-size: 16px;
    color: #A5A5A5;
}

.perfil-cv-input-contenedor {
    cursor: pointer;
    width: 265px;
    height: 85px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: #E6E6E6;
    border: 1px dashed #989898;
    border-radius: 14px;
    margin-right: 30px;
}

.perfil-cv-input-subcontenedor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #202256;
    border-radius: 14px;
    width: 55px;
    height: 55px;
}


.perfil-cv-input-contenedor2 {
    width: 265px;
    height: 85px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: transparent;
    border: 1px solid #202256;
    border-radius: 14px;
}

.perfil-cv-input-subtitle2 {
    font-weight: 400;
    font-size: 16px;
    color: #202256;
}

.perfil-cv-input-subcontenedor2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F7F2FF;
    border-radius: 14px;
    width: 55px;
    height: 55px;
}


@media (max-width: 699px) {
    .perfil-cv-button-group {
        display: flex;
        width: 100%;
        height: 70px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }

    .perfil-cv-subir {
        background: #202256;
        border-radius: 5px;
        height: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
    }

    .perfil-cv-cancel {
        background: #D6D6D6;
        border-radius: 5px;
        height: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
        color: #5D5D5D;
    }
}

@media (max-width: 500px) {
    .perfil-cv-input-subcontenedor {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #202256;
        border-radius: 14px;
        width: 55px;
        height: 55px;
        margin-right: 20px;
    }
    .perfil-trab-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}

@media (max-width: 1550px) {
    .perfil-prof-body-izq {
        width: 38%;
        min-height: 10em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .perfil-prof-body-der {
        width: 58%;
    }
    .perfil-cv-input-contenedor-global {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .perfil-cv-input-contenedor {
        cursor: pointer;
        width: 265px;
        height: 85px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: #E6E6E6;
        border: 1px dashed #989898;
        border-radius: 14px;
        margin-right: 0px
    }

    .perfil-cv-input-contenedor2 {
        width: 265px;
        height: 85px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: transparent;
        border: 1px solid #202256;
        border-radius: 14px;
    }
}

@media screen and (max-width: 950px) {
    .perfil-prof-body-izq {
        width: 100%;
        min-height: 10em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .perfil-prof-body-der {
        width: 100%;
    }

    .perfil-prof-body {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
    }

    .perfil-prof-body-izq {
        margin-left: -.5em;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
    }

    .perfil-cv-input-contenedor {
        cursor: pointer;
        width: 100%;
        height: 85px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: #E6E6E6;
        border: 1px dashed #989898;
        border-radius: 14px;
    }

    .perfil-cv-input-contenedor2 {
        width: 100%;
        height: 85px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: transparent;
        border: 1px solid #202256;
        border-radius: 14px;
    }

}

