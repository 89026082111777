.body-card-oportunidad {
    background-color: white;
    width: 100%;
    height: 150px;
    border-radius: 8px;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    margin: 8px;
    display: flex;

}

.img-card-oportunidad {
    border-radius: 8px 0 0 8px;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.titulo-card-oportunidad {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}

.descripcion-card-oportunidad {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #5D5D5D;
}

.boton-vermas-card-oportunidad {
    background-color: transparent;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    color: #C5C5C5;
}

.boton-vermas-card-oportunidad:hover {
    color: #151515;
}

.boton-editar-card-oportunidad:hover {
    cursor: pointer;
}

@media (max-width:625px){
    .descripcion-card-oportunidad{
        display: none;
    }
    .applicant-company-card-home{
        display: none;
    }
}
