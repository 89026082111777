.Container-CardDescripcion{
  background-color: #fff;
  width: 100%;
  height: 229px;
  margin-top: 32px;
  border-radius: 24px;
  display: flex;
  align-items: center;
}

.SubContainer-CardDescripcion{
  margin-inline: 61px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 60%;
}

.Title-Card-CardDescripcion{
  font-size: 16px;
  font-weight: 700;
  color: #393939;
}

@media (max-width: 768px) {
  .Container-CardDescripcion{
    height: 100%; 
  }

  .SubContainer-CardDescripcion{
    margin-inline: 0;
    height: 100%;
    padding: 1em;
  }
}


