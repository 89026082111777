.input-login-new input{
  
  background: #F5F5F5;
  font-style: normal;
  font-weight: 400;
  width: 348px;
  height: 41px;
  padding-left: 22px;
  font-size: 16px;
  border: 1px solid #C5C5C5;
  
}
.input-login-new input::placeholder{
  color: #C5C5C5;
}
.input-login-new input:focus{
  color: #636161;
  padding-left: 22px;
  outline: 0.5px solid #E7BF00;
}

.login-forgot-pass{
  color: #E7BF00;
  font-size: 14px;
  font-weight: 400;
  margin-top: 2%;
  cursor: pointer;
}
.login-forgot-pass:hover{
  color: #D4B000;
}
.login-button-new{
  background: #202256;
  border-radius: 5px;
  width: 347px;
  height: 33px;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: none;
  margin-left: -1.2em;
}
.login-register-new{
  color: #202256;
  font-size: 14px;
  font-weight: bold;
  margin-top: 2%;
  margin-left: 0.2em;
  cursor: pointer;
  text-align: center;
}
.login-register-new:hover{
  color: #0B27E6;
}
 

.wave2{
  z-index: 1;

  width: 100%;
}


.wavess{
  position: absolute;
bottom: 0;
width: 100%;

 
}
.wave1{
margin-top: -12em;

  z-index: 2;
  width: 100%;
} 
.foto-creativity{
  position: absolute;
  width: 235px;
height: 235px;
left: 8em;
top: 8em;
background-color: #0B27E6;
}

.foto-imgcodigo{
  position: absolute;
  width: 235px;
height: 235px;
right: 8em;
bottom: 1em;
background-color: #D4B000;
}
