.CircularProfile{
    
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content: center;
}

.non-circle{
    display:none;
}

.CircularProfile span{
    color:#f07130;
    width: 100% !important;
    margin-left: -6.1em;
    
}

#SecondCirclie{
    color:#dfdfdf;
    position:absolute;
    width: 8.7em !important;
}

.Avatar-container{
    width:6.5em;
    height: 6.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position:absolute;
   
    border-radius:50%;
    margin-left:-.5em;

}

.Avatar-container img{
    width:100%;
    position:absolute;
    background-color: #f07130;
    
}

/* Icon Nav Styles */



.CircularProfile-nab{

width:100%;
height: 100%;
display:flex;
align-items: center;
justify-content: center;
}

.CircularProfile-nab span{
    color:#f07130;
    width: 72px !important;
    margin-left: -2em;
    
    
}

#SecondCirclie-nab{
    color:#dfdfdf;
    position:absolute;
    
}

.Avatar-container-nav{
    width:56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position:absolute;
    /* border:1px solid black; */
    border-radius:50%;
    margin-left:-1px;
}

.Avatar-container-nav img{
    width:100%;
    position:absolute;

}