/**/
.profile-empresa-global-cont {
    margin-left: calc(237px + 98px);
    margin-right: 20px;
}

.profile-title-cont {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
}

.subcont-profile-empresa {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
}

.cont-card-info {
    width: 50%;
}

.profle-empresa-infoprincipal-cont {
    display: flex;
    /*align-items: center;*/
    justify-content: space-between;
    flex-direction: row;
}

.subcont-profile-empresa-summary {
    margin-left: 40px;
    margin-right: 50px;
}

/**/

@media (max-width: 1300px) {
    .profile-empresa-global-cont {
        margin-left: 20px;
    }
}


@media (max-width: 725px) {
    .subcont-profile-empresa {
        flex-direction: column;
        align-items:center;
    }

    .cont-card-info {
        width: 100%;
    }
    .container-infoCompany{
        margin-right: 0px !important;
    }
}

@media (max-width: 620px) {
    .profle-empresa-infoprincipal-cont {
        flex-direction: column;
    }

    .subcont-profile-empresa-summary {
        margin-left: 0px;
        margin-right: 0px;
    }
    .resp-image {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 35px;
    }
}