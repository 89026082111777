.bootcamp-home-administracion-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0 50px 5% 50px;
}

.bootcamp-home-administracion-banner{
    width: 100%;
    height: 150px;
    padding: 10px;
    border-radius: 8px;
    /* background-image: url(https://coally.com/wp-content/uploads/2022/03/banners-landing-1-2.png); */
    background-image: url(../../../assets/imagenes/businessbanner.png);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: white;
    text-align: center;
    flex-direction: row;
}

.bootcamp-home-administracion-description-card{
    text-align: center;
    display: flex;
    align-self: flex-start;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 2%;
}

.bootcamp-home-administracion-videos-cards{
    display: grid;
    margin-bottom: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
    width: 100%;
    place-content: center;
    grid-gap: 20px;
    justify-items: center;
    align-items: center;
    padding-top: 2%;
}

.bootcamp-home-administracion-cursos-buttons{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    margin-top: 2%;
}

.bootcamp-home-administracion-button-courses{
    width: 150px;
    height: 33px;
    border-radius: 10px;
    background-color: #202256;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    transition: all 0.3s ease;
}

.bootcamp-home-welcome-video{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.bootcamp-home-administracion-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;    
}

/*de aca para abajo es mio*/

.bootcamp-home-administracion-titles{
    font-weight: 700;
    font-size: 24px;
    display: flex;
    align-items: flex-end;
    color: #393939;
}

.bootcamp-home-administracion-desc{
    font-weight: 400;
    font-size: 14px;
    color: #C5C5C5;
}

.bootcamp-home-administracion-home-subcont1{
    margin-top: 65px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.bootcamp-home-administracion-home-subcont1-1{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    margin: 0 10px;
}

.bootcamp-home-administracion-footer{
    padding: 10px;
    width: 100%;
    height: fit-content;
    margin: 45px 0;
    background: #FFFFFF;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

@media screen and (max-width: 1681px) {
    .bootcamp-home-administracion-home-subcont1{
        flex-direction: column;
    }
    .bootcamp-home-administracion-home-subcont1-1{
        width: 100%;
        margin-bottom: 20px;
    }
}


@media screen and (max-width: 1300px) {
    .bootcamp-home-administracion-container{
        padding: 0;
    }
}

@media screen and (max-width: 1000px) {
    .bootcamp-home-administracion-description-card{
        flex-direction: column;
    }
}

@media screen and (max-width: 450px) {
    .bootcamp-home-administracion-banner{
        justify-content: center;
    }
    .bootcamp-home-administracion-buttons{
        width: 95%;
    }
}