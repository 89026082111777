.contenedorcompanynewcreate{
    width: 98vw;
    max-width: 772px; 
    position: relative; 
    z-index: 3;
}
.steppernewproject{
    display: initial;
}

@media (max-width:767px){
    .steppernewproject{
        display: none;
    }
    .create-company-form{
            height: 90% !important;
    }
}