.step-global-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.step-mini-sub-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    width: 150px;
}

.step-sub-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    margin-left: 10px;
    margin-right: 10px;
}

.title-active {
    font-weight: 400;
    font-size: 12px;
    color: #202256;
    margin-top: 13px;
}

.title-inactive {
    font-weight: 400;
    font-size: 12px;
    color: #C5C5C5;
    margin-top: 13px;
}

.step-circle-inactive {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #C5C5C5;
}

.step-circle-active {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #202256;
}

.step-big-circle-active {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #202256;
    display: flex;
    align-items: center;
    justify-content: center;
}

.step-big-circle-inactive {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #C5C5C5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.step-inner-circle-active {
    width: 20px;
    height: 20px;
    background: #202256;
    border-radius: 50%;
}

.step-inner-circle-inactive {
    width: 20px;
    height: 20px;
    background: #C5C5C5;
    border-radius: 50%;
}

@media  (min-height: 420px) {
    .step-global-cont {
        margin-top: 20px;
    }
}