.userprojects{
    width: 85%;
    margin: 0 auto;
    padding-bottom: 35px;
}

.userprojects__buttons{
    display: flex;
    justify-content: center;
    padding: 5px;
    border: none;
    margin-bottom: 1rem;
}

.userprojects__buttons-btn{
    background-color: #212156 !important;
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    font-size: 16px;
    padding: 10px;
}
.userprojects__buttons-btn:hover{
    background-color: white !important;
    color: #212156 !important;
    cursor: pointer;
    border-bottom: 5px solid #212156;
    font-weight: 700;
}
.userprojects__buttons-btn:focus{
    background-color: white !important;
    color: #212156 !important;
    cursor: pointer;
    border-bottom: 5px solid #212156;
    font-weight: 700;
}
.userprojects__buttons-btn:active{
    background-color: white !important;
    color: #212156 !important;
    cursor: pointer;
    border-bottom: 5px solid #212156;
    font-weight: 700;
}
.userprojects__buttons-btn2{
    background-color: #f07130;
       color: white;
    border: 1px solid white;
    border-radius: 10px;
    font-size: 16px;
    padding: 10px;
}
.userprojects__buttons-btn2:hover{
    background-color: rgb(240, 240, 240) !important;
    color: #212156 !important;
    cursor: pointer;
    border-bottom: 5px solid #212156;
    font-weight: 700;
}
.userprojects__buttons-btn2:focus{
    background-color: rgb(240, 240, 240) !important;
    color: #212156 !important;
    cursor: pointer;
    border-bottom: 5px solid #212156;
    font-weight: 700;
}
.userprojects__buttons-btn2:active{
    background-color: rgb(240, 240, 240) !important;
    color: #212156 !important;
    cursor: pointer;
    border-bottom: 5px solid #212156;
    font-weight: 700;
}
.userprojects__list{
    
    margin-top: 15px;
}

.login_form_button{
    background-color: #F46036;
    color: white;
    padding: 5px 7px 5px 7px;
    border-radius: 5px;
    margin-top: 20px;
}

.n-projects{
    width: 100%;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.volver-legal{
    background-color: #212156 !important;
}

.hr_projects{
    height: 1px;
    background-color: rgb(185, 185, 185);
    width: 100%;
    position: relative;
    margin-top: -15px;
}

