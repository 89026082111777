.sectioncards-card1-home {
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2rem;
}

.section-three-home {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.section-one {
    display: flex;
    justify-content: space-between;
}

.input-contenedor {
    background-color: #f6f6f5;
    border-radius: 10px;
    padding: 3px 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 1rem;
}

.input {
    border: none;
    border-style: none;
    box-shadow: none;
    flex: 1;
    padding: 10px;
    background: transparent;
    outline: none;
    font-size: 1rem;
    color: #c4c4c4;
}

.section-two {
    display: flex;
    margin-top: 1rem;
}

.section-two p {
    color: #c4c4c4;
    font-size: 1rem;
    padding: 0;
    margin: 0 0.2rem;
}

.section-two p:last-child {
    color: black;
}


.section-three-home .cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.section-three-home .card {
    width: 20rem;
    box-shadow: -2px 2px 7px -3px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -2px 2px 7px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -2px 2px 7px -3px rgba(0, 0, 0, 0.75);
    padding: 1rem;
    border-radius: 10px;
    margin-right: 2rem;
    height: 100%;
}

.section-three-home .card .card-header {
    height: 5rem;
    margin-bottom: 1rem;
    background-color: #f3f3f3;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.section-three-home .card .card-body a {
    color: #c4c4c4;
}

.section-three-home .card .card-footer {
    margin-top: 1rem;
}

.section-three-home .card .card-footer button {
    font-size: 1rem;
    padding: 0.3rem 0.6rem;
    font-weight: 550;
    box-shadow: none;
    border: none;
}

.section-three-home .card2 {
    display: flex;
    background-color: #e6e7e8;
    border-radius: 10px;
    padding: 3rem 5rem;
}

.section-three-home .card2 .card-item-left {
    margin-right: 1rem;
}

.section-three-home .card2 .card-item-rigth {
    margin-left: 1rem;
}

.section-four {
    margin-top: 2rem;
}

.section-four .tab-header {
    border-bottom: 1px solid #52575c;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section-four .tab-header a {
    text-decoration: none;
    cursor: pointer;
}

.section-four .tab {
    margin-right: 15rem;
}

.section-four .tab-header .tab:last-child {
    flex: 1;
}

.section-four .tab-active {
    border-bottom: 5px solid #52575c;
}

.section-four .tab-body {
    padding: 1rem;
}

.section-four .tab-body .card-body {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    align-items: center;
    -webkit-box-shadow: -1px 0px 13px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 0px 13px -7px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 0px 13px -7px rgba(0, 0, 0, 0.75);
    margin-bottom: 1rem;
}

.section-four .tab-body .card-body .card-item-one {
    height: 5rem;
    width: 5rem;
    background-color: #f3f3f3;
    margin-right: 1.5rem;
    border-radius: 10px;
}

.section-four .tab-body .card-body .card-item-two {
    flex: 1;
}

.section-four .tab-body .card-body .card-item-three {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13%;
}

.section-four .tab-body .card-body .card-item-three a {
    color: #c4c4c4;
}

.sectioncards-cards {
    display: flex;
    width: 100%;
}

.card2 {
    width: 25%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.contenedor-oportunidad {
    display: flex;
    z-index: 100;
}

.input {
    margin-top: 0 !important;
}

.contenedor-formacion-oportunidades {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start

}

.contenedor-oportunidades {
    width: 613px;
    margin-right: 33px;
}

.card-contenedor {
    display: flex;
    flex-direction: row;
    background-color: white;
    height: 144px;
    width: 100%;
    margin-bottom: 21px;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 8px;
}

@media (max-width: 1100px) {
    .contenedor-formacion-oportunidades {
        flex-direction: column-reverse;
        align-items: center

    }

    .contenedor-oportunidades {
        width: 90vw;
        margin-right: 0;
    }
}

.card-contenedor-div-img {
    display: flex;
}

.card-contenedor-img-img {
    object-fit: cover;
    border-radius: 8px;
    width: 140px;
    height: 144px;
}

.card-contenedor-habilidades {
    height: 48px;
}

.card-contenedor-botones {
    display: flex;
    margin-top: -2%;
    width: 100%;
    flex-direction: row-reverse;
}

.videos-contenedor {
    margin-top: 2rem;
    gap: 10px;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-evenly;
}

.card-formacion-contenedor {
    width: 360px;
    height: 407px;
    max-width: 360px;
    max-height: 407px;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    margin-bottom: 51px;
}

.selector-home-consultor-style {
    background-color: transparent;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    height: 35px;
    border-color: #C5C5C5;
    width: 33.33%;
    margin-bottom: 10px;
}

.selector-home-consultor-style2 {
    background-color: transparent;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    height: 35px;
    border-color: #C5C5C5;
    width: 40%;
    margin-bottom: 10px;
}

.input-home-consultor-style {
    color: black;
    padding-left: 10%;
    background-color: transparent;
    height: 35px;
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: #C5C5C5;
    margin-bottom: 10px;
}

.searchabar-style {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 725px) {
    .selector-home-consultor-style {
        width: 100%;
    }
    .selector-home-consultor-style2 {
        width: 100%;
    }

    .input-home-consultor-style {
        width: 100%;
    }

    .card-contenedor {
        flex-direction: column;
        padding: 0px 10px 10px 10px;
        height: 100%;
    }

    .searchabar-style {
        flex-direction: column;
    }

    .card-contenedor-div-img {
        justify-content: center;
    }

    .card-contenedor-img-img {
        width: 100%;
    }

    .card-contenedor-habilidades {
        height: 100%;
    }

    .tertiary-button-card {
        margin-right: 0 !important
    }

    .card-contenedor-botones {
        justify-content: space-around;
    }

    .paginateStyle li {
        padding: 0 !important;
        margin: 0.4em !important;
    }

    .paginateStyle {
        width: 100% !important;
        display: flex !important;
        justify-content: space-between !important;
        padding: 0 !important;
        align-items:center;
    }

    .videos-contenedor {
        flex-direction: column;
        justify-content: space-evenly;
    }

    .videos-contenedor iframe {
        width: 87vw;
    }

    .card-formacion-contenedor {
        width: 100%;
    }
}