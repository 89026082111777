.UserInfo-global-container {
    background: #FFFFFF;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 5px;
   
}

.UserInfo-name {
    margin-top: 15px;
    font-weight: 700;
    font-size: 16px;
    color: #393939;
}

.UserInfo-separator {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 90%;
    height: 1px;
    background-color: #E5E5E5;
}

.UserInfo-button-pricing {
    color: #202256;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #202256;
    border-radius: 5px;
    width: 190px;
}
.UserInfo-button-pricing:hover{
    background: #202256;
    color: #FFFFFF;
}

.UserInfo-plan{
    font-weight: 700;
    font-size: 14px;
    color: #202256;
}

.UserInfo-image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 140px;
    
    border-radius: 50%;
    margin-bottom: 30px;
}
.UserInfo-image-container div div{
    
    margin-left:.1em;
}

.container-viewProfile{
    min-width: 105px;
    color: #202256;
    filter: grayscale(100%);   
}
.container-viewProfile:hover {   
   filter: grayscale(0%); 
   color: #202256; 
   font-weight: 500;
   stroke-width: 1.5px; 
}

.container-viewProfile svg{
    stroke: #202256;
}





a:hover{
    cursor:pointer;
}

@media (max-width: 425px) {
    .UserInfo-global-container {
        width: 100%;
    }
}




/* Card de consultor */

.container-card-data{
    
    padding: 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}


.Data_usuario{
   
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top:-1em;
    
    width:fit-content;
    padding: 5px;
}

.contenedor-iner-data{
    
    width:100%;
    margin-bottom:30px;
    margin-left:20px;
}

.nombre{
    font-weight: bold;
    margin-bottom:2em;
}

.TituloDesc{
 color:#3d3c60;
 font-weight: bold;
}

.TextoInterno{
color:#737373;
margin-top: -1em;
}

.Idioma{
    
    display:flex;
    align-items:center;
    justify-content: flex-start;
    
}

.Idioma p{
    margin-right: .5em;
    
}
.herre{
    color: #ececec;
    width:100%;
    /* margin-left:5%; */
}

.img-company-perfil{
    border-radius: 50%;
    width:138.94px;
    height: 138.94px;
    background-color: #f07130;
}



a:hover{
    cursor:pointer;
}

@media (max-width: 425px) {
    .UserInfo-global-container {
        width: 100%;
    }
}




/* Card de consultor */

.container-card-data{
    
    padding: 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}


.Data_usuario{
   
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top:-1em;
    
    width:fit-content;
    padding: 5px;
}

.contenedor-iner-data{
    
    width:100%;
    margin-bottom:30px;
    margin-left:20px;
}

.nombre{
    font-weight: bold;
    margin-bottom:2em;
}

.TituloDesc{
 color:#3d3c60;
 font-weight: bold;
}

.TextoInterno{
color:#737373;
margin-top: -1em;
}

.Idioma{
    
    display:flex;
    align-items:center;
    justify-content: flex-start;
    
}

.Idioma p{
    margin-right: .5em;
    
}
.herre{
    color: #ececec;
    width:100%;
    /* margin-left:5%; */
}

.img-company-perfil{
    border-radius: 50%;
    width:138.94px;
    height: 138.94px;
    background-color: #f07130;
}