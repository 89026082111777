.dashOp-global-cont {
    padding-right: 100px;
    padding-left: 337px;
}

.dashOp-cards-cont {
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
}

.dashOp-cont-info-emp {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 98px;
}

.dashOp-cont-info-emp-serv {
    margin-left: 75px;
    width: 50%;
}

.dashOp-empresa {
    color: #5D5D5D;
    font-weight: 700;
    font-size: 16px;
}

.dashOp-text-sub {
    color: #5D5D5D;
    font-weight: 400;
    font-size: 14px;
}

.dashOp-met-text {
    font-weight: 400;
    font-size: 14px;
    color: #B0B0B0;
    margin-bottom: 52px;
}

.dashOp-adq-button {
    margin-top: 45px;
    width: 100%;
    background: #202256;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 5px;
    color: #FFFFFF;
}

.dashOp-met-button {
    border: 1px solid #D6D6D6;
    background: transparent;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #D6D6D6;
    padding-right: 25px;
    padding-left: 25px;
    margin-bottom: 23px;
    margin-top: 17px;
}

.dashOp-salary {
    margin-top: 25px;
    color: #0B27E6;
    font-weight: 700;
    font-size: 16px;
}

.dashOp-solicitudes {
    color: #F07130;
    font-weight: 400;
    font-size: 14px;
}

.dashOp-time {
    color: #B0B0B0;
    font-weight: 400;
    font-size: 14px;
}


.dashOp-subtitle {
    font-weight: 700;
    font-size: 24px;
    color: #393939;
    margin-top: 35px;
}

.perfiles-de-prof-similares {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(330px, 2fr));
    justify-items: start;
}

.dashOp-btn-ver-mas {
    margin-top: 25px;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #5D5D5D;
}

.dashOp-title {
    font-weight: 700;
    font-size: 40px;
    color: #202256;
}

.dasOp-card {
    background: #FFFFFF;
    border-radius: 8px;
    /*width: 535px;*/
    /*height: 283px;*/
    width: 100%;
    padding: 33px 42px 16px 31px;
    margin-top: 22px;
    margin-bottom: 22px;
    margin-right: 75px;
}

.dasOp-card-title {
    font-weight: 700;
    font-size: 14px;
    color: #5D5D5D;
}

.dasOp-card-desc {
    font-weight: 400;
    font-size: 14px;
    color: #5D5D5D;
    margin-top: 5px;
    list-style: disc;
    list-style-position: inside;
    word-break: break-all;
}
.legal-card-desc{
    font-size:14px;
    color: #5D5D5D;
    font-weight:600;
    margin-top: 5px;
}
.legal-information-company{
    text-transform: capitalize;
    margin-left: 5px;
    font-weight:400;
}

@media (max-width: 1024px) {
    .dashOp-cont-info-emp {
        flex-direction: column;
    }

    .dashOp-cont-info-emp-serv {
        margin-left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .dasOp-card {
        width: 100%;
        margin-right: 0;
        padding: 10px;
    }
}


@media (max-width: 1024px) {
    .dashOp-cards-cont {
        flex-direction: row;
        padding: 0;
    }
}

@media (max-width: 768px) {
    .dashOp-cards-cont {
        flex-direction: column;
        padding: 0;
    }
}


@media (max-width: 1300px) {
    .dashOp-global-cont {
        padding-right: 50px;
        padding-left: 50px;
    }
}
