.card-grupo-preguntas {
    background: #FFFFFF;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px 20px;
}

.card-grupo-preguntas2 {
    background: #FFFFFF;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.input-crear-oportunidad {
    width: 100%;
    background: #FFFFFF;
    height: 40px;
    border: 1px solid #C5C5C5;
    text-indent: 5px;
}
.input-crear-oportunidad::placeholder {
    color: #C5C5C5;
}

.text-crear-oportunidad {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    min-height: 160px;
}

.item-crear-oportunidad {
    font-weight: 400;
    font-size: 14px;
    color: #151515;
}

.title-crear-oportunidad {
    font-weight: 700;
    font-size: 14px;
    color: #151515;
    margin-top: 10px;
    margin-bottom: 10px;
}

.contenedor-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 14px;
    color: #151515;
}

.submit-button-crear-oportunidad {
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    height: 40px;
    color: #FFFFFF;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
}

.Obligatorio {
    color: #da781d;
    font-weight: 600;
    font-size: 18px;
}

.banner-crear-oportunidad-st {
    font-weight: 600;
    font-size: 18px;
}

.new-empresa-home-banner{
    width: 100%;
    height: 158px;
    padding: 10px;
    border-radius: 8px 8px 0 0;
    /* background-image: url(https://coally.com/wp-content/uploads/2022/03/banners-landing-1-2.png); */
    background-image: url(../../assets/imagenes/BannerHome.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    text-shadow: black 1px 0 10px;
}

.Range-Ability-Form-Create{   
    margin-left: 10px;   
}

/**/

.control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 16px;
}
.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.control_indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #e6e6e6;
    border: 0px solid #000000;
    border-radius: undefinedpx;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: #cccccc;
}

.control input:checked ~ .control_indicator {
    background: #da781d;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: #0e6647d;
}
.control input:disabled ~ .control_indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: block;
}
.control-radio .control_indicator {
    border-radius: 50%;
}

.control-radio .control_indicator:after {
    left: 0px;
    top: 0px;
    height: 0px;
    width: 0px;
    border-radius: 50%;
    background: #ffffff;
    transition: background 250ms;
}
.control-radio input:disabled ~ .control_indicator:after {
    background: #7b7b7b;
}

.button-add-language{
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 40px;
    width: 170px;
}

.input-add-language{
    width: 25%;
    background: #FFFFFF;
    height: 40px;
    border: 1px solid #C5C5C5;
    text-indent: 5px;
}

.input-add-resp{
    width: 80%;
    background: #FFFFFF;
    height: 40px;
    border: 1px solid #C5C5C5;
    text-indent: 5px;
}

.button-add-resp{
    background: #F07130;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 40px;
    width: 170px;
}



@media (max-width: 768px) {
    .input-add-language{
        width: 50%;
    }
}

@media(max-width: 620px){
    .new-empresa-home-banner{
        height: 100% !important;
    }
}
