.Admin-Layout-Cont{
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background: #F5F5F5;
}
.Admin-Layout-Sub-Cont{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: #F5F5F5;
}