.NewCardOportunidad-Container-rectangular{
  background: #FFFFFF;
  width: 100%;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 175px;
  margin-bottom: 22px;
}
.NewCardOportunidad-Container-square{
  background: #FFFFFF;
  width: 350px;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column; 
  height: 350px;
  margin: 22px;
}


.NewCardOportunidad-Top-Container{
 width: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 margin-bottom: 15px;
 margin-top: 25px;
}
.NewCardOportunidad-Top-Container-rectangle{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.NewCardOportunidad-Top-Container-rectangle-inner-1{
  margin-left: 34px;
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.NewCardOportunidad-Top-Container-rectangle-inner-1-1{
  width: 100%;
  display: flex;
  height: 55px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  margin-top: 20px;
}

.NewCardOportunidad-Top-subContainer-1{
 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.NewCardOportunidad-Top-subContainer-2{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 60px;
}

.NewCardOportunidad-Top-Container-rectangle-inner-1-2{
  width: 100%;
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.NewCardOportunidad-Top-subContainer-3{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.NewCardOportunidad-Top-subContainer-4{
  width: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  margin-top: 8px;
  word-wrap: break-word;
  font-size: 14px;
  color: #5D5D5D;
  text-align: left;
  font-weight: 400;
  height: 50px;
}

.NewCardOportunidad-Top-Container-rectangle-inner-2{
  margin-right: 34px;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.NewCardOportunidad-Top-Container-rectangle-inner-2-1{
  margin-top: 20px;
}
.NewCardOportunidad-Top-Container-rectangle-inner-2-2{
  margin-bottom: 25px;
}

.NewCardOportunidad-mid-Container{
  width: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  align-items: center;
  margin-bottom: 38px;
  margin-top: 15px;
  margin-left: 25px;
}
.NewCardOportunidad-mid-Container-inner-1{
  width: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
}
.NewCardOportunidad-mid-Container-inner-2{
  width: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
  word-wrap: break-word;
  padding-right: 40px;
  font-size: 14px;
  color: #5D5D5D;
  text-align: left;
  font-weight: 400;
  height: 50px;
}

.NewCardOportunidad-mid-Container-inner-3{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
}
.NewCardOportunidad-mid-Container-inner-3-1{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.NewCardOportunidad-mid-Container-inner-3-2{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
}

.CardOportunidadProfesional-Button-new {
  height: 30px;
  width: 120px;
  background: transparent;
  border: 0.5px solid #202256;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #202256;
}
.CardOportunidadProfesional-Button-new:hover {
  background: #202256;
  color: #FFFFFF;
}

.text-card-status-Close{
  color:red;
  font-size: 14px;
  font-weight: 400;
}

.text-card-status-Open{
  color: #24D054;
  font-size: 14px;
  font-weight: 400;
}

.text-card-info2{
  font-size: 14px;
  font-weight: 400;
  color: #989898;
}

.text-card-info3{
  font-size: 14px;
  font-weight: 400;
  color: #C5C5C5;
  margin:0px;
}

.stop-info2{
  margin-left: 5px;
  margin-right: 5px;
  color: #989898
}

.container-info-apli-fecha{
    display: flex;
    justify-content: space-evenly;
    width: 50%;
}

.NewCardOportunidad-mid-Container-rectangle{
 width: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 margin-bottom: 18px;
 margin-top: 15px;
}

.NewCardOportunidad-Image{
  object-fit: cover;
  height: 55px;
  width: 60px;
  border-radius: 8px;
}

.NewCardOportunidad-Image-rectangle{
  object-fit: cover;
  height: 175px;
  width: 206px;
  border-radius: 8px;
}

@media (max-width: 725px) {
  .NewCardOportunidad-Container-square{
    margin: auto;
  }
}